import { Button, ButtonProps, styled } from "@mui/material";

const ButtonSubmit = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  fontWeight: 600,
  backgroundColor: "#9f1831",
  "&:hover": {
    backgroundColor: "#9f1831",
  },
  "&:disabled": {
    opacity: 0.5,
    color: "#fff",
  },
  "& .MuiButton-startIcon": {
    marginRight: 0,
  },
}));

export default ButtonSubmit;
