import React from "react";
import { Box } from "@mui/material";

interface ImagePreviewProps {
  img: string;
  width: number;
  height: number;
}
const ImagePreview: React.FC<ImagePreviewProps> = ({ img, width, height }) => {
  return (
    <Box
      sx={{
        marginY: 2,
        backgroundColor: "#9f1831",
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
      }}
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    ></Box>
  );
};

export default ImagePreview;
