import React from "react";
import { Grid, Typography } from "@mui/material";
import { InvoiceType } from "../../types";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { toastError, toastSuccess } from "../../utils/toaster";

interface BillingReceiptItemProps {
  item: InvoiceType;
}

const BillingReceiptItem: React.FC<BillingReceiptItemProps> = ({ item }) => {
  const [t] = useTranslation();

  const handleClick = () => {
    if (item?.invoicePdf) {
      toastSuccess("ファイルのダウンロードに成功しています");
      window.open(item?.invoicePdf, "_blank");
    } else toastError("申し訳ありませんが、リンクが見つかりません");
  };

  return (
    <Grid container spacing={1} sx={{ py: 1 }}>
      <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "0.6rem" }}>
          {moment.unix(item?.createdAt).format("YYYY/MM/DD")}
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "0.6rem" }}>
          <CurrencyFormat
            value={item?.total}
            displayType={"text"}
            thousandSeparator={true}
          />
          円
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: "0.6rem" }}>{t(item?.status)}</Typography>
      </Grid>
      <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
        <ButtonSubmit
          type="button"
          sx={{ fontSize: "0.6rem", fontWeight: 600 }}
          onClick={handleClick}
        >
          DL
          <OpenInNewIcon sx={{ fontSize: "0.8rem" }} />
        </ButtonSubmit>
      </Grid>
    </Grid>
  );
};

export default BillingReceiptItem;
