import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Grid, styled, Typography } from "@mui/material";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ErrorHandler from "../../components/ErrorHandler";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Initial = () => {
  const navigate = useNavigate();
  const { getContract } = useCommonApi();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    localStorage.removeItem("store_info");
  }, []);

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.contract, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getContract();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isError) return <ErrorHandler url="/" />;
  return (
    <Box sx={{ paddingX: 2 }}>
      {isLoading && <Loader />}
      <Paper sx={{ padding: 2, marginBottom: "50px" }}>
        <Box sx={{ backgroundColor: "#9f1831", padding: "5px 0 5px 0" }}>
          <Typography sx={{ color: "#fff", fontWeight: "600" }} align="center">
            ご契約の流れ
          </Typography>
        </Box>
        <Box sx={{ padding: "20px 0 20px 0" }}>
          <TypographyExt5
            sx={{ fontWeight: "600", fontSize: "15px" }}
            dangerouslySetInnerHTML={{ __html: data?.details?.contents }}
          ></TypographyExt5>
        </Box>
        <Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: "13px" }}
            align="center"
          >
            上記の内容を理解し、ご契約の手順に進みますか？<br></br>
            一度画面を閉じても、再表示もしくはログインしてこちらの画面に戻ることができます。
          </Typography>
          <Grid my={3} sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonSubmit
              sx={{ width: 0.9, fontWeight: "600" }}
              variant="contained"
              onClick={() => {
                navigate("/contract/customer-info");
              }}
            >
              次へ
            </ButtonSubmit>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};
const TypographyExt5 = styled(Typography)(({ theme }) => ({
  h2: {
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
  },
  blockquote: {
    backgroundColor: "#E4DFDE",
    margin: "10px",
    padding: "5px 20px",
  },
}));
export default Initial;
