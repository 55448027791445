import { Button, ButtonProps, styled } from "@mui/material";

const ButtonCustom = styled(Button)<ButtonProps>(({ theme }) => ({
  "&:disabled": {
    opacity: 0.5,
  },
  "& .MuiButton-startIcon": {
    marginRight: 0,
  },
}));

export default ButtonCustom;
