import { Fragment, useContext } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import Badge from "../../components/Badge";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreGroupSettingsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const { hasPermission } = usePermissions();
  const { getDeviceGroupDetail } = useDeviceApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.storeDeviceDetails, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getDeviceGroupDetail({
          customer_store_id: authCtx.user?.store_id,
          thing_group: id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isError)
    return (
      <Box
        sx={{
          height: `calc(100vh - 250px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            グループが見つかりません
          </Typography>
        </Box>
      </Box>
    );
  if (isLoading) return <Loader />;
  return data?.group ? (
    <ReloadAnimation>
      <Box sx={{ paddingX: 2 }}>
        <Grid pb={2}>
          <ButtonSubmit
            fullWidth
            type="button"
            onClick={() => {
              navigate(`/store/groups/${data?.group?.thingGroupId}/update`);
            }}
            disabled={!hasPermission("group.update")}
          >
            グループ変更する
          </ButtonSubmit>
        </Grid>
        <Paper sx={{ p: 2 }}>
          {/* <Grid container my={2}>
          <Grid item xs={6}>
            グループID
          </Grid>
          <Grid item xs={6}>
            <Typography>{data?.group?.thingGroupId || "なし"}</Typography>
          </Grid>
        </Grid> */}

          <Grid container my={2}>
            <Grid item xs={6}>
              グループ名
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ wordWrap: "break-word" }}>
                {data?.group?.thingGroupName || data?.group?.thingGroupId}
              </Typography>
            </Grid>
          </Grid>

          <Grid my={1}>
            <Grid>
              <Typography>グループの説明</Typography>
            </Grid>
            <Grid my={2}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  wordWrap: "break-word",
                }}
              >
                {data?.group?.thingGroupDescription || "なし"}
              </Typography>
            </Grid>
          </Grid>

          {data?.group?.thingsInGroup &&
            data?.group?.thingsInGroup.map((group: any, index: number) => (
              <Fragment key={index}>
                <Box
                  mx={0.5}
                  my={0.5}
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <Badge
                    text={group?.thingName}
                    color={group?.thingColors?.fontColor}
                    bgColor={group?.thingColors?.themeColor}
                  />
                </Box>
              </Fragment>
            ))}
        </Paper>
        {loading && <Loader />}
      </Box>
    </ReloadAnimation>
  ) : (
    <ReloadAnimation>
      <Box
        sx={{
          height: `calc(100vh - 300px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            グループが見つかりません
          </Typography>

          <Typography
            align="center"
            sx={{
              width: 1,
              marginX: "auto",
              marginY: 4,
              fontSize: "0.8rem",
            }}
          >
            再度この画面を表示し直してください。
          </Typography>
        </Box>
      </Box>
    </ReloadAnimation>
  );
};

export default StoreGroupSettingsDetail;
