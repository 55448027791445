import React, { useEffect, useState } from "react";
import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { TotalServedDrinkType } from "../../types";
import BottomRefresher from "./BottomRefresher";
import moment from "moment";

interface TotalServedDrinkInfoProps {
  data: TotalServedDrinkType;
  isError: boolean;
  isLoading: boolean;
  fetch: () => void;
}

const TotalServedDrinkInfo: React.FC<TotalServedDrinkInfoProps> = ({
  data,
  isError,
  isLoading,
  fetch,
}) => {
  const [dataDate, setDataDate] = useState<string>(
    moment().format("YYYY/MM/DD(ddd) HH:mm")
  );

  const onFetch = () => {
    setDataDate(moment().format("YYYY/MM/DD(ddd) HH:mm"));
    fetch();
  };

  return (
    <Paper sx={{ p: 2, pb: 0.5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ color: "#9f1831", fontWeight: 600 }}>
          抽出杯数
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignitems: "center",
            borderRadius: "20px",
            backgroundColor: "#9f1831",
            px: 1.5,
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: "0.8rem",
              fontWeight: 600,
              lineHeight: 1.8,
            }}
          >
            Daily
          </Typography>
        </Box>
      </Box>
      <Box>
        {!data || isLoading || isError ? (
          <Box>
            <Skeleton width="50px" height="72px" />
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{ fontWeight: 600, fontSize: "3rem" }}
              component="span"
            >
              {data?.count}
            </Typography>
          </Box>
        )}
      </Box>
      {!data || isLoading || isError ? (
        <Skeleton width="110px" height="34px" />
      ) : (
        <Box>
          <Typography
            sx={{ color: "#C3C3C3", fontWeight: 600, fontSize: "0.8rem" }}
            component="span"
          >
            {dataDate}時点
          </Typography>
        </Box>
      )}
      <BottomRefresher refetch={onFetch} />
    </Paper>
  );
};

export default TotalServedDrinkInfo;
