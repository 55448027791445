import { useContext } from "react";
import { PermissionName, permissions, ROLE } from "../permissions";
import AuthContext from "./use-auth-context";

export const usePermissions = () => {
  const authCtx = useContext(AuthContext);
  const role = authCtx.user?.contract?.type;
  // const role = ROLE.DEVICE_LIMIT; // それは、APIから取得するユーザーのロールです。

  const hasPermission = (permissionName: PermissionName) => {
    if (!role) return false;
    return permissions[permissionName].includes(role);
  };

  return { hasPermission };
};
