import { useCallback, useContext, useMemo } from "react";
import appDeviceApi from "../../apis/device-api";
import {
  CreateDeviceGroupBodyRequestType,
  CreateDeviceGroupRawParamsType,
  CustomerStoreIdType,
  EdgeCommandParam,
  ThingNameType,
  UpdateDeviceGroupBodyRequestType,
  UpdateDeviceRawParamsType,
  WifiSetupType,
} from "../../types";
import { cloneObjWithoutAttr } from "../../utils/helper";
import GlobalDataContext from "../use-global-data";

export const useDeviceApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const deviceApi = useMemo(
    () =>
      new appDeviceApi(
        process.env.REACT_APP_API_2 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const deviceApi2 = useMemo(
    () =>
      new appDeviceApi(
        process.env.REACT_APP_API_4 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const deviceApi3 = useMemo(
    () =>
      new appDeviceApi(
        process.env.REACT_APP_API_5 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const deviceApi4 = useMemo(
    () =>
      new appDeviceApi(
        process.env.REACT_APP_API_7 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const deviceApi5 = useMemo(
    () =>
      new appDeviceApi(
        process.env.REACT_APP_API_8 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getDevices = useCallback(
    async (params: any) => {
      loadingOp.start();
      const deviceList = await deviceApi.getDevices(params);
      loadingOp.finish();
      return deviceList;
    },
    [deviceApi]
  );

  const getDevice = useCallback(
    async (params: any) => {
      loadingOp.start();
      const response = await deviceApi.getDevice(params);
      loadingOp.finish();
      return response;
    },
    [deviceApi]
  );

  const updateDevice = useCallback(
    async (params: UpdateDeviceRawParamsType) => {
      loadingOp.start();

      let param: ThingNameType = {
        thing_name: params.thing_name,
      };

      let body: UpdateDeviceRawParamsType = cloneObjWithoutAttr(
        params,
        "thing_name"
      );

      const response = await deviceApi.updateDevice(param, body);
      loadingOp.finish();
      return response;
    },
    [deviceApi]
  );

  const getDeviceGroupList = useCallback(
    async (params: any) => {
      loadingOp.start();
      const deviceGroupList = await deviceApi2.getDeviceGroupList(params);
      loadingOp.finish();
      return deviceGroupList;
    },
    [deviceApi2]
  );

  const getDeviceGroupDetail = useCallback(
    async (params: any) => {
      loadingOp.start();
      const deviceGroupList = await deviceApi2.getDeviceGroupDetail(params);
      loadingOp.finish();
      return deviceGroupList;
    },
    [deviceApi2]
  );

  const createDeviceGroup = useCallback(
    async (params: CreateDeviceGroupRawParamsType) => {
      loadingOp.start();

      let param: CustomerStoreIdType = {
        customer_store_id: params.customer_store_id,
      };

      let body: CreateDeviceGroupBodyRequestType = cloneObjWithoutAttr(
        params,
        "customer_store_id"
      );

      const response = await deviceApi2.createDeviceGroup(param, body);
      loadingOp.finish();
      return response;
    },
    [deviceApi2]
  );

  const updateDeviceGroup = useCallback(
    async (params: UpdateDeviceGroupBodyRequestType) => {
      loadingOp.start();

      const response = await deviceApi2.updateDeviceGroup(params);
      loadingOp.finish();
      return response;
    },
    [deviceApi2]
  );

  const getStoreTerminalNames = useCallback(
    async (params: any) => {
      loadingOp.start();
      const storeTerminalNames = await deviceApi.getStoreTerminalNames(params);
      loadingOp.finish();
      return storeTerminalNames;
    },
    [deviceApi]
  );

  const getSakeList = useCallback(async () => {
    loadingOp.start();
    const deviceList = await deviceApi3.getSakeList();
    loadingOp.finish();
    return deviceList;
  }, [deviceApi3]);

  const setupWifi = useCallback(
    async (params: WifiSetupType) => {
      loadingOp.start();

      const response = await deviceApi5.setupWifi(params);
      loadingOp.finish();
      return response;
    },
    [deviceApi5]
  );

  const edgeCommand = useCallback(
    async (params: EdgeCommandParam) => {
      loadingOp.start();

      const response = await deviceApi4.edgeCommand(params);
      loadingOp.finish();
      return response;
    },
    [deviceApi4]
  );

  return {
    getDevices,
    getDevice,
    updateDevice,
    getDeviceGroupList,
    getStoreTerminalNames,
    updateDeviceGroup,
    createDeviceGroup,
    getSakeList,
    getDeviceGroupDetail,
    setupWifi,
    edgeCommand,
  };
};
