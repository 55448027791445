import { useCallback, useContext, useMemo } from "react";
import { CreateStore } from "../../types";
import appCustomerApi from "../../apis/customer-api";
import AuthContext from "../use-auth-context";
import GlobalDataContext from "../use-global-data";

export const useCustomerApi = () => {
  const authCtx = useContext(AuthContext);
  const { loadingOp } = useContext(GlobalDataContext);

  const customerApi = useMemo(
    () =>
      new appCustomerApi(
        process.env.REACT_APP_API_6 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );
  const getStatus = useCallback(async () => {
    loadingOp.start();
    const response = await customerApi.getStatus();

    authCtx.setUserContract({
      type: response.constract_type,
      status: response.status,
    });
    loadingOp.finish();
    return response;
  }, [customerApi, authCtx?.user]);

  const getPlans = useCallback(async () => {
    loadingOp.start();
    const ticketList = await customerApi.getPlans();
    loadingOp.finish();
    return ticketList;
  }, [customerApi]);

  const checkStock = useCallback(
    async (params: any) => {
      loadingOp.start();
      const ticketList = await customerApi.checkStock(params);
      loadingOp.finish();
      return ticketList;
    },
    [customerApi]
  );

  const createStore = useCallback(
    async (params: CreateStore) => {
      loadingOp.start();
      const response = await customerApi.createStore(params);
      loadingOp.finish();
      return response;
    },
    [customerApi]
  );

  return { getStatus, getPlans, checkStock, createStore };
};
