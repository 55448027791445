import { useCallback, useContext, useMemo } from "react";
import { PostContactBodyRequestType } from "../../types";
import GlobalDataContext from "../use-global-data";
import appCommonApi from "../../apis/common-api";

export const useCommonApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);
  const commonApi = useMemo(() => new appCommonApi(), []);

  const postContact = useCallback(
    async (params: PostContactBodyRequestType) => {
      loadingOp.start();
      const response = await commonApi.postContact(params);
      loadingOp.finish();
      return response;
    },
    [commonApi]
  );

  const getNotifications = useCallback(async () => {
    loadingOp.start();
    const notifications = await commonApi.getNotifications();
    loadingOp.finish();
    return notifications;
  }, [commonApi]);

  const getNotification = useCallback(
    async (topics_id: string) => {
      loadingOp.start();
      const notification = await commonApi.getNotification(topics_id);
      loadingOp.finish();
      return notification;
    },
    [commonApi]
  );

  const getContract = useCallback(async () => {
    loadingOp.start();
    const response = await commonApi.getContract();
    loadingOp.finish();
    return response;
  }, [commonApi]);

  const getCommerce = useCallback(async () => {
    loadingOp.start();
    const data = await commonApi.getCommerce();
    loadingOp.finish();
    return data;
  }, [commonApi]);

  const getWifiInfo = useCallback(async () => {
    loadingOp.start();
    const data = await commonApi.getWifiInfo();
    loadingOp.finish();
    return data;
  }, [commonApi]);

  const getWifiInfoSimple = useCallback(async () => {
    loadingOp.start();
    const data = await commonApi.getWifiInfoSimple();
    loadingOp.finish();
    return data;
  }, [commonApi]);

  const getTermsOfUseInfo = useCallback(async () => {
    loadingOp.start();
    const data = await commonApi.getTermsOfUseInfo();
    loadingOp.finish();
    return data;
  }, [commonApi]);

  const getPrivacyPolicyInfo = useCallback(async () => {
    loadingOp.start();
    const data = await commonApi.getPrivacyPolicyInfo();
    loadingOp.finish();
    return data;
  }, [commonApi]);

  return {
    getContract,
    getNotifications,
    getNotification,
    postContact,
    getCommerce,
    getWifiInfo,
    getWifiInfoSimple,
    getTermsOfUseInfo,
    getPrivacyPolicyInfo,
  };
};
