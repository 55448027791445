import React from "react";
import {
  Typography,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";

const Billing = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        paddingX: 2,
      }}
    >
      <Paper sx={{ width: 1, paddingY: 3, minHeight: "400px" }}>
        <MenuList dense>
          <MenuItem
            sx={{ paddingY: "10px", marginLeft: "10px" }}
            onClick={() => navigate("/billing/receipt")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>請求・領収書</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "15px", fontSize: "1rem !important" }}
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ paddingY: "10px", marginLeft: "10px" }}
            onClick={() => navigate("/billing/payment-method")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>決済方法変更</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "15px", fontSize: "1rem !important" }}
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ paddingY: "10px", marginLeft: "10px" }}
            onClick={() => navigate("/billing/payment")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>入金情報</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            sx={{ paddingY: "10px", marginLeft: "10px" }}
            onClick={() => navigate("/billing/deposit-account")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>入金先口座変更</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
          <MenuItem sx={{ paddingY: "10px", marginLeft: "10px" }}>
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>その他</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                fontSize="small"
              />
            </ListItemIcon>
          </MenuItem>
        </MenuList>
      </Paper>
    </Box>
  );
};

export default Billing;
