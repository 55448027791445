import React from "react";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ErrorHandlerProps {
  url?: string;
  back?: () => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ url, back }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: `calc(100vh - 250px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography align="center" sx={{ fontWeight: 550 }}>
          ページの表示に失敗しました
        </Typography>

        <Typography
          align="center"
          sx={{
            width: 0.85,
            marginX: "auto",
            marginY: 4,
            fontSize: "0.8rem",
          }}
        >
          ページ表示時にエラーが起きてしまいました。トップからやり直すか、不具合の場合は管理者に問い合わせてください。
        </Typography>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonSubmit
            type="button"
            sx={{ width: 0.85 }}
            onClick={() => (back ? back() : url ? navigate(url) : navigate(-1))}
          >
            戻る
          </ButtonSubmit>
        </Grid>
      </Box>
    </Box>
  );
};

export default ErrorHandler;
