import React from "react";
import { Box, Typography } from "@mui/material";

interface SubTitleProps {
  title: string;
}

const SubTitle: React.FC<SubTitleProps> = ({ title }) => {
  return (
    <Box sx={{ backgroundColor: "#9f1831", py: 1 }}>
      <Typography sx={{ color: "#fff" }} align="center">
        - {title} -
      </Typography>
    </Box>
  );
};

export default SubTitle;
