import { Dispatch, SetStateAction, Fragment, useContext, useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Box
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QueryParam, EkaSearchItem } from "../../types";
import * as Yup from "yup";
import "dayjs/locale/ja";
import EkaItem from "./EkaItem";
import moment from "moment";
import dayjs from "dayjs";
import AuthContext from "../../custom-hooks/use-auth-context";
import { EkaItemType, EkaTicketType } from "../../types";
import SearchPaper from "../../components/Search/SerachPaper";
import { useEkaApi } from "../../custom-hooks/apis/use-eka-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import LoaderWithText from "../../components/Loader/LoaderWithText";
import ErrorHandler from "../../components/ErrorHandler";


type Props = {
    list: EkaItemType[] | null;
    setList: Dispatch<SetStateAction<EkaItemType[] | null>>;
    searchItem: EkaSearchItem;
    setSearchItem: Dispatch<SetStateAction<EkaSearchItem>>;
}

const EkaData = ({ list, setList, searchItem, setSearchItem }: Props) => {
    const authCtx = useContext(AuthContext);

    const { postEkaDataList } = useEkaApi();
    const [queryParam, setParam] = useState<QueryParam | null>(null);


    const fetchEkaData = (nextIndex?: any) => {
        if (authCtx.user?.signInUserSession.idToken && queryParam)
            return postEkaDataList(queryParam, nextIndex);
        return Promise.resolve();
    };

    const { isFetching, isError, isRefetchError, isSuccess, isLoading } = useQuery(
        [QueryKeys.ekaDataList, authCtx.user?.signInUserSession.idToken, queryParam],
        async () => {
            setList([])
            let allData: EkaItemType[] = [];
            let data = await fetchEkaData();

            allData = allData.concat(data?.issuedEka || []);
            let nextIndex = data?.nextIndex ? data.nextIndex : null;


            while (nextIndex !== undefined && nextIndex !== null) {
                data = await fetchEkaData(nextIndex);
                allData = allData.concat(data?.issuedEka || []);
                nextIndex = data.nextIndex;
            }

            return allData;
        },
        {
            onSuccess: (data) => {
                setList(data);
            },
            staleTime: 0,
            refetchOnWindowFocus: false,
            enabled: !!queryParam,
            retry: false,
        }
    );

    const defaultValues = {
        ekaId: null,
        start: null,
        end: null,
    };

    const schema = Yup.object().shape({
        ekaId: Yup.string()
            .optional()
            .nullable()
            .test("is not null", "伝票IDまたは日付を入力して下さい", function (value) {
                if (this.parent.start || this.parent.end) {
                    return true;
                }
                return !!value;
            })
            .matches(
                /^[0-9]{8}-[0-9]{5}$/,
                () => {
                    return (
                        <>
                            <Box component="span" sx={{ display: "block" }}>伝票IDが正しくありません。</Box>
                            <Box component="span" sx={{ display: "block" }}>例に従って入力して下さい。 例: 20240101-00001</Box>
                        </>
                    )
                }
            ),
        start: Yup.date()
            .optional()
            .nullable()
            .max(Yup.ref("end"), "検索期間が長すぎます。最長3ヶ月間の検索が可能です。"),
        end: Yup.date()
            .optional()
            .nullable()
            .min(Yup.ref("start"), "検索期間が長すぎます。最長3ヶ月間の検索が可能です。")
            .test("is-between", "検索期間が長すぎます。最長3ヶ月間の検索が可能です。", function (value) {
                if (this.parent.ekaId) {
                    return true;
                }
                if (this.parent.start && !this.parent.end) {
                    return true;
                }
                if (!this.parent.start && !this.parent.end && !this.parent.ekaId) {
                    return true;
                }
                const start = moment(this.parent.start);
                const end = moment(value);
                let diff = end.diff(start, "months");
                return diff <= 3;
            }),
    });


    const onSubmit = (data: FieldValues) => {
        if (!authCtx.user?.store_id) return;

        let from_date = moment(dayjs(data?.start).toDate()).unix();
        let until_date = moment(dayjs(data?.end).toDate()).unix();

        let setSearchPeriod_from_date = formValues?.start ? moment(dayjs(formValues?.start).toDate()).format("YYYY/MM/DD") : null
        let setSearchPeriod_until_date = formValues?.end ? moment(dayjs(formValues?.end).toDate()).format("YYYY/MM/DD") : null

        let params: QueryParam = {
            customerStoreId: authCtx.user?.store_id,
            issuedEkaSerial: data?.ekaId ? data?.ekaId : null,
            startDate: from_date ? from_date : null,
            endDate: until_date ? until_date : null,
        };

        setParam(params);

        setSearchItem({
            ekaId: data?.ekaId,
            start: setSearchPeriod_from_date,
            end: setSearchPeriod_until_date
        })
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });
    const formValues = watch();


    if (isError || isRefetchError) return <ErrorHandler />;

    return (
        <div>
            <SearchPaper
                formValues={formValues}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                reset={reset}
            />


            {!isLoading && !isFetching && list && (
                <>
                    <Grid sx={{ mx: 2 }}>
                        {searchItem?.ekaId !== null &&
                            <Typography sx={{ color: "#606060", fontSize: "0.6rem" }}>
                                伝票ID：{searchItem.ekaId}
                            </Typography>
                        }
                        {searchItem?.start !== null && searchItem?.end !== null &&
                            <Typography sx={{ color: "#606060", fontSize: "0.6rem" }}>
                                出力期間：
                                {searchItem.start}
                                {" ~ " + searchItem.end}
                            </Typography>
                        }
                    </Grid>
                    {list.length > 0 && (
                        <Grid sx={{ pb: 10 }}>
                            {list.map((item: EkaItemType, index: number) => (
                                <Fragment key={index}>
                                    <EkaItem item={item} />
                                </Fragment>
                            ))}
                        </Grid>
                    )}
                    {list.length === 0 && (
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: `calc(100vh - 300px)`,
                            }}
                        >
                            <Typography sx={{ fontWeight: 600 }}>
                                Ekaデータが見つかりませんでした
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
            {(isFetching || isLoading) && <LoaderWithText />}
        </div>
    );
};

export default EkaData;