import { useContext } from "react";
import { useQuery } from "react-query";
import { Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";

const BillingDepositAccount = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getAccountPaymentInformation } = useBillingApi();

  const { isLoading, data, isError } = useQuery(
    [
      QueryKeys.accountPaymentInformation,
      authCtx.user?.signInUserSession.idToken,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getAccountPaymentInformation();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isError) return <ErrorHandler url={"/billing"} />;
  if (isLoading) return <Loader />;
  return (
    <Paper sx={{ marginX: 2, padding: 2 }}>
      <Grid sx={{ pt: 2, pb: 4 }}>
        <Typography sx={{ fontSize: "0.8rem" }}>
          {data?.account?.name}銀行 {data?.account?.type}営業部 普通
          {data?.account?.last4}
        </Typography>
      </Grid>
      <Grid sx={{ marginX: 2 }}>
        <ButtonSubmit
          type="button"
          sx={{ width: 1 }}
          onClick={() => navigate("/billing/deposit-account/update")}
        >
          入金口座の変更
        </ButtonSubmit>
      </Grid>
    </Paper>
  );
};

export default BillingDepositAccount;
