import { Button, ButtonProps, styled } from "@mui/material";

const ButtonCleaning = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  fontWeight: 600,
  backgroundColor: "#9071F5",
  minWidth: "40px",
  width: "40px",
  height: "40px",
  padding: 2,
  "&:hover": {
    backgroundColor: "#9071F5",
  },
  "&:disabled": {
    opacity: 0.5,
    color: "#fff",
  },
}));

export default ButtonCleaning;
