import React from "react";
import { Container } from "@mui/material";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { useStoreApi } from "../../custom-hooks/apis/use-store-api";
import { CreateStoreBodyRequestType } from "../../types";
import { toastError, toastSuccess } from "../../utils/toaster";
import { usePermissions } from "../../custom-hooks/use-permissions";
import StoreForm from "../../components/StoreForm";

const AddStore = () => {
  const { createStore } = useStoreApi();
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();

  const createStoreMutation = useMutation(
    (requestBody: CreateStoreBodyRequestType) => createStore(requestBody),
    {
      onSuccess: async () => {
        toastSuccess("ストアの作成に成功");
        navigate("/store");
      },
      onError: async (error: any) => {
        console.log("🚀 ~ file: index.tsx:40 ~ onError: ~ error:", error);
        toastError("ストアの作成に失敗しました");
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    let params: CreateStoreBodyRequestType = {
      storeType: "store",
      storeName: data.storeName,
      storeSubname: data.storeSubname,
      storeRegion: data.storeRegion,
      storeLocationState: data.storeLocationState,
      storeLocationCity: data.storeLocationCity,
    };
    if (data?.storeImage)
      params = {
        ...params,
        storeImage: data.storeImage,
      };

    createStoreMutation.mutate(params);
  };

  // if (createStoreMutation.isLoading) return <Loader />;
  return (
    <Container sx={{ minHeight: `calc(100vh-300px)` }}>
      <StoreForm
        isLoading={createStoreMutation.isLoading}
        isDisabled={!hasPermission("store.create")}
        submit={(data: FieldValues) => onSubmit(data)}
      />
    </Container>
  );
};

export default AddStore;
