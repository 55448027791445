import React from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import ButtonCustom from "../../components/Buttons/ButtonCustom";
import { SelectedAlertType } from "../../types";

interface ModalConfirmLogProps {
  open: boolean;
  totalAlertCount: number;
  values: SelectedAlertType[];
  handleClose: () => void;
  handleSubmit: (params: SelectedAlertType[]) => void;
}

const ModalConfirmLog: React.FC<ModalConfirmLogProps> = ({
  open,
  totalAlertCount,
  values,
  handleSubmit,
  handleClose,
}) => {
  const onSubmit = () => {
    handleSubmit(values);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "20px",
          minWidth: 300,
          p: 2,
        }}
      >
        <Typography
          sx={{ fontWeight: 600, fontSize: "0.9rem", textAlign: "center" }}
        >
          {totalAlertCount === values.length
            ? "すべてのアラートを既読にしますか？"
            : "既読にしますか？"}
        </Typography>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            my: 2,
          }}
        >
          <Typography
            sx={{ fontSize: "0.65rem", textAlign: "center", width: 0.9 }}
          >
            {totalAlertCount === values.length
              ? "【注意】一度既読にすると読み直しできません。 よろしいですか？"
              : "一度既読にすると読み直しできません"}
          </Typography>

          {/* <Grid sx={{ fontSize: "0.65rem" }}>
            <ul>
              {selectedAlerts.map((item: SelectedAlertType, index: number) => (
                <li key={index}>{item.filename}</li>
              ))}
            </ul>
          </Grid> */}
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "end",
            width: 1,
          }}
        >
          <ButtonCustom
            type="button"
            sx={{ color: "#000", fontSize: "0.8rem" }}
            onClick={handleClose}
          >
            キャンセル
          </ButtonCustom>
          <ButtonCustom
            type="button"
            sx={{ color: "#9F1831", fontSize: "0.8rem" }}
            onClick={onSubmit}
          >
            既読
          </ButtonCustom>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalConfirmLog;
