import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import {
  ModalDeviceParam,
  ModalDeviceType,
  DeviceDetailType,
} from "../../types";
import { isOutVersion } from "../../utils/helper";
import ButtonFilling from "./ButtonFilling";
import DeviceDetailMaintenance from "./DeviceDetailMaintenance";
import moment from "moment";
interface FillUpListItemProps {
  item: DeviceDetailType;
  setItem: (value: ModalDeviceParam) => void;
}

const FillUpListItem: React.FC<FillUpListItemProps> = ({ item, setItem }) => {
  return (
    <Paper
      elevation={2}
      sx={{ p: 2, my: 1, cursor: "pointer", position: "relative" }}
    >
      <DeviceDetailMaintenance
        isOutVersion={isOutVersion(
          item.softVersion,
          process.env.REACT_APP_OUT_VERSION || "0.55"
        )}
        status={item?.maintenance_qr}
      />
      <Grid container sx={{ height: "40px" }}>
        <Grid
          item
          xs={9}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "0.8rem",
            }}
          >
            {item?.thingNickname || item?.thingName}
          </Typography>
          {item?.actionData?.filling && item?.actionData?.filling !== "" && (
            <Typography
              sx={{
                fontSize: "0.6rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              最終充填日時：
              <>
                {moment
                  .unix(parseInt(item?.actionData?.filling))
                  .format("YYYY/MM/DD(ddd) HH:mm")}
              </>
            </Typography>
          )}
          {item?.serveProductName && (
            <Typography
              sx={{
                fontSize: "0.6rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              銘柄:
              <>{item?.serveProductName}</>
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          <ButtonFilling
            type="button"
            variant="contained"
            onClick={() => setItem({ item: item, type: ModalDeviceType.FILL })}
          >
            充填
          </ButtonFilling>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FillUpListItem;
