export enum ROLE {
  SUBSCRIPTION = "subscription",
  SUBSCRIPTION_LIMIT = "subscription_limit",
  OPERATION_CONTROL = "operation_control",
  DEVICE_LIMIT = "device_limit",
}

export const permissions = {
  "home.read": Object.values(ROLE),
  "store.read": Object.values(ROLE),
  "store.create": [ROLE.SUBSCRIPTION],
  "commerce.read": Object.values(ROLE),
  "device.read": [
    ROLE.SUBSCRIPTION,
    ROLE.SUBSCRIPTION_LIMIT,
    ROLE.OPERATION_CONTROL,
  ],
  "device.update": [ROLE.SUBSCRIPTION, ROLE.SUBSCRIPTION_LIMIT],
  "contact.create": Object.values(ROLE),
  "contact.read": Object.values(ROLE),
  "wifi.create": [ROLE.SUBSCRIPTION, ROLE.SUBSCRIPTION_LIMIT],
  "wifi.read": [ROLE.SUBSCRIPTION, ROLE.SUBSCRIPTION_LIMIT],
  "group.create": [ROLE.SUBSCRIPTION, ROLE.SUBSCRIPTION_LIMIT],
  "group.read": [
    ROLE.SUBSCRIPTION,
    ROLE.SUBSCRIPTION_LIMIT,
    ROLE.OPERATION_CONTROL,
  ],
  "group.update": [ROLE.SUBSCRIPTION, ROLE.SUBSCRIPTION_LIMIT],
  "group.delete": [ROLE.SUBSCRIPTION, ROLE.SUBSCRIPTION_LIMIT],
  "ticket.create": [
    ROLE.SUBSCRIPTION,
    ROLE.SUBSCRIPTION_LIMIT,
    ROLE.DEVICE_LIMIT,
  ],
  "ticket.read": Object.values(ROLE),
  "ticket.update": [
    ROLE.SUBSCRIPTION,
    ROLE.SUBSCRIPTION_LIMIT,
    ROLE.DEVICE_LIMIT,
  ],
  "ticket.delete": [
    ROLE.SUBSCRIPTION,
    ROLE.SUBSCRIPTION_LIMIT,
    ROLE.DEVICE_LIMIT,
  ],
  "contract.create": [ROLE.SUBSCRIPTION],
  "contract.update": [ROLE.SUBSCRIPTION],
  "contract.read": [ROLE.SUBSCRIPTION],
  "contract.delete": [ROLE.SUBSCRIPTION],
  "billing.create": [ROLE.SUBSCRIPTION],
  "billing.update": [ROLE.SUBSCRIPTION],
  "billing.read": [ROLE.SUBSCRIPTION],
  "billing.delete": [ROLE.SUBSCRIPTION],
  "settings.read": Object.values(ROLE),
  "settings.update": Object.values(ROLE),
  "management.create": Object.values(ROLE),
  "management.read": Object.values(ROLE),
  "management.update": Object.values(ROLE),
  "management.delete": Object.values(ROLE),
  "issued_ticket.create": Object.values(ROLE),
  "issued_ticket.read": Object.values(ROLE),
  "issued_ticket.update": Object.values(ROLE),
  "issued_ticket.delete": Object.values(ROLE),
  "beverage_data.create": Object.values(ROLE),
  "beverage_data.read": Object.values(ROLE),
  "beverage_data.update": Object.values(ROLE),
  "beverage_data.delete": Object.values(ROLE),
  "notifications.create": Object.values(ROLE),
  "notifications.read": Object.values(ROLE),
  "notifications.update": Object.values(ROLE),
  "notifications.delete": Object.values(ROLE),
};

export type PermissionName = keyof typeof permissions;
