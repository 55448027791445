import { useCallback, useContext, useMemo } from "react";
import { GetTotalServedDrinkParams, StoreParamType } from "../../types";
import appDashboardApi from "../../apis/dashboard-api";
import GlobalDataContext from "../use-global-data";

export const useDashboardApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const dashboardApi = useMemo(
    () =>
      new appDashboardApi(
        process.env.REACT_APP_API_9 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getTotalIssuedTicket = useCallback(
    async (params: any) => {
      loadingOp.start();
      const ticketList = await dashboardApi.getTotalIssuedTicket(params);
      loadingOp.finish();
      return ticketList;
    },
    [dashboardApi]
  );

  const getTotalServedDrink = useCallback(
    async (params: GetTotalServedDrinkParams) => {
      loadingOp.start();
      const ticketList = await dashboardApi.getTotalServedDrink(params);
      loadingOp.finish();
      return ticketList;
    },
    [dashboardApi]
  );

  const getRemainingBeverages = useCallback(
    async (params: StoreParamType) => {
      loadingOp.start();
      const ticketList = await dashboardApi.getRemainingBeverages(params);
      loadingOp.finish();
      return ticketList;
    },
    [dashboardApi]
  );

  return {
    getTotalIssuedTicket,
    getTotalServedDrink,
    getRemainingBeverages,
  };
};
