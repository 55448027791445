import { useEffect, useContext, useState } from "react";
import {
  useNavigate,
  Outlet,
  useLocation,
  matchRoutes,
} from "react-router-dom";
import {
  Box,
  CssBaseline,
  Container,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { PrivateRoutes } from "../../routes";
import Loader from "../Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import RequireAuth from "../Screens/RequireAuth";
import Footer from "./Footer";
import ContractAppbar from "./ContractAppbar";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [currentRoute, setCurrentRoute] = useState<any>(null);
  const [visibleFooterBar, setVisibleFooterBar] = useState<boolean>(true);
  const {
    tab,
    pageTitle,
    pageSubTitle,
    setCurrentTab,
    setPageTitle,
    setPageSubTitle,
  } = useContext(GlobalDataContext);

  useEffect(() => {
    setCurrentTab(location.pathname);
    const [{ route }]: any = matchRoutes(PrivateRoutes, location);
    setPageTitle(route.title);
    setPageSubTitle(route.subTitle || "");
    setVisibleFooterBar(route.hideFooterBar ? false : true);
    setCurrentRoute(route);
  }, [location]);

  return (
    <Box
      sx={{
        height: !visibleFooterBar ? "100vh" : "auto",
        backgroundColor: "#E6E4EB",
      }}
    >
      <CssBaseline />
      <Container
        maxWidth={false}
        sx={{
          minHeight: `calc(100vh - 76px)`,
          bgcolor: "#E6E4EB",
          padding: 0,
          maxWidth: ["600px", "800px"],
        }}
      >
        {currentRoute?.headerStyle === 0 && (
          <>
            {authCtx?.isLoggedIn === false && (
              <AppBar
                position="sticky"
                color="transparent"
                elevation={0}
                sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
              >
                <Toolbar
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingX: 0,
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={{
                      color: "#4F4F62",
                      fontWeight: 600,
                      fontSize: "1.2rem",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {pageTitle}
                  </Typography>
                </Toolbar>
              </AppBar>
            )}
            {authCtx?.isLoggedIn === true && pageTitle && (
              <AppBar
                position="sticky"
                color="transparent"
                elevation={0}
                sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
              >
                <Toolbar
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingX: 0,
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      component="h1"
                      sx={{
                        color: "#4F4F62",
                        fontWeight: 600,
                        fontSize: "1.2rem",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                      align="center"
                    >
                      {pageTitle}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#4F4F62",
                        fontWeight: 600,
                        fontSize: "13px",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                      align={"center"}
                    >
                      {pageSubTitle || ""}
                    </Typography>
                  </Box>
                </Toolbar>
              </AppBar>
            )}
          </>
        )}
        {currentRoute?.headerStyle === 1 && (
          <AppBar
            elevation={0}
            position="sticky"
            color="transparent"
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => navigate(currentRoute?.backUrl)}
              >
                <ArrowBackIos />
              </IconButton>
              <Box>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{
                    color: "#4F4F62",
                    fontWeight: 600,
                    fontSize: "1.2rem",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                  align="center"
                >
                  {pageTitle}
                </Typography>
                <Typography
                  sx={{
                    color: "#4F4F62",
                    fontWeight: 600,
                    fontSize: "13px",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                  align={"center"}
                >
                  {pageSubTitle || ""}
                </Typography>
              </Box>
              <Box sx={{ width: "36px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
        )}
        {currentRoute?.headerStyle === 2 && (
          <ContractAppbar title={pageTitle} />
        )}

        <Box
          sx={{
            minHeight: authCtx.isLoggedIn ? `calc(100vh - 160px)` : `100%`,
          }}
        >
          {authCtx.loginLoading ? (
            <Loader />
          ) : authCtx.isLoggedIn ? (
            <Outlet />
          ) : (
            <RequireAuth />
          )}
        </Box>
      </Container>
      {visibleFooterBar && <Footer />}
    </Box>
  );
};

export default Layout;
