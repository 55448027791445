import { Translations } from "@aws-amplify/ui-components";

export const vocabularies = {
  [Translations.NETWORK_ERROR]:
    "ネットワークエラー。ネットワーク状況を確認してください。",
  [Translations.DEFAULT_MSG]: "認証エラーです。ログインし直してください。",
  [Translations.EMPTY_EMAIL]: "認メールアドレスが空です。",
  [Translations.EMPTY_PHONE]: "電話番号が空です。",
  [Translations.EMPTY_USERNAME]: "ユーザー名が空です。",
  [Translations.EMPTY_PASSWORD]: "パスワードが空です。",
  [Translations.EMPTY_CODE]: "確認コードが空です。",
  [Translations.INVALID_USERNAME]: "ユーザー名は文字列である必要があります。",
  [Translations.SIGN_UP_ERROR]:
    "アカウント作成時にエラーが発生しました。もう一度試してください。",
  [Translations.NO_MFA]: "有効なMFAメソッドが提供されていません。",
  [Translations.INVALID_MFA]: "MFAタイプが無効です。",
  [Translations.EMPTY_CHALLENGE]: "チャレンジレスポンスが空です。",
  [Translations.NO_USER_SESSION]:
    "ユーザーが空のため、セッションを取得できませんでした。再度ログインし直してください。",
  [Translations.DEVICE_CONFIG]:
    "このユーザープールではデバイストラッキングが構成されていません。",
  "Password did not conform with policy: Password not long enough":
    "パスワードは8文字以上にしてください",
  "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$":
    "パスワードは8文字以上、大文字小文字を含む英数字を指定してください",
  "User does not exist.": "ユーザーが存在しません",
  "Incorrect username or password.": "ユーザー名またはパスワードが違います",
  "User is not confirmed.": "ユーザーは検証されていません",
  "User already exists": "ユーザーは既に存在します",
  "Invalid verification code provided, please try again.":
    "指定された確認コードが無効です。もう一度お試しください",
  "Invalid password format": "パスワードのフォーマットが不正です",
  "Invalid phone number format":
    "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
  "An account with the given email already exists.":
    "そのメールアドレスは既に存在します",
  "Password attempts exceeded": "パスワード試行回数が超過しました",
  "Attempt limit exceeded, please try after some time.":
    "試行制限を超過しました。しばらくしてからもう一度お試しください",
  "Username/client id combination not found.": "ユーザーが存在しません",
  "CUSTOM_AUTH is not enabled for the client.": "パスワードは必須です",
  "Password does not conform to policy: Password not long enough":
    "パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)",
  "Password does not conform to policy: Password must have uppercase characters":
    "パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)",
  "Password does not conform to policy: Password must have lowercase characters":
    "パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)",
  "Password does not conform to policy: Password must have numeric characters":
    "パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)",
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
    "パスワードは8文字以上、大文字小文字を含む英数字を指定してください",
  "Your card number is incomplete.": "カード番号に不備があります。",
  "Your card's expiration date is incomplete.":
    "カードの有効期限に不備があります。",
  "Your card's security code is incomplete.":
    "カードのセキュリティコードに不備があります。",
  "Your card number is invalid.": "お客様のカード番号は無効です。",
  "Your card's expiration year is in the past.":
    "カードの有効期限が過去になっている。",
  "Password did not conform with policy: Password must have uppercase characters":
    "パスワードがポリシーに適合していません： パスワードは大文字でなければなりません",
  "Password did not conform with policy: Password must have numeric characters":
    "パスワードがポリシーに適合していません： パスワードは数字でなければなりません",
  "Password did not conform with policy: Password must have symbol characters":
    "パスワードには記号を使用することです",
  active: "使用可能",
  inactive: "使用不可",
  coupon: "クーポン",
  all_you_can_drink: "飲み放題",
  prepaid: "プリペイド",
  paid: "支払済",
  draft: "発行中",
  open: "請求確定",
  void: "キャンセル",
  uncollectible: "回収失敗",
};
