import { useContext } from "react";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import moment from "moment";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import GlobalDataContext from "../../custom-hooks/use-global-data";

const NotificationDetail = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const { id } = useParams();
  const { getNotification } = useCommonApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.notifcation, id],
    () => {
      if (authCtx.user?.signInUserSession.idToken && id)
        return getNotification(id);
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isError)
    return (
      <Box
        sx={{
          height: `calc(100vh - 250px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            ページの表示に失敗しました
          </Typography>

          <Typography
            align="center"
            sx={{
              width: 0.85,
              marginX: "auto",
              marginY: 4,
              fontSize: "0.8rem",
            }}
          >
            ページ表示時にエラーが起きてしまいました。トップからやり直すか、不具合の場合は管理者に問い合わせてください。
          </Typography>
        </Box>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonSubmit
            type="button"
            sx={{ width: 0.85 }}
            onClick={() => navigate("/")}
          >
            トップに戻る
          </ButtonSubmit>
        </Grid>
      </Box>
    );

  return isLoading ? (
    <Loader />
  ) : (
    <Box sx={{ paddingX: 2 }}>
      <Paper sx={{ padding: 2, marginBottom: "50px" }}>
        <Grid marginY={2}>
          <Typography fontSize="12px">
            投稿日
            {moment(data?.details?.inst_ymdhi).format("YYYY/MM/DD HH:mm")}
            &nbsp; 更新日
            {moment(data?.details?.update_ymdhi).format("YYYY/MM/DD HH:mm")}
          </Typography>
        </Grid>
        <Grid marginY={2}>
          <Typography component="h1" variant="h6" sx={{ fontWeight: 550 }}>
            {data?.details?.subject}
          </Typography>
        </Grid>
        <Grid marginY={2}>
          <TypographyExt5
            sx={{ fontSize: "0.8rem" }}
            dangerouslySetInnerHTML={{ __html: data?.details?.ext_3 }}
          ></TypographyExt5>
        </Grid>
      </Paper>
      {loading && <Loader />}
    </Box>
  );
};

const TypographyExt5 = styled(Typography)(({ theme }) => ({
  h2: {
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
  },
  blockquote: {
    backgroundColor: "#E4DFDE",
    margin: "10px",
    padding: "5px 20px",
  },
}));
export default NotificationDetail;
