import { useEffect } from "react";
import {
  AppBar,
  Box,
  Checkbox,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { ArrowBackIos } from "@mui/icons-material";
import * as Yup from "yup";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import Footer from "../../Layout/Footer";
import GroupNameSelectionLabel from "./GroupNameSelectionLabel";
import FormLabelDefault from "../../FormLabelDefault";
import ReloadAnimation from "../../ReloadAnimation";

interface ModalConfirmDeviceUpdateProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  preValues: string[];
  options: any[];
  onSubmit: (data: FieldValues) => void;
}

const ModalGroupNameSelection: React.FC<ModalConfirmDeviceUpdateProps> = ({
  open,
  setOpen,
  onSubmit,
  preValues,
  options,
}) => {
  const schema = Yup.object().shape({
    groupNames: Yup.array().test(
      "at-least-one",
      "少なくとも１つの端末名を選択する必要があります",
      (value) => {
        return Array.isArray(value) && value.length > 0;
      }
    ),
  });

  const defaultValues = {
    groupNames: preValues ? preValues : [],
  };

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const formValues = watch();

  useEffect(() => {
    if (open && preValues.length > 0) setValue("groupNames", preValues);

    return () => {
      reset();
    };
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ pb: 7, backgroundColor: "#E6E4EB" }}>
        <Container
          maxWidth="sm"
          sx={{
            minHeight: `100vh`,
            bgcolor: "#E6E4EB",
            padding: 0,
          }}
        >
          <AppBar
            elevation={0}
            position="sticky"
            color="transparent"
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(false)}
              >
                <ArrowBackIos />
              </IconButton>
              <Box>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{
                    color: "#4F4F62",
                    fontWeight: 600,
                    fontSize: "1.2rem",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                  align="center"
                >
                  グループ選択
                </Typography>
              </Box>
              <Box sx={{ width: "36px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
          <Box>
            <ReloadAnimation>
              <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {!!errors?.groupNames?.message && (
                    <Grid sx={{ my: 2 }}>
                      <FormHelperText error id="groupNames_helper">
                        <>{errors?.groupNames?.message}</>
                      </FormHelperText>
                    </Grid>
                  )}
                  {!!options ? (
                    <>
                      <Paper
                        sx={{ p: 2, maxHeight: "550px", overflowY: "scroll" }}
                      >
                        {options.map((option: any, index: number) => (
                          <Grid key={index}>
                            <FormLabelDefault
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setValue("groupNames", [
                                        ...formValues.groupNames,
                                        option.id,
                                      ]);
                                    } else {
                                      setValue(
                                        "groupNames",
                                        formValues.groupNames.filter(
                                          (val: string) => val !== option.id
                                        )
                                      );
                                    }
                                  }}
                                  value={option?.id}
                                  defaultChecked={
                                    preValues && preValues.includes(option?.id)
                                  }
                                />
                              }
                              label={
                                <GroupNameSelectionLabel option={option} />
                              }
                            />
                          </Grid>
                        ))}
                      </Paper>
                      <Grid
                        sx={{
                          my: 2,
                          width: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ButtonSubmit sx={{ width: 0.9 }} type="submit">
                          選択したグループを追加
                        </ButtonSubmit>
                      </Grid>
                    </>
                  ) : (
                    <Box
                      sx={{
                        height: `calc(100vh - 300px)`,
                        marginX: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography align="center">
                          選択できるのまっせグループはありません
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </form>
              </Container>
            </ReloadAnimation>
          </Box>
        </Container>
        <Footer />
      </Box>
    </Modal>
  );
};

export default ModalGroupNameSelection;
