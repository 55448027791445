export const cloneObjWithoutAttr = (
  originalObject: any,
  attributeToExclude: string
) => {
  // Create a new object with all properties from the original object
  const clonedObject = { ...originalObject };

  // Delete the specific attribute from the cloned object
  delete clonedObject[attributeToExclude];

  return clonedObject;
};

export const cloneObjWithoutMultiAttr = (
  originalObject: any,
  attributeToExcludes: string[]
) => {
  // Create a new object with all properties from the original object
  const clonedObject = { ...originalObject };
  for (let i = 0; i < attributeToExcludes.length; i++) {
    // Delete the specific attribute from the cloned object
    delete clonedObject[attributeToExcludes[i]];
  }
  return clonedObject;
};

export const isNumberString = (value: any) => {
  if (value === null || value === undefined) {
    return false;
  }
  const numericValue = parseFloat(value);
  return !isNaN(numericValue) && isFinite(numericValue);
};

export const findDuplicatesById = (list: any[], values: string[]) => {
  const idSet = new Set();
  const duplicates: any[] = [];

  values.forEach((item) => {
    idSet.add(item);
  });

  list.forEach((item) => {
    if (idSet.has(item.id)) {
      duplicates.push(item);
    }
  });

  return duplicates;
};

export const createBlob = (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      });
      resolve(blob);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const validateBase64ImageSize = (
  base64Image: any,
  maxSizeInBytes: number
): boolean => {
  if (!base64Image || base64Image.startsWith("http")) return true;

  const decodedImage = atob(base64Image.split(",")[1]);
  const sizeInBytes = decodedImage.length;
  const sizeInKb = sizeInBytes / 1024;
  return sizeInKb <= maxSizeInBytes / 1024;
};

export const isOutVersion = (
  currentVersion: string,
  outVersion: string
): boolean => {
  const partsA = currentVersion.split(".").map(Number);
  const partsB = outVersion.split(".").map(Number);

  for (let i = 0; i < 3; i++) {
    if (partsA[i] > partsB[i]) {
      return false;
    } else if (partsA[i] < partsB[i]) {
      return true;
    }
  }
  return false;
};
