import { useContext } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { UpdateDeviceGroupBodyRequestType } from "../../types";
import { toastError, toastSuccess } from "../../utils/toaster";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import GroupForm from "../../components/GroupForm";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreGroupUpdate = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { id } = useParams();
  const { hasPermission } = usePermissions();
  const { getDeviceGroupDetail, updateDeviceGroup } = useDeviceApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.storeDeviceDetails, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getDeviceGroupDetail({
          customer_store_id: authCtx.user?.store_id,
          thing_group: id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const updateDeviceGroupMutation = useMutation(
    (requestBody: UpdateDeviceGroupBodyRequestType) =>
      updateDeviceGroup(requestBody),
    {
      onSuccess: async () => {
        toastSuccess("グループのアップデートに成功しました。");
        navigate("/store/groups");
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage = "グループを更新できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const onSubmit = async (param: any) => {
    let params: UpdateDeviceGroupBodyRequestType = {
      devices: param?.terminalNames,
      group: {
        thingGroupName: data?.group?.thingGroupId,
        thingGroupDescription: param?.groupDesc,
      },
    };
    updateDeviceGroupMutation.mutate(params);
  };

  if (isError)
    return (
      <Box
        sx={{
          height: `calc(100vh - 250px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            グループが見つかりません
          </Typography>
        </Box>
      </Box>
    );
  if (isLoading) return <Loader />;
  return (
    <Container>
      <ReloadAnimation>
        <GroupForm
          onSubmit={onSubmit}
          preValues={data?.group}
          loading={updateDeviceGroupMutation.isLoading}
          isDisabled={!hasPermission("group.update")}
        />
      </ReloadAnimation>
    </Container>
  );
};

export default StoreGroupUpdate;
