import React from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface StoreGroupSettingsItemProps {
  id?: string;
  title: string;
  desc?: string;
}

const StoreGroupSettingsItem: React.FC<StoreGroupSettingsItemProps> = ({
  id,
  title,
  desc,
}) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{ paddingY: 2, paddingLeft: 2, paddingRight: 1, my: 2 }}
      elevation={2}
    >
      <Grid
        container
        onClick={() => {
          navigate(`/store/groups/${id || title}`);
        }}
      >
        <Grid item xs={11}>
          <Typography
            sx={{
              display: "block",
              fontWeight: 550,
              fontSize: "18px",
              width: 1,
            }}
            component="h1"
            variant="h6"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {title || id}
          </Typography>
          <Typography
            sx={{
              display: "inline",
              color: "#000",
              fontSize: "10px",
              wordWrap: "break-word",
            }}
          >
            {desc}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ArrowForwardIos
            sx={{
              height: "0.8em",
              width: "0.8em",
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StoreGroupSettingsItem;
