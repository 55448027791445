import { Route, Routes } from "react-router-dom";
import { usePermissions } from "./custom-hooks/use-permissions";
import { PermissionName } from "./permissions";
import pages from "./pages";
import Layout from "./components/Layout";
import PublicLayout from "./components/Layout/PublicLayout";
import AuthLayout from "./components/Auth/Layout";

const {
  HomePage,
  Login,
  ForgotPassword,
  ResetPassword,
  StorePage,
  AddStorePage,
  StoreDetailMenuPage,
  StoreDeviceListPage,
  StoreDeviceDetailPage,
  StoreDeviceUpdatePage,
  StoreSettingsPage,
  StoreContractsPage,
  StoreContractsDetailPage,
  StoreTicketSettingsPage,
  StoreTicketSettingsDetailPage,
  StoreTicketSettingsCreatePage,
  StoreGroupSettingsPage,
  StoreGroupSettingsDetailPage,
  StoreTicketSettingsUpdatePage,
  StoreGroupCreatePage,
  StoreGroupUpdatePage,
  SettingsPage,
  ContactUsPage,
  SentContactUsPage,
  NotificationDetailPage,
  NotFoundErrorPage,
  ConfirmPhoneNumberPage,
  ContractInitialPage,
  ContractCustomerInfoPage,
  ContractPlanSelectPage,
  ContractConfirmationInfoPage,
  BillingPage,
  BillingReceiptPage,
  BillingPaymentMethodPage,
  BillingPaymentMethodChangePage,
  BillingPaymentInformationPage,
  BillingDepositAccountPage,
  BillingDepositAccountChangePage,
  NewContractPage,
  NewContractConfirmationPage,
  NewContractPaymentPage,
  NewContractCompletePage,
  CommercePage,
  UnauthorizationPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  NewPasswordPage,
  ManagementPage,
  DeviceRebootPage,
  DeviceWirelessPage,
  IssuedTicketListPage,
  IssuedTicketCreatePage,
  IssuedTicketUpdatePage,
  ReceiptLabelEditingPage,
  IssuedTicketDetailPage,
  BeverageDataPage,
  NotificationsPage,
  DataPage,
} = pages;

interface ProtectedRouteProps {
  permissions: PermissionName;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  permissions,
  children,
}) => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(permissions)) {
    return <UnauthorizationPage />;
  }
  return children;
};

export const PrivateRoutes = [
  {
    path: "/",
    key: "home-page",
    title: "トップ",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="home.read">
        <HomePage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/store",
  //   key: "store-page",
  //   title: "店舖一覧",
  //   headerStyle: 0,
  //   component: <StorePage />,
  // },
  // {
  //   path: "/store/:id",
  //   key: "store-detail-menu-page",
  //   title: "店舗メニュー",
  //   headerStyle: 1,
  //   backUrl: "/store",
  //   component: <StoreDetailMenuPage />,
  // },
  {
    path: "/store",
    key: "store-menu-page",
    title: "店舗メニュー",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="store.read">
        <StoreDetailMenuPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/devices",
    key: "store-detail-list-page",
    //title: "のまっせ一覧",
    headerStyle: 0,
    backUrl: "/store",
    component: (
      <ProtectedRoute permissions="device.read">
        <StoreDeviceListPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/devices/:id",
    key: "store-device-detail-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="device.read">
        <StoreDeviceDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/devices/:id/update",
    key: "store-device-update-page",
    title: "のまっせ設定変更",
    headerStyle: 1,
    backUrl: -1,
    component: (
      <ProtectedRoute permissions="device.update">
        <StoreDeviceUpdatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/settings",
    key: "store-settings-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="settings.read">
        <StoreSettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/tickets",
    key: "store-ticket-settings-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="ticket.read">
        <StoreTicketSettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/tickets/:id",
    key: "store-ticket-settings-detail-page",
    headerStyle: 1,
    title: "チケット詳細",
    backUrl: "/store/tickets",
    component: (
      <ProtectedRoute permissions="ticket.read">
        <StoreTicketSettingsDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/tickets/create",
    key: "store-ticket-settings-create-page",
    headerStyle: 1,
    title: "新規チケット追加",
    backUrl: "/store/tickets",
    component: (
      <ProtectedRoute permissions="ticket.create">
        <StoreTicketSettingsCreatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/tickets/:id/update",
    key: "store-ticket-settings-detail-page",
    headerStyle: 1,
    title: "チケット情報変更",
    backUrl: -1,
    component: (
      <ProtectedRoute permissions="ticket.update">
        <StoreTicketSettingsUpdatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/groups",
    key: "store-group-settings-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="group.read">
        <StoreGroupSettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/groups/:id",
    key: "store-group-settings-detail-page",
    headerStyle: 1,
    title: "グループ設定",
    backUrl: "/store/groups",
    component: (
      <ProtectedRoute permissions="group.read">
        <StoreGroupSettingsDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/groups/create",
    key: "store-group-settings-creation-page",
    headerStyle: 1,
    title: "新規グループ追加",
    backUrl: "/store/groups",
    component: (
      <ProtectedRoute permissions="group.create">
        <StoreGroupCreatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/groups/:id/update",
    key: "store-group-settings-updating-page",
    headerStyle: 1,
    title: "グループ変更",
    backUrl: -1,
    component: (
      <ProtectedRoute permissions="group.update">
        <StoreGroupUpdatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contracts",
    key: "contracts-page",
    title: "のまっせのご契約",
    headerStyle: 1,
    backUrl: "/settings",
    component: (
      <ProtectedRoute permissions="contract.read">
        <StoreContractsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contracts/detail",
    key: "contracts-detail-page",
    title: "ご契約詳細",
    headerStyle: 1,
    backUrl: "/contracts",
    component: (
      <ProtectedRoute permissions="contract.read">
        <StoreContractsDetailPage />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/store/add",
  //   key: "add-store-page",
  //   title: "新規店舗追加",
  //   headerStyle: 1,
  //   backUrl: "/store",
  //   component: <AddStorePage />,
  // },
  {
    path: "/contact-us",
    key: "contact-us-page",
    title: "お問い合わせ",
    headerStyle: 1,
    backUrl: -1,
    component: (
      <ProtectedRoute permissions="contact.read">
        <ContactUsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sent-contact-us",
    key: "sent-contact-us-page",
    title: "お問い合わせ",
    headerStyle: 1,
    backUrl: "/contact-us",
    component: (
      <ProtectedRoute permissions="contact.create">
        <SentContactUsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/notifications/:id",
    key: "notification-detail-page",
    title: "お知らせ",
    headerStyle: 1,
    backUrl: -1,
    component: (
      <ProtectedRoute permissions="notifications.read">
        <NotificationDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    key: "settings-page",
    title: "設定",
    headerStyle: 0,
    component: <SettingsPage />,
  },
  {
    path: "/contract/initial",
    key: "contract-initial-page",
    title: "ご契約",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="contract.create">
        <ContractInitialPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/customer-info",
    key: "contract-customer-info-page",
    title: "ご契約",
    headerStyle: 2,
    component: (
      <ProtectedRoute permissions="contract.create">
        <ContractCustomerInfoPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/plan",
    key: "contract-plan-page",
    title: "ご契約",
    headerStyle: 2,
    component: (
      <ProtectedRoute permissions="contract.create">
        <ContractPlanSelectPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/confirmation",
    key: "contract-confirmation-page",
    title: "ご契約",
    headerStyle: 2,
    component: (
      <ProtectedRoute permissions="contract.create">
        <ContractConfirmationInfoPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing",
    key: "billing-page",
    title: "請求・支払い設定",
    headerStyle: 1,
    backUrl: "/settings",
    component: (
      <ProtectedRoute permissions="billing.read">
        <BillingPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing/receipt",
    key: "billing-receipt-page",
    title: "請求・領収書",
    headerStyle: 1,
    backUrl: "/billing",
    component: (
      <ProtectedRoute permissions="billing.read">
        <BillingReceiptPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing/payment-method",
    key: "billing-payment-method-page",
    title: "決済方法変更",
    headerStyle: 1,
    backUrl: "/billing",
    component: (
      <ProtectedRoute permissions="billing.read">
        <BillingPaymentMethodPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing/payment-method/update",
    key: "billing-payment-method-update-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="billing.update">
        <BillingPaymentMethodChangePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing/payment",
    key: "billing-payment-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="billing.read">
        <BillingPaymentInformationPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing/deposit-account",
    key: "billing-deposit-account-page",
    title: "入金先口座変更",
    headerStyle: 1,
    backUrl: "/billing",
    component: (
      <ProtectedRoute permissions="billing.read">
        <BillingDepositAccountPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/billing/deposit-account/update",
    key: "billing-deposit-account-update-page",
    title: "入金先口座変更",
    headerStyle: 1,
    backUrl: "/billing/deposit-account",
    component: (
      <ProtectedRoute permissions="billing.update">
        <BillingDepositAccountChangePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/new",
    key: "contract-new-page",
    title: "ご契約",
    headerStyle: 1,
    backUrl: "/contracts",
    hideFooterBar: true,
    component: (
      <ProtectedRoute permissions="contract.create">
        <NewContractPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/new/confirmation",
    key: "contract-new-confirmation-page",
    title: "ご契約",
    headerStyle: 1,
    backUrl: "/contract/new",
    hideFooterBar: true,
    component: (
      <ProtectedRoute permissions="contract.create">
        <NewContractConfirmationPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/new/payment",
    key: "contract-new-payment-page",
    headerStyle: 0,
    hideFooterBar: true,
    component: (
      <ProtectedRoute permissions="contract.create">
        <NewContractPaymentPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/contract/new/complete",
    key: "contract-new-complete-page",
    headerStyle: 0,
    hideFooterBar: true,
    component: (
      <ProtectedRoute permissions="contract.create">
        <NewContractCompletePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/commerce",
    key: "commerce-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="commerce.read">
        <CommercePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/management",
    key: "management-page",
    title: "のまっせ管理設定",
    backUrl: "/settings",
    headerStyle: 1,
    component: (
      <ProtectedRoute permissions="management.read">
        <ManagementPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/management/reboot",
    key: "managemen-reboot-page",
    title: "のまっせ再起動",
    backUrl: "/management",
    headerStyle: 1,
    component: (
      <ProtectedRoute permissions="management.read">
        <DeviceRebootPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/management/wireless",
    key: "managemen-wireless-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="management.read">
        <DeviceWirelessPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/issued-tickets",
    key: "issued-ticket-list-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="issued_ticket.read">
        <IssuedTicketListPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/issued-tickets/create",
    key: "issued-ticket-create-page",
    title: "店舗発行チケット追加",
    headerStyle: 1,
    backUrl: "/store/issued-tickets",
    component: (
      <ProtectedRoute permissions="issued_ticket.read">
        <IssuedTicketCreatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/issued-tickets/receipt",
    key: "receipt-label-editing-page",
    title: "レシートラベル編集",
    headerStyle: 1,
    backUrl: "/store/issued-tickets",
    component: (
      <ProtectedRoute permissions="issued_ticket.read">
        <ReceiptLabelEditingPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/issued-tickets/:id",
    key: "issued-ticket-detail-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="issued_ticket.read">
        <IssuedTicketDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/issued-tickets/:id/update",
    key: "issued-ticket-update-page",
    title: "店舗発行チケット変更",
    headerStyle: 1,
    backUrl: -1,
    component: (
      <ProtectedRoute permissions="issued_ticket.read">
        <IssuedTicketUpdatePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/beverage",
    key: "beverage-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="beverage_data.read">
        <DataPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/store/data",
    key: "data-page",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="beverage_data.read">
        <DataPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/notifications",
    key: "notifications-page",
    title: "お知らせ",
    headerStyle: 0,
    component: (
      <ProtectedRoute permissions="notifications.read">
        <NotificationsPage />
      </ProtectedRoute>
    ),
  },
];

export const AuthRoutes = [
  {
    path: "/login",
    key: "login-page",
    component: <Login />,
  },
  {
    path: "/forgot-password",
    key: "forgot-password-page",
    component: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    key: "reset-password-page",
    component: <ResetPassword />,
  },
  {
    path: "/new-password",
    key: "new-password-page",
    component: <NewPasswordPage />,
  },
  {
    path: "/confirm-phone-number",
    key: "confirm-phone-number-page",
    component: <ConfirmPhoneNumberPage />,
  },
];

export const PublicRoutes = [
  {
    path: "/privacy-policy",
    key: "privacy-policy-page",
    title: "プライバシーポリシー",
    headerStyle: 1,
    backUrl: -1,
    component: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-of-use",
    key: "terms-of-use-page",
    title: "利用規約",
    headerStyle: 1,
    backUrl: -1,
    component: <TermsOfUsePage />,
  },
  {
    path: "*",
    key: "not-found",
    hideFooterBar: true,
    component: <NotFoundErrorPage />,
  },
];

const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />} key="Layout">
        {PrivateRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
      <Route element={<AuthLayout />} key="AuthLayout">
        {AuthRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
      <Route element={<PublicLayout />} key="PublicLayout">
        {PublicRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
    </Routes>
  );
};

export default Router;
