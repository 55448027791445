import React from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import ReloadAnimation from "../../ReloadAnimation";

interface ModalQRProps {
  open: boolean;
  userStoreId: string;
  setOpen: (value: boolean) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalQR: React.FC<ModalQRProps> = ({ open, userStoreId, setOpen }) => {
  const downloadQR = async () => {
    const qrCodeImage = document.getElementById("qrcode");
    if (!qrCodeImage) return;
    const canvas = await html2canvas(qrCodeImage);

    const imageURL = canvas.toDataURL("image/png");
    const a = document.createElement("a");
    a.href = imageURL;
    a.download = Date.now() + ".png";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: 1 }}>
        <ReloadAnimation>
          <>
            <Typography align="center" sx={{ py: 2, fontWeight: 600 }}>
              店舗QRコード
            </Typography>
            <Typography align="center" sx={{ fontSize: "0.9rem", py: 0.5 }}>
              コンシューマ用御酒飲帳アプリケーションで下のQRを読み取ると、店舗で設定したチケット一覧を表示できます。
            </Typography>
            <Grid sx={{ my: 2, display: "flex", justifyContent: "center" }}>
              <a
                href="#"
                style={{
                  color: "#9f1831",
                  fontWeight: 600,
                  textDecoration: "none",
                }}
                onClick={() => downloadQR()}
              >
                QRコードをPNGでダウンロードする
              </a>
            </Grid>
            <Grid
              id="qrcode"
              sx={{ display: "flex", justifyContent: "center", py: 4 }}
            >
              <QRCode
                value={userStoreId}
                style={{ width: "250px", height: "250px" }}
              />
            </Grid>
            <Grid sx={{ pt: 1, display: "flex", justifyContent: "center" }}>
              <Button
                type="button"
                onClick={() => setOpen(false)}
                sx={{ color: "#000", fontWeight: 600, fontSize: "1rem" }}
              >
                閉じる
              </Button>
            </Grid>
          </>
        </ReloadAnimation>
      </Box>
    </Modal>
  );
};

export default ModalQR;
