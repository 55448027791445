import React from "react";
import { Grid, Typography } from "@mui/material";

interface ConfirmLabelItemProps {
  label: string;
  value?: string;
}

const ConfirmLabelItem: React.FC<ConfirmLabelItemProps> = ({
  label,
  value,
}) => {
  return (
    <Grid container spacing={1} sx={{ my: 0.5 }}>
      <Grid item xs={6}>
        <Typography sx={{ fontSize: "0.8rem" }}>{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          align="right"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          sx={{ fontSize: "0.8rem" }}
        >
          {value ? value : ""}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConfirmLabelItem;
