import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";

const TermsOfUse = () => {
  const { getTermsOfUseInfo } = useCommonApi();

  const { isLoading, data, isError } = useQuery([QueryKeys.termsOfUse], () =>
    getTermsOfUseInfo()
  );

  if (isError) return <ErrorHandler />;
  return (
    <Box
      sx={{
        paddingX: 2,
        mb: 9,
      }}
    >
      <Paper sx={{ padding: 2 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Typography
            dangerouslySetInnerHTML={{
              __html: data?.details?.contents,
            }}
          ></Typography>
        )}
      </Paper>
    </Box>
  );
};

export default TermsOfUse;
