import { Fragment, useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import {
  DeviceDetailMaintenanceType,
  DeviceDetailType,
  EdgeCommandParam,
  ModalDeviceType,
  SocketDeviceData,
} from "../../types";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import DeviceItem from "./DeviceItem";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import DialogDeviceRebootConfirm from "./DialogDeviceRebootConfirm";
import ReloadAnimation from "../../components/ReloadAnimation";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";
import { isOutVersion } from "../../utils/helper";

const RebootDevice = () => {
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState<boolean>(false);
  const [itemSelect, setItemSelect] = useState<string | null>(null);
  const [list, setList] = useState<DeviceDetailType[]>([]);

  const websocket = useRef<null | WebSocket>(null);
  const [connectionId, setConnectionId] = useState(null);
  const [socketData, setSocketData] = useState<SocketDeviceData | null>(null);
  const [resetDeviceCount, setResetDeviceCount] = useState<number>(0);

  const { getDevices, edgeCommand } = useDeviceApi();

  const { isLoading, isError } = useQuery(
    [
      QueryKeys.storeDevices,
      authCtx.user?.signInUserSession.idToken,
      authCtx.user?.store_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken && authCtx.user?.store_id)
        return getDevices({
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      onSuccess: (data) => {
        setList(data?.things);
      },
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const edgeCommandMutation = useMutation(
    (requestBody: EdgeCommandParam) => edgeCommand(requestBody),
    {
      onSuccess: async (data) => {},
      onError: async (error: any) => {
        console.log("🚀 ~ onError: ~ error:", error);
      },
    }
  );

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken || !authCtx.user?.store_id) return;

    let url = `wss://3y757tihmk.execute-api.ap-northeast-1.amazonaws.com/${process.env.REACT_APP_ENVNAME}?authorizationToken=${idToken}&customerStoreId=${authCtx.user?.store_id}`;
    // let url = `wss://3y757tihmk.execute-api.ap-northeast-1.amazonaws.com/${process.env.REACT_APP_ENVNAME}?authorizationToken=${idToken}`;

    websocket.current = new WebSocket(url);

    websocket.current.addEventListener("open", (event) => {
      console.log("connected");
    });

    websocket.current.addEventListener("message", (event) => {
      let socket_data = JSON.parse(event.data);
      setConnectionId(socket_data.connectionId);
      setSocketData(socket_data);

      console.log(`connection id is ${socket_data.connectionId}`);
    });

    websocket.current.addEventListener("close", () => {
      console.log("disconnected");
    });

    websocket.current.addEventListener("error", (error) => {
      console.error("WebSocket Error:", error);
    });

    return () => {
      if (websocket?.current?.readyState === WebSocket.OPEN) {
        websocket.current.close();
      }
    };
  }, []);

  useEffect(() => {
    // fix it
    if (socketData && list.length > 0) {
      setList((prevList) =>
        prevList.map((item: DeviceDetailType) =>
          item.thingName === socketData?.device_name
            ? {
                ...item,
                maintenance_qr: socketData?.status?.maintenance_qr,
                working: socketData?.status?.working,
              }
            : item
        )
      );
    }
    if (list.length > 0) {
      let resetDevices = list.filter((item) => {
        return (
          !item?.maintenance_qr === null ||
          !isOutVersion(
            item.softVersion,
            process.env.REACT_APP_OUT_VERSION || "0.55"
          )
        );
      });
      setResetDeviceCount(resetDevices.length);
    }
  }, [socketData, list]);

  const handleClose = () => {
    if (formValues?.selected?.length === resetDeviceCount)
      setValue("selected", []);
    if (itemSelect) setItemSelect(null);
    setOpen(false);
  };

  const defaultValues: { selected: string[] } = {
    selected: [],
  };

  const schema = Yup.object().shape({
    selected: Yup.array().optional(),
  });

  const onSubmit = (data: FieldValues) => {
    if (data?.selected.length <= 0) {
      let arr = list.filter((item) => {
        return (
          !item?.maintenance_qr === null ||
          !isOutVersion(
            item.softVersion,
            process.env.REACT_APP_OUT_VERSION || "0.55"
          )
        );
      });
      const result = arr.map((item) => item.thingName);
      setValue("selected", result);
    }
    setOpen(true);
  };

  const submit = (devices: string[]) => {
    let param: EdgeCommandParam = {
      command: {
        type: ModalDeviceType.BOTTLE, // reset
        body: {},
      },
      devices: devices,
    };
    edgeCommandMutation.mutate(param);

    const newList: DeviceDetailType[] = [...list];

    devices.forEach((device) => {
      const duplicateItem = newList.find(
        (item: DeviceDetailType) => item.thingName === device
      );
      if (duplicateItem) {
        duplicateItem.maintenance_qr = DeviceDetailMaintenanceType.WAITING;
      }
    });
    setList(newList);
    handleClose();
  };

  const { setValue, handleSubmit, watch } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  let formValues = watch();

  const onChange = (isChecked: boolean, id: string) => {
    if (isChecked) {
      setValue("selected", [...formValues?.selected, id]);
    } else {
      setValue(
        "selected",
        formValues?.selected.filter((val: string) => val !== id)
      );
    }
  };

  const reboot = (id: string) => {
    setItemSelect(id);
    setOpen(true);
  };

  if (isError) return <ErrorHandler />;
  return (
    <ReloadAnimation>
      <Grid sx={{ px: 2, pb: 11 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ mb: 1 }}>
            <ButtonSubmit type="submit" sx={{ width: 1, py: 1.5 }}>
              {formValues?.selected?.length > 0 &&
              formValues?.selected?.length !== resetDeviceCount ? (
                <>{formValues?.selected?.length}デバイスを再起動する</>
              ) : (
                <>すべて再起動する</>
              )}
            </ButtonSubmit>
          </Grid>
          {list.map((item: DeviceDetailType, index: number) => (
            <Fragment key={index}>
              <DeviceItem
                item={item}
                reboot={reboot}
                onChange={onChange}
                isDisabled={formValues?.selected?.length > 0}
              />
            </Fragment>
          ))}
          <DialogDeviceRebootConfirm
            open={open}
            value={itemSelect}
            values={formValues?.selected}
            handleClose={handleClose}
            handleSubmit={submit}
          />
        </form>
        {(isLoading || edgeCommandMutation.isLoading) && <Loader />}
      </Grid>
    </ReloadAnimation>
  );
};

export default RebootDevice;
