import { useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import ButtonSubmit from "../../../components/Buttons/ButtonSubmit";
import ButtonSimple from "../../../components/Buttons/ButtonSimple";
import ButtonCustom from "../../../components/Buttons/ButtonCustom";
import { ModalDeviceType, ModalDeviceParam } from "../../../types";
import Loader from "../../../components/Loader";

interface ModalBottleExchangeProps {
  item: ModalDeviceParam;
  isLoading: boolean;
  setItem: (value: ModalDeviceParam | null) => void;
  onSubmit: (param: ModalDeviceParam) => void;
}

enum CLEANING_STATUS {
  CLEAN = "CLEAN",
  CLEANED = "CLEANED",
}

type Step = {
  value: number;
  type?: CLEANING_STATUS | null;
};

const ModalBottleExchange: React.FC<ModalBottleExchangeProps> = ({
  item,
  isLoading,
  setItem,
  onSubmit,
}) => {
  enum FLOW {
    INIT,
    SAME_BRAND,
    DIFF_BRAND,
  }

  const [flow, setFlow] = useState<FLOW>(FLOW.INIT);
  const [step, setStep] = useState<Step>({
    value: 0,
  });

  const onSubmitWash = () => {
    let param: ModalDeviceParam = {
      ...item,
      option: {
        type: ModalDeviceType.CLEANING,
      },
    };
    onSubmit(param);

    setStep({ value: 1, type: CLEANING_STATUS.CLEAN });
  };

  const onSubmitSameBrand = () => {
    console.log("submit with same brand");
    let param: ModalDeviceParam = {
      ...item,
      option: {
        type: ModalDeviceType.EXCHANGE,
        reset: "SAME",
      },
    };
    resetStep();
    onSubmit(param);
  };

  const onSubmitDiffBrand = () => {
    if (step?.type === CLEANING_STATUS.CLEAN) {
      console.log("submit with diff brand to clean");
      let param: ModalDeviceParam = {
        ...item,
        option: {
          type: ModalDeviceType.EXCHANGE,
        },
      };
      resetStep();
      onSubmit(param);
    } else if (step?.type === CLEANING_STATUS.CLEANED) {
      let param: ModalDeviceParam = {
        ...item,
        option: {
          type: ModalDeviceType.EXCHANGE,
        },
      };
      console.log("submit with diff brand to cleaned");
      resetStep();
      onSubmit(param);
    }
  };

  const resetStep = () => {
    setStep({ value: 0, type: null });
    setFlow(FLOW.INIT);
    setItem(null);
  };

  if (isLoading) return <Loader />;
  return (
    <div>
      <Modal
        open={!!item}
        onClose={() => setItem(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "20px",
            px: 2,
            pt: 4,
            minWidth: 300,
          }}
        >
          {flow === FLOW.INIT && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                ボトル交換
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  my: 1,
                }}
              >
                対象：{item?.item?.thingNickname}
              </Typography>
              <Typography sx={{ fontSize: "0.6rem", textAlign: "center" }}>
                日本酒を呑む方に美味しくお酒を呑んでいただく方法をご提案いたします。
              </Typography>
              <Typography sx={{ fontSize: "0.6rem", textAlign: "center" }}>
                同じ銘柄でボトルだけ交換する
              </Typography>
              <Grid sx={{ my: 2 }}>
                <ButtonCustom
                  type="button"
                  sx={{
                    width: 1,
                    my: 1,
                    fontSize: "0.8rem",
                    height: "46px",
                    py: 5,
                    bgcolor: "#F02D51",
                    color: "#fff",
                    fontWeight: 600,
                    "&:hover": {
                      bgcolor: "#F02D51",
                    },
                  }}
                  onClick={() => setFlow(FLOW.SAME_BRAND)}
                >
                  同じ銘柄でボトルだけ交換する
                </ButtonCustom>
                <ButtonSubmit
                  type="button"
                  sx={{
                    width: 1,
                    height: "46px",
                    py: 5,
                  }}
                  onClick={() => setFlow(FLOW.DIFF_BRAND)}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                      }}
                    >
                      異なる銘柄のボトルに交換する
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "0.6rem",
                      }}
                    >
                      ※洗浄作業が入ります
                    </Typography>
                  </Grid>
                </ButtonSubmit>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <ButtonSimple
                    type="button"
                    sx={{
                      fontSize: "0.7rem",
                    }}
                    onClick={() => {
                      setItem(null);
                    }}
                  >
                    キャンセル
                  </ButtonSimple>
                </Grid>
              </Grid>
            </Box>
          )}
          {flow === FLOW.SAME_BRAND && (
            <>
              {step.value === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    同じ銘柄でボトルだけ交換する
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      my: 1,
                    }}
                  >
                    対象：{item?.item?.thingNickname}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    現在のまっせに設置されているボトルを交換してください。交換し終えたら次へをタップしてください。
                  </Typography>
                  <Grid
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "end",
                      width: 1,
                    }}
                  >
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#000", fontSize: "0.8rem" }}
                      onClick={() => setFlow(FLOW.INIT)}
                    >
                      キャンセル
                    </ButtonCustom>
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#9F1831", fontSize: "0.8rem" }}
                      onClick={() => setStep({ value: 1 })}
                    >
                      次へ
                    </ButtonCustom>
                  </Grid>
                </Box>
              )}
              {step.value === 1 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    同じ銘柄でボトルだけ交換する
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      my: 1,
                    }}
                  >
                    対象：{item?.item?.thingNickname}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    新しいお酒を充填します。お酒が設置されていることを確認し、準備できている場合は充填をタップしてください。
                  </Typography>
                  <Grid
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "end",
                      width: 1,
                    }}
                  >
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#000", fontSize: "0.8rem" }}
                      onClick={() => setStep({ value: 0 })}
                    >
                      戻る
                    </ButtonCustom>
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#9F1831", fontSize: "0.8rem" }}
                      onClick={onSubmitSameBrand}
                    >
                      充填
                    </ButtonCustom>
                  </Grid>
                </Box>
              )}
            </>
          )}
          {flow === FLOW.DIFF_BRAND && (
            <>
              {step.value === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    異なる銘柄のボトルに交換する
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      my: 1,
                    }}
                  >
                    対象：{item?.item?.thingNickname}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    美味しく呑んでいただくため、ボトルを交換する場合は洗浄作業を実施してください。
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    洗浄水と排水用具を確保し、設置できたら洗浄するをタップしてください。
                  </Typography>

                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      my: 2,
                      width: 1,
                    }}
                  >
                    <ButtonSubmit
                      type="button"
                      sx={{
                        width: 1,
                      }}
                      onClick={onSubmitWash}
                    >
                      洗浄する
                    </ButtonSubmit>
                    <ButtonCustom
                      type="button"
                      sx={{
                        width: 1,
                        fontWeight: 600,
                        color: "#9f1831",
                        backgroundColor: "#fff",
                        border: "1px solid #9f1831",
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                      onClick={() =>
                        setStep({ value: 2, type: CLEANING_STATUS.CLEANED })
                      }
                    >
                      洗浄済みの場合はこちら
                    </ButtonCustom>
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <ButtonSimple
                        type="button"
                        sx={{
                          fontSize: "0.7rem",
                        }}
                        onClick={() => setFlow(FLOW.INIT)}
                      >
                        戻る
                      </ButtonSimple>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {step.value === 1 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    異なる銘柄のボトルに交換する
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      my: 1,
                    }}
                  >
                    対象：{item?.item?.thingNickname}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    洗浄中です。のまっせの洗浄が終了しましたら、次へをタップしてください。一旦画面を閉じても洗浄は続きます。再度ボトル交換の作業に戻る場合は、「洗浄済みの場合はこちら」のボタンをタップして続きの作業を実施してください。
                  </Typography>
                  <Grid
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "end",
                      width: 1,
                    }}
                  >
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#000", fontSize: "0.8rem" }}
                      onClick={() => resetStep()}
                    >
                      一覧に戻る
                    </ButtonCustom>
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#9F1831", fontSize: "0.8rem" }}
                      onClick={() => setStep({ value: 2, type: step?.type })}
                    >
                      次へ
                    </ButtonCustom>
                  </Grid>
                </Box>
              )}
              {step.value === 2 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    異なる銘柄のボトルに交換する
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      my: 1,
                    }}
                  >
                    対象：{item?.item?.thingNickname}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    洗浄がお済みの場合は、新しいお酒のボトルを設置してください。設置し終えたら次へをタップしてください。
                  </Typography>
                  <Grid
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "end",
                      width: 1,
                    }}
                  >
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#000", fontSize: "0.8rem" }}
                      onClick={() => {
                        console.log("🚀 ~ value: step.type:", step.type);
                        setStep({
                          value: step.type === CLEANING_STATUS.CLEAN ? 1 : 0,
                        });
                      }}
                    >
                      戻る
                    </ButtonCustom>
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#9F1831", fontSize: "0.8rem" }}
                      onClick={() => setStep({ value: 3, type: step.type })}
                    >
                      次へ
                    </ButtonCustom>
                  </Grid>
                </Box>
              )}
              {step.value === 3 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    異なる銘柄のボトルに交換する
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      my: 1,
                    }}
                  >
                    対象：{item?.item?.thingNickname}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                    新しいお酒を充填します。お酒が設置されていることを確認し、準備できている場合は充填をタップしてください。
                  </Typography>
                  <Grid
                    sx={{
                      my: 2,
                      display: "flex",
                      justifyContent: "end",
                      width: 1,
                    }}
                  >
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#000", fontSize: "0.8rem" }}
                      onClick={() => {
                        setStep({ value: 2, type: step?.type });
                      }}
                    >
                      戻る
                    </ButtonCustom>
                    <ButtonCustom
                      type="button"
                      sx={{ color: "#9F1831", fontSize: "0.8rem" }}
                      onClick={onSubmitDiffBrand}
                    >
                      充填
                    </ButtonCustom>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
export default ModalBottleExchange;
