import React from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

interface ModalConfirmDeviceUpdateProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalConfirmDeviceUpdate: React.FC<ModalConfirmDeviceUpdateProps> = ({
  open,
  setOpen,
  onSubmit,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: 0.95 }}>
        <Typography align="center" sx={{ py: 2, fontWeight: 600 }}>
          設定を変更しています
        </Typography>
        <Typography align="center" sx={{ fontSize: "0.8rem", py: 1 }}>
          のまっせのご利用は1-2分ほどお待ち下さい。設定が反映されない場合はのまっせ詳細画面の更新ボタンをタップし、更新を確認してください
        </Typography>
        <Typography align="center" sx={{ fontSize: "0.8rem", py: 1 }}>
          数分経過しても更新されない状態が続く場合は、管理者に問い合わせてください。
        </Typography>
        <Grid sx={{ pt: 5 }}>
          <ButtonSubmit type="button" fullWidth onClick={onSubmit}>
            詳細画面に戻る
          </ButtonSubmit>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalConfirmDeviceUpdate;
