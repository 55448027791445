import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import * as Yup from "yup";
import { QuickPayType } from "../../types";
import { useMutation } from "react-query";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import { toastError } from "../../utils/toaster";

interface EasyCheckoutFormProps {
  cardNumberLast4: string;
  transactionId: string;
  stripePriceObjectId: string;
  stripePriceObjectQuantity: number;
}
const EasyCheckoutForm: React.FC<EasyCheckoutFormProps> = ({
  cardNumberLast4,
  transactionId,
  stripePriceObjectId,
  stripePriceObjectQuantity,
}) => {
  const navigate = useNavigate();
  const { loading, loadingOp } = useContext(GlobalDataContext);
  const { payQuick } = useBillingApi();

  const quickPayMutation = useMutation(
    (requestBody: QuickPayType) => payQuick(requestBody),
    {
      onSuccess: async (data) => {
        navigate(`/contract/new/complete`);
      },
      onError: async (error: any) => {
        loadingOp.finish();
        toastError("申し訳ありませんが、支払うことができませんでした。");
      },
    }
  );

  const schema = Yup.object().shape({
    card_number: Yup.string().required(),
  });

  const defaultValues = {
    card_number: "････ ････ ････  " + cardNumberLast4,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    let params: QuickPayType = {
      transaction_id: transactionId,
      stripePriceObjectId: stripePriceObjectId,
      stripePriceObjectQuantity: stripePriceObjectQuantity,
    };
    quickPayMutation.mutate(params);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ paddingY: 0.5 }}>
        <Controller
          render={({ field }) => (
            <FormControl fullWidth variant="standard">
              <InputLabel shrink htmlFor="card_number" sx={{ paddingY: 1 }}>
                力一卜番号
              </InputLabel>
              <BootstrapInput
                {...field}
                sx={{ width: 1 }}
                id="card_number"
                disabled={true}
              />
            </FormControl>
          )}
          name="card_number"
          control={control}
        />
        {!!errors?.card_number?.message && (
          <FormHelperText error id="card_number_helper">
            <>{errors?.card_number?.message}</>
          </FormHelperText>
        )}
      </Grid>
      <Grid sx={{ marginTop: "7px", fontSize: "12px", color: "#7964ED" }}>
        <Link
          to={
            "/billing/payment-method/update?redirect_url=" +
            window.location.href
          }
        >
          カード情報を変更する
        </Link>
      </Grid>

      <Grid sx={{ paddingTop: 4, paddingBottom: 1 }}>
        <ButtonSubmit type="submit" sx={{ width: 1, fontWeight: 600 }}>
          支払う
        </ButtonSubmit>
      </Grid>
      <Grid sx={{ paddingY: 0.5 }}>
        <Typography
          align="center"
          sx={{ fontSize: 11, color: "#4C4B4E", fontWeight: 600 }}
        >
          ボタンをタップすると注文が確定されます
        </Typography>
      </Grid>
      {loading && <Loader />}
    </form>
  );
};

export default EasyCheckoutForm;
