import AwsApi from "./aws-api";
import { QueryParam } from "../types";

export default class appeEkaApi extends AwsApi {
    async postEkaDataList(params: QueryParam, nextIndex: any): Promise<any> {
        let url = process.env.REACT_APP_ENVNAME + "/customer/store-ticket/printable?customerStoreId=" +
            params.customerStoreId;

        if (params.startDate && params.endDate) {
            url += "&startDate=" +
                params.startDate +
                "&endDate=" +
                params.endDate
        }

        if (params.issuedEkaSerial) {
            url += "&issuedEkaSerial=" +
                params.issuedEkaSerial
        }

        return await this.post(
            url,
            nextIndex
        );
    }
}