import React from "react";
import { Grid } from "@mui/material";

interface BadgeProps {
  text: string;
  color: string;
  bgColor: string;
}

const Badge: React.FC<BadgeProps> = ({ text, color, bgColor }) => {
  return (
    <Grid
      sx={{
        px: 1,
        borderRadius: "10px",
        display: "inline-block",
        backgroundColor: bgColor || "#B1B1B1",
        color: color || "#ffffff",
      }}
    >
      {text}
    </Grid>
  );
};

export default Badge;
