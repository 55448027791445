import React from "react";
import { Grid, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

interface StoreDeviceUpdateItemProps {
  label: string;
  value: string;
  textAlign?: string;
  handleClick?: () => void;
}

const StoreDeviceUpdateItem: React.FC<StoreDeviceUpdateItemProps> = ({
  label,
  value,
  textAlign,
  handleClick,
}) => {
  return (
    <Grid container spacing={1} sx={{ my: 0.5 }} onClick={handleClick}>
      <Grid item xs={6}>
        <Typography sx={{ fontSize: "13px" }}>{label}</Typography>
      </Grid>
      <Grid item xs={handleClick ? 5 : 6}>
        <Typography
          sx={{
            fontSize: "13px",
            color: "#000",
            wordWrap: "break-word",
            wordBreak: "break-all",
            textAlign: textAlign ? textAlign : "left",
          }}
        >
          {value}
        </Typography>
      </Grid>
      {handleClick && (
        <Grid item xs={1}>
          <Typography
            sx={{
              fontSize: "13px",
              color: "#000",
            }}
            align="right"
          >
            <ArrowForwardIos
              sx={{
                height: "0.8em",
                width: "0.8em",
              }}
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default StoreDeviceUpdateItem;
