import { useCallback, useContext, useMemo } from "react";
import { BeverageBodyParam } from "../../types";
import GlobalDataContext from "../use-global-data";
import appBeverageApi from "../../apis/beverage-api";

export const useBeverageApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const beverageApi = useMemo(
    () =>
      new appBeverageApi(
        process.env.REACT_APP_API_7 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getBeverageDataList = useCallback(
    async (params: BeverageBodyParam) => {
      loadingOp.start();
      const response = await beverageApi.getBeverageDataList(params);
      loadingOp.finish();
      return response;
    },
    [beverageApi]
  );

  const postBeverageDataList = useCallback(
    async (params: BeverageBodyParam, nextIndex: any) => {
      loadingOp.start();
      const response = await beverageApi.postBeverageDataList(params, nextIndex);
      loadingOp.finish();
      return response;
    },
    [beverageApi]
  );

  return { getBeverageDataList, postBeverageDataList };
};
