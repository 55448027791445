import { useContext } from "react";
import {
  Typography,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../custom-hooks/use-permissions";
import AuthContext from "../../custom-hooks/use-auth-context";
import ReloadAnimation from "../../components/ReloadAnimation";

const Management = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { hasPermission } = usePermissions();

  return (
    <ReloadAnimation>
      <Box
        sx={{
          paddingX: 2,
        }}
      >
        <Paper sx={{ width: 1, paddingY: 3, minHeight: "400px" }}>
          <MenuList dense>
            <MenuItem
              sx={{ paddingY: "10px", marginLeft: "10px" }}
              onClick={() => navigate("/management/reboot")}
            >
              <ListItemText>
                <Typography sx={{ fontWeight: "600" }}>
                  のまっせ再起動
                </Typography>
              </ListItemText>
              <ListItemIcon>
                <ArrowForwardIos
                  sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              sx={{ paddingY: "10px", marginLeft: "10px" }}
              onClick={() => navigate("/management/wireless")}
            >
              <ListItemText>
                <Typography sx={{ fontWeight: "600" }}>
                  無線LAN接続設定
                </Typography>
              </ListItemText>
              <ListItemIcon>
                <ArrowForwardIos
                  sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                />
              </ListItemIcon>
            </MenuItem>
          </MenuList>
        </Paper>
      </Box>
    </ReloadAnimation>
  );
};

export default Management;
