import { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Tabs,
  Tab,
  Grid,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { emailValidation, passwordValidation } from "../../utils/validations";
import BootstrapInput from "../Inputs/BootstrapInput";
import Loader from "../../components/Loader";
import BootstrapPassword from "../Inputs/BootstrapPassword";
import AuthContext from "../../custom-hooks/use-auth-context";
import PasswordField from "../../components/Inputs/PasswordField";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import CustomTitle from "../CustomTitle";

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
});

const Regist = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState(0);
  const [error, setError] = useState<string>("");
  const [t] = useTranslation();

  const schema = yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    // console.log("🚀 ~ file: RegisterForm.tsx:52 ~ onSubmit ~ data", data);
    try {
      const user = await Auth.signUp({
        username: data.email,
        password: data.confirm_password,
        attributes: {
          email: data.email,
          preferred_username: data.username,
        },
      });
      navigate("/confirm-phone-number", {
        state: {
          username: user?.userSub,
          email: data.email,
          password: data.confirm_password,
        },
        replace: true,
      });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  return (
    <Box sx={{ width: 1 }}>
      <Typography
        sx={{ paddingY: 2.5, fontWeight: 600 }}
        component="h1"
        variant="h6"
        align="center"
      >
        会員登録をする
      </Typography>
      <CustomTitle
        sx={{
          paddingY: 2.5,
          fontWeight: 200,
        }}
        align="left"
      >
        ご契約を開始するには、まずは会員登録をお願いします。会員登録が完了すると、ご契約手順が開始できます。
      </CustomTitle>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="email_lbl" sx={{ paddingY: 1 }}>
                    メールアドレス
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    type="email"
                    sx={{ width: 1 }}
                    id="email_lbl"
                    placeholder="メールアドレスを入力してください"
                  />
                </FormControl>
              )}
              name="email"
              control={control}
            />
            {!!errors?.email?.message && (
              <FormHelperText error id="email_helper">
                <>{errors?.email?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="usernameLbl" sx={{ paddingY: 1 }}>
                    会社・団体名
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="usernameLbl"
                    placeholder="会社名を入力してください"
                  />
                </FormControl>
              )}
              name="username"
              control={control}
            />
            {!!errors?.username?.message && (
              <FormHelperText error id="username_helper">
                <>{errors?.username?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Controller
            render={({ field }) => {
              return (
                <FormControl sx={{ my: 1, width: 1 }}>
                  <InputLabel
                    shrink
                    htmlFor="password"
                    sx={{
                      transform: "none",
                      fontSize: "12px",
                    }}
                  >
                    パスワード
                  </InputLabel>
                  <BootstrapPassword
                    field={field}
                    sx={{ width: 1 }}
                    id="password"
                    placeholder="パスワードを入力してください"
                  />
                </FormControl>
              );
            }}
            name="password"
            control={control}
          />
          {!!errors?.password?.message && (
            <FormHelperText error id="password_helper">
              <>{errors?.password?.message}</>
            </FormHelperText>
          )}

          <Controller
            render={({ field }) => {
              return (
                <FormControl sx={{ my: 1, width: 1 }}>
                  <InputLabel
                    shrink
                    htmlFor="confirm_password"
                    sx={{
                      transform: "none",
                      fontSize: "12px",
                    }}
                  >
                    パスワードの再確認
                  </InputLabel>
                  <BootstrapPassword
                    field={field}
                    sx={{ width: 1 }}
                    id="confirm_password"
                    placeholder="パスワードの再確認を入力してください"
                  />
                </FormControl>
              );
            }}
            name="confirm_password"
            control={control}
          />
          {!!errors?.confirm_password?.message && (
            <FormHelperText error id="confirm_password_helper">
              <>{errors?.confirm_password?.message}</>
            </FormHelperText>
          )}

          <Typography sx={{ fontSize: "0.8rem", marginY: 1 }}>
            アカウントの作成またはログインによって、御酒飲帳の
            <Link to="/terms-of-use" style={{ color: "#26CED5" }}>
              利用規約
            </Link>
            、
            <Link to="/privacy-policy" style={{ color: "#26CED5" }}>
              プライバシー
            </Link>
            ポリシーを理解し、同意したものとします。
          </Typography>
          <Typography sx={{ fontSize: "0.6rem", marginY: 1 }}>
            検証コードの画面が閉じられてしまった場合は、ログイン画面からログインすると再度検証画面が表示されます。
          </Typography>

          {error && (
            <Box
              sx={{
                width: 1,
                paddingY: 1,
                border: 1,
                color: "#9f1831",
                borderColor: "#9f1831",
                borderBlockColor: "#9f1831",
                margin: "35px 0 10px 0",
              }}
            >
              <Typography align="center">{t(error)}</Typography>
            </Box>
          )}

          <ButtonSubmit
            type="submit"
            sx={{ my: 3, width: 1, fontWeight: 600 }}
            variant="contained"
            disabled={loading}
          >
            会員登録
          </ButtonSubmit>
        </form>
      </Box>
    </Box>
  );
};
export default Regist;
