import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const StoreTicketSettingsDetailItem = (props: any) => {
  const { label, value } = props;
  return (
    <Box
      sx={{
        paddingX: 1,
        paddingY: 0.8,
        m: 2,
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "13px" }}>{label}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontSize: "13px",
              // whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {label === "チケット状況" && (
              <>
                <Typography
                  component={"span"}
                  sx={{
                    height: "8px",
                    width: "8px",
                    background: value === "使用可能" ? "#90EE90" : "#c3c3c3",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></Typography>
                &nbsp;&nbsp;
              </>
            )}
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoreTicketSettingsDetailItem;
