import { Fragment, useContext } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIos, Refresh } from "@mui/icons-material";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import StoreDeviceDetailItem from "./StoreDeviceDetailItem";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreDeviceDetail = () => {
  const { id } = useParams();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const { getDevice } = useDeviceApi();
  const { hasPermission } = usePermissions();

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useQuery(
      [
        QueryKeys.storeDevice,
        authCtx.user?.signInUserSession.idToken,
        id,
        authCtx.user?.store_id,
      ],
      () => {
        if (authCtx.user?.signInUserSession.idToken)
          return getDevice({
            thing_name: id,
            customer_store_id: authCtx.user?.store_id,
          });
        return;
      },
      {
        staleTime: 0,
        refetchOnWindowFocus: true,
      }
    );
  if (isError || isRefetchError) return <ErrorHandler />;
  // if (isLoading) return <Loader />;
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        padding: 0,
      }}
    >
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/store/devices")}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: "#4F4F62",
              fontWeight: 600,
              fontSize: "1.2rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {data?.thing?.thingNickname || "のまっせ"}
          </Typography>
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => refetch()}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ReloadAnimation>
        <>
          {isError ? (
            <ErrorHandler url="/store/devices" />
          ) : (
            <Box sx={{ minHeight: `calc(100vh - 160px)`, padding: 2, mb: 9 }}>
              {data ? (
                <Box>
                  <Box id="header-action-list">
                    {/* <Grid container spacing={3}>
                <Grid item xs={4}>
                  <StoreDeviceActionButton title="再起動" icon={RESTART_ICON} />
                </Grid>
                <Grid item xs={4}>
                  <StoreDeviceActionButton
                    title="洗浄モード"
                    icon={SHOWER_ICON}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StoreDeviceActionButton
                    title="酒交換"
                    icon={RECYCLE_ALCOHOLIC_ICON}
                  />
                </Grid>
              </Grid> */}

                    <Grid sx={{ mb: 2 }}>
                      <ButtonSubmit
                        fullWidth
                        sx={{
                          paddingY: 1.5,
                          paddingLeft: 2.5,
                          justifyContent: "flex-start",
                          color: "#fff",
                          fontWeight: 600,
                        }}
                        onClick={() => {
                          navigate(`/store/devices/${id}/update`);
                        }}
                        disabled={!hasPermission("device.update")}
                      >
                        のまっせの設定を変更する
                      </ButtonSubmit>
                    </Grid>
                  </Box>

                  <Paper elevation={3}>
                    <Box id="basic-settings-detail" sx={{ p: 2 }}>
                      <Typography sx={{ color: "#817D7D" }}>
                        基本設定
                      </Typography>
                      {/* <StoreDeviceDetailItem
                        label={"ステータス"}
                        value={"テスト"}
                      />  */}
                      <StoreDeviceDetailItem
                        label={"端末ID"}
                        value={data?.thing?.thingName || "未設定"}
                      />
                      <StoreDeviceDetailItem
                        label={"端末名(カスタム)"}
                        value={data?.thing?.thingNickname || "未設定"}
                      />
                      <StoreDeviceDetailItem
                        label={"流量"}
                        value={
                          data?.thing?.servePour
                            ? `${data?.thing?.servePour}ml`
                            : "未設定"
                        }
                      />
                      <StoreDeviceDetailItem
                        label={"ボトルサイズ"}
                        value={
                          data?.thing?.bottleSize
                            ? `${data?.thing?.bottleSize}ml`
                            : "未設定"
                        }
                      />
                      <StoreDeviceDetailItem
                        label={"現在のボトル内飲料量"}
                        value={
                          data?.thing?.bottleLiquidVolume
                            ? `${data?.thing?.bottleLiquidVolume}ml`
                            : "未設定"
                        }
                      />
                      <StoreDeviceDetailItem
                        label={"充填時の排出飲料量"}
                        value={
                          data?.thing?.serveFilling
                            ? `${data?.thing?.serveFilling}ml`
                            : "未設定"
                        }
                      />
                      <StoreDeviceDetailItem
                        label={"洗浄時の排出飲料量"}
                        value={
                          data?.thing?.serveWashing
                            ? `${data?.thing?.serveWashing}ml`
                            : "未設定"
                        }
                      />
                      <StoreDeviceDetailItem
                        label={"グループ"}
                        value={
                          data?.thing?.thingsBelongToGroup
                            ? data?.thing?.thingsBelongToGroup.map(
                                (group: any, index: number) => (
                                  <Fragment key={index}>
                                    {group.groupName || group.groupId}{" "}
                                  </Fragment>
                                )
                              )
                            : "未設定"
                        }
                        values={data?.thing?.thingsBelongToGroup}
                      />
                    </Box>
                    <Box id="sake-settings-detail" sx={{ px: 2, pb: 2 }}>
                      <Typography sx={{ color: "#817D7D" }}>酒設定</Typography>

                      {data?.thing?.thingType === "nomasse3rd" ? (
                        <>
                          <StoreDeviceDetailItem
                            label={"銘柄"}
                            value={data?.thing?.serveProductName || "未設定"}
                          />
                          <StoreDeviceDetailItem
                            label={"単品金額"}
                            value={
                              data?.thing?.serveProductPrice
                                ? `${data?.thing?.serveProductPrice}(JPY)`
                                : "未設定"
                            }
                          />
                        </>
                      ) : (
                        <>
                          <StoreDeviceDetailItem
                            label={"銘柄（ポートA）"}
                            value={
                              data?.thing?.portA?.serveProductName || "未設定"
                            }
                          />
                          <StoreDeviceDetailItem
                            label={"単品金額（ポートA）"}
                            value={
                              data?.thing?.portA?.serveProductPrice
                                ? `${data?.thing?.portA?.serveProductPrice}(JPY)`
                                : "未設定"
                            }
                          />
                          <StoreDeviceDetailItem
                            label={"銘柄（ポートB）"}
                            value={
                              data?.thing?.portB?.serveProductName || "未設定"
                            }
                          />
                          <StoreDeviceDetailItem
                            label={"単品金額（ポートB）"}
                            value={
                              data?.thing?.portB?.serveProductPrice
                                ? `${data?.thing?.portB?.serveProductPrice}(JPY)`
                                : "未設定"
                            }
                          />
                        </>
                      )}
                    </Box>
                  </Paper>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: `calc(100vh - 300px)`,
                    marginX: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography align="center" sx={{ fontWeight: 550 }}>
                      のまっせが見つかりません
                    </Typography>

                    <Typography
                      align="center"
                      sx={{
                        width: 1,
                        marginX: "auto",
                        marginY: 4,
                        fontSize: "0.8rem",
                      }}
                    >
                      再度この画面を表示し直してください。
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {(isLoading || isRefetching) && <Loader />}
        </>
      </ReloadAnimation>
    </Container>
  );
};

export default StoreDeviceDetail;
