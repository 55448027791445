import { FormControlLabel, FormControlLabelProps, styled } from "@mui/material";

const CustomFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    paddingLeft: "16px",
    paddingRight: "10px",
    marginLeft: 0,
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
    "& .Mui-checked": {
      color: "#9f1831",
    },
  })
);

export default CustomFormControlLabel;
