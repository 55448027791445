import AwsApi from "./aws-api";
import { BeverageBodyParam } from "../types";

export default class appBeverageApi extends AwsApi {
  async getBeverageDataList(params: BeverageBodyParam): Promise<any> {
    return await this.get(
      process.env.REACT_APP_ENVNAME + "/customer/drinking-history",
      {
        params,
      }
    );
  }

  async postBeverageDataList(params: BeverageBodyParam, nextIndex: any): Promise<any> {
    let url = process.env.REACT_APP_ENVNAME + "/customer/drinking-history?customerStoreId=" +
      params.customerStoreId + "&startDate=" +
      params.startDate +
      "&endDate=" +
      params.endDate;

    return await this.post(
      url,
      nextIndex
    );
  }
}
