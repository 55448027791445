import {
  CreateDeviceGroupBodyRequestType,
  CustomerStoreIdType,
  EdgeCommandParam,
  ThingNameType,
  UpdateDeviceBodyRequestType,
  UpdateDeviceGroupBodyRequestType,
  WifiSetupType,
} from "../types";
import AwsApi from "./aws-api";

export default class appDeviceApi extends AwsApi {
  async getDevices(params: any): Promise<any> {
    return await this.get("store/device/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getDevice(params: any): Promise<any> {
    return await this.get(
      "store/device/detail/" + process.env.REACT_APP_ENVNAME,
      {
        params,
      }
    );
  }

  async updateDevice(
    params: ThingNameType,
    body: UpdateDeviceBodyRequestType
  ): Promise<any> {
    return await this.put(
      "store/device/" + process.env.REACT_APP_ENVNAME,
      body,
      {
        params,
      }
    );
  }

  async createDeviceGroup(
    params: CustomerStoreIdType,
    body: CreateDeviceGroupBodyRequestType
  ): Promise<any> {
    return await this.post(
      "device/group/" + process.env.REACT_APP_ENVNAME,
      body,
      {
        params,
      }
    );
  }

  async updateDeviceGroup(
    body: UpdateDeviceGroupBodyRequestType
  ): Promise<any> {
    return await this.put(
      "device/group/" + process.env.REACT_APP_ENVNAME,
      body
    );
  }

  async getDeviceGroupList(params: any): Promise<any> {
    return await this.get("device/group/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getStoreTerminalNames(params: any): Promise<any> {
    return await this.get("store/device/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getDeviceGroupDetail(params: any): Promise<any> {
    return await this.get(
      "device/group/details/" + process.env.REACT_APP_ENVNAME,
      {
        params,
      }
    );
  }

  async getSakeList(): Promise<any> {
    return await this.get("sake/" + process.env.REACT_APP_ENVNAME);
  }

  async setupWifi(params: WifiSetupType): Promise<any> {
    return await this.post(
      "wifi-setting-qr-generate/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }

  async edgeCommand(params: EdgeCommandParam): Promise<any> {
    return await this.post(
      process.env.REACT_APP_ENVNAME + "/customer/device/command",
      params
    );
  }
}
