import { Fragment } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ReloadAnimation from "../../components/ReloadAnimation";
import AppNotificationSkeleton from "./AppNotificationSkeleton";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

interface AppNotificationsProps {
  list: any[] | null;
  isLoading: boolean;
}

const AppNotifications: React.FC<AppNotificationsProps> = ({
  list,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <ReloadAnimation>
      <>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              mb: 2,
            }}
          >
            <AppNotificationSkeleton />
            <AppNotificationSkeleton />
            <AppNotificationSkeleton />
          </Box>
        ) : (
          <Demo sx={{ borderRadius: "5px" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                boxShadow: "0px 3px 5px rgb(0 0 0 / 10%)",
                borderRadius: "5px",
                marginBottom: "25px",
              }}
            >
              {list && list.length > 0 ? (
                list.map((notification: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      {index > 0 && <Divider />}
                      <ListItem
                        alignItems="center"
                        onClick={() => {
                          navigate("/notifications/" + notification?.topics_id);
                        }}
                      >
                        <ListItemText
                          primary={
                            <Fragment>
                              <Typography sx={{ fontSize: "0.6rem" }}>
                                {moment(notification?.inst_ymdhi).format(
                                  "YYYY/MM/DD HH:mm"
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontWeight: 550,
                                  marginBottom: 1,
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  fontSize: "0.8rem",
                                }}
                                component="h1"
                                variant="h6"
                                align="center"
                              >
                                {notification?.subject}
                              </Typography>
                            </Fragment>
                          }
                          secondary={
                            <Fragment>
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontSize: "0.6rem",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}
                              >
                                {notification?.ext_2.substring(0, 50) + "..."}
                              </Typography>
                            </Fragment>
                          }
                        />
                        <ListItemIcon sx={{ justifyContent: "end" }}>
                          <ArrowForwardIos
                            sx={{
                              height: "0.6em",
                              width: "0.6em",
                            }}
                          />
                        </ListItemIcon>
                      </ListItem>
                    </Fragment>
                  );
                })
              ) : (
                <Box
                  sx={{
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "0.8rem" }}>
                    アプリのお知らせはありません
                  </Typography>
                </Box>
              )}
            </List>
          </Demo>
        )}
      </>
    </ReloadAnimation>
  );
};

export default AppNotifications;
