import { Fragment } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";

const Commerce = () => {
  const navigate = useNavigate();
  const { getCommerce } = useCommonApi();

  const { isLoading, data, isError } = useQuery([QueryKeys.commerce], () =>
    getCommerce()
  );

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                fontWeight: 600,
                fontSize: "1.2rem",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              特定商取引法に基づく表記
            </Typography>
          </Box>
          <Box sx={{ width: "36px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      {/* <Box
        sx={{
          minHeight: "calc(100vh - 160px)",
        }}
      > */}
      {isError ? (
        <ErrorHandler url="/contract/new" />
      ) : (
        <Paper sx={{ marginX: 2, padding: 2, overflowY: "scroll", mb: 9 }}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: data?.details?.contents,
            }}
          ></Typography>
        </Paper>
      )}
      {/* </Box> */}
    </Fragment>
  );
};

export default Commerce;
