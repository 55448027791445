import BaseApi from "./base-api";
import { PostContactBodyRequestType } from "../types";

export default class appCommonApi extends BaseApi {
  async postContact(params: PostContactBodyRequestType): Promise<any> {
    return (await this.post("rcms-api/4/form", params)).data;
  }

  async getNotifications(): Promise<any> {
    return (await this.get("rcms-api/3/news-cs")).data;
  }

  async getNotification(topics_id: string): Promise<any> {
    return (await this.get("rcms-api/3/news-cs/" + topics_id)).data;
  }

  async getContract(): Promise<any> {
    return (await this.get("rcms-api/3/contract-cs/18")).data;
  }

  async getCommerce(): Promise<any> {
    return (await this.get("rcms-api/3/commerce-cs/20")).data;
  }

  async getWifiInfo(): Promise<any> {
    return (await this.get("rcms-api/3/commerce-cs/22")).data;
  }

  async getWifiInfoSimple(): Promise<any> {
    return (await this.get("rcms-api/3/commerce-cs/help-wifi-simple")).data;
  }

  async getPrivacyPolicyInfo(): Promise<any> {
    return (await this.get("rcms-api/3/info/policy/privacy-policy")).data;
  }

  async getTermsOfUseInfo(): Promise<any> {
    return (await this.get("rcms-api/3/info/term/terms-of-use")).data;
  }
}
