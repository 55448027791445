import { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { FieldValues } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { UpdateStoreRawParamsType } from "../../types";
import { toastError, toastSuccess } from "../../utils/toaster";
import { useStoreApi } from "../../custom-hooks/apis/use-store-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { QR_ICON } from "../../assets/icons";
import { usePermissions } from "../../custom-hooks/use-permissions";
import Loader from "../../components/Loader";
import StoreForm from "../../components/StoreForm";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ModalQR from "../../components/Modals/QrModal";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreSettings = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { hasPermission } = usePermissions();
  const { getStore, updateStore } = useStoreApi();
  const [openQRModal, setOpenQRModal] = useState<boolean>(false);

  const { isLoading, data, isError } = useQuery(
    [
      QueryKeys.store,
      authCtx.user?.signInUserSession.idToken,
      authCtx.user?.store_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken && authCtx.user?.store_id)
        return getStore({
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const updateStoreMutation = useMutation(
    (requestBody: UpdateStoreRawParamsType) => updateStore(requestBody),
    {
      onSuccess: async (data: any) => {
        toastSuccess("店舗情報の更新に成功");
      },
      onError: async (error: any) => {
        console.log("failed ", error);
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage = "店舗情報の更新に失敗しました。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const onSubmit = async (raw_data: FieldValues) => {
    let params: UpdateStoreRawParamsType = {
      customer_store_id: data?.branch?.store?.customer_store_id,
      storeType: raw_data?.storeType,
      storeName: raw_data?.storeName,
      storeSubname: raw_data?.storeSubname,
      storeRegion: raw_data?.storeRegion,
      storeLocationState: raw_data?.storeLocationState,
      storeLocationCity: raw_data?.storeLocationCity,
    };

    if (raw_data?.storeImage) {
      params = {
        ...params,
        storeImage: raw_data?.storeImage,
      };
    }
    updateStoreMutation.mutate(params);
  };

  if (isLoading) return <Loader />;
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        padding: 0,
      }}
    >
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ minWidth: "64px" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIos />
            </IconButton>
          </Grid>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: "#4F4F62",
              fontWeight: 600,
              fontSize: "1.2rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            店舗設定
          </Typography>

          <ButtonSubmit
            type="button"
            onClick={() => setOpenQRModal(true)}
            sx={{ py: "5px", px: "10px", minWidth: "40px", boxShadow: 5 }}
          >
            <Grid sx={{ flexDirection: "column" }}>
              <img src={QR_ICON} alt="qr_icon" width="30" height="30" />
              <Typography
                sx={{
                  fontSize: "0.45rem",
                  color: "#fff",
                  marginTop: "-8px",
                  fontWeight: "bold",
                }}
              >
                店舗QR
              </Typography>
            </Grid>
          </ButtonSubmit>
        </Toolbar>
      </AppBar>
      <ReloadAnimation>
        <>
          {isError ? (
            <ErrorHandler />
          ) : (
            <Box sx={{ minHeight: `calc(100vh - 160px)`, px: 2, pb: 2, mb: 7 }}>
              <StoreForm
                isLoading={updateStoreMutation.isLoading}
                data={{
                  id: data?.branch?.store?.customer_store_id || null,
                  storeName:
                    data?.branch?.store?.Config_Value?.store_name || null,
                  storeSubname:
                    data?.branch?.store?.Config_Value?.store_subname || null,
                  storeType:
                    data?.branch?.store?.Config_Value?.store_type || null,
                  storeRegion:
                    data?.branch?.store?.Config_Value?.store_country || null,
                  storeLocationState:
                    data?.branch?.store?.Config_Value?.store_prefectures ||
                    null,
                  storeLocationCity:
                    data?.branch?.store?.Config_Value?.store_municipalities ||
                    null,
                  storeImage:
                    data?.branch?.store?.Config_Value?.store_image_url ||
                    undefined,
                }}
                submit={(data: FieldValues) => onSubmit(data)}
                isDisabled={!hasPermission("settings.update")}
              />
              <ModalQR
                open={openQRModal}
                setOpen={setOpenQRModal}
                userStoreId={authCtx.user?.store_id || ""}
              />
            </Box>
          )}
        </>
      </ReloadAnimation>
    </Container>
  );
};

export default StoreSettings;
