import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import EasyCheckoutForm from "./EasyCheckoutForm";
import CurrencyFormat from "react-currency-format";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";

const NewContractPayment = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const [store, setStore] = useState<any | null>(null);
  const { getPaymentCard } = useBillingApi();
  const authCtx = useContext(AuthContext);

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.paymentCard, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getPaymentCard();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );
  useEffect(() => {
    let new_contract_raw = localStorage.getItem("new_contract");
    let new_contract = new_contract_raw ? JSON.parse(new_contract_raw) : null;
    setStore(new_contract);
  }, []);

  if (!store) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          height: "100vh",
          bgcolor: "#E6E4EB",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Grid
            sx={{
              paddingY: 2,
            }}
          >
            <Typography
              sx={{
                width: 1,
                fontWeight: 550,
              }}
              align="center"
            >
              チャージに失敗しました
            </Typography>
          </Grid>

          <Grid
            sx={{
              paddingTop: 3,
              paddingBottom: 5,
            }}
          >
            <Typography sx={{ width: 1, fontSize: "0.8rem" }} align="center">
              チャージに失敗しました。再度やり直すか、管理者に問い合わせてください。
            </Typography>
          </Grid>
          {/* {chargeReturnUrl && (
              <Grid
                sx={{
                  paddingY: 3,
                }}
              >
                <ButtonSubmit fullWidth type="button" onClick={() => backApp()}>
                  戻る
                </ButtonSubmit>
              </Grid>
            )} */}
        </Box>
      </Container>
    );
  }
  if (isLoading) return <Loader />;
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          height: `100vh`,
          bgcolor: "#E6E4EB",
          paddingX: 0,
        }}
      >
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
        >
          <Toolbar
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => {
                navigate("/contract/new/confirmation");
              }}
              sx={{
                bgcolor: "#fff",
                border: "1px solid #000",
              }}
            >
              <ArrowBackIos sx={{ height: "1rem", width: "1rem" }} />
            </IconButton>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                bgcolor: "#fff",
                paddingY: 0.5,
                paddingX: 5,
                borderRadius: 15,
                fontSize: "12pt",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              決済
            </Typography>
            <Box sx={{ width: "31px", height: "1px" }}></Box>
          </Toolbar>
        </AppBar>
        {isError ? (
          <ErrorHandler />
        ) : (
          <Fragment>
            <Paper
              sx={{
                mb: 3,
                padding: 2,
                color: "#fff",
                marginX: "16px",
                backgroundColor: "#9f1831",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={7}>
                  <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {store?.plan?.name}
                  </Typography>
                  <Typography sx={{ fontSize: "0.8rem", opacity: 0.8 }}>
                    単価
                    <CurrencyFormat
                      value={store?.plan?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                    円、数量{store?.plan?.intervalCount}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      display: "inline-block",
                      fontSize: "1.2rem",
                      alignSelf: "end",
                    }}
                  >
                    <CurrencyFormat
                      value={store?.plan?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      display: "inline-block",
                      alignSelf: "end",
                    }}
                  >
                    円/
                    {store?.plan?.interval === "month" && "月"}
                    {store?.plan?.interval === "year" && "年"}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              sx={{
                marginY: 3,
                paddingX: 2,
                paddingTop: "30px",
                marginX: "16px",
                overflowY: "scroll",
                maxHeight: `calc(100vh - 250px)`,
                paddingBottom: `calc(4.5em + env(safe-area-inset-bottom))`,
              }}
            >
              <Grid sx={{ marginBottom: 2 }}>
                <Typography
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: 600 }}
                >
                  支払い情報の入力
                </Typography>
              </Grid>
              {data && data?.card && (
                <EasyCheckoutForm
                  transactionId={store?.transaction_id}
                  stripePriceObjectId={store?.plan?.id}
                  stripePriceObjectQuantity={store?.contract_units}
                  cardNumberLast4={data?.card?.last4}
                />
              )}
            </Paper>
          </Fragment>
        )}
      </Container>
    </>
  );
};

export default NewContractPayment;
