import { useContext } from "react";
import * as Yup from "yup";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  accountHolderValidation,
  accountNumberValidation,
  bankCodeValidation,
  bankNameValidation,
  branchCodeValidation,
  branchNameValidation,
} from "../../utils/validations";
import { loadStripe, Token } from "@stripe/stripe-js";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { useMutation } from "react-query";
import { toastError, toastSuccess } from "../../utils/toaster";
import { useNavigate } from "react-router-dom";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY || ""
);

const BillingDepositAccountChange = () => {
  const navigate = useNavigate();
  const { updateAccountPaymentInformation } = useBillingApi();

  const updateAccountPaymentInformationMutation = useMutation(
    (requestBody: Token) => updateAccountPaymentInformation(requestBody),
    {
      onSuccess: async (data) => {
        toastSuccess("アカウント情報の更新に成功しました。");
        navigate("/billing/deposit-account");
      },
      onError: async (error: any) => {
        toastError(
          "申し訳ありませんが、アカウントを変更することはできません。"
        );
      },
    }
  );

  const schema = Yup.object().shape({
    bankName: bankNameValidation,
    branchName: branchNameValidation,
    routing_number_1: bankCodeValidation,
    routing_number_2: branchCodeValidation,
    accountNumber: accountNumberValidation,
    accountHolder: accountHolderValidation,
  });

  const defaultValues = {
    bankName: "",
    branchName: "",
    routing_number_1: "",
    routing_number_2: "",
    accountNumber: "",
    accountHolder: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const submit = async (data: FieldValues) => {
    const stripe = await stripePromise;
    const response = await stripe?.createToken("bank_account", {
      country: "JP",
      currency: "jpy",
      routing_number: data?.routing_number_1 + data?.routing_number_2,
      account_number: data?.accountNumber,
      account_holder_name: data?.accountHolder,
      account_holder_type: "individual",
      account_type: "futsu",
    });
    let params: Token | undefined = response?.token;
    if (response?.error) {
      // cannot create token
      toastError("申し訳ありませんが、アカウントを変更することはできません。");
    }
    if (params) updateAccountPaymentInformationMutation.mutate(params);
  };

  return (
    <Paper sx={{ marginX: 2, padding: 2, mb: 8 }}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="bankName" sx={{ paddingY: 1 }}>
                  銀行名
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="bankName"
                  error={!!errors?.bankName?.message}
                />
              </FormControl>
            )}
            name="bankName"
            control={control}
          />
          {!!errors?.bankName?.message && (
            <FormHelperText error id="bankName_helper">
              <>{errors?.bankName?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="branchName" sx={{ paddingY: 1 }}>
                  支店名
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="branchName"
                  error={!!errors?.branchName?.message}
                />
              </FormControl>
            )}
            name="branchName"
            control={control}
          />
          {!!errors?.branchName?.message && (
            <FormHelperText error id="branchName_helper">
              <>{errors?.branchName?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor="routing_number_1"
                  sx={{ paddingY: 1 }}
                >
                  銀行コード（4ケタ）
                </InputLabel>
                <BootstrapInput
                  {...field}
                  type="number"
                  sx={{ width: 1 }}
                  id="routing_number_1"
                  error={!!errors?.routing_number_1?.message}
                />
              </FormControl>
            )}
            name="routing_number_1"
            control={control}
          />
          {!!errors?.routing_number_1?.message && (
            <FormHelperText error id="routing_number_1_helper">
              <>{errors?.routing_number_1?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor="routing_number_2"
                  sx={{ paddingY: 1 }}
                >
                  支店コード（3ケタ）
                </InputLabel>
                <BootstrapInput
                  {...field}
                  type="number"
                  sx={{ width: 1 }}
                  id="routing_number_2"
                  error={!!errors?.routing_number_2?.message}
                />
              </FormControl>
            )}
            name="routing_number_2"
            control={control}
          />
          {!!errors?.routing_number_2?.message && (
            <FormHelperText error id="routing_number_2_helper">
              <>{errors?.routing_number_2?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="accountNumber" sx={{ paddingY: 1 }}>
                  口座番号（4〜8ケタ）
                </InputLabel>
                <BootstrapInput
                  {...field}
                  type="number"
                  sx={{ width: 1 }}
                  id="accountNumber"
                  error={!!errors?.accountNumber?.message}
                />
              </FormControl>
            )}
            name="accountNumber"
            control={control}
          />
          {!!errors?.accountNumber?.message && (
            <FormHelperText error id="accountNumber_helper">
              <>{errors?.accountNumber?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="accountHolder" sx={{ paddingY: 1 }}>
                  口座名義人{" "}
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="accountHolder"
                  error={!!errors?.accountHolder?.message}
                />
              </FormControl>
            )}
            name="accountHolder"
            control={control}
          />
          {!!errors?.accountHolder?.message && (
            <FormHelperText error id="accountHolder_helper">
              <>{errors?.accountHolder?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <ButtonSubmit type="submit" variant="contained" sx={{ width: 1 }}>
            変更
          </ButtonSubmit>
        </Grid>
      </form>
      {updateAccountPaymentInformationMutation.isLoading && <Loader />}
    </Paper>
  );
};

export default BillingDepositAccountChange;
