import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import MiniLoader from "../../components/Loader/MiniLoader";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import ErrorHandler from "../../components/ErrorHandler";

const DialogWifiSetupInfo = (props: any) => {
  const { open, handleClose } = props;
  const { getWifiInfo } = useCommonApi();

  const { isLoading, data, isError } = useQuery([QueryKeys.wifiInfo], () =>
    getWifiInfo()
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "100%",
          },
        },
      }}
    >
      {isError ? (
        <ErrorHandler back={handleClose} />
      ) : (
        <Box>
          <DialogTitle id="responsive-dialog-title">
            <Typography
              variant="h6"
              component="h1"
              sx={{ fontWeight: 600, fontSize: "16px" }}
              align="center"
            >
              のまっせを無線LANに接続する方法
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box>
                {isLoading ? (
                  <MiniLoader />
                ) : (
                  <Typography sx={{ fontSize: "13px", color: "#000" }}>
                    {parse(String(data?.details?.contents))}
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              autoFocus
              sx={{ color: "#000", fontWeight: 600 }}
            >
              閉じる
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default DialogWifiSetupInfo;
