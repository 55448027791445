import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { AlertDetailType, SelectedAlertType } from "../../types";
import { CheckCircle } from "@mui/icons-material";

interface AppAlertItemProps {
  alert: AlertDetailType;
  submit: (selectedAlert: SelectedAlertType) => void;
}

const AppAlertItem: React.FC<AppAlertItemProps> = ({ alert, submit }) => {
  return (
    <Paper
      sx={{ p: 2, borderRadius: 0 }}
      onClick={() => submit({ filename: alert.filename, device: alert.device })}
    >
      <Grid container>
        <Grid item xs={10}>
          <Typography sx={{ fontSize: "0.6rem" }}>{alert?.datetime}</Typography>
          <Typography
            sx={{
              wordWrap: "break-word",
              wordBreak: "break-all",
              fontSize: "0.6rem",
            }}
          >
            {alert?.deviceNickname}
          </Typography>
          <Typography
            sx={{
              display: "inline",
              fontWeight: 550,
              marginBottom: 1,
              wordWrap: "break-word",
              wordBreak: "break-all",
              fontSize: "0.8rem",
            }}
          >
            {alert?.title}
          </Typography>

          <Typography
            sx={{
              fontSize: "0.6rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {alert?.detail && alert?.detail.length > 50
              ? alert.detail.substring(0, 50) + "..."
              : alert.detail}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <CheckCircle sx={{ color: "#9f1831" }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AppAlertItem;
