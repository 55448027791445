import { useContext } from "react";
import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Home,
  Store,
  Login,
  Notifications,
  Settings,
} from "@mui/icons-material";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useNotificationApi } from "../../custom-hooks/apis/use-notification";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";

const Footer = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { tab, setCurrentTab } = useContext(GlobalDataContext);
  const { getUnreadLog } = useNotificationApi();

  const { data } = useQuery(
    [QueryKeys.unreadAlerts, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getUnreadLog();
      return;
    }
  );

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: 0,
        height: "76px",
        zIndex: 1000,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        sx={{ bgcolor: "#9f1831", paddingY: "10px", height: "100%" }}
        value={tab}
        onChange={(event, newValue) => {
          setCurrentTab(newValue);
          navigate(newValue);
        }}
      >
        <BottomNavigationAction
          label="トップ"
          value="/"
          style={{ color: "#fff", fontWeight: 600 }}
          icon={<Home fontSize="large" />}
        />

        <BottomNavigationAction
          label="店舗"
          value="/store"
          style={{ color: "#fff", fontWeight: 600 }}
          icon={<Store fontSize="large" />}
        />

        {authCtx.isLoggedIn ? (
          <BottomNavigationAction
            label="設定"
            value="/settings"
            style={{ color: "#fff", fontWeight: 600 }}
            icon={<Settings fontSize="large" />}
          />
        ) : (
          <BottomNavigationAction
            label="ログイン"
            value="/login"
            style={{ color: "#fff", fontWeight: 600 }}
            icon={<Login fontSize="large" />}
          />
        )}
        <BottomNavigationAction
          label="お知らせ"
          value="/notifications"
          style={{ color: "#fff", fontWeight: 600 }}
          icon={
            <Box sx={{ position: "relative" }}>
              <Notifications fontSize="large" />
              {data?.numberOfUnreadLog > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    ml: 2.5,
                    background: "#fff",
                    px:
                      data?.numberOfUnreadLog < 10
                        ? "8px"
                        : data?.numberOfUnreadLog < 100
                        ? "4px"
                        : "1px",
                    borderRadius: "50%",
                    mt: -0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#9f1831",
                      fontWeight: 600,
                      fontSize: "0.8rem",
                    }}
                  >
                    {data?.numberOfUnreadLog < 100
                      ? data?.numberOfUnreadLog
                      : "99+"}
                  </Typography>
                </Box>
              )}
            </Box>
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Footer;
