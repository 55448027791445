import { Fragment, useContext, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { TransactionType } from "../../types";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import HelpIcon from "@mui/icons-material/Help";
import CurrencyFormat from "react-currency-format";
import TransactionItem from "./TransactionItem";
import DialogBillingPaymentInformation from "./DialogBillingPaymentInformation";
import ErrorHandler from "../../components/ErrorHandler";

const BillingPaymentInformation = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [openInfo, setOpenInfo] = useState(false);
  const { getPaymentInformation } = useBillingApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.paymentInformation, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getPaymentInformation();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isLoading) return <Loader />;
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        padding: 0,
      }}
    >
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ minWidth: "64px" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate("/billing")}
            >
              <ArrowBackIos />
            </IconButton>
          </Grid>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: "#4F4F62",
              fontWeight: 600,
              fontSize: "1.2rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            入金情報
          </Typography>

          {/* <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setOpenInfo(true);
            }}
          >
            <HelpIcon />
          </IconButton> */}
          <Box sx={{ width: "48px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      {isError ? (
        <ErrorHandler />
      ) : (
        <Box sx={{ minHeight: `calc(100vh - 160px)`, padding: 2 }}>
          <Grid>
            <Paper sx={{ p: 2, borderRadius: 0 }}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "0.8rem",
                }}
              >
                現在の残高
              </Typography>
              <Grid
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "inline-block",
                    fontWeight: 600,
                    fontSize: "2rem",
                    alignSelf: "end",
                    mx: 0.5,
                  }}
                >
                  <CurrencyFormat
                    value={data?.total}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
                <Typography
                  sx={{
                    display: "inline-block",
                    fontWeight: 600,
                    alignSelf: "end",
                    fontSize: "1rem",
                  }}
                >
                  円
                </Typography>
              </Grid>
              <Grid sx={{ py: 2 }}>
                <Typography sx={{ textAlign: "center", fontSize: "0.8rem" }}>
                  入金予定日：2023年12月10日
                </Typography>
              </Grid>
              <Grid
                sx={{ pb: 1, pt: 2, display: "flex", justifyContent: "center" }}
              >
                <ButtonSubmit
                  type="button"
                  sx={{ px: 3, fontWeight: 600 }}
                  onClick={() => navigate("/billing/deposit-account")}
                >
                  支払い方法の変更
                </ButtonSubmit>
              </Grid>
            </Paper>
          </Grid>
          {data?.transactions && data?.transactions?.length > 0 && (
            <Grid sx={{ my: 2 }}>
              <Paper sx={{ p: 2, mb: 4 }}>
                <Grid>
                  {data?.transactions?.map(
                    (transaction: TransactionType, index: number) => (
                      <Fragment key={index}>
                        <TransactionItem item={transaction} />
                      </Fragment>
                    )
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}
          <DialogBillingPaymentInformation
            open={openInfo}
            handleClose={() => setOpenInfo(false)}
          />
        </Box>
      )}
    </Container>
  );
};

export default BillingPaymentInformation;
