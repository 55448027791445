import { Fragment } from "react";
import { Box, Paper } from "@mui/material";
import { TabType } from "../../types";
import TabItem from "./TabItem";

interface TabDeviceSmProps {
  tabs: TabType[];
  selectedTab: number;
  setTab: (tab: number) => void;
}
const TabDeviceSm: React.FC<TabDeviceSmProps> = ({
  tabs,
  selectedTab,
  setTab,
}) => {
  return (
    <Paper
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        my: 2,
        borderRadius: "25px",
      }}
    >
      {tabs.map((tab, index) => (
        <Box key={index} onClick={() => setTab(index)}>
          <TabItem
            label={tab.label}
            icon={tab.icon}
            selected={selectedTab === index}
            labelSize="1rem"
          />
        </Box>
      ))}
    </Paper>
  );
};

export default TabDeviceSm;
