import { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CreateStore } from "../../types";
import { useMutation } from "react-query";
import { useCustomerApi } from "../../custom-hooks/apis/use-customer-api";
import { toastError, toastSuccess } from "../../utils/toaster";
import Loader from "../../components/Loader";
import ButtonBack from "../../components/Buttons/ButtonBack";
import ConfirmLabelItem from "../../components/ConfirmLabelItem";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import AuthContext from "../../custom-hooks/use-auth-context";

const step = ["お客様情報", "店舗情報", "ご契約台数", "在庫確認", "確認/決済"];

const NewContractConfirmation = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading, loadingOp } = useContext(GlobalDataContext);
  const [store, setStore] = useState<any>({});

  useEffect(() => {
    let raw_new_contract = localStorage.getItem("new_contract");
    if (!raw_new_contract || raw_new_contract === null) {
      navigate("/");
      return;
    }
    let new_contract = JSON.parse(raw_new_contract);
    setStore(new_contract);
  }, []);

  const handleSubmit = () => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    let params = {
      ...store,
      transaction_id: authCtx?.user?.id + "" + currentTimestamp,
    };
    localStorage.setItem("new_contract", JSON.stringify(params));
    navigate("/contract/new/payment");
  };

  if (loading) return <Loader />;
  return (
    <Box sx={{ paddingX: 2 }}>
      <Paper sx={{ paddingX: 1, paddingY: 2, marginBottom: "50px" }}>
        <Box sx={{ backgroundColor: "#9f1831", padding: "5px 0 5px 0" }}>
          <Typography
            sx={{ color: "#fff", fontWeight: "600", paddingLeft: "10px" }}
          >
            確認
          </Typography>
        </Box>
        <Grid paddingX={2}>
          <Grid id="contract-info">
            <Grid sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: 600 }}>ご契約情報</Typography>
            </Grid>
            <ConfirmLabelItem label={"プラン名"} value={store?.plan?.name} />
            <ConfirmLabelItem
              label={"ご契約台数"}
              value={`${store?.contract_units}台`}
            />
            <Typography sx={{ fontSize: "0.7rem", my: 1 }}>
              ※気ご期約を討頂いているタイミングによって、在庫が確保できない場合がらざいます。その場合は弊社より別途道連絡いたしますのでご了承ください。
            </Typography>
          </Grid>

          <Grid>
            <ConfirmLabelItem label={"ご要望"} />
            <Typography sx={{ fontSize: "0.8rem" }}>
              {store?.customRequest}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <Typography
              sx={{ fontSize: "0.7rem", fontWeight: 550, width: 0.85 }}
            >
              内容にお間違いがない場合は、次へをタップしてください。決済両面に移動します。
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            gap: "5px",
            flexDirection: "column",
            mt: 5,
          }}
        >
          <ButtonSubmit
            type="button"
            sx={{ width: 1, fontWeight: 600 }}
            onClick={handleSubmit}
          >
            次へ
          </ButtonSubmit>
          <ButtonBack
            type="button"
            sx={{ width: 1, fontWeight: 600 }}
            onClick={() => navigate("/contract/new")}
          >
            戻る
          </ButtonBack>
        </Grid>
      </Paper>
    </Box>
  );
};

export default NewContractConfirmation;
