import { useContext, useEffect } from "react";
import AuthContext from "./custom-hooks/use-auth-context";
import Router from "./routes";
import moment from "moment";
import "moment/locale/ja";
import { useQuery } from "react-query";
import { QueryKeys } from "./global-state/react-query-keys";
import { useCustomerApi } from "./custom-hooks/apis/use-customer-api";
import { Box, Typography } from "@mui/material";
import Loader from "./components/Loader";

const App = () => {
  const authCtx = useContext(AuthContext);
  const { getStatus } = useCustomerApi();

  const { isLoading, isError } = useQuery(
    [QueryKeys.status, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getStatus();
      return;
    }
  );

  // if (isError)
  //   return (
  //     <Box
  //       sx={{
  //         height: `calc(100vh - 250px)`,
  //         marginX: "16px",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Box>
  //         <Typography align="center" sx={{ fontWeight: 550 }}>
  //           ページの表示に失敗しました
  //         </Typography>

  //         <Typography
  //           align="center"
  //           sx={{
  //             width: 0.85,
  //             marginX: "auto",
  //             marginY: 4,
  //             fontSize: "0.8rem",
  //           }}
  //         >
  //           ページ表示時にエラーが起きてしまいました。トップからやり直すか、不具合の場合は管理者に問い合わせてください。
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );

  useEffect(() => {
    authCtx.assessLoggedInState();
    moment.locale("ja");
  }, []);

  if (isLoading) return <Loader />;
  return <Router />;
};

export default App;
