import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Lock } from "@mui/icons-material";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const RequireAuth = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: "150px", marginBottom: "50px" }}
      >
        <Lock sx={{ fontSize: "75px" }} />
      </Box>

      <Typography
        sx={{ paddingY: 1, fontWeight: 550, fontSize: "1rem" }}
        component="h1"
        variant="h6"
        align="center"
      >
        こちらのコンテンツはログインが必要です。
      </Typography>
      <Typography
        sx={{ fontSize: "0.8rem", width: 0.8, marginX: "auto" }}
        align="center"
      >
        下記ポタンよりログインまたは新規会員登録 を行ってください。
      </Typography>

      <Box marginY={3} display="flex" justifyContent="center">
        <ButtonSubmit sx={{ width: 0.9 }} onClick={() => navigate("/login")}>
          ログイン・会具登録
        </ButtonSubmit>
      </Box>
    </Box>
  );
};

export default RequireAuth;
