// カラーボタンなし
import { Button, ButtonProps, styled } from "@mui/material";

const ButtonSimple = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#000",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "&:disabled": {
    opacity: 0.5,
  },
}));

export default ButtonSimple;
