import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

interface DialogDeviceRebootConfirmProps {
  open: boolean;
  value: string | null;
  values: string[];
  handleClose: () => void;
  handleSubmit: (devices: string[]) => void;
}

const DialogDeviceRebootConfirm: React.FC<DialogDeviceRebootConfirmProps> = ({
  open,
  value,
  values,
  handleSubmit,
  handleClose,
}) => {
  const submit = () => {
    let devices: string[] = [];

    if (values.length > 0) devices = values;
    else if (value) devices = [value];

    handleSubmit(devices);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: 16,
          paddingTop: "25px",
          paddingBottom: "15px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography sx={{ fontWeight: 600 }} align="center">
          のまっせを再起動しますか？
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid>
            <Typography sx={{ fontSize: "0.82rem" }}>
              作動中ののまっせを再起動すると不具合が起きる原因になります。また、再起動するのまっせに間違いがないかご確認の上再起動してください。
            </Typography>
          </Grid>
          <Grid sx={{ mt: 2, mb: 1 }}>
            <ul>
              {values.map((value: string, index: number) => (
                <li key={index}>{value}</li>
              ))}
              {value && <li key={"single_item"}>{value}</li>}
            </ul>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end" }}>
        <Button onClick={handleClose} sx={{ color: "#000" }}>
          キャンセル
        </Button>
        <Button onClick={submit} sx={{ color: "#9F1831", fontWeight: 600 }}>
          再起動
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeviceRebootConfirm;
