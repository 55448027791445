import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { Controller, FieldValues, useForm } from "react-hook-form";
import AuthContext from "../../custom-hooks/use-auth-context";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { verificationCodeValidation } from "../../utils/validations";

const RegisterConfirmEmailPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { state } = useLocation();
  const authCtx = useContext(AuthContext);
  const [t] = useTranslation();

  const schema = Yup.object().shape({
    verification_code: verificationCodeValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!state?.username || !state?.password || !state?.email) {
      navigate("/", { replace: true });
    }
  }, [state?.username]);

  const resendCode = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.resendSignUp(state?.username);
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    try {
      await Auth.confirmSignUp(state?.username, data.verification_code);
      const user = await Auth.signIn({
        username: state?.username,
        password: state?.password,
      });
      authCtx.onLogin(user);
      navigate("/", { replace: true });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{ paddingY: 2.5 }}
          component="h1"
          variant="h6"
          align="center"
        >
          メールにコードを送りました
        </Typography>
        <Typography sx={{ fontSize: "0.8rem" }}>
          コードが届きました。ログインするには、{state?.email}
          のメールに送信されたコードを入力してください。到着まで1分ほどかかる場合があります。
        </Typography>

        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              sx={{ my: 1 }}
              type="number"
              id="filled-basic"
              placeholder="コードを入力してください"
            />
          )}
          name="verification_code"
          control={control}
        />
        {!!errors?.verification_code?.message && (
          <FormHelperText error id="verification_code_helper">
            <>{errors?.verification_code?.message}</>
          </FormHelperText>
        )}

        {error && (
          <Box
            sx={{
              width: 1,
              paddingY: 1,
              border: 1,
              color: "#9f1831",
              borderColor: "#9f1831",
              borderBlockColor: "#9f1831",
              margin: "10px 0 10px 0",
            }}
          >
            <Typography align="center">{t(error)}</Typography>
          </Box>
        )}

        <ButtonSubmit
          type="submit"
          sx={{ my: 1, width: 1, fontWeight: 600 }}
          variant="contained"
          disabled={loading}
        >
          確認する
        </ButtonSubmit>
      </form>
      <Button
        type="button"
        onClick={(e) => resendCode(e)}
        sx={{ my: 1, width: 1, fontWeight: 600 }}
        variant="outlined"
      >
        再送する
      </Button>
    </Box>
  );
};

export default RegisterConfirmEmailPage;
