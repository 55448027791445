import { useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import { ModalDeviceParam } from "../../../types";
import ButtonCustom from "../../../components/Buttons/ButtonCustom";
import Loader from "../../../components/Loader";

interface ModalConfirmCleaningProps {
  item: ModalDeviceParam;
  isLoading: boolean;
  setItem: (value: ModalDeviceParam | null) => void;
  onSubmit: () => void;
}

const ModalConfirmCleaning: React.FC<ModalConfirmCleaningProps> = ({
  item,
  isLoading,
  setItem,
  onSubmit,
}) => {
  const [step, setStep] = useState<number>(0);

  const handleSubmit = () => {
    onSubmit();
    setStep(1);
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <Modal
        open={!!item}
        onClose={() => setItem(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "20px",
            minWidth: 300,
            px: 2,
            pt: 4,
          }}
        >
          {step === 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                のまっせを洗浄しますか？
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  my: 1,
                }}
              >
                対象：{item?.item?.thingNickname}
              </Typography>
              <Typography sx={{ fontSize: "0.8rem", textAlign: "center" }}>
                必ず洗浄する準備ができているかを確認してから洗浄してください
              </Typography>

              <Grid
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "end",
                  width: 1,
                }}
              >
                <ButtonCustom
                  type="button"
                  sx={{ color: "#000", fontSize: "0.8rem" }}
                  onClick={() => setItem(null)}
                >
                  キャンセル
                </ButtonCustom>
                <ButtonCustom
                  type="button"
                  sx={{ color: "#9F1831", fontSize: "0.8rem" }}
                  onClick={handleSubmit}
                >
                  洗浄
                </ButtonCustom>
              </Grid>
            </Box>
          )}
          {step === 1 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                pb: 2,
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>
                洗浄処理が実行されました
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  my: 1,
                }}
              >
                対象：{item?.item?.thingNickname}
              </Typography>
              <Typography sx={{ fontSize: "0.7rem" }}>
                洗浄処理を実行しています。処理が開始されると「処理待機中」から「洗浄中」に変わります。洗浄が終わると再度ボタンが押せるようになります。
              </Typography>

              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <ButtonCustom
                  type="button"
                  sx={{ color: "#9F1831", fontSize: "0.8rem", fontWeight: 600 }}
                  onClick={() => {
                    setStep(0);
                    setItem(null);
                  }}
                >
                  ok
                </ButtonCustom>
              </Grid>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
export default ModalConfirmCleaning;
