import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./custom-hooks/use-auth-context";
import { GlobalDataContextProvider } from "./custom-hooks/use-global-data";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18n";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { jaJP as coreJaJp } from "@mui/material/locale";
import { jaJP } from "@mui/x-date-pickers/locales";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
      staleTime: process.env.APP_ENV === "admin" ? 900000 : 90000,
    },
  },
});

const theme = createTheme(
  {},
  jaJP, // x-date-pickers translations
  coreJaJp // core translations
);

root.render(
  <React.StrictMode>
    <GlobalDataContextProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <App />
            </QueryClientProvider>
          </ThemeProvider>
        </BrowserRouter>
      </AuthContextProvider>
    </GlobalDataContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
