import { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Help, ArrowBackIos } from "@mui/icons-material";
import {
  wifiSSIDValidation,
  wifiPasswordValidation,
} from "../../utils/validations";
import { useMutation } from "react-query";
import { WifiSetupType } from "../../types";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import BootstrapPassword from "../../components/Inputs/BootstrapPassword";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import DialogWifiSetupInfo from "./DialogWifiSetupInfo";
import DialogWifiSetupQR from "./DialogWifiSetupQR";
import Loader from "../../components/Loader";
import { toastError } from "../../utils/toaster";
import ReloadAnimation from "../../components/ReloadAnimation";

const DeviceWireless = () => {
  const navigate = useNavigate();
  const [openInfo, setOpenInfo] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [qr, setQR] = useState<string | null>(null);
  const { hasPermission } = usePermissions();
  const { setupWifi } = useDeviceApi();

  const SetupWifiMutation = useMutation(
    (requestBody: WifiSetupType) => setupWifi(requestBody),
    {
      onSuccess: async (data) => {
        setOpenQR(true);
        setQR(data?.encodeImage);
      },
      onError: async (error: any) => {
        // console.log("🚀 ~ onError: ~ error:", error);
        toastError("Wi-Fi設定用QRコードを生成することができませんでした。");
      },
    }
  );

  const reset = () => {
    setValue("wifi_ssid", "");
    setValue("wifi_password", "");
  };

  const schema = yup.object().shape({
    wifi_ssid: wifiSSIDValidation,
    wifi_password: wifiPasswordValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    SetupWifiMutation.mutate({
      ssid: data?.wifi_ssid,
      password: data?.wifi_password,
    });
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  };

  const handleQRClose = () => {
    setOpenQR(false);
    reset();
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        padding: 0,
      }}
    >
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/management")}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: "#4F4F62",
              fontWeight: 600,
              fontSize: "1.2rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            無線LAN接続設定
          </Typography>
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            disabled={!hasPermission("wifi.read")}
            onClick={() => setOpenInfo(true)}
          >
            <Help />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ReloadAnimation>
        <Box sx={{ minHeight: `calc(100vh - 160px)`, padding: 2 }}>
          <Paper sx={{ padding: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid sx={{ paddingY: 1 }}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        shrink
                        htmlFor="wifi_ssid"
                        sx={{ paddingY: 1 }}
                      >
                        SSID
                      </InputLabel>
                      <BootstrapInput
                        {...field}
                        sx={{ width: 1 }}
                        id="wifi_ssid"
                        type="text"
                        error={!!errors?.wifi_ssid?.message}
                        inputProps={{
                          autocomplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        placeholder="SSIDを入力してください"
                      />
                    </FormControl>
                  )}
                  name="wifi_ssid"
                  control={control}
                />
                {!!errors?.wifi_ssid?.message && (
                  <FormHelperText error id="wifi_ssid_helper">
                    <>{errors?.wifi_ssid?.message}</>
                  </FormHelperText>
                )}
              </Grid>
              <Grid sx={{ paddingY: 1 }}>
                <Controller
                  render={({ field }) => {
                    return (
                      <FormControl sx={{ my: 1, width: 1 }}>
                        <InputLabel
                          shrink
                          htmlFor="wifi_password"
                          sx={{
                            transform: "none",
                            fontSize: "12px",
                          }}
                        >
                          パスワード
                        </InputLabel>
                        <BootstrapPassword
                          field={field}
                          sx={{ width: 1 }}
                          id="wifi_password"
                          placeholder="パスワードを入力してください"
                        />
                      </FormControl>
                    );
                  }}
                  name="wifi_password"
                  control={control}
                />
                {!!errors?.wifi_password?.message && (
                  <FormHelperText error id="wifi_password_helper">
                    <>{errors?.wifi_password?.message}</>
                  </FormHelperText>
                )}
              </Grid>
              <Grid sx={{ paddingY: 2 }}>
                <ButtonSubmit
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!hasPermission("wifi.create")}
                >
                  無線LAN接続設定用QRコードの生成
                </ButtonSubmit>
              </Grid>
            </form>
          </Paper>
          <DialogWifiSetupInfo open={openInfo} handleClose={handleInfoClose} />
          <DialogWifiSetupQR
            qr={qr}
            open={openQR}
            handleClose={handleQRClose}
          />

          {SetupWifiMutation.isLoading && <Loader />}
        </Box>
      </ReloadAnimation>
    </Container>
  );
};

export default DeviceWireless;
