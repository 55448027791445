import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        zIndex: 1500,
        overflow: "show",
        margin: "auto",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: 1,
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "rgba(204, 204, 204, 0.5)",
      }}
    >
      <Box sx={{ paddingX: 5.5, paddingY: 5, bgcolor: "#fff" }}>
        <CircularProgress sx={{ color: "#ccc" }} />
      </Box>
    </Box>
  );
};

export default Loader;
