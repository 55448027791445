import React from "react";
import { Grid, Typography } from "@mui/material";
import { GLASS_ICON } from "../../assets/icons";

interface GlassIconProps {
  currentSize: number;
  size: number;
}

const GlassIcon: React.FC<GlassIconProps> = ({ currentSize, size }) => {
  return (
    <>
      <Grid>
        <Typography
          sx={{ fontSize: "0.75rem", fontWeight: 600 }}
          align="center"
        >
          {currentSize}ml
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <img src={GLASS_ICON} height={15} width={15} />
        <Typography sx={{ fontSize: "0.6rem" }}>{size}ml</Typography>
      </Grid>
    </>
  );
};

export default GlassIcon;
