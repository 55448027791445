import {
  Box,
  Container,
  Dialog,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import ButtonSubmit from "../Buttons/ButtonSubmit";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const DialogFullWidth = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    margin: 0,
  },
}));

interface ImageUploaderProps {
  src: any;
  open: boolean;
  locked?: boolean;
  defaultAspect?: number;
  handleClose: () => void;
  setImgSrc: (blobURI: any, blob: any) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  open,
  src,
  defaultAspect,
  locked,
  setImgSrc,
  handleClose,
}) => {
  const [crop, setCrop] = useState<Crop>();
  const [aspect, setAspect] = useState<number | undefined>(
    defaultAspect ? defaultAspect : 16 / 9
  );
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef("");

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleSubmit = () => {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      setImgSrc(URL.createObjectURL(blob), blob);
      handleClose();
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <DialogFullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            margin: 0,
          }}
        >
          <Box
            sx={{
              bgcolor: "#E6E4EB",
            }}
          >
            <Box sx={{ paddingY: 2 }}>
              <Typography
                variant="h6"
                component="h1"
                align="center"
                sx={{ fontWeight: 600 }}
              >
                画像のリサイズ
              </Typography>
            </Box>
            <Grid sx={{ display: "flex", justifyContent: "center", width: 1 }}>
              <Box sx={{ width: 0.95 }}>
                <ReactCrop
                  aspect={aspect}
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  minWidth={100}
                  minHeight={100}
                  locked={locked || false}
                >
                  <img
                    ref={imgRef}
                    src={src}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                {!!completedCrop && (
                  <>
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          display: "none",
                          border: "1px solid black",
                          objectFit: "contain",
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                    </div>
                  </>
                )}
              </Box>
            </Grid>
            <Box sx={{ paddingY: 1, paddingX: 2 }}>
              <ButtonSubmit sx={{ width: 1 }} onClick={() => handleSubmit()}>
                アップロードをする
              </ButtonSubmit>
            </Box>
          </Box>
        </Container>
      </>
    </DialogFullWidth>
  );
};
export default ImageUploader;
