import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {
  DeviceDetailType,
  ModalDeviceParam,
  ModalDeviceType,
} from "../../types";
import { isOutVersion } from "../../utils/helper";
import DeviceDetailMaintenance from "./DeviceDetailMaintenance";
import ButtonExchange from "./ButtonExchange";
import GlassIcon from "../../components/Icons/GlassIcon";
import moment from "moment";

interface BottleReplacementListItemProps {
  item: DeviceDetailType;
  setItem: (value: ModalDeviceParam) => void;
}

const BottleReplacementListItem: React.FC<BottleReplacementListItemProps> = ({
  item,
  setItem,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{ p: 2, my: 1, cursor: "pointer", position: "relative" }}
    >
      <DeviceDetailMaintenance
        isOutVersion={isOutVersion(
          item.softVersion,
          process.env.REACT_APP_OUT_VERSION || "0.55"
        )}
        status={item?.maintenance_qr}
      />
      <Grid container sx={{ height: "40px" }}>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "0.8rem",
            }}
          >
            {item?.thingNickname || item?.thingName}
          </Typography>
          {item?.actionData?.exchange && item?.actionData?.exchange !== "" && (
            <Typography
              sx={{
                fontSize: "0.6rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              最終交換日時:
              <>
                {moment
                  .unix(parseInt(item?.actionData?.exchange))
                  .format("YYYY/MM/DD(ddd) HH:mm")}
              </>
            </Typography>
          )}
          {item?.serveProductName && (
            <Typography
              sx={{
                fontSize: "0.6rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              銘柄:
              <>{item?.serveProductName}</>
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <GlassIcon
            currentSize={parseFloat(item?.bottleLiquidVolume || "0")}
            size={parseFloat(item.bottleSize || "0")}
          />
        </Grid>
        {/* <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.2rem",
            marginRight: { md: "20px", lg: "50px", xl: "0px" },
          }}
        >
          <WarningIcon sx={{ color: "#9f1831" }}></WarningIcon>
        </Grid> */}
        <Grid
          item
          xs={3}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          {/* {item?.bottleStatus} */}
          {item?.bottleStatus === "caution" ||
          item?.bottleStatus === "alert" ? (
            <WarningIcon
              sx={{ color: "#9f1831", marginRight: "8px", fontSize: "1.2rem" }}
            ></WarningIcon>
          ) : null}
          <ButtonExchange
            type="button"
            variant="contained"
            onClick={() =>
              setItem({ item: item, type: ModalDeviceType.BOTTLE })
            }
          >
            交換
          </ButtonExchange>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BottleReplacementListItem;
