import React from "react";
import { Grid, Typography } from "@mui/material";
import { TransactionType } from "../../types";
import CurrencyFormat from "react-currency-format";

interface TransactionItemProps {
  item: TransactionType;
}

const TransactionItem: React.FC<TransactionItemProps> = ({ item }) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{ display: "flex", alignItems: "center", py: 1 }}
    >
      <Grid item xs={8}>
        <Typography sx={{ fontSize: "0.8rem", fontWeight: 550 }}>
          入金
        </Typography>
        <Typography sx={{ fontSize: "0.7rem", color: "#908B8B" }}>
          2023年11月30日
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontSize: "0.9rem", textAlign: "right" }}>
          <CurrencyFormat
            value={"30200"}
            displayType={"text"}
            thousandSeparator={true}
          />
          円
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TransactionItem;
