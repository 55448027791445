import { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CreateStore } from "../../types";
import { useMutation } from "react-query";
import { useCustomerApi } from "../../custom-hooks/apis/use-customer-api";
import { toastError, toastSuccess } from "../../utils/toaster";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ButtonBack from "../../components/Buttons/ButtonBack";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import Loader from "../../components/Loader";
import ConfirmLabelItem from "../../components/ConfirmLabelItem";

const step = ["お客様情報", "店舗情報", "ご契約台数", "在庫確認", "確認/決済"];

const ConfirmationInfo = () => {
  const navigate = useNavigate();
  const { loading, loadingOp } = useContext(GlobalDataContext);
  const [store, setStore] = useState<any>({});
  const { createStore } = useCustomerApi();

  const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    ".Mui-completed .MuiSvgIcon-root": {
      color: "#9f1831",
    },
    ".Mui-active .MuiSvgIcon-root": {
      color: "#9f1831",
    },
  }));

  const createStoreMutation = useMutation(
    (requestBody: CreateStore) => createStore(requestBody),
    {
      onSuccess: async (data: any) => {
        toastSuccess("ユーザー登録に成功しました！");
      },
      onError: async (error: any) => {
        loadingOp.finish();
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage = "登録するこのできませんでした。再試行してください！";
        toastError(errMessage);
      },
    }
  );

  useEffect(() => {
    let raw_store_info = localStorage.getItem("store_info");
    if (!raw_store_info || raw_store_info === null) {
      navigate("/");
      return;
    }
    let store_info = JSON.parse(raw_store_info);
    setStore(store_info);
  }, []);

  const handleSubmit = () => {
    let params: CreateStore = {
      storeName: store?.storeName,
      storeSubname: store?.storeSubName,
      storeCountry: store?.storeRegion,
      storeType: store?.storeType,
      storePostalCode: "",
      storeState: store?.storeLocationState,
      storeCity: store?.storeLocationCity,
      storePhoneNumber: "",
    };

    createStoreMutation.mutate(params);
  };

  if (loading) return <Loader />;
  return (
    <Box sx={{ px: 2, pb: 4 }}>
      <Box sx={{ paddingY: 2 }}>
        <Stepper activeStep={4} alternativeLabel>
          {step.map((label) => (
            <Step key={label}>
              <CustomStepLabel sx={{ fontSize: "0.8px" }}>
                {label}
              </CustomStepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Paper sx={{ paddingX: 1, paddingY: 2, marginBottom: "50px" }}>
        <Box sx={{ backgroundColor: "#9f1831", padding: "5px 0 5px 0" }}>
          <Typography
            sx={{ color: "#fff", fontWeight: "600", paddingLeft: "10px" }}
          >
            確認
          </Typography>
        </Box>
        <Grid paddingX={2}>
          <Grid id="store-info">
            <Grid sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: 600 }}>店舗情報</Typography>
            </Grid>
            <ConfirmLabelItem label={"店舗名"} value={store?.storeName} />
            <ConfirmLabelItem
              label={"サブ店舗名"}
              value={store?.storeSubName}
            />
            <ConfirmLabelItem label={"店舖形態"} value={store?.storeType} />
            <ConfirmLabelItem
              label={"都道府県"}
              value={store?.storeLocationState}
            />
            <ConfirmLabelItem
              label={"市町村以降"}
              value={store?.storeLocationCity}
            />
          </Grid>
          <Grid id="contract-info">
            <Grid sx={{ mt: 5 }}>
              <Typography sx={{ fontWeight: 600 }}>ご契約情報</Typography>
            </Grid>
            <ConfirmLabelItem label={"プラン名"} value={store?.plan?.name} />
            <ConfirmLabelItem
              label={"ご契約台数"}
              value={`${store?.contract_units}台`}
            />
            <Typography sx={{ fontSize: "0.7rem", my: 1 }}>
              ※気ご期約を討頂いているタイミングによって、在庫が確保できない場合がらざいます。その場合は弊社より別途道連絡いたしますのでご了承ください。
            </Typography>
          </Grid>

          <Grid>
            <ConfirmLabelItem label={"ご要望"} />
            <Typography sx={{ fontSize: "0.8rem" }}>
              {store?.customRequest}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <Typography
              sx={{ fontSize: "0.7rem", fontWeight: 550, width: 0.85 }}
            >
              内容にお間違いがない場合は、次へをタップしてください。決済両面に移動します。
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            gap: "5px",
            flexDirection: "column",
            mt: 5,
          }}
        >
          <ButtonSubmit
            type="button"
            sx={{ width: 1, fontWeight: 600 }}
            onClick={handleSubmit}
          >
            次へ
          </ButtonSubmit>
          <ButtonBack
            type="button"
            sx={{ width: 1, fontWeight: 600 }}
            onClick={() => navigate("/contract/plan")}
          >
            戻る
          </ButtonBack>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ConfirmationInfo;
