import { Fragment } from "react";
import { Box, Paper } from "@mui/material";
import { TabType } from "../../types";
import TabItem from "./TabItem";

interface TabDeviceLgProps {
  tabs: TabType[];
  selectedTab: number;
  setTab: (tab: number) => void;
}
const TabDeviceLg: React.FC<TabDeviceLgProps> = ({
  tabs,
  selectedTab,
  setTab,
}) => {
  return (
    <Paper
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "10px",
        my: 2,
        borderRadius: "25px",
        position: "absolute",
        left: "10%",
        right: "10%",
        top: "100px",
      }}
    >
      {tabs.map((tab, index) => (
        <Box key={index} onClick={() => setTab(index)}>
          <TabItem
            label={tab.label}
            icon={tab.icon}
            selected={selectedTab === index}
          />
        </Box>
      ))}
    </Paper>
  );
};

export default TabDeviceLg;
