import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  confirmNewPasswordValidation,
  newPasswordValidation,
} from "../../utils/validations";
import { Box, FormControl, FormHelperText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import PasswordField from "../../components/Inputs/PasswordField";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import AuthContext from "../../custom-hooks/use-auth-context";
import { toastError } from "../../utils/toaster";

const NewPassword = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { state } = useLocation();
  const [t] = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const schema = Yup.object().shape({
    new_password: newPasswordValidation,
    confirm_password: confirmNewPasswordValidation,
  });

  useEffect(() => {
    if (!state?.email || !state?.password) navigate("/login");
    if (authCtx?.isLoggedIn) navigate("/");
  }, [state?.email, state?.password, authCtx?.isLoggedIn]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    try {
      const curr_user = await Auth.signIn(state?.email, state?.password);
      const user = await Auth.completeNewPassword(
        curr_user,
        data?.new_password
      );
      authCtx.onLogin(user);
      navigate("/", { replace: true });
    } catch (error: any) {
      console.log("🚀 ~ file: NewPassword.tsx:52 ~ onSubmit ~ error:", error);
      if (String(error).startsWith("InvalidPasswordException")) {
        setError("パスワードには記号を使用することです");
      } else if (
        String(error).startsWith("NotAuthorizedException: Incorrect username")
      ) {
        toastError(
          "パスワードを変更できませんでした。もう一度お試しください。"
        );
        navigate("/login", { replace: true });
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field }) => {
            return (
              <FormControl sx={{ my: 1, width: 1 }}>
                <PasswordField
                  field={field}
                  id={"password"}
                  placeholder={"新しいパスワード"}
                />
              </FormControl>
            );
          }}
          name="new_password"
          control={control}
        />
        {!!errors?.new_password?.message && (
          <FormHelperText error id="new_password_helper">
            <>{errors?.new_password?.message}</>
          </FormHelperText>
        )}
        <Controller
          render={({ field }) => {
            return (
              <FormControl sx={{ my: 1, width: 1 }}>
                <PasswordField
                  field={field}
                  id={"confirm_password"}
                  placeholder={"新しいパスワードの再確認"}
                />
              </FormControl>
            );
          }}
          name="confirm_password"
          control={control}
        />
        {!!errors?.confirm_password?.message && (
          <FormHelperText error id="confirm_password_helper">
            <>{errors?.confirm_password?.message}</>
          </FormHelperText>
        )}
        {error && (
          <Box
            sx={{
              width: 1,
              paddingY: 1,
              border: 1,
              color: "#9f1831",
              borderColor: "#9f1831",
              borderBlockColor: "#9f1831",
              margin: "10px 0 10px 0",
            }}
          >
            <Typography align="center">{t(error)}</Typography>
          </Box>
        )}
        <ButtonSubmit
          type="submit"
          sx={{ my: 1, width: 1, fontWeight: 600 }}
          variant="contained"
          disabled={loading}
        >
          パスワードを変更
        </ButtonSubmit>
      </form>
    </Box>
  );
};

export default NewPassword;
