import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { GroupItem, PortType, UpdateDeviceRawParamsType } from "../../types";
import {
  findDuplicatesById,
  isNumberString,
  isOutVersion,
} from "../../utils/helper";
import { toastError, toastSuccess } from "../../utils/toaster";
import {
  terminalNameValidation,
  flowRateValidation,
  singleAmountValidation,
  brandNameValidation,
  colorValidation,
  bgColorValidation,
  bottleVolumeValidation,
  bottleLiquidVolumeValidation,
} from "../../utils/validations";
import { usePermissions } from "../../custom-hooks/use-permissions";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import StoreDeviceUpdateItem from "./StoreDeviceUpdateItem";
import ModalBrandName from "../../components/Modals/BrandNameModal";
import ModalGroupNameSelection from "../../components/Modals/GroupNameSelectionModal";
import ModalConfirmDeviceUpdate from "../../components/Modals/ConfirmDeviceUpdateModal";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreDeviceUpdate = () => {
  const [openGroupNameModal, setOpenGroupNameModal] = useState(false);
  const [openBrandNameModalA, setOpenBrandNameModalA] = useState(false);
  const [portA, setPortA] = useState<PortType | null>(null);
  const [portB, setPortB] = useState<PortType | null>(null);
  // const [sakeSettingsTypeA, setSakeSettingsTypeA] = useState<string>("0");
  // const [sakeSettingsTypeB, setSakeSettingsTypeB] = useState<string>("0");
  const [rawParams, setRawParams] = useState<UpdateDeviceRawParamsType | null>(
    null
  );
  const [confirmDeviceUpdateModal, setConfirmDeviceUpdateModal] =
    useState(false);
  const [openBrandNameModalB, setOpenBrandNameModalB] = useState(false);
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [groupNameIds, setGroupNameIds] = useState<string[]>([]);
  const [waitingIsLoading, setWaitingIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { hasPermission } = usePermissions();
  const { getDevice, getDeviceGroupList, updateDevice } = useDeviceApi();

  const defaultVolumes: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const updateDeviceMutation = useMutation(
    (requestBody: UpdateDeviceRawParamsType) => updateDevice(requestBody),
    {
      onSuccess: async (data: any) => {
        setWaitingIsLoading(true);
        toastSuccess("デバイス情報の更新に成功しました。");
        //シャドウ反映のために遷移を遅らせる
        setTimeout(function () {
          navigate(`/store/devices/${id}`);
        }, 3000);
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage = "デバイスを更新できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const { isLoading, data, isError, refetch } = useQuery(
    [QueryKeys.storeDevice, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getDevice({
          thing_name: id,
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const deviceGroupList = useQuery(
    [QueryKeys.storeDeviceGroupList, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getDeviceGroupList({
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const handleGroupSelections = (data: FieldValues) => {
    const duplicatedItems = findDuplicatesById(
      deviceGroupList?.data?.groups,
      data?.groupNames
    );

    let arr: string[] = [];
    let arr2: string[] = [];

    duplicatedItems.forEach((item) => {
      arr.push(item.name || item.id);
      arr2.push(item.id);
    });

    setGroupNames(arr);
    setGroupNameIds(arr2);
    setOpenGroupNameModal(false);
  };

  const schema = Yup.object().shape(
    data?.thing?.thingType === "nomasse3rd"
      ? {
          terminalName: terminalNameValidation,
          flowRate: flowRateValidation,
          brandNamePortA: brandNameValidation,
          singleAmountPortA: singleAmountValidation,
          color: colorValidation,
          bgColor: bgColorValidation,
          outVersion: Yup.boolean().optional(),
          bottleVolume: Yup.string().when(
            "outVersion",
            ([outVersion], schema) => {
              return outVersion === false
                ? bottleVolumeValidation
                : Yup.string().optional();
            }
          ),
          bottleLiquidVolume: Yup.string().when(
            "outVersion",
            ([outVersion], schema) => {
              return outVersion === false
                ? bottleLiquidVolumeValidation
                : Yup.string().optional();
            }
          ),
        }
      : {
          terminalName: terminalNameValidation,
          flowRate: flowRateValidation,
          brandNamePortA: brandNameValidation,
          singleAmountPortA: singleAmountValidation,
          brandNamePortB: brandNameValidation,
          singleAmountPortB: singleAmountValidation,
          color: colorValidation,
          bgColor: bgColorValidation,
          outVersion: Yup.boolean().optional(),
          bottleVolume: Yup.string().when(
            "outVersion",
            ([outVersion], schema) => {
              return outVersion === false
                ? bottleVolumeValidation
                : Yup.string().optional();
            }
          ),
          bottleLiquidVolume: Yup.string().when(
            "outVersion",
            ([outVersion], schema) => {
              return outVersion === false
                ? bottleLiquidVolumeValidation
                : Yup.string().optional();
            }
          ),
        }
  );

  const defaultValues = {
    terminalName: data?.thing?.thingNickname || "",
    flowRate: data?.thing?.servePour || "",
    bottleVolume: "",
    bottleLiquidVolume: "",
    serveFilling: "",
    serveWashing: "",
    outVersion: true,
    brandNamePortA: data?.thing?.portA?.serveProductName || "",
    singleAmountPortA: data?.thing?.portA?.serveProductPrice || 0,
    brandNamePortB: data?.thing?.portB?.serveProductName || "",
    singleAmountPortB: data?.thing?.portB?.serveProductPrice || 0,
    color: data?.thing?.thingColors?.fontColor || "#ffffff",
    bgColor: data?.thing?.thingColors?.themeColor || "#B1B1B1",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data?.thing) {
      setValue("terminalName", data?.thing?.thingNickname);
      setValue("flowRate", data?.thing?.servePour);
      setValue("color", data?.thing?.thingColors?.fontColor || "#ffffff");
      setValue("bgColor", data?.thing?.thingColors?.themeColor || "#B1B1B1");

      let resultIsOutVersion: boolean = isOutVersion(
        data?.thing?.softVersion,
        process.env.REACT_APP_OUT_VERSION || "0.55"
      );
      setValue("outVersion", resultIsOutVersion);
      if (!resultIsOutVersion) {
        setValue("bottleVolume", data?.thing?.bottleSize);
        setValue("bottleLiquidVolume", data?.thing?.bottleLiquidVolume);
        setValue("serveFilling", data?.thing?.serveFilling);
        setValue("serveWashing", data?.thing?.serveWashing);
      }

      if (data?.thing?.thingType === "nomasse3rd") {
        setValue("brandNamePortA", data?.thing?.serveProductName || "");
        setValue("singleAmountPortA", data?.thing?.serveProductPrice || 0);

        setPortA({
          product_id: data?.thing?.serveProductId,
          product_name: data?.thing?.serveProductName,
          product_sake_brewer: data?.thing?.serveProductSakeBrewer,
          product_sake_brewery: data?.thing?.serveProductSakeBrewery,
          product_special_designation_sake:
            data?.thing?.serveProductSpecialDesignationSake,
        });
      } else {
        setValue("brandNamePortA", data?.thing?.portA?.serveProductName);
        setValue("brandNamePortB", data?.thing?.portB?.serveProductName);
        setValue("singleAmountPortA", data?.thing?.portA?.serveProductPrice);
        setValue("singleAmountPortB", data?.thing?.portB?.serveProductPrice);

        setPortA({
          product_id: data?.thing?.portA?.serveProductId,
          product_name: data?.thing?.portA?.serveProductName,
          product_sake_brewer: data?.thing?.portA?.serveProductSakeBrewer,
          product_sake_brewery: data?.thing?.portA?.serveProductSakeBrewery,
          product_special_designation_sake:
            data?.thing?.portA?.serveProductSpecialDesignationSake,
        });

        setPortB({
          product_id: data?.thing?.portB?.serveProductId,
          product_name: data?.thing?.portB?.serveProductName,
          product_sake_brewer: data?.thing?.portB?.serveProductSakeBrewer,
          product_sake_brewery: data?.thing?.portB?.serveProductSakeBrewery,
          product_special_designation_sake:
            data?.thing?.portB?.serveProductSpecialDesignationSake,
        });
      }

      if (
        data?.thing?.thingsBelongToGroup &&
        data?.thing?.thingsBelongToGroup.length > 0 &&
        deviceGroupList?.data?.groups &&
        deviceGroupList?.data?.groups.length > 0
      ) {
        setGroupNames(
          data?.thing?.thingsBelongToGroup.map(
            (item: GroupItem) => item?.groupName || item?.groupId
          )
        );

        const duplicatedItems = findDuplicatesById(
          deviceGroupList?.data?.groups,
          data?.thing?.thingsBelongToGroup.map(
            (item: GroupItem) => item?.groupId
          )
        );

        let arr: string[] = [];

        duplicatedItems.forEach((item) => {
          arr.push(item.id);
        });
        setGroupNameIds(arr);
      }
    }
  }, [data?.thing, deviceGroupList?.data]);

  const onSubmit = (val: FieldValues) => {
    if (data?.thing?.thingType === "nomasse3rd") {
      setRawParams({
        thing_name: data?.thing?.thingName,
        thing_group: groupNameIds,
        thing_shadow: val?.outVersion
          ? {
              thing_nickname: val?.terminalName,
              serviceunit_serve_pour: val?.flowRate,

              serviceunit_product_id: String(portA?.product_id),
              serviceunit_product_name: portA?.product_name,
              serviceunit_product_special_designation_sake:
                portA?.product_special_designation_sake,
              serviceunit_product_sake_brewer: portA?.product_sake_brewer,
              serviceunit_product_sake_brewery: portA?.product_sake_brewery,
              serviceunit_sake_price: val?.singleAmountPortA,
            }
          : {
              thing_nickname: val?.terminalName,
              serviceunit_serve_pour: val?.flowRate,
              bottle_size: val?.bottleVolume,
              bottle_liquidvolume: val?.bottleLiquidVolume,
              serviceunit_serve_filling: val?.serveFilling,
              serviceunit_serve_washing: val?.serveWashing,

              serviceunit_product_id: String(portA?.product_id),
              serviceunit_product_name: portA?.product_name,
              serviceunit_product_special_designation_sake:
                portA?.product_special_designation_sake,
              serviceunit_product_sake_brewer: portA?.product_sake_brewer,
              serviceunit_product_sake_brewery: portA?.product_sake_brewery,
              serviceunit_sake_price: val?.singleAmountPortA,
            },
        thing_colors: {
          theme_color: val?.bgColor,
          font_color: val?.color,
        },
      });
    } else {
      setRawParams({
        thing_name: data?.thing?.thingName,
        thing_group: groupNameIds,
        thing_shadow: val?.outVersion
          ? {
              thing_nickname: val?.terminalName,
              serviceunit_serve_pour: val?.flowRate,

              serviceunit_sdrs_1_a_product_id: String(portA?.product_id),
              serviceunit_sdrs_1_a_product_name: portA?.product_name,
              serviceunit_sdrs_1_a_product_special_designation_sake:
                portA?.product_special_designation_sake,
              serviceunit_sdrs_1_a_product_sake_brewer:
                portA?.product_sake_brewer,
              serviceunit_sdrs_1_a_product_sake_brewery:
                portA?.product_sake_brewery,
              serviceunit_sdrs_1_a_sake_price: val?.singleAmountPortA,

              serviceunit_sdrs_1_b_product_id: String(portB?.product_id),
              serviceunit_sdrs_1_b_product_name: portB?.product_name,
              serviceunit_sdrs_1_b_product_special_designation_sake:
                portB?.product_special_designation_sake,
              serviceunit_sdrs_1_b_product_sake_brewer:
                portB?.product_sake_brewer,
              serviceunit_sdrs_1_b_product_sake_brewery:
                portB?.product_sake_brewery,
              serviceunit_sdrs_1_b_sake_price: val?.singleAmountPortB,
            }
          : {
              thing_nickname: val?.terminalName,
              serviceunit_serve_pour: val?.flowRate,
              bottle_size: val?.bottleVolume,
              bottle_liquidvolume: val?.bottleLiquidVolume,
              serviceunit_serve_filling: val?.serveFilling,
              serviceunit_serve_washing: val?.serveWashing,

              serviceunit_sdrs_1_a_product_id: String(portA?.product_id),
              serviceunit_sdrs_1_a_product_name: portA?.product_name,
              serviceunit_sdrs_1_a_product_special_designation_sake:
                portA?.product_special_designation_sake,
              serviceunit_sdrs_1_a_product_sake_brewer:
                portA?.product_sake_brewer,
              serviceunit_sdrs_1_a_product_sake_brewery:
                portA?.product_sake_brewery,
              serviceunit_sdrs_1_a_sake_price: val?.singleAmountPortA,

              serviceunit_sdrs_1_b_product_id: String(portB?.product_id),
              serviceunit_sdrs_1_b_product_name: portB?.product_name,
              serviceunit_sdrs_1_b_product_special_designation_sake:
                portB?.product_special_designation_sake,
              serviceunit_sdrs_1_b_product_sake_brewer:
                portB?.product_sake_brewer,
              serviceunit_sdrs_1_b_product_sake_brewery:
                portB?.product_sake_brewery,
              serviceunit_sdrs_1_b_sake_price: val?.singleAmountPortB,
            },
        thing_colors: {
          theme_color: val?.bgColor,
          font_color: val?.color,
        },
      });
    }

    setConfirmDeviceUpdateModal(true);
  };

  const submit = () => {
    if (rawParams) updateDeviceMutation.mutate(rawParams);
    setConfirmDeviceUpdateModal(false);
  };

  const formValues = watch();

  const handleBrandNameASubmit = (
    data: FieldValues,
    selectedPort: PortType | null
  ) => {
    if (data.sakeSettingType === "0") {
      if (selectedPort) {
        console.log(
          "🚀 ~ file: index.tsx:244 ~ StoreDeviceUpdate ~ selectedPort:",
          selectedPort
        );
        setPortA(selectedPort);
      }
      setValue("brandNamePortA", data.selectedSake);
    } else if (data.sakeSettingType === "1") {
      setPortA({
        product_id: 0,
        product_name: data.sakeName,
        product_sake_brewer: data.breweryCompanyName,
        product_sake_brewery: data.breweryName,
        product_special_designation_sake: data.sakeSpecifiedName,
      });
      setValue("brandNamePortA", data.sakeName);
    }
    setOpenBrandNameModalA(false);
  };

  const handleBrandNameBSubmit = (
    data: FieldValues,
    selectedPort: PortType | null
  ) => {
    if (data.sakeSettingType === "0") {
      if (selectedPort) setPortB(selectedPort);
      setValue("brandNamePortB", data.selectedSake);
    } else if (data.sakeSettingType === "1") {
      setPortB({
        product_id: 0,
        product_name: data.sakeName,
        product_sake_brewer: data.breweryCompanyName,
        product_sake_brewery: data.breweryName,
        product_special_designation_sake: data.sakeSpecifiedName,
      });
      setValue("brandNamePortB", data.sakeName);
    }
    setOpenBrandNameModalB(false);
  };

  if (isError) return <ErrorHandler />;
  if (isLoading) return <Loader />;
  return (
    <ReloadAnimation>
      <Container sx={{ mb: 9 }}>
        <Paper sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid id="basic-settings-detail" sx={{ py: 1 }}>
              <Typography sx={{ fontWeight: 600, color: "#4F4F62" }}>
                基本設定
              </Typography>

              <StoreDeviceUpdateItem
                label={"端末ID"}
                textAlign={"right"}
                value={data?.thing?.thingName}
              />
              <Grid sx={{ paddingY: 1 }}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        shrink
                        htmlFor="terminalName"
                        sx={{ paddingY: 1 }}
                      >
                        <Typography sx={{ color: "#000" }}>
                          端末名(カスタム)
                        </Typography>
                      </InputLabel>
                      <BootstrapInput
                        {...field}
                        sx={{ width: 1 }}
                        id="terminalName"
                        error={!!errors?.terminalName?.message}
                      />
                    </FormControl>
                  )}
                  name="terminalName"
                  control={control}
                />
                {!!errors?.terminalName?.message && (
                  <FormHelperText error id="terminalName_helper">
                    <>{errors?.terminalName?.message}</>
                  </FormHelperText>
                )}
              </Grid>

              <Grid sx={{ paddingY: 1 }}>
                <InputLabel shrink htmlFor="flowRate" sx={{ paddingY: 1 }}>
                  <Typography sx={{ color: "#000" }} component="span">
                    1杯あたりの流量(ml)
                  </Typography>
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <FormControl
                      sx={{
                        width: 1,
                        textAlign: "right",
                      }}
                    >
                      <Select
                        {...field}
                        size="small"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ fontSize: { xs: "1rem", md: "1rem" } }}
                        variant="outlined"
                      >
                        {defaultVolumes.map((v) => (
                          <MenuItem
                            value={v}
                            sx={{
                              fontSize: { xs: "1rem", md: "1rem" },
                            }}
                            disabled={v === 0}
                          >
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  name="flowRate"
                  control={control}
                />
                {/* <Controller
                  render={({ field }) => (
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        shrink
                        htmlFor="flowRate"
                        sx={{ paddingY: 1 }}
                      >
                        <Typography sx={{ color: "#000" }} component="span">
                          1回あたりの流量(ml)
                        </Typography>
                      </InputLabel>
                      <BootstrapInput
                        {...field}
                        sx={{ width: 1 }}
                        id="flowRate"
                        type="number"
                        inputProps={{ style: { textAlign: "right" } }}
                        error={!!errors?.flowRate?.message}
                      />
                    </FormControl>
                  )}
                  name="flowRate"
                  control={control}
                /> */}
                {!!errors?.flowRate?.message && (
                  <FormHelperText error id="flowRate_helper">
                    <>{errors?.flowRate?.message}</>
                  </FormHelperText>
                )}
              </Grid>
              {/* bottle volume */}
              {formValues.outVersion === false && (
                <>
                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="bottleVolume"
                            sx={{ paddingY: 1 }}
                          >
                            <Typography sx={{ color: "#000" }} component="span">
                              ボトルサイズ(ml)
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="bottleVolume"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.bottleVolume?.message}
                          />
                        </FormControl>
                      )}
                      name="bottleVolume"
                      control={control}
                    />
                    {!!errors?.bottleVolume?.message && (
                      <FormHelperText error id="bottleVolume_helper">
                        <>{errors?.bottleVolume?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="bottleLiquidVolume"
                            sx={{ paddingY: 1 }}
                          >
                            <Typography sx={{ color: "#000" }} component="span">
                              現在のボトル内飲料量（ml）
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="bottleLiquidVolume"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.bottleLiquidVolume?.message}
                          />
                        </FormControl>
                      )}
                      name="bottleLiquidVolume"
                      control={control}
                    />
                    {!!errors?.bottleLiquidVolume?.message && (
                      <FormHelperText error id="bottleLiquidVolume_helper">
                        <>{errors?.bottleLiquidVolume?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="serveFilling"
                            sx={{ paddingY: 1 }}
                          >
                            <Typography sx={{ color: "#000" }} component="span">
                              充填時の排出飲料量(ml)
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="serveFilling"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.serveFilling?.message}
                          />
                        </FormControl>
                      )}
                      name="serveFilling"
                      control={control}
                    />
                    {!!errors?.serveFilling?.message && (
                      <FormHelperText error id="serveFilling_helper">
                        <>{errors?.serveFilling?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="serveWashing"
                            sx={{ paddingY: 1 }}
                          >
                            <Typography sx={{ color: "#000" }} component="span">
                              洗浄時の排出飲料量(ml)
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="serveWashing"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.serveWashing?.message}
                          />
                        </FormControl>
                      )}
                      name="serveWashing"
                      control={control}
                    />
                    {!!errors?.serveWashing?.message && (
                      <FormHelperText error id="serveWashing_helper">
                        <>{errors?.serveWashing?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>
                </>
              )}
              {/* start of selection group */}
              <Grid
                my={0.5}
                container
                spacing={1}
                onClick={() => {
                  setOpenGroupNameModal(true);
                }}
              >
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "0.8rem", color: "#000" }}>
                    のまっせグループ
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      color: "#726F6F",
                    }}
                    align="right"
                  >
                    {groupNames.length > 0
                      ? groupNames.map((name, index) => (
                          <span key={index}>
                            {index > 0 ? `, ` : ""} {name}
                          </span>
                        ))
                      : "なし"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <ArrowForwardIos
                    sx={{
                      height: "0.8em",
                      width: "0.8em",
                    }}
                  />
                </Grid>
              </Grid>
              {/* end of selection group */}
            </Grid>
            <Grid id="sake-settings-detail" sx={{ py: 2 }}>
              <Typography sx={{ fontWeight: 600, color: "#4F4F62" }}>
                酒設定
              </Typography>

              {data?.thing?.thingType === "nomasse3rd" ? (
                <>
                  <StoreDeviceUpdateItem
                    label={"銘柄"}
                    value={
                      isNumberString(formValues.brandNamePortA)
                        ? portA?.product_name
                        : formValues.brandNamePortA
                    }
                    handleClick={() => {
                      setOpenBrandNameModalA(true);
                    }}
                    textAlign="right"
                  />
                  {!!errors?.brandNamePortA?.message && (
                    <FormHelperText error id="brandNamePortA_helper">
                      <>{errors?.brandNamePortA?.message}</>
                    </FormHelperText>
                  )}
                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="singleAmountPortA"
                            sx={{ paddingY: 1 }}
                          >
                            <Typography
                              sx={{ display: "inline-block", color: "#000" }}
                            >
                              単品金額
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                display: "inline-block",
                                color: "#000",
                              }}
                            >
                              &nbsp;※現在は日本円のみの取り扱いとなります
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="singleAmountPortA"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.singleAmountPortA?.message}
                          />
                        </FormControl>
                      )}
                      name="singleAmountPortA"
                      control={control}
                    />
                    {!!errors?.singleAmountPortA?.message && (
                      <FormHelperText error id="singleAmountPortA_helper">
                        <>{errors?.singleAmountPortA?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <StoreDeviceUpdateItem
                    label={"銘柄（ポートA）"}
                    value={
                      !isNaN(formValues.brandNamePortA)
                        ? portA?.product_name
                        : formValues.brandNamePortA
                    }
                    textAlign="right"
                    handleClick={() => {
                      setOpenBrandNameModalA(true);
                    }}
                  />
                  {!!errors?.brandNamePortA?.message && (
                    <FormHelperText error id="brandNamePortA_helper">
                      <>{errors?.brandNamePortA?.message}</>
                    </FormHelperText>
                  )}
                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="singleAmountPortA"
                            sx={{ paddingY: 1, textOverflow: "initial" }}
                          >
                            <Typography
                              sx={{ display: "inline-block", color: "#000" }}
                            >
                              単品金額（ポートA）
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.8rem",
                                display: "inline-block",
                                color: "#000",
                              }}
                            >
                              ※現在は日本円のみの取り扱いとなります
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="singleAmountPortA"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.singleAmountPortA?.message}
                          />
                        </FormControl>
                      )}
                      name="singleAmountPortA"
                      control={control}
                    />
                    {!!errors?.singleAmountPortA?.message && (
                      <FormHelperText error id="singleAmountPortA_helper">
                        <>{errors?.singleAmountPortA?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>
                  <StoreDeviceUpdateItem
                    label={"銘柄（ポートB）"}
                    value={
                      !isNaN(formValues.brandNamePortB)
                        ? portB?.product_name
                        : formValues.brandNamePortB
                    }
                    textAlign="right"
                    handleClick={() => {
                      setOpenBrandNameModalB(true);
                    }}
                  />
                  {!!errors?.brandNamePortB?.message && (
                    <FormHelperText error id="brandNamePortB_helper">
                      <>{errors?.brandNamePortB?.message}</>
                    </FormHelperText>
                  )}

                  <Grid sx={{ paddingY: 1 }}>
                    <Controller
                      render={({ field }) => (
                        <FormControl fullWidth variant="standard">
                          <InputLabel
                            shrink
                            htmlFor="singleAmountPortB"
                            sx={{
                              paddingY: 1,
                              textOverflow: "initial",
                            }}
                          >
                            <Typography
                              sx={{
                                display: "inline-block",
                                color: "#000",
                              }}
                            >
                              単品金額（ポートB）
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "0.8rem",
                                display: "inline-block",
                                color: "#000",
                              }}
                            >
                              ※現在は日本円のみの取り扱いとなります
                            </Typography>
                          </InputLabel>
                          <BootstrapInput
                            {...field}
                            sx={{ width: 1 }}
                            id="singleAmountPortB"
                            type="number"
                            inputProps={{ style: { textAlign: "right" } }}
                            error={!!errors?.singleAmountPortB?.message}
                          />
                        </FormControl>
                      )}
                      name="singleAmountPortB"
                      control={control}
                    />
                    {!!errors?.singleAmountPortB?.message && (
                      <FormHelperText error id="singleAmountPortB_helper">
                        <>{errors?.singleAmountPortB?.message}</>
                      </FormHelperText>
                    )}
                  </Grid>
                </>
              )}

              <Grid container spacing={1} sx={{ paddingY: 1 }}>
                <Grid item xs={6}>
                  <Controller
                    name="color"
                    control={control}
                    defaultValue={formValues.color}
                    render={({ field }) => (
                      <FormControl fullWidth variant="standard">
                        <InputLabel shrink htmlFor="color" sx={{ paddingY: 1 }}>
                          <Typography sx={{ color: "#000" }}>
                            バッジカラー
                          </Typography>
                        </InputLabel>
                        <BootstrapInput
                          {...field}
                          sx={{ width: 1 }}
                          id="color"
                          type="color"
                          error={!!errors?.color?.message}
                        />
                      </FormControl>
                    )}
                  />
                  {!!errors?.color?.message && (
                    <FormHelperText error id="color_helper">
                      <>{errors?.color?.message}</>
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="bgColor"
                    control={control}
                    defaultValue={formValues.bgColor}
                    render={({ field }) => (
                      <FormControl fullWidth variant="standard">
                        <InputLabel
                          shrink
                          htmlFor="terminalName"
                          sx={{ paddingY: 1 }}
                        >
                          <Typography sx={{ color: "#000" }}>
                            バッジの背景色
                          </Typography>
                        </InputLabel>
                        <BootstrapInput
                          {...field}
                          sx={{ width: 1 }}
                          id="bgColor"
                          type="color"
                          error={!!errors?.color?.message}
                        />
                      </FormControl>
                    )}
                  />
                  {!!errors?.bgColor?.message && (
                    <FormHelperText error id="bgColor_helper">
                      <>{errors?.bgColor?.message}</>
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid my={1.5}>
              <ButtonSubmit
                fullWidth
                type="submit"
                sx={{ py: 1, fontWeight: 600, color: "#fff" }}
                disabled={!hasPermission("device.update")}
              >
                設定を変更
              </ButtonSubmit>
            </Grid>
          </form>
        </Paper>
        {openGroupNameModal && (
          <ModalGroupNameSelection
            open={openGroupNameModal}
            setOpen={setOpenGroupNameModal}
            preValues={groupNameIds}
            options={deviceGroupList?.data?.groups}
            onSubmit={handleGroupSelections}
          />
        )}
        {openBrandNameModalA && (
          <ModalBrandName
            open={openBrandNameModalA}
            setOpen={setOpenBrandNameModalA}
            port={portA}
            preValues={data?.portA?.serveProductName}
            onSubmit={handleBrandNameASubmit}
          />
        )}
        {openBrandNameModalB && (
          <ModalBrandName
            open={openBrandNameModalB}
            setOpen={setOpenBrandNameModalB}
            port={portB}
            preValues={data?.portB?.serveProductName}
            onSubmit={handleBrandNameBSubmit}
          />
        )}

        <ModalConfirmDeviceUpdate
          onSubmit={submit}
          open={confirmDeviceUpdateModal}
          setOpen={setConfirmDeviceUpdateModal}
        />
        {updateDeviceMutation.isLoading && <Loader />}
        {waitingIsLoading && <Loader />}
      </Container>
    </ReloadAnimation>
  );
};

export default StoreDeviceUpdate;
