import { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

interface ChangeCardFormProps {
  clientSecret: string;
}

const ChangeCardForm: React.FC<ChangeCardFormProps> = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [t] = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  let redirect_url = urlParams.get("redirect_url");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const paramsObject = Object.fromEntries(urlParams.entries());
    for (const [key, value] of Object.entries(paramsObject)) {
      if (key !== "redirect_url") {
        redirect_url += `&${key}=${value}`;
      }
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirect_url || window.location.origin,
        payment_method_data: {
          billing_details: {
            address: {
              country: "JP",
            },
          },
        },
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setMessage(error?.message || "");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {message && (
        <Box
          sx={{
            width: 1,
            paddingY: 1.5,
            paddingX: 2.5,
            border: 1,
            color: "#df1b41",
            borderColor: "#df1b41",
            borderBlockColor: "#df1b41",
            margin: "15px 0",
          }}
        >
          <Typography>{message}</Typography>
        </Box>
      )}

      <PaymentElement
        id="payment-element"
        className="payment-element-container"
        onLoaderStart={(event: any) => {
          setIsProcessing(true);
        }}
        onReady={() => {
          setIsProcessing(false);
        }}
        onLoadError={(event: any) => {
          setMessage(event?.error?.message || "");
        }}
        options={{
          fields: {
            billingDetails: {
              address: {
                country: "never",
              },
            },
          },
        }}
      />

      <ButtonSubmit
        type="submit"
        sx={{ width: 1, marginY: 2 }}
        disabled={isProcessing || isLoading || !stripe || !elements}
      >
        変更する
      </ButtonSubmit>
      {(isLoading || isProcessing) && <Loader />}
      {/* Show any error or success messages */}
    </form>
  );
};

export default ChangeCardForm;
