import { styled } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

const FilterDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  "& input": {
    // backgroundColor: "#ccc",
    // borderRadius: "25px",
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
}));

export default FilterDatePicker;
