import AwsApi from "./aws-api";
import { GetTotalServedDrinkParams, StoreParamType } from "../types";

export default class appDashboardApi extends AwsApi {
  async getTotalIssuedTicket(params: any): Promise<any> {
    return await this.get(process.env.REACT_APP_ENVNAME + "/data/issued", {
      params,
    });
  }

  async getTotalServedDrink(params: GetTotalServedDrinkParams): Promise<any> {
    return await this.get(process.env.REACT_APP_ENVNAME + "/data/provided", {
      params,
    });
  }

  async getRemainingBeverages(params: StoreParamType): Promise<any> {
    return await this.get(process.env.REACT_APP_ENVNAME + "/data/remaining", {
      params,
    });
  }
}
