import { useContext } from "react";
import {
  Typography,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Box,
  Grid,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../custom-hooks/use-permissions";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ReloadAnimation from "../../components/ReloadAnimation";

const Settings = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { hasPermission } = usePermissions();

  return (
    <ReloadAnimation>
      <Box
        sx={{
          paddingX: 2,
        }}
      >
        <Paper sx={{ width: 1, paddingY: 3 }}>
          <MenuList dense>
            {hasPermission("management.read") && (
              <MenuItem
                sx={{ paddingY: "10px", marginLeft: "10px" }}
                onClick={() => navigate("/management")}
              >
                <ListItemText>
                  <Typography sx={{ fontWeight: "600" }}>
                    のまっせの管理
                  </Typography>
                </ListItemText>
                <ListItemIcon>
                  <ArrowForwardIos
                    sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                  />
                </ListItemIcon>
              </MenuItem>
            )}
            {/* <MenuItem
            sx={{ paddingY: "10px", marginLeft: "10px" }}
            // onClick={() => navigate("/store/settings")}
          >
            <ListItemText>
              <Typography sx={{ fontWeight: "600" }}>カスタマー情報</Typography>
            </ListItemText>
            <ListItemIcon>
              <ArrowForwardIos
                sx={{ minWidth: "15px", fontSize: "1rem !important" }}
              />
            </ListItemIcon>
          </MenuItem> */}
            <MenuItem
              sx={{ paddingY: "10px", marginLeft: "10px" }}
              onClick={() => navigate("/store/settings")}
            >
              <ListItemText>
                <Typography sx={{ fontWeight: "600" }}>店舗情報</Typography>
              </ListItemText>
              <ListItemIcon>
                <ArrowForwardIos
                  sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                />
              </ListItemIcon>
            </MenuItem>
            {hasPermission("contract.read") && (
              <MenuItem
                sx={{ paddingY: "10px", marginLeft: "10px" }}
                onClick={() => navigate("/contracts")}
              >
                <ListItemText>
                  <Typography sx={{ fontWeight: "600" }}>
                    のまっせのご契約
                  </Typography>
                </ListItemText>
                <ListItemIcon>
                  <ArrowForwardIos
                    sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                  />
                </ListItemIcon>
              </MenuItem>
            )}
            {hasPermission("contract.read") && (
              <MenuItem
                sx={{ paddingY: "10px", marginLeft: "10px" }}
                onClick={() => navigate("/billing")}
              >
                <ListItemText>
                  <Typography sx={{ fontWeight: "600" }}>
                    請求・入金情報
                  </Typography>
                </ListItemText>
                <ListItemIcon>
                  <ArrowForwardIos
                    sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                  />
                </ListItemIcon>
              </MenuItem>
            )}
            <MenuItem
              sx={{ paddingY: "10px", marginLeft: "10px" }}
              onClick={() => navigate("/contact-us")}
            >
              <ListItemText>
                <Typography sx={{ fontWeight: "600" }}>お問い合わせ</Typography>
              </ListItemText>
              <ListItemIcon>
                <ArrowForwardIos
                  sx={{ minWidth: "15px", fontSize: "1rem !important" }}
                  fontSize="small"
                />
              </ListItemIcon>
            </MenuItem>
          </MenuList>
        </Paper>
        <Grid sx={{ m: 2 }}>
          <ButtonSubmit
            sx={{ width: 1, fontWeight: 600 }}
            onClick={() => {
              authCtx.onLogout();
              localStorage.removeItem("idToken");
              navigate("/");
            }}
          >
            ログアウトする
          </ButtonSubmit>
        </Grid>
      </Box>
    </ReloadAnimation>
  );
};

export default Settings;
