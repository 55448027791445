import { useEffect, useRef, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

interface StoreDeviceDetailItemProps {
  label: string;
  value?: string;
  values?: string[];
}

const StoreDeviceDetailItem: React.FC<StoreDeviceDetailItemProps> = ({
  label,
  value,
  values,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setIsOverflowed(container.scrollWidth > container.clientWidth);
    }
  }, []);

  let content = (
    <Typography
      ref={containerRef}
      sx={
        open
          ? {
              fontSize: "13px",
            }
          : {
              fontSize: "13px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
      }
    >
      {value}
    </Typography>
  );

  if (open && values && values.length > 0)
    content = (
      <Typography ref={containerRef}>
        {values.map((val: any, index: number) => (
          <Typography
            key={index}
            sx={{
              fontSize: "13px",
            }}
          >
            {val.groupName || val.groupId}
          </Typography>
        ))}
      </Typography>
    );

  return (
    <Grid container spacing={1} sx={{ pl: 2, my: 0.5 }}>
      <Grid item xs={6}>
        <Typography sx={{ fontSize: "13px" }}>{label}</Typography>
      </Grid>
      <Grid item xs={isOverflowed ? 5 : 6} sx={{ wordWrap: "break-word" }}>
        {content}
      </Grid>
      {isOverflowed && (
        <Grid item xs={1} sx={{ padding: "0 !important" }}>
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default StoreDeviceDetailItem;
