import { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { PortType } from "../../types";
import {
  breweryCompanyNameValidation,
  breweryNameValidation,
  sakeNameValidation,
  sakeSettingTypeValidation,
  sakeSpecifiedNameValidation,
  selectedSakeValidation,
} from "../../utils/validations";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import AuthContext from "../../custom-hooks/use-auth-context";
import BootstrapInput from "../Inputs/BootstrapInput";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import Footer from "../Layout/Footer";
import ModalSakeSelection from "./SakeSelectionModal";
import Loader from "../Loader";
import ErrorHandler from "../ErrorHandler";
import FormLabelDefault from "../FormLabelDefault";
import ReloadAnimation from "../ReloadAnimation";

interface ModalBrandNameProps {
  open: boolean;
  preValues: any;
  port: PortType | null;
  setOpen: (value: boolean) => void;
  onSubmit: (data: FieldValues, selectedPort: PortType | null) => void;
}

const ModalBrandName: React.FC<ModalBrandNameProps> = ({
  open,
  port,
  preValues,
  setOpen,
  onSubmit,
}) => {
  const authCtx = useContext(AuthContext);
  const [openSakeSelection, setOpenSakeSelection] = useState(false);
  const [selectedPort, setSelectedPort] = useState<PortType | null>(null);
  const { getSakeList } = useDeviceApi();
  const { isLoading, data, isError } = useQuery(
    [QueryKeys.storeSakeList, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getSakeList();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const schema = Yup.object().shape({
    sakeSettingType: sakeSettingTypeValidation,
    selectedSake: Yup.string().when(
      "sakeSettingType",
      ([sakeSettingType], schema) => {
        return sakeSettingType === "0"
          ? selectedSakeValidation
          : Yup.string().optional();
      }
    ),
    breweryCompanyName: Yup.string().when(
      "sakeSettingType",
      ([sakeSettingType], schema) => {
        return sakeSettingType === "1"
          ? breweryCompanyNameValidation
          : Yup.string().optional();
      }
    ),
    breweryName: Yup.string().when(
      "sakeSettingType",
      ([sakeSettingType], schema) => {
        return sakeSettingType === "1"
          ? breweryNameValidation
          : Yup.string().optional();
      }
    ),
    sakeName: Yup.string().when(
      "sakeSettingType",
      ([sakeSettingType], schema) => {
        return sakeSettingType === "1"
          ? sakeNameValidation
          : Yup.string().optional();
      }
    ),
    sakeSpecifiedName: Yup.string().when(
      "sakeSettingType",
      ([sakeSettingType], schema) => {
        return sakeSettingType === "1"
          ? sakeSpecifiedNameValidation
          : Yup.string().optional();
      }
    ),
  });

  const defaultValues = {
    sakeSettingType: "0",
    breweryCompanyName: "",
    breweryName: "",
    sakeName: "",
    sakeSpecifiedName: "",
    selectedSake: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  let formValues = watch();

  useEffect(() => {
    setValue("sakeSettingType", "0");
  }, []);

  useEffect(() => {
    if (formValues.sakeSettingType === "0") {
      if (data && data.length > 0) {
        setValue(
          "selectedSake",
          port && String(port?.product_id) !== "0"
            ? port.product_id
            : data[0].sake_id
        );
        setSelectedPort(
          port && String(port?.product_id) !== "0"
            ? port
            : {
                product_id: data[0].sake_id,
                product_name: data[0].product_name || "-",
                product_sake_brewer: data[0].sake_brewer || "-",
                product_sake_brewery: data[0].sake_brewery || "-",
                product_special_designation_sake:
                  data[0].special_designation_sake || "-",
              }
        );
      }
    }
  }, [formValues.sakeSettingType]);

  useEffect(() => {
    if (data && data.length > 0) {
      if (port) {
        if (String(port?.product_id) === "0") {
          setValue("sakeSettingType", "1");

          setValue("breweryCompanyName", port?.product_sake_brewer || "");
          setValue("breweryName", port?.product_sake_brewery || "");
          setValue("sakeName", port?.product_name || "");
          setValue(
            "sakeSpecifiedName",
            port?.product_special_designation_sake || ""
          );
        } else {
          setValue("sakeSettingType", "0");
          setSelectedPort(port);
          setValue("selectedSake", port?.product_id || data[0].sake_id);
        }
      }
    }
  }, [data]);

  const handleSakeSelection = (data: FieldValues) => {
    setValue("selectedSake", data.sake);
    setOpenSakeSelection(false);
  };

  const submit = (data: FieldValues) => {
    onSubmit(data, selectedPort);
  };

  if (openSakeSelection)
    return (
      <ModalSakeSelection
        options={data}
        open={openSakeSelection}
        preValue={formValues.selectedSake}
        setOpen={setOpenSakeSelection}
        onSubmit={handleSakeSelection}
        setPort={setSelectedPort}
      />
    );

  if (isLoading) return <Loader />;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ pb: 7, backgroundColor: "#E6E4EB" }}>
        <Container
          maxWidth="sm"
          sx={{
            minHeight: `100vh`,
            bgcolor: "#E6E4EB",
            padding: 0,
          }}
        >
          <AppBar
            elevation={0}
            position="sticky"
            color="transparent"
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setOpen(false);
                  // resetForm();
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <Box>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{
                    color: "#4F4F62",
                    fontWeight: 600,
                    fontSize: "1.2rem",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                  align="center"
                >
                  日本酒設定
                </Typography>
              </Box>
              <Box sx={{ width: "36px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
          {isError ? (
            <ErrorHandler back={() => setOpen(false)} />
          ) : (
            <Box>
              <ReloadAnimation>
                <Container>
                  <form onSubmit={handleSubmit(submit)}>
                    <Paper sx={{ p: 2 }}>
                      <Grid sx={{ paddingY: 1 }}>
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          期限タイプ
                        </Typography>
                        <Grid marginLeft={1}>
                          <Controller
                            name="sakeSettingType"
                            control={control}
                            defaultValue={formValues.sakeSettingType}
                            render={({ field }) => (
                              <RadioGroup {...field}>
                                <FormLabelDefault
                                  value="0"
                                  control={<Radio />}
                                  label={
                                    <Typography sx={{ fontSize: "0.8rem" }}>
                                      登録されている酒から選択する
                                    </Typography>
                                  }
                                />
                                {formValues.sakeSettingType === "0" && (
                                  <Grid
                                    sx={{ ml: 4 }}
                                    onClick={() => {
                                      setOpenSakeSelection(true);
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={10}>
                                        <Typography
                                          sx={{
                                            fontSize: "0.8rem",
                                            fontWeight: 600,
                                            my: 0.5,
                                          }}
                                        >
                                          日本酒を選ぶ
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontSize: "0.8rem",
                                            opacity: 0.7,
                                          }}
                                        >
                                          {selectedPort?.product_name || ""}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "end",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ArrowForwardIos
                                          sx={{
                                            minWidth: "25px",
                                            fontSize: "1rem !important",
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                                <FormLabelDefault
                                  value="1"
                                  control={<Radio />}
                                  label={
                                    <Typography sx={{ fontSize: "0.8rem" }}>
                                      カスタムで日本酒名を選択する
                                    </Typography>
                                  }
                                />
                                {/* start of form */}
                                <Grid sx={{ ml: 4 }}>
                                  <Grid sx={{ pb: 1 }}>
                                    <Controller
                                      render={({ field }) => (
                                        <FormControl
                                          fullWidth
                                          variant="standard"
                                        >
                                          <InputLabel
                                            shrink
                                            htmlFor="breweryCompanyName"
                                            sx={{ paddingY: 1 }}
                                          >
                                            酒蔵会社名
                                          </InputLabel>
                                          <BootstrapInput
                                            {...field}
                                            sx={{ width: 1 }}
                                            id="breweryCompanyName"
                                            disabled={
                                              formValues.sakeSettingType !== "1"
                                            }
                                            error={
                                              !!errors?.breweryCompanyName
                                                ?.message
                                            }
                                          />
                                        </FormControl>
                                      )}
                                      name="breweryCompanyName"
                                      control={control}
                                    />
                                    {!!errors?.breweryCompanyName?.message && (
                                      <FormHelperText
                                        error
                                        id="breweryCompanyName_helper"
                                      >
                                        <>
                                          {errors?.breweryCompanyName?.message}
                                        </>
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid sx={{ pb: 1 }}>
                                    <Controller
                                      render={({ field }) => (
                                        <FormControl
                                          fullWidth
                                          variant="standard"
                                        >
                                          <InputLabel
                                            shrink
                                            htmlFor="breweryName"
                                            sx={{ paddingY: 1 }}
                                          >
                                            醸造所名
                                          </InputLabel>
                                          <BootstrapInput
                                            {...field}
                                            sx={{ width: 1 }}
                                            id="breweryName"
                                            disabled={
                                              formValues.sakeSettingType !== "1"
                                            }
                                            error={
                                              !!errors?.breweryName?.message
                                            }
                                          />
                                        </FormControl>
                                      )}
                                      name="breweryName"
                                      control={control}
                                    />
                                    {!!errors?.breweryName?.message && (
                                      <FormHelperText
                                        error
                                        id="breweryName_helper"
                                      >
                                        <>{errors?.breweryName?.message}</>
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid sx={{ pb: 1 }}>
                                    <Controller
                                      render={({ field }) => (
                                        <FormControl
                                          fullWidth
                                          variant="standard"
                                        >
                                          <InputLabel
                                            shrink
                                            htmlFor="sakeName"
                                            sx={{ paddingY: 1 }}
                                          >
                                            お酒の名前
                                          </InputLabel>
                                          <BootstrapInput
                                            {...field}
                                            sx={{ width: 1 }}
                                            id="sakeName"
                                            disabled={
                                              formValues.sakeSettingType !== "1"
                                            }
                                            error={!!errors?.sakeName?.message}
                                          />
                                        </FormControl>
                                      )}
                                      name="sakeName"
                                      control={control}
                                    />
                                    {!!errors?.sakeName?.message && (
                                      <FormHelperText
                                        error
                                        id="sakeName_helper"
                                      >
                                        <>{errors?.sakeName?.message}</>
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid sx={{ pb: 1 }}>
                                    <Controller
                                      render={({ field }) => (
                                        <FormControl
                                          fullWidth
                                          variant="standard"
                                        >
                                          <InputLabel
                                            shrink
                                            htmlFor="sakeSpecifiedName"
                                            sx={{ paddingY: 1 }}
                                          >
                                            特定名称酒
                                          </InputLabel>
                                          <BootstrapInput
                                            {...field}
                                            sx={{ width: 1 }}
                                            id="sakeSpecifiedName"
                                            disabled={
                                              formValues.sakeSettingType !== "1"
                                            }
                                            error={
                                              !!errors?.sakeSpecifiedName
                                                ?.message
                                            }
                                          />
                                        </FormControl>
                                      )}
                                      name="sakeSpecifiedName"
                                      control={control}
                                    />
                                    {!!errors?.sakeSpecifiedName?.message && (
                                      <FormHelperText
                                        error
                                        id="sakeSpecifiedName_helper"
                                      >
                                        <>
                                          {errors?.sakeSpecifiedName?.message}
                                        </>
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                                {/* end of form */}
                              </RadioGroup>
                            )}
                          />
                        </Grid>
                        {!!errors?.sakeSettingType?.message && (
                          <FormHelperText error id="sakeSettingType_helper">
                            <>{errors?.sakeSettingType?.message}</>
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid sx={{ my: 1 }}>
                        <ButtonSubmit
                          type="submit"
                          fullWidth
                          sx={{ fontWeight: 600 }}
                        >
                          完了
                        </ButtonSubmit>
                      </Grid>
                    </Paper>
                  </form>
                </Container>
              </ReloadAnimation>
            </Box>
          )}
        </Container>
        <Footer />
      </Box>
    </Modal>
  );
};

export default ModalBrandName;
