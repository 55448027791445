import { styled, Typography, TypographyProps } from "@mui/material";

const CustomTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "2rem",
  },
}));

export default CustomTitle;
