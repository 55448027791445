import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { TotalServedDrinkRankingType } from "../../../types";

interface PopularDrinkItemProps {
  item?: TotalServedDrinkRankingType;
}

const PopularDrinkItem: React.FC<PopularDrinkItemProps> = ({ item }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {item ? (
        <Box>
          <Typography
            sx={{
              fontWeight: 600,
              wordWrap: "break-word",
              wordBreak: "break-all",
              fontSize: "0.8rem",
            }}
          >
            {item.productName}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.65rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {item.productBrewer}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Skeleton width="100px" height="34px" />
          <Skeleton width="140px" height="34px" />
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {item ? (
          <Box sx={{ display: "inline-flex" }}>
            <Typography
              sx={{ alignSelf: "end", fontWeight: 600, fontSize: "1.5rem" }}
            >
              {item.productProvidedCount}
            </Typography>
            <Typography
              sx={{ fontSize: "0.65rem", alignSelf: "end", fontWeight: 600 }}
            >
              杯
            </Typography>
          </Box>
        ) : (
          <Box>
            <Skeleton width="50px" height="34px" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PopularDrinkItem;
