import { useRef, useState } from "react";
import { createBlob } from "../../utils/helper";
import { CAMERA_ICON } from "../../assets/icons";
import { Box, Typography } from "@mui/material";
import ImageUploader from "../../components/ImageUploader";

interface IconUploaderProps {
  img?: any;
  onChange: (blob: any, image: any) => void;
}

const IconUploader: React.FC<IconUploaderProps> = ({ img, onChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<any>(img);

  const handleUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files?.length > 0) {
      const file = files[0];
      if (file && file.size < 1024 * 1024 * 5) {
        const blob = await createBlob(file);
        setImgSrc(URL.createObjectURL(blob));
        setOpen(true);
      } else {
        alert("ファイルサイズが大きすぎる");
      }
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          marginY: 2,
          backgroundColor: "#9f1831",
          height: "80px",
          width: "80px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={
        //   imgSrc
        //     ? {
        //         backgroundImage: `url(${imgSrc})`,
        //         backgroundRepeat: "no-repeat",
        //         backgroundPosition: "center",
        //         backgroundSize: "cover",
        //       }
        //     : {
        //         backgroundImage: `url(${CAMERA_ICON})`,
        //         backgroundRepeat: "no-repeat",
        //         backgroundPosition: "center",
        //         backgroundSize: "50px",
        //       }
        // }
        onClick={handleUploadFile}
      >
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.7,
          }}
        >
          <img src={CAMERA_ICON} width={30} height={30} />
          <Typography
            sx={{ fontSize: "0.6rem", fontWeight: 600, color: "#fff" }}
          >
            画像を選択
          </Typography>
        </Box>
        {imgSrc && <img src={imgSrc} width={80} height={80} />}
        <input
          type="file"
          accept="image/jpeg,image/png"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileInputChange}
          onFocus={handleFileInputChange}
        />
      </Box>
      {open && (
        <ImageUploader
          src={imgSrc}
          defaultAspect={4 / 4}
          setImgSrc={(blobURI: any, blob: any) => {
            setImgSrc(blobURI);
            onChange(blob, imgSrc);
          }}
          open={open}
          locked={true}
          handleClose={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            setOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default IconUploader;
