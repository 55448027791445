import React from "react";
import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";

interface ReceiptTabItemProps {
  selected: boolean;
  label: string;
  setSelectedTab: () => void;
}

const ReceiptTabItem: React.FC<ReceiptTabItemProps> = ({
  label,
  selected,
  setSelectedTab,
}) => {
  const defaultCss = {
    px: 1,
    py: 1.2,
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
  };
  const activeCss = {
    background: "#9F1831",
    color: "#fff",
  };

  return (
    <Grid
      item
      xs={6}
      sx={selected ? { ...activeCss, ...defaultCss } : defaultCss}
      onClick={setSelectedTab}
    >
      {selected && (
        <motion.div
          layoutId="active-pill"
          style={{
            position: "absolute",
            inset: "0",
            borderRadius: "20px",
            backgroundColor: "#9f1831",
          }}
          transition={{ type: "spring", duration: 1 }}
        />
      )}
      <Typography
        sx={{ fontSize: "1rem", fontWeight: 600, position: "relative" }}
      >
        {label}
      </Typography>
    </Grid>
  );
};

export default ReceiptTabItem;
