import React, { Fragment } from "react";
import { Box, Paper, Typography } from "@mui/material";
import {
  TotalServedDrinkRankingType,
  TotalServedDrinkType,
} from "../../../types";
import PopularDrinkItem from "./PopularDrinkItem";
import BottomRefresher from "../BottomRefresher";

interface PopularDrinkProps {
  data: TotalServedDrinkType;
  isError: boolean;
  isLoading: boolean;
  fetch: () => void;
}

const PopularDrink: React.FC<PopularDrinkProps> = ({
  data,
  isError,
  isLoading,
  fetch,
}) => {
  return (
    <Paper sx={{ p: 2, my: 2, pb: 0.5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ color: "#9f1831", fontWeight: 600 }}>
          人気の日本酒
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "20px",
            backgroundColor: "#9f1831",
            px: 1.5,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontSize: "0.8rem", fontWeight: 600 }}
          >
            Daily
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", rowGap: 1.5, mt: 2 }}
      >
        {!data || isLoading || isError ? (
          <>
            <PopularDrinkItem />
            <PopularDrinkItem />
            <PopularDrinkItem />
            <PopularDrinkItem />
          </>
        ) : (
          <>
            {data?.ranking?.map((item: TotalServedDrinkRankingType) => (
              <Fragment>
                <PopularDrinkItem item={item} />
              </Fragment>
            ))}
          </>
        )}
      </Box>

      <BottomRefresher refetch={fetch} />
    </Paper>
  );
};

export default PopularDrink;
