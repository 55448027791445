import {
    FormHelperText,
    Grid,
    Hidden,
    InputAdornment,
    Paper,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Control, Controller, FieldErrors, FieldValues, UseFormHandleSubmit } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarMonth } from "@mui/icons-material";
import "dayjs/locale/ja";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import FilterDatePicker from "../Inputs/FilterDatePicker";

type Props = {
    formValues: {
        start: null;
        end: null;
    };
    control: Control<{
        start: null;
        end: null;
    }, any>
    errors: FieldErrors<{
        start: null;
        end: null;
    }>
    handleSubmit: UseFormHandleSubmit<{
        start: null;
        end: null;
    }>
    onSubmit: (data: FieldValues) => void
    errorText?: string;
}

const SpecifiedPeriod = ({ formValues, control, errors, handleSubmit, onSubmit, errorText }: Props) => {

    console.log('errorText:', errorText)
    return (
        <>
            <Paper sx={{ p: 2, mx: 2, my: 1, borderRadius: "14px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={"10px"} columnSpacing={"5px"}>
                        <Grid
                            item
                            xs={6}
                            sm={5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Controller
                                render={({ field }) => (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={"ja"}
                                    >
                                        <DemoContainer
                                            components={["DatePicker"]}
                                            sx={{ pt: 0, width: 1 }}
                                        >
                                            <FilterDatePicker
                                                {...field}
                                                maxDate={formValues?.end}
                                                format="YYYY/MM/DD"
                                                sx={{
                                                    minWidth: "50px !important",
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        toolbarFormat: "MMMM DD日 (ddd)",
                                                        hidden: false,
                                                    },
                                                    textField: {
                                                        size: "small",
                                                        placeholder: "",
                                                        style: {
                                                            background: "#E6E4EB",
                                                            borderRadius: "25px",
                                                            cursor: "pointer",
                                                        },
                                                        InputProps: {
                                                            sx: {
                                                                fontSize: { xs: "0.8rem", sm: "1rem" },
                                                            },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <CalendarMonth
                                                                        sx={{
                                                                            fontSize: { xs: "1rem", sm: "1.5rem" },
                                                                        }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                )}
                                name="start"
                                control={control}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Controller
                                render={({ field }) => (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale={"ja"}
                                    >
                                        <DemoContainer
                                            components={["DatePicker"]}
                                            sx={{ pt: 0, width: 1 }}
                                        >
                                            <FilterDatePicker
                                                {...field}
                                                minDate={formValues?.start}
                                                format="YYYY/MM/DD"
                                                sx={{
                                                    minWidth: "50px !important",
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        border: "none",
                                                    },
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        toolbarFormat: "MMMM DD日 (ddd)",
                                                        hidden: false,
                                                    },
                                                    textField: {
                                                        size: "small",
                                                        placeholder: "",
                                                        style: {
                                                            background: "#E6E4EB",
                                                            borderRadius: "25px",
                                                            cursor: "pointer",
                                                        },
                                                        InputProps: {
                                                            sx: {
                                                                fontSize: { xs: "0.8rem", sm: "1rem" },
                                                            },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <CalendarMonth
                                                                        sx={{
                                                                            fontSize: { xs: "1rem", sm: "1.5rem" },
                                                                        }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                )}
                                name="end"
                                control={control}
                            />
                        </Grid>

                        {(errors?.start?.message || errors?.end?.message || errorText) && (
                            <Hidden smUp>
                                <Grid item xs={12} sx={{ color: "#9f1831" }}>
                                    <ul>
                                        {!!errors?.start?.message && (
                                            <li>
                                                <FormHelperText error id="start_helper">
                                                    <>{errors?.start?.message}</>
                                                </FormHelperText>
                                            </li>
                                        )}
                                        {!!errors?.end?.message && (
                                            <li>
                                                <FormHelperText error id="end_helper">
                                                    <>{errors?.end?.message}</>
                                                </FormHelperText>
                                            </li>
                                        )}
                                        {!!errorText && (
                                            <li>
                                                <FormHelperText error id="errorText_helper">
                                                    <>{errorText}</>
                                                </FormHelperText>
                                            </li>
                                        )}
                                    </ul>
                                </Grid>
                            </Hidden>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={2}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <ButtonSubmit
                                type="submit"
                                startIcon={<Search />}
                                sx={{ borderRadius: "20px", py: 1, width: 1 }}
                            >
                                表示
                            </ButtonSubmit>
                        </Grid>
                    </Grid>
                    {(errors?.start?.message || errors?.end?.message || errorText) && (
                        <Hidden smDown>
                            <Grid item xs={12} sx={{ color: "#9f1831" }}>
                                <ul>
                                    {!!errors?.start?.message && (
                                        <li>
                                            <FormHelperText error id="start_helper">
                                                <>{errors?.start?.message}</>
                                            </FormHelperText>
                                        </li>
                                    )}
                                    {!!errors?.end?.message && (
                                        <li>
                                            <FormHelperText error id="end_helper">
                                                <>{errors?.end?.message}</>
                                            </FormHelperText>
                                        </li>
                                    )}
                                    {!!errorText && (
                                        <li>
                                            <FormHelperText error id="errorText_helper">
                                                <>{errorText}</>
                                            </FormHelperText>
                                        </li>
                                    )}
                                </ul>
                            </Grid>
                        </Hidden>
                    )}
                </form>
            </Paper >
        </>
    )
}


export default SpecifiedPeriod