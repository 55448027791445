import { Button, ButtonProps, styled } from "@mui/material";

const FillingButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  fontWeight: 600,
  backgroundColor: "#E36A29",
  minWidth: "40px",
  width: "40px",
  height: "40px",
  padding: 2,
  "&:hover": {
    backgroundColor: "#E36A29",
  },
  "&:disabled": {
    opacity: 0.5,
    color: "#fff",
  },
}));

export default FillingButton;
