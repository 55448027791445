import { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  DeviceDetailType,
  ModalDeviceParam,
  ModalDeviceType,
} from "../../types";
import { isOutVersion } from "../../utils/helper";
import DeviceDetailMaintenance from "./DeviceDetailMaintenance";

interface ExtractionVolumeListItemProps {
  item: DeviceDetailType;
  setItem: (value: ModalDeviceParam) => void;
}

const ExtractionVolumeListItem: React.FC<ExtractionVolumeListItemProps> = ({
  item,
  setItem,
}) => {
  const defaultVolumes: number[] = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const [volumeOptions, setVolumeOptions] = useState<number[]>(defaultVolumes);
  const [volume, setVolume] = useState<string>(item.servePour + "" || "0");

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;

    setItem({
      type: ModalDeviceType.EXCTRACTION,
      item: item,
      option: {
        volume: volume,
        newVolume: newValue,
        onSubmit: () => {
          setVolume(newValue);
        },
      },
    });
  };

  useEffect(() => {
    let uniqueItems: Set<number> = new Set(defaultVolumes);
    if (item?.servePour && item?.servePour !== 0)
      uniqueItems.add(+item.servePour);
    const volumes: number[] = Array.from(uniqueItems);
    volumes.sort(function (a, b) {
      return a - b;
    });
    setVolumeOptions(volumes);
  }, []);

  return (
    <Paper
      elevation={2}
      sx={{ p: 2, my: 1, cursor: "pointer", position: "relative" }}
    >
      <DeviceDetailMaintenance
        isOutVersion={isOutVersion(
          item.softVersion,
          process.env.REACT_APP_OUT_VERSION || "0.55"
        )}
        status={item?.maintenance_qr}
      />

      <Grid container sx={{ height: "40px" }}>
        <Grid
          item
          xs={9}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "0.8rem",
            }}
          >
            {item?.thingNickname || item?.thingName}
          </Typography>
          {item?.serveProductName && (
            <Typography
              sx={{
                fontSize: "0.6rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              銘柄:
              <>{item?.serveProductName}</>
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl
            sx={{
              minWidth: { xs: "65px", md: "100px" },
              mr: { xs: 0.2, md: 1 },
              textAlign: "right",
            }}
          >
            <Select
              value={volume}
              defaultValue={volume}
              onChange={handleChange}
              size="small"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
              variant="outlined"
            >
              {volumeOptions.map((v) => (
                <MenuItem
                  value={v}
                  sx={{
                    fontSize: { xs: "1rem", md: "1rem" },
                  }}
                  disabled={v === 0}
                >
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography sx={{ fontSize: "0.6rem" }} component="span">
            ml
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ExtractionVolumeListItem;
