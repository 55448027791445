import { useContext } from "react";
import { Box, Grid, styled, List, ListItem, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import moment from "moment";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreContactList = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getContracts } = useBillingApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.contracts, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getContracts();
      return;
    }
  );

  if (isError) return <ErrorHandler url="/settings" />;
  if (isLoading) return <Loader />;
  return (
    <ReloadAnimation>
      <Box sx={{ px: 2, pb: 7 }}>
        {/* <Paper sx={{ padding: 2 }} elevation={2}>
        <Typography>ご契約台数：6台</Typography>
      </Paper> */}
        <Grid sx={{ my: 2, display: "flex", justifyContent: "center" }}>
          <ButtonSubmit
            variant="contained"
            sx={{
              width: 0.9,
              height: "50px",
              fontSize: "15px",
              fontWeight: 600,
            }}
            onClick={() => navigate("/contract/new")}
          >
            新規にご契約
          </ButtonSubmit>
        </Grid>
        <Grid sx={{ marginY: 3, display: "flex", justifyContent: "center" }}>
          <List
            sx={{
              width: 1,
            }}
          >
            {data?.subscriptions?.length > 0 ? (
              data?.subscriptions?.map((item: any) => (
                <ListItem
                  sx={{
                    width: 1,
                    bgcolor: "background.paper",
                    boxShadow: "0px 3px 5px rgb(0 0 0 / 10%)",
                    borderRadius: "5px",
                    marginBottom: "10px",
                  }}
                  alignItems="center"
                  onClick={() => {
                    navigate(`/contracts/detail?id=${item?.id}`);
                  }}
                >
                  <Grid
                    container
                    sx={{ display: "flex", width: 1, padding: "10px" }}
                  >
                    <Grid item xs={8}>
                      <Box sx={{}}>
                        <Typography
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          sx={{
                            display: "block",
                            fontWeight: 550,
                            fontSize: "15px",
                            width: 1,
                          }}
                          component="h1"
                          variant="h6"
                        >
                          {item?.productName}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "11px",
                          }}
                        >
                          単価 {item?.amount.toLocaleString()}円, 数量{" "}
                          {item?.quantity}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ textAlign: "right" }}>
                        <Typography
                          sx={{
                            fontWeight: 550,
                            fontSize: "15px",
                            display: "inline",
                            marginRight: "3px",
                            textAlign: "right",
                            width: 1,
                          }}
                          component="h1"
                          variant="h6"
                        >
                          {(item?.amount * item?.quantity).toLocaleString()}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "8px",
                            display: "inline",
                            textAlign: "right",
                          }}
                        >
                          円/月
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 550,
                            fontSize: "10px",
                            display: "block",
                            marginRight: "3px",
                            textAlign: "right",
                            width: 1,
                          }}
                          component="h1"
                          variant="h6"
                        >
                          {item?.startDate
                            ? moment.unix(item?.startDate).format("YYYY/MM/DD")
                            : ""}
                          ~
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            ) : (
              <Box></Box>
            )}
          </List>
        </Grid>
        <Grid sx={{ marginY: 3, display: "flex", justifyContent: "center" }}>
          <Link
            to="/contact-us"
            style={{
              fontSize: "14px",
              textUnderlineOffset: "0.5em",
              color: "#4F4F62",
              fontWeight: 600,
            }}
          >
            店舗設置のまっせの移動・返却のお問い合わせ
          </Link>
        </Grid>
      </Box>
    </ReloadAnimation>
  );
};

export default StoreContactList;
