import { Dispatch, SetStateAction, Fragment, useContext, useState, useEffect } from "react";
import {
  AppBar,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarMonth } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ArrowBackIos, DownloadForOffline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BeverageBodyParam, BeverageItemType, SearchPeriod } from "../../types";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { useBeverageApi } from "../../custom-hooks/apis/use-beverage-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Papa from "papaparse";
import * as Yup from "yup";
import "dayjs/locale/ja";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import FilterDatePicker from "../../components/Inputs/FilterDatePicker";
import ReloadAnimation from "../../components/ReloadAnimation";
import BeverageItem from "./BeverageItem";
import moment from "moment";
import dayjs from "dayjs";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";
import SpecifiedPeriod from "../../components/Search/SpecifiedPeriod";
import ErrorHandler from "../../components/ErrorHandler";
import LoaderWithText from "../../components/Loader/LoaderWithText";

type Props = {
  list: BeverageItemType[] | null;
  setList: Dispatch<SetStateAction<BeverageItemType[] | null>>
  searchPeriod: SearchPeriod;
  setSearchPeriod: Dispatch<SetStateAction<SearchPeriod>>;
}

const BeverageData = ({ list, setList, searchPeriod, setSearchPeriod }: Props) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { postBeverageDataList } = useBeverageApi();
  const [param, setParam] = useState<BeverageBodyParam | null>(null);
  const [errorText, setErrorText] = useState<string | undefined>(undefined)

  const fetchBeverageData = (nextIndex?: any) => {
    if (authCtx.user?.signInUserSession.idToken && param)
      return postBeverageDataList(param, nextIndex);
    return Promise.resolve();
  };

  const { isFetching, isLoading, isRefetchError, isError } = useQuery(
    [
      QueryKeys.beverageDataList,
      authCtx.user?.signInUserSession.idToken,
      param,
    ],
    async () => {
      let allData: BeverageItemType[] = [];
      let data = await fetchBeverageData();
      allData = allData.concat(data?.results || []);
      let nextIndex = data?.nextIndex ? data.nextIndex : null;

      while (nextIndex !== undefined && nextIndex !== null) {
        data = await fetchBeverageData(nextIndex);
        allData = allData.concat(data?.results || []);
        nextIndex = data.nextIndex;
      }

      return allData;
    },
    {
      onSuccess: (data) => {
        setList(data);
      },
      onError: async (error: any) => {
        let msg = "申し訳ありませんが、結果を取得できませんでした。";
        if (error.response?.data?.errorMessage) {
          msg = error.response?.data?.errorMessage;
        }

        setErrorText(msg)
      },
      staleTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!param,
      retry: false,
    }
  );

  const defaultValues = {
    start: null,
    end: null,
  };

  const schema = Yup.object().shape({
    start: Yup.date()
      .required("開始日が入力されていません")
      .nullable()
      .max(Yup.ref("end"), "開始日は終了日より前でなければなりません"),
    end: Yup.date()
      .required("終了日が入力されていません")
      .nullable()
      .min(Yup.ref("start"), "終了日は開始日以降でなければなりません")
      .test("is-between", "検索可能な期間は2ヶ月以内です", function (value) {
        const start_y = Number(moment(this.parent.start).format('YYYY'));
        const start_m = Number(moment(this.parent.start).format('M'));
        const end_y = Number(moment(value).format('YYYY'));
        const end_m = Number(moment(value).format('M'));
        // 年数が 0 かつ 月が <= 2
        return start_y - end_y === 0 && end_m - start_m <= 2;
      }),
  });

  const onSubmit = (data: FieldValues) => {
    if (!authCtx.user?.store_id) return;

    let from_date = moment(dayjs(data?.start).toDate()).unix();
    let until_date = moment(dayjs(data?.end).toDate()).unix();

    let setSearchPeriod_from_date = moment(dayjs(formValues?.start).toDate()).format("YYYY/MM/DD")
    let setSearchPeriod_until_date = moment(dayjs(formValues?.end).toDate()).format("YYYY/MM/DD")


    let params: BeverageBodyParam = {
      customerStoreId: authCtx.user?.store_id,
      startDate: from_date,
      endDate: until_date,
    };

    setSearchPeriod({
      start: setSearchPeriod_from_date,
      end: setSearchPeriod_until_date
    })
    setParam(params);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const formValues = watch();

  const { i18n } = useTranslation();

  const convertJsonToCsv = (jsonData: BeverageItemType[]) => {
    const csv = Papa.unparse(jsonData);
    return csv;
  };

  const downloadCsv = (jsonData: BeverageItemType[], filename: string) => {
    const csv = convertJsonToCsv(jsonData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
  };

  const handleDownload = () => {
    if (!list || list?.length === 0) return;

    downloadCsv(list, uuidv4() + ".csv");
  };

  if ((isError || isRefetchError) && !errorText) return <ErrorHandler />;
  return (
    <div>
      <SpecifiedPeriod
        formValues={formValues}
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        errorText={errorText}
      />

      {!isLoading && !isFetching && list && (
        <>
          <Grid sx={{ mx: 2 }}>
            <Typography sx={{ color: "#606060", fontSize: "0.6rem" }}>
              出力期間：
              {searchPeriod?.start
                ? searchPeriod?.start
                : ""}
              {searchPeriod?.end
                ? " ~ " + searchPeriod?.end
                : ""}
            </Typography>
          </Grid>
          {list.length > 0 && (
            <Grid sx={{ pb: 10 }}>
              {list.map((item: BeverageItemType, index: number) => (
                <Fragment key={index}>
                  <BeverageItem item={item} />
                </Fragment>
              ))}
            </Grid>
          )}
          {list.length === 0 && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: `calc(100vh - 300px)`,
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                飲料データが見つかりませんでした
              </Typography>
            </Grid>
          )}
        </>
      )}
      {(isFetching || isLoading) && <LoaderWithText />}
    </div>
  );
};

export default BeverageData;
