import AwsApi from "./aws-api";
import { Token } from "@stripe/stripe-js";
import { QuickPayType } from "../types";

export default class appBillingApi extends AwsApi {
  async getInvoices(): Promise<any> {
    return await this.get(
      "customer/contract/user/subscription/invoice/" +
        process.env.REACT_APP_ENVNAME
    );
  }

  async getPaymentCard(): Promise<any> {
    return await this.get(
      "customer/contract/user/customer/payment/" + process.env.REACT_APP_ENVNAME
    );
  }

  async getClientSecret(): Promise<any> {
    return await this.get(
      "customer/contract/user/customer/payment/setup/" +
        process.env.REACT_APP_ENVNAME
    );
  }

  async getPaymentInformation(): Promise<any> {
    return await this.get(
      "customer/contract/user/balance/" + process.env.REACT_APP_ENVNAME
    );
  }

  async getAccountPaymentInformation(): Promise<any> {
    return await this.get(
      "customer/contract/user/balance/account/" + process.env.REACT_APP_ENVNAME
    );
  }

  async updateAccountPaymentInformation(params: Token): Promise<any> {
    return await this.post(
      "customer/contract/user/balance/account/" + process.env.REACT_APP_ENVNAME,
      { token: params }
    );
  }

  async payQuick(params: QuickPayType): Promise<any> {
    let param = {
      stripePriceObjectId: params.stripePriceObjectId,
      stripePriceObjectQuantity: params.stripePriceObjectQuantity,
    };
    return await this.post(
      "customer/contract/user/subscription/" + process.env.REACT_APP_ENVNAME,
      param,
      { params: { transaction_id: params.transaction_id } }
    );
  }

  async getContracts(): Promise<any> {
    return await this.get(
      "customer/contract/user/subscription/" + process.env.REACT_APP_ENVNAME
    );
  }

  async getContract(subscription_id: string): Promise<any> {
    return await this.get(
      "customer/contract/user/subscription/detail/" +
        process.env.REACT_APP_ENVNAME +
        "?subscriptionId=" +
        subscription_id
    );
  }
}
