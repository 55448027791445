import React from "react";
import { Grid, Typography } from "@mui/material";
import { LOGO } from "../../../assets";

interface SakeSelectionItemProps {
  title: string;
  desc: string;
}

const SakeSelectionItem: React.FC<SakeSelectionItemProps> = ({
  title,
  desc,
}) => {
  return (
    <Grid container spacing={1} sx={{ my: 0.5 }}>
      <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
        <img src={LOGO} height={50} width={50} />
      </Grid>
      <Grid item xs={9}>
        <Typography sx={{ fontSize: "0.8rem" }}>{title}</Typography>
        <Typography sx={{ fontSize: "0.8rem" }}>{desc}</Typography>
      </Grid>
    </Grid>
  );
};

export default SakeSelectionItem;
