export const QueryKeys = {
  storeList: "getStoreList",
  store: "getStore",
  storeTickets: "getStoreTickets",
  storeTicket: "getStoreTicket",
  storeDevice: "getDevice",
  storeDeviceDetails: "getDeviceDetails",
  storeDevices: "getDevices",
  storeDeviceGroupList: "getDeviceGroupList",
  storeTerminalNames: "getStoreTerminalNames",
  storeSakeList: "getStoreSakeList",
  notifcations: "getNotifications",
  notifcation: "getNotification",
  plans: "getPlans",
  status: "getStatus",
  contract: "getContract",
  invoices: "getInvoices",
  paymentCard: "getPaymentCard",
  clientSecretForUpdating: "getClientSecretForUpdating",
  paymentInformation: "getPaymentInformation",
  accountPaymentInformation: "getAccountPaymentInformation",
  contracts: "getContracts",
  contractDetail: "getContractDetail",
  commerce: "getCommerce",
  wifiInfo: "getWifiInfo",
  wifiInfoSimple: "getWifiInfoSimple",
  termsOfUse: "getTermsOfUse",
  privacyPolicy: "getPrivacyPolicy",
  issuedTickets: "getIssuedTickets",
  issuedTicket: "getIssuedTicket",
  issuedTicketUpdate: "getIssuedTicketUpdate",
  alerts: "getAlerts",
  unreadAlerts: "getUnreadAlerts",
  beverageDataList: "getBeverageDataList",
  totalServedDrink: "getTotalServedDrink",
  remaningBeverages: "getRemainingBeverages",
  ekaDataList: 'getEkaDataList'
} as const;
