import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import HomePage from "./Home";
import StorePage from "./Store";
import AddStorePage from "./AddStore";
import StoreDetailMenuPage from "./StoreDetailMenu";
import StoreDeviceListPage from "./StoreDeviceList";
import StoreDeviceDetailPage from "./StoreDeviceDetail";
import StoreDeviceUpdatePage from "./StoreDeviceUpdate";
import StoreSettingsPage from "./StoreSettings";
import StoreContractsPage from "./StoreContracts";
import StoreContractsDetailPage from "./StoreContractsDetail";
import StoreTicketSettingsPage from "./StoreTicketSettings";
import StoreTicketSettingsDetailPage from "./StoreTicketSettingsDetail";
import StoreTicketSettingsCreatePage from "./StoreTicketSettingsCreate";
import StoreGroupSettingsPage from "./StoreGroupSettings";
import StoreGroupSettingsDetailPage from "./StoreGroupSettingsDetail";
import StoreTicketSettingsUpdatePage from "./StoreTicketSettingsUpdate";
import StoreGroupCreatePage from "./StoreGroupCreate";
import StoreGroupUpdatePage from "./StoreGroupUpdate";
import SettingsPage from "./Settings";
import ContactUsPage from "./ContactUs";
import SentContactUsPage from "./SentContactUs";
import NotificationDetailPage from "./NotificationDetail";
import NotFoundErrorPage from "./NotFoundError";
import ConfirmPhoneNumberPage from "./auth/ConfirmPhoneNumber";
import ContractInitialPage from "./Contract/Initial";
import ContractCustomerInfoPage from "./Contract/CustomerInfo";
import ContractPlanSelectPage from "./Contract/SelectPlan";
import ContractConfirmationInfoPage from "./Contract/ConfirmationInfo";
import BillingPage from "./Billing";
import BillingReceiptPage from "./BillingReceipt";
import BillingPaymentMethodPage from "./BillingPaymentMethod";
import BillingPaymentMethodChangePage from "./BillingPaymentMethodChange";
import BillingPaymentInformationPage from "./BillingPaymentInformation";
import BillingDepositAccountPage from "./BillingDepositAccount";
import BillingDepositAccountChangePage from "./BillingDepositAccountChange";
import NewContractPage from "./NewContract";
import NewContractConfirmationPage from "./NewContractConfirmation";
import NewContractPaymentPage from "./NewContractPayment";
import NewContractCompletePage from "./NewContractComplete";
import CommercePage from "./Commerce";
import UnauthorizationPage from "./Unauthorization";
import PrivacyPolicyPage from "./PrivacyPolicy";
import TermsOfUsePage from "./TermsOfUse";
import NewPasswordPage from "./auth/NewPassword";
import ManagementPage from "./Management";
import DeviceRebootPage from "./DeviceReboot";
import DeviceWirelessPage from "./DeviceWireless";
import IssuedTicketListPage from "./IssuedTicketList";
import IssuedTicketCreatePage from "./IssuedTicketCreate";
import IssuedTicketUpdatePage from "./IssuedTicketUpdate";
import ReceiptLabelEditingPage from "./ReceiptLabelEditing";
import IssuedTicketDetailPage from "./IssuedTicketDetail";
import BeverageDataPage from "./BeverageData";
import NotificationsPage from "./Notifications";
import DataPage from "./Data";

export default {
  Login,
  ForgotPassword,
  ResetPassword,
  HomePage,
  StorePage,
  AddStorePage,
  StoreDetailMenuPage,
  StoreDeviceListPage,
  StoreDeviceDetailPage,
  StoreDeviceUpdatePage,
  StoreSettingsPage,
  StoreContractsPage,
  StoreContractsDetailPage,
  StoreTicketSettingsPage,
  StoreTicketSettingsDetailPage,
  StoreTicketSettingsCreatePage,
  StoreGroupSettingsPage,
  StoreGroupSettingsDetailPage,
  StoreTicketSettingsUpdatePage,
  StoreGroupCreatePage,
  StoreGroupUpdatePage,
  SettingsPage,
  ContactUsPage,
  SentContactUsPage,
  NotificationDetailPage,
  NotFoundErrorPage,
  ConfirmPhoneNumberPage,
  ContractInitialPage,
  ContractCustomerInfoPage,
  ContractPlanSelectPage,
  ContractConfirmationInfoPage,
  BillingPage,
  BillingReceiptPage,
  BillingPaymentMethodPage,
  BillingPaymentMethodChangePage,
  BillingPaymentInformationPage,
  BillingDepositAccountPage,
  BillingDepositAccountChangePage,
  NewContractPage,
  NewContractConfirmationPage,
  NewContractPaymentPage,
  NewContractCompletePage,
  CommercePage,
  UnauthorizationPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  NewPasswordPage,
  ManagementPage,
  DeviceRebootPage,
  DeviceWirelessPage,
  IssuedTicketListPage,
  IssuedTicketCreatePage,
  IssuedTicketUpdatePage,
  ReceiptLabelEditingPage,
  IssuedTicketDetailPage,
  BeverageDataPage,
  NotificationsPage,
  DataPage,
};
