import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import MiniLoader from "../../components/Loader/MiniLoader";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import ErrorHandler from "../../components/ErrorHandler";

interface DialogWifiSetupQRProps {
  qr: string | null;
  open: boolean;
  handleClose: () => void;
}

const DialogWifiSetupQR: React.FC<DialogWifiSetupQRProps> = ({
  qr,
  open,
  handleClose,
}) => {
  const { getWifiInfoSimple } = useCommonApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.wifiInfoSimple],
    () => getWifiInfoSimple()
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {isError ? (
        <ErrorHandler back={handleClose} />
      ) : (
        <Box>
          <DialogTitle id="responsive-dialog-title">
            <Typography
              variant="h6"
              component="h1"
              sx={{ fontWeight: 600, fontSize: "16px" }}
              align="center"
            >
              のまっせを無線LANに接続する方法
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box>
                {isLoading ? (
                  <MiniLoader />
                ) : (
                  <Typography sx={{ fontSize: "9px", color: "#000" }}>
                    {parse(String(data?.details?.contents))}
                  </Typography>
                )}
              </Box>
              <Grid my={3} sx={{ display: "flex", justifyContent: "center" }}>
                {qr && (
                  <img
                    src={`data:image/svg+xml;base64,${qr}`}
                    width={200}
                    height={200}
                  />
                )}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              autoFocus
              sx={{ color: "#000", fontWeight: 600 }}
            >
              閉じる
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default DialogWifiSetupQR;
