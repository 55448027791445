import { Button, ButtonProps, styled } from "@mui/material";

const ButtonRegular = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#9f1831",
  fontWeight: 600,
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiButton-startIcon": {
    marginRight: 0,
  },
}));

export default ButtonRegular;
