import React from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReloadAnimation from "../../components/ReloadAnimation";

const SentContactUs = () => {
  const navigate = useNavigate();
  return (
    <ReloadAnimation>
      <Box
        sx={{
          paddingX: 2,
        }}
      >
        <Paper sx={{ padding: 3 }}>
          <Grid paddingY={3}>
            <Typography align="center" sx={{ fontWeight: 550 }}>
              お問い合わせいただき
            </Typography>
            <Typography align="center" sx={{ fontWeight: 550 }}>
              ありがとうございます
            </Typography>
          </Grid>
          <Grid paddingY={3}>
            <Typography sx={{ fontSize: "0.8rem" }}>
              メッセージを送信しました。5営業日までに弊社からお問い合わせ内容について返信いたします。※返信がない場合はお手数ですが、再度ご連絡ください。
            </Typography>
          </Grid>

          <Grid paddingTop={2}>
            <Button
              type="button"
              onClick={() => navigate("/settings")}
              sx={{
                width: 1,
                color: "#000000",
                fontWeight: 600,
                borderColor: "#000000",
              }}
              variant="outlined"
            >
              設定画面に戻る
            </Button>
          </Grid>
        </Paper>
      </Box>
    </ReloadAnimation>
  );
};

export default SentContactUs;
