import { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailValidation } from "../../utils/validations";
import { Controller, FieldValues, useForm } from "react-hook-form";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { toastSuccess } from "../../utils/toaster";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const authCtx = useContext(AuthContext);
  const [t] = useTranslation();
  const schema = yup.object().shape({
    email: emailValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (authCtx.isLoggedIn) navigate("/");
  }, [authCtx.isLoggedIn]);

  const backLogin = (e: any) => {
    e.preventDefault();
    navigate("/login", {
      replace: true,
    });
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    try {
      await Auth.forgotPassword(data.email);
      toastSuccess('確認メールが送信されました。ご確認ください。')
      navigate("/reset-password", {
        state: { email: data.email },
        replace: true,
      });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{ paddingY: 2.5, fontWeight: 550 }}
          component="h1"
          variant="h6"
          align="center"
        >
          パスワードをリセットする
        </Typography>
        <Typography sx={{ fontSize: "0.8rem" }}>
          以下にメールアドレスを入力してください。
          入力したメールアドレス宛てにパスワードをリセットするためのメッセージが送信されます。
        </Typography>

        <Grid sx={{ marginY: 1 }}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{ my: 1 }}
                type="email"
                id="filled-basic"
                placeholder="メールを入力してください"
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
        {!!errors?.email?.message && (
          <FormHelperText error id="email_helper">
            <>{errors?.email?.message}</>
          </FormHelperText>
        )}

        {error && (
          <Box
            sx={{
              width: 0.95,
              paddingY: 1,
              border: 1,
              color: "#9f1831",
              borderColor: "#9f1831",
              borderBlockColor: "#9f1831",
              margin: "10px 0 10px 0",
            }}
          >
            <Typography align="center">{t(error)}</Typography>
          </Box>
        )}

        <ButtonSubmit
          type="submit"
          sx={{ my: 1, width: 1, fontWeight: 600 }}
          variant="contained"
          disabled={loading}
        >
          パスワードをリセット
        </ButtonSubmit>
      </form>

      <Button
        type="button"
        onClick={(e) => backLogin(e)}
        sx={{
          my: 1,
          width: 1,
          color: "#000",
          fontWeight: 600,
          borderColor: "#000",
        }}
        variant="outlined"
      >
        ログイン画面に戻る
      </Button>
    </Box>
  );
};

export default ForgotPassword;
