import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { RemainingBeverageItemType } from "../../../types";

interface BottleLevelProps {
  item?: RemainingBeverageItemType;
}

const BottleLevelItem: React.FC<BottleLevelProps> = ({ item }) => {
  return (
    <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
      {item ? (
        <Grid item xs={8}>
          <Typography
            sx={{
              fontWeight: 600,
              wordWrap: "break-word",
              wordBreak: "break-all",
              fontSize: "0.8rem",
            }}
          >
            {item.thingNickname}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.6rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {item?.thingName}
          </Typography>
        </Grid>
      ) : (
        <Box>
          <Skeleton width="140px" height="34px" />
          <Skeleton width="100px" height="34px" />
        </Box>
      )}
      <Grid
        item
        xs={4}
        sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        {item ? (
          <Box sx={{ display: "inline-flex" }}>
            <Typography sx={{ alignSelf: "end", fontWeight: 600 }}>
              {item.bottleLiquidVolume || 0}ml
            </Typography>
            <Typography
              sx={{ fontSize: "0.6rem", alignSelf: "end", fontWeight: 600 }}
            >
              /
            </Typography>
            <Typography
              sx={{ fontSize: "0.6rem", alignSelf: "end", fontWeight: 600 }}
            >
              {item.bottleSize || 0}ml
            </Typography>
          </Box>
        ) : (
          <Box>
            <Skeleton width="100px" height="34px" />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default BottleLevelItem;
