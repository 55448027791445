import { Fragment, useContext } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useStoreApi } from "../../custom-hooks/apis/use-store-api";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Store = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getStoreList } = useStoreApi();

  const { isLoading, data, error } = useQuery(
    [QueryKeys.storeList, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getStoreList();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  return (
    <Box sx={{ paddingX: 2 }}>
      <Grid sx={{ justifyContent: "center", display: "flex", my: 2 }}>
        <ButtonSubmit
          sx={{ width: 0.9 }}
          onClick={() => navigate("/store/add")}
        >
          ＋新規店舗追加
        </ButtonSubmit>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : data && data?.branches?.length > 0 ? (
        <Grid item>
          <Demo sx={{ borderRadius: "5px" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                boxShadow: "0px 3px 5px rgb(0 0 0 / 10%)",
                borderRadius: "5px",
                marginBottom: "50px",
              }}
            >
              {data?.branches.map((branch: any, index: number) => {
                return (
                  <Fragment key={"store"}>
                    {index > 0 && <Divider />}
                    <ListItem
                      alignItems="center"
                      key={`store_${index}`}
                      onClick={() => {
                        navigate(`/store/${branch?.store?.customer_store_id}`, {
                          state: {
                            branch: branch,
                          },
                        });
                      }}
                    >
                      <ListItemText
                        primary={
                          <Fragment>
                            <Typography
                              sx={{
                                fontSize: "10px",
                              }}
                            >
                              接続のまっせ数：{branch?.device_count || 0}台
                              &nbsp;&nbsp;&nbsp; チケット数：
                              {branch?.ticket_count || 0}枚
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                fontWeight: 550,
                                marginY: 1,
                                fontSize: "18px",
                                width: 1,
                              }}
                              component="h1"
                              variant="h6"
                              align="center"
                            >
                              {branch?.store?.store_name}&nbsp;&nbsp;
                              {branch?.store?.store_subname}
                            </Typography>
                          </Fragment>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              sx={{
                                display: "inline",
                                color: "#8B8686",
                                fontSize: "10px",
                              }}
                            >
                              {branch?.store?.store_city}&nbsp;&nbsp;
                              {branch?.store?.store_municipalities}&nbsp;&nbsp;
                              {branch?.store?.store_prefectures}
                            </Typography>
                          </Fragment>
                        }
                      />
                      <ListItemIcon sx={{ justifyContent: "end" }}>
                        <ArrowForwardIos
                          sx={{
                            height: "0.8em",
                            width: "0.8em",
                          }}
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Fragment>
                );
              })}
            </List>
          </Demo>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            minHeight: `calc(100vh - 250px)`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid my={0.5}>
            <Typography
              align="center"
              variant="h6"
              component="h1"
              sx={{ display: "block", fontWeight: 600 }}
            >
              店舗情報が存在しません
            </Typography>
          </Grid>
          <Grid my={0.5} width={0.9}>
            <Typography
              align="center"
              sx={{ display: "block", fontSize: "0.8rem" }}
            >
              店舗を登録していない場合は、上記ボタンから追加してください。意図しない動作の場合は、再読み込みいただくか、管理者に問い合わせてください。
            </Typography>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Store;
