import { useContext } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { CreateDeviceGroupRawParamsType } from "../../types";
import { toastError, toastSuccess } from "../../utils/toaster";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import AuthContext from "../../custom-hooks/use-auth-context";
import GroupForm from "../../components/GroupForm";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreGroupCreate = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { hasPermission } = usePermissions();
  const { createDeviceGroup } = useDeviceApi();

  const createDeviceGroupMutation = useMutation(
    (requestBody: CreateDeviceGroupRawParamsType) =>
      createDeviceGroup(requestBody),
    {
      onSuccess: async (data: any) => {
        toastSuccess("新しいグループの作成に成功");
        navigate("/store/groups");
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage =
            "新しいグループを作成できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const onSubmit = async (data: any) => {
    if (!authCtx.user?.store_id) return;

    let params: CreateDeviceGroupRawParamsType = {
      customer_store_id: authCtx.user?.store_id,
      devices: data?.terminalNames,
      group: {
        thingGroupName: data?.groupName,
        thingGroupDescription: data?.groupDesc,
      },
    };
    createDeviceGroupMutation.mutate(params);
  };

  return (
    <Container>
      <ReloadAnimation>
        <GroupForm
          onSubmit={onSubmit}
          isDisabled={!hasPermission("group.create")}
          loading={createDeviceGroupMutation.isLoading}
        />
      </ReloadAnimation>
    </Container>
  );
};

export default StoreGroupCreate;
