import { FC } from "react";
import { Grid, Typography } from "@mui/material";

interface GroupNameSelectionLabelProps {
  option: any;
}

const GroupNameSelectionLabel: FC<GroupNameSelectionLabelProps> = ({
  option,
}) => {
  return (
    <Grid container>
      <Grid item>
        <Typography
          component={"span"}
          sx={{
            display: "inline-block",
            wordWrap: "break-word",
            wordBreak: "break-all",
            fontSize: "0.8rem",
            mr: 2,
            fontWeight: 600,
          }}
        >
          {option?.name || option?.id}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          component={"span"}
          sx={{
            display: "block",
            fontSize: "0.65rem",
            opacity: 0.7,
            wordWrap: "break-word",
            wordBreak: "break-all",
            lineHeight: 2.4,
          }}
        >
          {option?.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GroupNameSelectionLabel;
