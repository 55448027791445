import { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import {
  groupNameValidation,
  groupDescriptionValidation,
} from "../../utils/validations";
import Loader from "../Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import BootstrapInput from "../Inputs/BootstrapInput";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import ModalTerminalNameSelection from "../Modals/TerminalNameSelection";

interface GroupFormProps {
  loading: boolean;
  isDisabled: boolean;
  preValues?: any;
  onSubmit: (data: any) => void;
}

const GroupForm: React.FC<GroupFormProps> = ({
  preValues,
  loading,
  isDisabled,
  onSubmit,
}) => {
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [terminalNames, setTerminalNames] = useState<string[]>([]);

  const { getStoreTerminalNames } = useDeviceApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.storeTerminalNames, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getStoreTerminalNames({
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );
  useEffect(() => {
    let arr: string[] = [];
    if (preValues && preValues?.thingsInGroup?.length > 0) {
      preValues.thingsInGroup.map((val: any) => arr.push(val?.thingName));
      setTerminalNames(arr);
    }
  }, [preValues]);

  const navigate = useNavigate();

  const schema = Yup.object().shape({
    groupName: groupNameValidation,
    groupDesc: groupDescriptionValidation,
  });

  const defaultValues = {
    groupName: preValues?.thingGroupName || "",
    groupDesc: preValues?.thingGroupDescription || "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleTerminalSelections = (data: FieldValues) => {
    setTerminalNames(data?.terminalNames);
    setOpen(false);
  };

  const submit = (data: FieldValues) => {
    onSubmit({
      ...data,
      terminalNames: terminalNames.length > 0 ? terminalNames : [],
    });
  };

  if (isLoading) return <Loader />;
  if (isError)
    return (
      <Box
        sx={{
          height: `calc(100vh - 250px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            ページの表示に失敗しました
          </Typography>

          <Typography
            align="center"
            sx={{
              width: 0.85,
              marginX: "auto",
              marginY: 4,
              fontSize: "0.8rem",
            }}
          >
            ページ表示時にエラーが起きてしまいました。トップからやり直すか、不具合の場合は管理者に問い合わせてください。
          </Typography>
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonSubmit
              type="button"
              sx={{ width: 0.85 }}
              onClick={() => navigate("/")}
            >
              トップに戻る
            </ButtonSubmit>
          </Grid>
        </Box>
      </Box>
    );

  return (
    <Paper sx={{ padding: 2, mb: 9 }}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="groupName" sx={{ paddingY: 1 }}>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    グループ名
                  </Typography>{" "}
                  <span style={{ color: "red" }}>※</span>
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: "0.65rem",
                      fontWeight: 600,
                    }}
                  >
                    グループ名は半角英数字のみ登録可能です。
                  </Typography>
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="groupName"
                  disabled={preValues?.thingGroupName}
                  error={!!errors?.groupName?.message}
                />
              </FormControl>
            )}
            name="groupName"
            control={control}
          />
          {!!errors?.groupName?.message && (
            <FormHelperText error id="groupName_helper">
              <>{errors?.groupName?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="groupDesc" sx={{ paddingY: 1 }}>
                  グループの説明 <span style={{ color: "red" }}>※</span>
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="groupDesc"
                  multiline={true}
                  minRows={5}
                  error={!!errors?.groupDesc?.message}
                />
              </FormControl>
            )}
            name="groupDesc"
            control={control}
          />
          {!!errors?.groupDesc?.message && (
            <FormHelperText error id="groupDesc_helper">
              <>{errors?.groupDesc?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid
          my={2}
          container
          spacing={2}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Grid item xs={5}>
            <Typography sx={{ fontSize: "0.8rem" }}>のまっせ</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontSize: "0.8rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              align="right"
            >
              {terminalNames.length > 0
                ? terminalNames.map((name: string, index: number) => (
                    <span key={index}>
                      {index > 0 ? `, ` : ""} {name}
                    </span>
                  ))
                : "なし"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              sx={{
                height: "0.8em",
                width: "0.8em",
              }}
            />
          </Grid>
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <ButtonSubmit
            type="submit"
            variant="contained"
            sx={{ width: 1 }}
            disabled={isDisabled}
          >
            グループを追加
          </ButtonSubmit>
        </Grid>
      </form>
      <ModalTerminalNameSelection
        open={open}
        setOpen={setOpen}
        preValues={terminalNames}
        options={data?.things}
        onSubmit={handleTerminalSelections}
      />
      {loading && <Loader />}
    </Paper>
  );
};

export default GroupForm;
