import { createContext, useCallback, useState } from "react";

export type GlobalDataType = {
  loading: boolean;
  tab: string | null;
  pageTitle: string;
  pageSubTitle: string;
  loadingOp: {
    start: () => void;
    finish: () => void;
  };
  setCurrentTab: (tab: string) => void;
  setPageTitle: (pageTitle: string) => void;
  setPageSubTitle: (pageTitle: string) => void;
};

const GlobalDataContext = createContext<GlobalDataType>({
  loading: false,
  pageTitle: "",
  pageSubTitle: "",
  tab: null,
  loadingOp: {
    start: () => {},
    finish: () => {},
  },
  setCurrentTab: (tab: string) => {},
  setPageTitle: (pageTitle: string) => {},
  setPageSubTitle: (pageTitle: string) => {},
});

export const GlobalDataContextProvider = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<string | null>(null);
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageSubTitle, setPageSubTitle] = useState<string>("");

  const setCurrentTab = (tab: string) => {
    setTab(tab);
  };

  const startLoading = useCallback(() => setLoading(true), []);
  const finishLoading = useCallback(() => setLoading(false), []);

  return (
    <GlobalDataContext.Provider
      value={{
        loading: loading,
        pageTitle: pageTitle,
        pageSubTitle: pageSubTitle,
        tab: tab,
        loadingOp: {
          start: startLoading,
          finish: finishLoading,
        },
        setCurrentTab: setCurrentTab,
        setPageTitle: setPageTitle,
        setPageSubTitle: setPageSubTitle,
      }}
    >
      {props.children}
    </GlobalDataContext.Provider>
  );
};

export default GlobalDataContext;
