import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  styled,
  Typography,
} from "@mui/material";

interface DialogContractInfoProps {
  open: boolean;
  data: any;
  handleClose: () => void;
}

const DialogContractInfo: React.FC<DialogContractInfoProps> = ({
  open,
  data,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          variant="h6"
          component="h1"
          sx={{ fontWeight: 600, fontSize: "16px" }}
          align="center"
        >
          ご契約の流れ
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid>
            <TypographyExt5
              sx={{ fontWeight: "600", fontSize: "15px" }}
              dangerouslySetInnerHTML={{ __html: data?.details?.contents }}
            ></TypographyExt5>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          autoFocus
          sx={{ color: "#000", fontWeight: 600 }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TypographyExt5 = styled(Typography)(({ theme }) => ({
  h2: {
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
  },
  blockquote: {
    backgroundColor: "#E4DFDE",
    margin: "10px",
    padding: "5px 20px",
  },
}));
export default DialogContractInfo;
