import { useCallback, useContext, useMemo } from "react";
import { Token } from "@stripe/stripe-js";
import appBillingApi from "../../apis/billing-api";
import GlobalDataContext from "../use-global-data";
import { QuickPayType } from "../../types";

export const useBillingApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const billingApi = useMemo(
    () =>
      new appBillingApi(
        process.env.REACT_APP_API_6 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getInvoices = useCallback(async () => {
    loadingOp.start();
    const response = await billingApi.getInvoices();
    loadingOp.finish();
    return response;
  }, [billingApi]);

  const getPaymentCard = useCallback(async () => {
    loadingOp.start();
    const response = await billingApi.getPaymentCard();
    loadingOp.finish();
    return response;
  }, [billingApi]);

  const getClientSecret = useCallback(async () => {
    loadingOp.start();
    const response = await billingApi.getClientSecret();
    loadingOp.finish();
    return response;
  }, [billingApi]);

  const getPaymentInformation = useCallback(async () => {
    loadingOp.start();
    const response = await billingApi.getPaymentInformation();
    loadingOp.finish();
    return response;
  }, [billingApi]);

  const getAccountPaymentInformation = useCallback(async () => {
    loadingOp.start();
    const response = await billingApi.getAccountPaymentInformation();
    loadingOp.finish();
    return response;
  }, [billingApi]);

  const updateAccountPaymentInformation = useCallback(
    async (params: Token) => {
      loadingOp.start();
      const response = await billingApi.updateAccountPaymentInformation(params);
      loadingOp.finish();
      return response;
    },
    [billingApi]
  );

  const payQuick = useCallback(
    async (params: QuickPayType) => {
      loadingOp.start();
      const response = await billingApi.payQuick(params);
      loadingOp.finish();
      return response;
    },
    [billingApi]
  );

  const getContracts = useCallback(async () => {
    loadingOp.start();
    const response = await billingApi.getContracts();
    loadingOp.finish();
    return response;
  }, [billingApi]);

  const getContract = useCallback(
    async (subscription_id: string) => {
      loadingOp.start();
      const response = await billingApi.getContract(subscription_id);
      loadingOp.finish();
      return response;
    },
    [billingApi]
  );

  return {
    getInvoices,
    getPaymentCard,
    getClientSecret,
    getPaymentInformation,
    getAccountPaymentInformation,
    updateAccountPaymentInformation,
    payQuick,
    getContracts,
    getContract,
  };
};
