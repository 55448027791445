import { Fragment } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DeviceDetailType } from "../../types";
import { isOutVersion } from "../../utils/helper";
import DeviceDetailMaintenance from "./DeviceDetailMaintenance";

interface ListItemProps {
  item: DeviceDetailType;
}

const StoreDeviceListItem: React.FC<ListItemProps> = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        px: 2,
        py: 1,
        cursor: "pointer",
        borderRadius: 0,
        height: "90px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <DeviceDetailMaintenance
        isOutVersion={false}
        status={item?.maintenance_qr}
        sx={{ height: "90px", borderRadius: "0px" }}
      />
      <Grid
        container
        sx={{ p: 0, m: 0 }}
        onClick={() => {
          navigate(`/store/devices/${item?.thingName}`);
        }}
      >
        <Grid
          item
          xs={11}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Fragment>
            <Typography
              sx={{
                display: "block",
                fontWeight: 550,
                fontSize: "0.8rem",
                width: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              component="h1"
              variant="h6"
            >
              {item?.thingNickname || item?.thingName}
            </Typography>

            <Typography
              sx={{
                color: "#000",
                fontSize: "0.6rem",
              }}
            >
              流量 : {item?.servePour}ml
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontSize: "0.6rem",
              }}
            >
              {item?.thingType === "nomasse3rd" ? (
                <>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "10px",
                    }}
                  >
                    {`銘柄 : ${item?.serveProductName}`}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "10px",
                    }}
                  >
                    {`Aポート 銘柄 : ${item?.portA?.serveProductName}`}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "10px",
                    }}
                  >
                    {`Bポート 銘柄 : ${item?.portB?.serveProductName}`}
                  </Typography>
                </>
              )}
            </Typography>
          </Fragment>
        </Grid>
        <Grid
          xs={1}
          item
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="option"
            onClick={() => {}}
          >
            <ArrowForwardIos
              sx={{ fontSize: "1rem !important" }}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StoreDeviceListItem;
