import AwsApi from "./aws-api";
import { CreateStore } from "../types";

export default class appCustomerApi extends AwsApi {
  async getStatus(): Promise<any> {
    return await this.get(
      "customer/contract/status/" + process.env.REACT_APP_ENVNAME
    );
  }
  
  async getPlans(): Promise<any> {
    return await this.get(
      "customer/contract/items/" + process.env.REACT_APP_ENVNAME
    );
  }

  async checkStock(params: any): Promise<any> {
    return await this.get(
      "customer/contract/devices/" + process.env.REACT_APP_ENVNAME,
      { params }
    );
  }

  async createStore(params: CreateStore): Promise<any> {
    return await this.post(
      "customer/contract/user/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }
}
