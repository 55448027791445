import { useCallback, useContext, useMemo } from "react";
import { QueryParam } from "../../types";
import GlobalDataContext from "../use-global-data";
import appeEkaApi from "../../apis/eka-api";

export const useEkaApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const ekaApi = useMemo(
    () =>
      new appeEkaApi(
        process.env.REACT_APP_API_7 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const postEkaDataList = useCallback(
    async (params: QueryParam, nextIndex: any) => {
      loadingOp.start();
      const response = await ekaApi.postEkaDataList(params, nextIndex);
      loadingOp.finish();
      return response;
    },
    [ekaApi]
  );

  return { postEkaDataList };
};
