import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

interface DialogPlanDetailProps {
  item: any;
  open: boolean;
  handleClose: () => void;
}

const DialogPlanDetail: React.FC<DialogPlanDetailProps> = ({
  item,
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          variant="h6"
          component="h1"
          sx={{ fontWeight: 600, fontSize: "16px" }}
          align="center"
        >
          {item?.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid>
            <Typography
              sx={{ fontSize: "0.6rem" }}
              dangerouslySetInnerHTML={{ __html: item?.desc }}
            ></Typography>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          autoFocus
          sx={{ color: "#000", fontWeight: 600 }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPlanDetail;
