import { Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useMutation } from "react-query";
import { AlertDetailType, SelectedAlertType } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { useNotificationApi } from "../../custom-hooks/apis/use-notification";
import Loader from "../../components/Loader";
import ReloadAnimation from "../../components/ReloadAnimation";
import ButtonCustom from "../../components/Buttons/ButtonCustom";
import AppAlertItem from "./AppAlertItem";
import ModalConfirmLog from "./ModalConfirmLog";
import * as Yup from "yup";

interface AppAlertsProps {
  data: AlertDetailType[];
  isLoading: boolean;
}

const AppAlerts: React.FC<AppAlertsProps> = ({ data, isLoading }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [list, setList] = useState<AlertDetailType[]>(data);
  const [selectedAlerts, setSelectedAlerts] = useState<SelectedAlertType[]>([]);

  const { markAlerts } = useNotificationApi();

  useEffect(() => {
    if (data.length > 0) {
      setList(data);
    }
  }, [data]);

  const markAlertsMutation = useMutation(
    (requestBody: SelectedAlertType[]) => markAlerts(requestBody),
    {
      onSuccess: async (data) => {
        console.log("🚀 ~ onSuccess: ~ data:", data);
        if (list.length === selectedAlerts.length) setList([]);
        else {
          let selectedAlert = selectedAlerts[0];
          let arr = list.filter((item) => {
            if (
              item.device === selectedAlert.device &&
              item.filename === selectedAlert.filename
            ) {
              return false;
            }
            return true;
          });
          setList(arr);
        }
        setSelectedAlerts([]);
      },
      onError: async (error: any) => {
        console.log("🚀 ~ onError: ~ error:", error);
        setSelectedAlerts([]);
      },
    }
  );

  const onSubmit = () => {
    const result: SelectedAlertType[] = list.map(({ filename, device }) => ({
      filename,
      device,
    }));
    setSelectedAlerts(result);
    setOpen(true);
  };

  const submit = (params: SelectedAlertType[]) => {
    markAlertsMutation.mutate(params);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitSingleAlert = (selectedItem: SelectedAlertType) => {
    setSelectedAlerts([selectedItem]);
    setOpen(true);
  };

  if (isLoading) return <Loader />;
  if (list.length <= 0)
    return (
      <Box
        sx={{
          display: "flex",
          minHeight: `calc(100vh - 200px)`,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        のまっせアラートはありません
      </Box>
    );
  return (
    <ReloadAnimation>
      <Fragment>
        <Grid sx={{ display: "flex", justifyContent: "end" }}>
          <ButtonCustom
            type="button"
            onClick={onSubmit}
            sx={{ color: "#9F1831", fontWeight: 600, fontSize: "0.8rem" }}
          >
            すべて既読にする
          </ButtonCustom>
        </Grid>

        <Grid sx={{ display: "flex", gap: "1px", flexDirection: "column" }}>
          {list.map((alert: AlertDetailType, index: number) => (
            <Fragment key={index}>
              <AppAlertItem alert={alert} submit={submitSingleAlert} />
            </Fragment>
          ))}
        </Grid>
        <ModalConfirmLog
          open={open}
          handleSubmit={submit}
          handleClose={handleClose}
          totalAlertCount={list.length}
          values={selectedAlerts}
        />
      </Fragment>
    </ReloadAnimation>
  );
};

export default AppAlerts;
