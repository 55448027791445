import { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Tabs,
  Tab,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { emailValidation, passwordValidation } from "../../utils/validations";
import AuthContext from "../../custom-hooks/use-auth-context";
import PasswordField from "../../components/Inputs/PasswordField";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import Regist from "../../components/Auth/Regist";
import Loader from "../../components/Loader";

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
});

const LoginPage = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState(0);
  const [error, setError] = useState<string>("");
  const [t] = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const login_redirect_url = urlParams.get("login_redirect_url");

  const schema = yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    authCtx.loginLoadingOp.start();
    try {
      const user = await Auth.signIn(data.email, data.password);

      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate(`/new-password`, {
          state: { email: data?.email, password: data?.password },
        });
      } else {
        authCtx.onLogin(user);

        if (login_redirect_url) {
          let redirect_url = login_redirect_url;
          const paramsObject = Object.fromEntries(urlParams.entries());
          for (const [key, value] of Object.entries(paramsObject)) {
            if (key !== "login_redirect_url") {
              redirect_url += "&" + key + "=" + value;
            }
          }
          authCtx.loginLoadingOp.finish();

          const BASE_URL =
            window.location.protocol + "//" + window.location.host;
          const red_url = redirect_url.replace(BASE_URL, "");
          navigate(red_url);
        } else {
          authCtx.loginLoadingOp.finish();
          navigate("/", { replace: true });
        }
      }
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
      authCtx.loginLoadingOp.finish();
    }
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const theme = useTheme();

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) navigate("/");
  }, [authCtx.isLoggedIn]);

  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{ bgcolor: "#9f1831", border: 0 }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#fff" },
            "& .MuiTab-root": { color: "#fff", bgcolor: "#9f1831" },
            "& .Mui-selected": {
              color: "#000",
              bgcolor: "#fff",
            },
          }}
        >
          <Tab
            style={{ opacity: 1, fontWeight: 600 }}
            label="ログイン"
            {...a11yProps(0)}
          />
          <Tab
            style={{ opacity: 1, fontWeight: 600 }}
            label="登録・ご契約"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0} dir={theme.direction}>
        <Box sx={{ width: 1 }}>
          <Typography
            sx={{ paddingY: 2.5, fontWeight: 600 }}
            component="h1"
            variant="h6"
            align="center"
          >
            アカウントにサインインする
          </Typography>
          <Box sx={{ padding: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="email"
                    sx={{ my: 1 }}
                    id="filled-basic"
                    placeholder="メールを入力してください"
                  />
                )}
                name="email"
                control={control}
              />
              {!!errors?.email?.message && (
                <FormHelperText error id="email_helper">
                  <>{errors?.email?.message}</>
                </FormHelperText>
              )}

              <Controller
                render={({ field }) => {
                  return (
                    <FormControl sx={{ my: 1, width: 1 }}>
                      <PasswordField
                        field={field}
                        id={"password"}
                        placeholder={"パスワードを入力してください"}
                      />
                    </FormControl>
                  );
                }}
                name="password"
                control={control}
              />
              {!!errors?.password?.message && (
                <FormHelperText error id="password_helper">
                  <>{errors?.password?.message}</>
                </FormHelperText>
              )}

              {error && (
                <Box
                  sx={{
                    width: 1,
                    paddingY: 1,
                    border: 1,
                    color: "#9f1831",
                    borderColor: "#9f1831",
                    borderBlockColor: "#9f1831",
                    margin: "35px 0 10px 0",
                  }}
                >
                  <Typography align="center">{t(error)}</Typography>
                </Box>
              )}

              <ButtonSubmit
                type="submit"
                sx={{ my: 3, width: 1, fontWeight: 600 }}
                variant="contained"
                disabled={loading}
              >
                ログイン
              </ButtonSubmit>
            </form>
            <Box textAlign={"center"}>
              <Link
                to="/forgot-password"
                style={{
                  color: "#959DFD",
                  textDecoration: "none",
                  fontSize: "0.8rem",
                }}
              >
                パスワードをお忘れですか？
              </Link>
            </Box>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1} dir={theme.direction}>
        <Regist />
      </TabPanel>
      {loading && <Loader />}
    </>
  );
};

export default LoginPage;
