import { useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  List,
  ListItem,
  FormHelperText,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useCustomerApi } from "../../custom-hooks/apis/use-customer-api";
import { contractUnitsValidation } from "../../utils/validations";
import { QueryKeys } from "../../global-state/react-query-keys";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastError } from "../../utils/toaster";
import * as Yup from "yup";
import AuthContext from "../../custom-hooks/use-auth-context";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import Loader from "../../components/Loader";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ButtonBack from "../../components/Buttons/ButtonBack";
import DialogPlanDetail from "../../components/Dialogs/DialogPlanDetail";
import CurrencyFormat from "react-currency-format";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import PlanItem from "../../components/PlanItem";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const NewContract = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { loading, loadingOp } = useContext(GlobalDataContext);
  const { getPlans, checkStock } = useCustomerApi();
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();

  const checkStockMutation = useMutation(
    (requestBody: any) => checkStock(requestBody),
    {
      onSuccess: async (response: any) => {
        if (String(response.status).toLowerCase() === "available") {
          navigate("/contract/new/confirmation");
        } else {
          setError(true);
        }
      },
      onError: async (error: any) => {
        toastError(
          "申し訳ありませんが、予期せぬエラーが発生しました。不具合の場合は管理者に問い合わせてください。"
        );
        loadingOp.finish();
      },
    }
  );

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.plans, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getPlans();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const schema = Yup.object().shape({
    contract_units: contractUnitsValidation,
  });

  const defaultValues = {
    contract_units: 0,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data?.items.length > 0) {
      setSelectedPlan(data?.items[0]);
    }
  }, [data?.items]);

  const onSubmit = async (data: FieldValues) => {
    let params = {
      ...data,
      plan: selectedPlan,
    };
    localStorage.setItem("new_contract", JSON.stringify(params));

    checkStockMutation.mutate({
      deviceCount: data?.contract_units,
    });
  };
  let contract_units = watch("contract_units");

  const handleBack = () => {
    setError(false);
  };

  if (isError) return <ErrorHandler url="/contracts" />;
  if (isLoading || loading) return <Loader />;
  return (
    <ReloadAnimation>
      <Box sx={{ paddingX: 2 }}>
        {error ? (
          <Paper sx={{ padding: 2, marginBottom: "50px" }}>
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: 2 }}
            >
              <Typography sx={{ fontWeight: "600" }}>
                在庫がありませんでした
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ fontSize: "0.8rem" }}>
                申し訳ございません。ご指定の台数ののまっせをご用意できておりませんでした。台数を減らしてのご契約か、追加の台数をご依頼ください。
              </Typography>
            </Grid>
            <Grid sx={{ marginY: 2 }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                台数を減らす場合
              </Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>
                「戻る」ボタンをタップし、ご契約台数を変更する
              </Typography>
            </Grid>
            <Grid sx={{ marginY: 2 }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                追加の台数を依頼する場合
              </Typography>
              <Typography sx={{ fontSize: "0.8rem" }}>
                「問い合わせる」をタップするとお問い合わせフォームが表示されます。そちらに設置したい台数を入力の上、ご要望を送信してください。
              </Typography>
            </Grid>
            <Grid
              sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
            >
              <ButtonSubmit
                type="button"
                variant="contained"
                sx={{ width: 1, fontWeight: 600 }}
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                問い合わせる
              </ButtonSubmit>
              <ButtonBack
                type="button"
                variant="contained"
                sx={{ width: 1, fontWeight: 600 }}
                onClick={handleBack}
              >
                戻る
              </ButtonBack>
            </Grid>
          </Paper>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Paper sx={{ padding: 2, marginBottom: "50px" }}>
                <Box
                  sx={{ backgroundColor: "#9f1831", padding: "5px 0 5px 0" }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "600",
                      paddingLeft: "10px",
                    }}
                  >
                    プラン
                  </Typography>
                </Box>
                <Grid
                  sx={{
                    paddingY: 1,
                    maxHeight: "200px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {data?.items?.length > 0
                    ? data?.items.map((item: any) => {
                        return (
                          <>
                            <PlanItem
                              item={item}
                              handleSelect={(item: any) => {
                                setOpen(true);
                                setSelectedPlan(item);
                              }}
                            />
                          </>
                        );
                      })
                    : "予定はありません"}
                </Grid>

                <Grid py={1}>
                  <Controller
                    render={({ field }) => (
                      <FormControl fullWidth variant="standard">
                        <InputLabel
                          shrink
                          htmlFor="contract_units"
                          sx={{ paddingY: 1 }}
                        >
                          ご契約台数
                        </InputLabel>
                        <BootstrapInput
                          {...field}
                          sx={{ width: 1 }}
                          id="contract_units"
                          type="number"
                          error={!!errors?.contract_units?.message}
                        />
                      </FormControl>
                    )}
                    name="contract_units"
                    control={control}
                  />
                  {!!errors?.contract_units?.message && (
                    <FormHelperText error id="contract_units_helper">
                      <>{errors?.contract_units?.message}</>
                    </FormHelperText>
                  )}
                  <Grid sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
                    <Typography component="span">計</Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        lineHeight: "1.4rem",
                      }}
                    >
                      <CurrencyFormat
                        value={
                          contract_units && selectedPlan?.amount
                            ? contract_units * selectedPlan?.amount
                            : "0"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </Typography>
                    <Typography component="span">円(税込)/月</Typography>
                  </Grid>
                </Grid>

                <Grid sx={{ my: 2 }}>
                  <Typography sx={{ fontSize: "0.7rem" }}>
                    特記事項の記載特記事項の記載特記事項の記載特記事項の記載特記事項の記載特記事項の記載特記事項の記載
                  </Typography>
                  <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
                    <ListItem sx={{ display: "list-item", padding: 0 }}>
                      <Typography sx={{ fontSize: "0.7rem" }}>
                        特記事項特記事項
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: "list-item", padding: 0 }}>
                      <Typography sx={{ fontSize: "0.7rem" }}>
                        特記事項特記事項
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>

                <Grid>
                  <Link
                    to="/commerce"
                    style={{
                      color: "#959DFD",
                      fontSize: "0.7rem",
                      textDecoration: "underline",
                    }}
                  >
                    特定商取引法に関する表記
                  </Link>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                    mt: 5,
                  }}
                >
                  <ButtonSubmit
                    type="submit"
                    sx={{ width: 1, fontWeight: 600 }}
                  >
                    次へ
                  </ButtonSubmit>
                  <ButtonBack
                    type="button"
                    sx={{ width: 1, fontWeight: 600 }}
                    onClick={() => navigate("/contracts")}
                  >
                    戻る
                  </ButtonBack>
                </Grid>
              </Paper>
            </form>
            <DialogPlanDetail
              open={open}
              item={selectedPlan}
              handleClose={() => setOpen(false)}
            />
          </>
        )}
      </Box>
    </ReloadAnimation>
  );
};

export default NewContract;
