import AwsApi from "./aws-api";
import { SelectedAlertType } from "../types";

class appNotificationApi extends AwsApi {
  async getAlertLog(params: any): Promise<any> {
    return await this.get(
      process.env.REACT_APP_ENVNAME + "/customer/device/alert",
      { params }
    );
  }
  async getUnreadLog(): Promise<any> {
    return await this.get(
      process.env.REACT_APP_ENVNAME + "/customer/device/alert/total"
    );
  }

  async markAlerts(params: SelectedAlertType[]): Promise<any> {
    return await this.post(
      process.env.REACT_APP_ENVNAME + "/customer/device/alert",
      params
    );
  }
}
export default appNotificationApi;
