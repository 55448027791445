import { useContext, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useNotificationApi } from "../../custom-hooks/apis/use-notification";
import AuthContext from "../../custom-hooks/use-auth-context";
import NotificationTabs from "./NotificationTabs";
import AppNotifications from "./AppNotifications";
import AppAlerts from "./AppAlerts";
import Loader from "../../components/Loader";

const Notifications = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const authCtx = useContext(AuthContext);
  const { getNotifications } = useCommonApi();
  const { getAlertLog, getUnreadLog } = useNotificationApi();

  const {
    data: notificationData,
    isError: isNotificationError,
    isLoading: isNotificationLoading,
  } = useQuery(
    [
      QueryKeys.notifcations,
      authCtx.user?.signInUserSession.idToken,
      selectedTab,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken && selectedTab === 1)
        return getNotifications();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const {
    data: unreadAlertData,
    isError: IsUnreadAlertError,
    isLoading: IsUnreadAlertLoading,
  } = useQuery(
    [QueryKeys.unreadAlerts, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getUnreadLog();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const {
    data: alertData,
    isError: isAlertError,
    isLoading: isAlertLoading,
  } = useQuery(
    [QueryKeys.alerts, authCtx.user?.signInUserSession.idToken, selectedTab],
    () => {
      if (authCtx.user?.signInUserSession.idToken && selectedTab === 0)
        return getAlertLog({ type: "unread" });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isNotificationError || isAlertError || IsUnreadAlertError)
    return (
      <Box
        sx={{
          height: `calc(100vh - 250px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            ページの表示に失敗しました
          </Typography>

          <Typography
            align="center"
            sx={{
              width: 0.85,
              marginX: "auto",
              marginY: 4,
              fontSize: "0.8rem",
            }}
          >
            ページ表示時にエラーが起きてしまいました。トップからやり直すか、不具合の場合は管理者に問い合わせてください。
          </Typography>
        </Box>
      </Box>
    );

  return (
    <Grid sx={{ px: 2, pb: 9 }}>
      <NotificationTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        unreadAlertCount={unreadAlertData?.numberOfUnreadLog}
        unreadNotificationCount={0}
      />

      <Grid sx={{ my: 2 }}>
        {selectedTab === 0 && (
          <AppAlerts
            data={alertData?.loglist || []}
            isLoading={isAlertLoading}
          />
        )}
        {selectedTab === 1 && (
          <AppNotifications
            list={notificationData?.list || []}
            isLoading={isNotificationLoading}
          />
        )}
        {IsUnreadAlertLoading && <Loader />}
      </Grid>
    </Grid>
  );
};

export default Notifications;
