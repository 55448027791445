import { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import { ROLE } from "../../permissions";
import AuthContext from "../../custom-hooks/use-auth-context";
import AppNotifications from "../Notifications/AppNotifications";
import SubTitle from "./SubTitle";
import TotalServedDrinkInfo from "./TotalServedDrinkInfo";
import PopularDrink from "./PopularDrink";
import BottleLevel from "./BottleLevel";
import { useDashboardApi } from "../../custom-hooks/apis/use-dashboard-api";
import {
  GetTotalServedDrinkParams,
  StoreParamType,
  TotalServedDrinkParamType,
} from "../../types";
import { toastError } from "../../utils/toaster";

const Home = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { getNotifications } = useCommonApi();
  const { getTotalServedDrink, getRemainingBeverages } = useDashboardApi();

  const {
    data: notificationData,
    isError: isNotificationError,
    isLoading: isNotificationLoading,
  } = useQuery(
    [QueryKeys.notifcations, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getNotifications();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const totalServedDrinkMutation = useMutation(
    (requestBody: GetTotalServedDrinkParams) =>
      getTotalServedDrink(requestBody),
    {
      onError: async (error: any) => {
        toastError(
          "申し訳ありませんが、お酒提供杯数取得ことができませんでした"
        );
      },
    }
  );

  const remainingBeveragesMutation = useMutation(
    (requestBody: StoreParamType) => getRemainingBeverages(requestBody),
    {
      onError: async (error: any) => {
        toastError(
          "申し訳ありませんが、お酒残量トップ5取得ことができませんでした"
        );
      },
    }
  );

  useEffect(() => {
    if (
      authCtx.user?.contract?.status === 0 &&
      authCtx.user?.contract?.type === ROLE.SUBSCRIPTION
    ) {
      navigate("/contract/initial");
    } else {
      fetchTotalServedDrink();
      fetchRemainingBeverages();
    }
  }, [authCtx.user?.contract?.status]);

  const fetchTotalServedDrink = () => {
    if (!authCtx.user?.store_id) return;

    totalServedDrinkMutation.mutate({
      customerStoreId: authCtx.user?.store_id,
      period: TotalServedDrinkParamType.DAILY,
    });
  };

  const fetchRemainingBeverages = () => {
    if (!authCtx.user?.store_id) return;

    remainingBeveragesMutation.mutate({
      customerStoreId: authCtx.user?.store_id,
    });
  };

  return (
    <Box sx={{ mx: 1, pb: 9 }}>
      <SubTitle title="お知らせ" />
      <AppNotifications
        list={notificationData?.list}
        isLoading={isNotificationLoading}
      />

      <SubTitle title="データ" />
      <TotalServedDrinkInfo
        data={totalServedDrinkMutation.data}
        isError={totalServedDrinkMutation.isError}
        isLoading={totalServedDrinkMutation.isLoading}
        fetch={fetchTotalServedDrink}
      />

      <PopularDrink
        data={totalServedDrinkMutation.data}
        isError={totalServedDrinkMutation.isError}
        isLoading={totalServedDrinkMutation.isLoading}
        fetch={fetchTotalServedDrink}
      />

      <BottleLevel
        data={remainingBeveragesMutation.data}
        isError={remainingBeveragesMutation.isError}
        isLoading={remainingBeveragesMutation.isLoading}
        fetch={fetchRemainingBeverages}
      />
    </Box>
  );
};

export default Home;
