import { useCallback, useContext, useMemo } from "react";
import appNotificationApi from "../../apis/notification-api";
import { SelectedAlertType } from "../../types";
import GlobalDataContext from "../use-global-data";

export const useNotificationApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const notificationApi = useMemo(
    () =>
      new appNotificationApi(
        process.env.REACT_APP_API_7 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getAlertLog = useCallback(
    async (params: any) => {
      loadingOp.start();
      const deviceList = await notificationApi.getAlertLog(params);
      loadingOp.finish();
      return deviceList;
    },
    [notificationApi]
  );

  const getUnreadLog = useCallback(async () => {
    loadingOp.start();
    const deviceList = await notificationApi.getUnreadLog();
    loadingOp.finish();
    return deviceList;
  }, [notificationApi]);

  const markAlerts = useCallback(
    async (params: SelectedAlertType[]) => {
      loadingOp.start();
      const response = await notificationApi.markAlerts(params);
      loadingOp.finish();
      return response;
    },
    [notificationApi]
  );

  return { getAlertLog, getUnreadLog, markAlerts };
};
