import { useState } from "react";
import { Box, Modal, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const Unauthorization = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ pb: 7, backgroundColor: "#E6E4EB" }}>
        <Container
          maxWidth="sm"
          sx={{
            minHeight: `100vh`,
            bgcolor: "#E6E4EB",
            padding: 0,
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: `100vh`,
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Grid sx={{ my: 2 }}>
              <Typography sx={{ fontWeight: 600 }}>
                このページにはアクセスできません
              </Typography>
            </Grid>
            <Grid sx={{ my: 2 }}>
              <Typography sx={{ fontSize: "0.85rem", textAlign: "center" }}>
                お客様の権限でアクセスできないページです。アクセスするためには管理者に問い合わせてください。
              </Typography>
            </Grid>
            <Grid sx={{ my: 2, width: 1 }}>
              <ButtonSubmit
                type="button"
                sx={{ width: 1 }}
                onClick={() => navigate("/")}
              >
                トップに戻る
              </ButtonSubmit>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Box>
    </Modal>
  );
};

export default Unauthorization;
