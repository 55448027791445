import React from "react";
import { Grid, Paper, Box } from "@mui/material";
import BoxChip from "../../components/Chip/BoxChip";
import { EkaItemType, TicketStyles } from "../../types";
import SmallTypography from "../../components/Text/EkaItem/SmallTypography";
import NormalTypography from "../../components/Text/EkaItem/NormalTypography";
import LargeTypography from "../../components/Text/EkaItem/LargeTypography";
import NormalRedTypography from "../../components/Text/EkaItem/NormalRedTypography"
import moment from "moment";

interface EkaItemProps {
  item: EkaItemType;
}

const EkaItem: React.FC<EkaItemProps> = ({ item }) => {
  const ticketStyles: TicketStyles = {
    'coupon': { backgroundColor: "#9f1831", fontColor: "#ffffff", text: '回数券残', unit: '杯' },
    'prepaid': { backgroundColor: "#3000af", fontColor: "#ffffff", text: 'プリペイド残', unit: '円' },
    'all_you_can_drink': { backgroundColor: "#a49c00", fontColor: "#ffffff", text: '飲み放題残' }
  };

  const { backgroundColor, fontColor, text, unit, fontSize } = ticketStyles[item.ticketType] || { color: 'transparent', text: '' };

  return (
    <Paper sx={{ mx: 2, my: 0.5, p: 2, borderRadius: "5px" }}>
      <Grid container spacing={2}>
        <Grid item>
          <SmallTypography>生成日時:{moment.unix(item.createdAt).format("YYYY/MM/DD HH:mm")}</SmallTypography>
        </Grid>
        <Grid item>
          <SmallTypography>最終利用日時:{moment.unix(item.lastUpdatedAt).format("YYYY/MM/DD HH:mm")}</SmallTypography>
        </Grid>
      </Grid>

      <Grid container
        justifyContent="flex-start"
        alignItems="center"
        columnSpacing={"15px"}
      >
        <Grid item xs={12} sm={'auto'} >
          <LargeTypography>
            {item.issuedEkaSerial}
          </LargeTypography>
        </Grid>

        <Grid item xs={12} sm={'auto'}>
          <NormalTypography noWrap={true}>
            チケット名:{item.ticketName}
          </NormalTypography>
        </Grid>
      </Grid>

      <Grid container
        columnSpacing={"15px"}
        justifyContent={"flex-start"}
        alignItems="center"
      >
        <Grid item>
          <NormalTypography>
            販売額:{item.ticketPrice}円
          </NormalTypography>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex", textAlign: 'center', justifyContent: 'center' }}>
            <BoxChip backgroundColor={backgroundColor} fontColor={fontColor} text={text} fontSize={fontSize} />
            {item.leftQuantity === null ?
              <NormalTypography ml={'4px'}>
                {item.ekaLifespanEnd ? moment.unix(item.ekaLifespanEnd).format("YYYY/MM/DD HH:mm") : "無制限"}
              </NormalTypography> :
              <NormalTypography ml={'4px'}>
                {item.leftQuantity}{unit}
              </NormalTypography>
            }
          </Box>
        </Grid>
        {item.ticketType !== 'all_you_can_drink' &&
          <Grid item xs={12} sm={'auto'} sx={{ display: "flex", gap: "4px" }}>
            <NormalRedTypography>
              利用期限:
            </NormalRedTypography>
            <NormalRedTypography>
              {item.ekaLifespanEnd ? moment.unix(item.ekaLifespanEnd).format("YYYY/MM/DD HH:mm") : "無制限"}
            </NormalRedTypography>
          </Grid>
        }
      </Grid>

    </Paper>
  );
};

export default EkaItem;
