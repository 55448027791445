import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

interface DialogPlanDetailProps {
  open: boolean;
  handleClose: () => void;
}

const DialogBillingPaymentInformation: React.FC<DialogPlanDetailProps> = ({
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          variant="h6"
          component="h1"
          sx={{ fontWeight: 600, fontSize: "16px" }}
          align="center"
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid>
            <Typography sx={{ fontSize: "0.6rem" }}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
              sit nostrum ullam quia, temporibus dignissimos corporis quibusdam
              libero voluptatum itaque expedita ipsum tenetur, quis earum nihil
              accusantium dicta? Unde, accusantium.
            </Typography>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          autoFocus
          sx={{ color: "#000", fontWeight: 600 }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBillingPaymentInformation;
