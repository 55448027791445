import {
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { AccountCircle, Search } from "@mui/icons-material";
import { Control, Controller, FieldErrors, FieldValues, UseFormHandleSubmit, UseFormReset } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarMonth } from "@mui/icons-material";
import "dayjs/locale/ja";
import ButtonSubmit from "../Buttons/ButtonSubmit";
import FilterDatePicker from "../Inputs/FilterDatePicker";
import ButtonWhite from "../Buttons/ButtonWhite";

type Props = {
  formValues: {
    ekaId: null;
    start: null;
    end: null;
  };
  control: Control<{
    ekaId: null;
    start: null;
    end: null;
  }, any>
  errors: FieldErrors<{
    ekaId: null;
    start: null;
    end: null;
  }>
  handleSubmit: UseFormHandleSubmit<{
    ekaId: null;
    start: null;
    end: null;
  }>
  onSubmit: (data: FieldValues) => void
  reset: UseFormReset<{
    ekaId: null;
    start: null;
    end: null;
  }>
}

const SearchPaper = ({ formValues, control, errors, handleSubmit, onSubmit, reset }: Props) => {
    return (
    <>
      <Paper sx={{
        p: 2, mx: 2, my: 1, borderRadius: "14px",
        maxWidth: '800px',
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={"10px"} columnSpacing={"5px"}>
            <Grid
              container
              item
              xs={12}
              sm={8}
              rowSpacing={"10px"} columnSpacing={"5px"}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Controller
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="standard"
                    >
                      <TextField
                        {...field}
                        value={field.value || ""}
                        onChange={field.onChange}
                        id="ekaId"
                        placeholder="伝票IDで検索 (例: 20240101-00001)"
                        style={{
                          borderRadius: "25px",
                          cursor: "pointer",
                        }}
                        size="small"
                        sx={{
                          bgcolor: !!formValues.start || !!formValues.end ? 'text.disabled' : "#E6E4EB",
                          'fieldset': {
                            border: 'none'
                          }
                        }}
                        inputProps={{
                          sx: {
                            fontSize: { xs: "0.8rem", sm: "1rem" },
                          },
                        }}
                      />
                    </FormControl>
                  )}
                  name="ekaId"
                  control={control}
                />
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Controller
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"ja"}
                    >
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ pt: 0, width: 1 }}
                      >
                        <FilterDatePicker
                          {...field}
                          maxDate={formValues?.end}
                          format="YYYY/MM/DD"
                          sx={{
                            minWidth: "50px !important",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              toolbarFormat: "MMMM DD日 (ddd)",
                              hidden: false,
                            },
                            textField: {
                              size: "small",
                              placeholder: "",
                              style: {
                                background: !!formValues.ekaId ? "rgba(0, 0, 0, 0.38)" : "#E6E4EB",
                                borderRadius: "25px",
                                cursor: "pointer",
                              },
                              InputProps: {
                                sx: {
                                  "input:disabled": {
                                    backgroundColor: "rgba(0, 0, 0, 0)"
                                  },
                                  fontSize: { xs: "0.8rem", sm: "1rem" },
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CalendarMonth
                                      sx={{
                                        fontSize: { xs: "1rem", sm: "1.5rem" },
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              },
                            },
                          }}
                          disabled={!!formValues.ekaId}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                  name="start"
                  control={control}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Controller
                  render={({ field }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"ja"}
                    >
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ pt: 0, width: 1 }}
                      >
                        <FilterDatePicker
                          {...field}
                          minDate={formValues?.start}
                          format="YYYY/MM/DD"
                          sx={{
                            minWidth: "50px !important",
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              toolbarFormat: "MMMM DD日 (ddd)",
                              hidden: false,
                            },
                            textField: {
                              size: "small",
                              placeholder: "",
                              style: {
                                background: !!formValues.ekaId ? "rgba(0, 0, 0, 0.38)" : "#E6E4EB",
                                borderRadius: "25px",
                                cursor: "pointer",
                              },
                              InputProps: {
                                sx: {
                                  "input:disabled": {
                                    backgroundColor: "rgba(0, 0, 0, 0)"
                                  },
                                  fontSize: { xs: "0.8rem", sm: "1rem" },
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CalendarMonth
                                      sx={{
                                        fontSize: { xs: "1rem", sm: "1.5rem" },
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              },
                            },
                          }}
                          disabled={!!formValues.ekaId}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                  name="end"
                  control={control}
                />
              </Grid>

              {(errors?.start?.message || errors?.end?.message || errors?.ekaId?.message) && (
                <Hidden smUp>
                  <Grid item xs={12} sx={{ color: "#9f1831" }}>
                    <ul>
                      {!!errors?.ekaId?.message && (
                        <li>
                          <FormHelperText error id="ekaId_helper">
                            <>{errors?.ekaId?.message}</>
                          </FormHelperText>
                        </li>
                      )}
                      {!!errors?.start?.message && (
                        <li>
                          <FormHelperText error id="start_helper">
                            <>{errors?.start?.message}</>
                          </FormHelperText>
                        </li>
                      )}
                      {!!errors?.end?.message && (
                        <li>
                          <FormHelperText error id="end_helper">
                            <>{errors?.end?.message}</>
                          </FormHelperText>
                        </li>
                      )}
                    </ul>
                  </Grid>
                </Hidden>
              )}

            </Grid>

            <Grid
              container
              alignItems="stretch"
              item
              xs={12}
              sm={4}
              rowSpacing={"10px"}
              columnSpacing={"5px"}
            >
              <Grid
                item
                xs={8}
                sm={7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ButtonSubmit
                  type="submit"
                  startIcon={<Search />}
                  sx={{ borderRadius: "20px", py: 1, width: 1, height: '100%' }}
                >
                  表示
                </ButtonSubmit>
              </Grid>
              <Grid
                item
                xs={4}
                sm={5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ButtonWhite
                  type="button"
                  onClick={() => reset()}
                  sx={{ borderRadius: "20px", py: 1, width: 1, height: '100%' }}
                >
                  リセット
                </ButtonWhite>
              </Grid>
            </Grid>




          </Grid>
          {(errors?.start?.message || errors?.end?.message || errors?.ekaId?.message) && (
            <Hidden smDown>
              <Grid item xs={12} sx={{ color: "#9f1831" }}>
                <ul>
                  {!!errors?.ekaId?.message && (
                    <li>
                      <FormHelperText error id="ekaId_helper">
                        <>{errors?.ekaId?.message}</>
                      </FormHelperText>
                    </li>
                  )}
                  {!!errors?.start?.message && (
                    <li>
                      <FormHelperText error id="start_helper">
                        <>{errors?.start?.message}</>
                      </FormHelperText>
                    </li>
                  )}
                  {!!errors?.end?.message && (
                    <li>
                      <FormHelperText error id="end_helper">
                        <>{errors?.end?.message}</>
                      </FormHelperText>
                    </li>
                  )}
                </ul>
              </Grid>
            </Hidden>
          )}
        </form>
      </Paper >
    </>
  )
}


export default SearchPaper