import { Button, ButtonProps, styled } from "@mui/material";

const ButtonWhite = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#9f1831",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    border: '1px solid #9f1831',
    "&:hover": {
        backgroundColor: "#ffffff",
    },
    "&:disabled": {
        opacity: 0.5,
        color: "#9f1831",
    },
    "& .MuiButton-startIcon": {
        marginRight: 0,
    },
}));

export default ButtonWhite;
