import React from "react";
import { Grid } from "@mui/material";
import NotificationTabItem from "./NotificationTabItem";

interface NotificationTabsProps {
  unreadAlertCount: number;
  unreadNotificationCount: number;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

const NotificationTabs: React.FC<NotificationTabsProps> = ({
  unreadAlertCount,
  unreadNotificationCount,
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <Grid
      container
      sx={{
        mt: 1,
        borderRadius: "40px",
        backgroundColor: "#fff",
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      }}
    >
      <NotificationTabItem
        unreadCount={unreadAlertCount}
        label="のまっせアラート"
        selected={selectedTab === 0}
        setSelectedTab={() => setSelectedTab(0)}
      />
      <NotificationTabItem
        unreadCount={unreadNotificationCount}
        label="アプリのお知らせ"
        selected={selectedTab === 1}
        setSelectedTab={() => setSelectedTab(1)}
      />
    </Grid>
  );
};

export default NotificationTabs;
