import * as Yup from "yup";

const phoneNumberValidation = Yup.string()
  .trim()
  .test("phone-number", "無効な電話番号", (value) => {
    const phoneRegexes = {
      Mongolia: /^((\+?976)|0)[6789]\d{7}$/,
      Japan: /^((\+?81)|0)([789]0|4\d{1})\d{4}\d{4}$/,
      USA: /^((\+?1)[ -]?)?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/,
    };
    for (const [country, regex] of Object.entries(phoneRegexes)) {
      if (value && regex.test(value)) {
        return true;
      }
    }
    return false;
  })
  .required("電話番号が入力されていません");

const emailValidation = Yup.string()
  .trim()
  .email("メールアドレスが無効です")
  .required("メールアドレスが入力されていません");

const usernameValidation = Yup.string()
  .trim()
  .min(1, "ニックネームは1文字以上でなければなりません")
  .max(20, "ニックネームは20文字以内とします")
  .required("ニックネームが入力されていません");

const lastnameValidation = Yup.string()
  .trim()
  .min(1, "名字は1文字以上でお願いします")
  .max(50, "名字は50文字以内でお願いします")
  .required("名字が入力されていません。");

const storeNameValidation = Yup.string()
  .trim()
  .min(1, "店舖名は1文字以上でお願いします")
  .max(50, "店舖名は50文字以内でお願いします")
  .required("店舖名が入力されていません");

const terminalNameValidation = Yup.string()
  .trim()
  .min(1, "端末名(カスタム)は1文字以上でお願いします")
  .max(30, "端末名(カスタム)は30文字以内でお願いします")
  .required("端末名(カスタム)が入力されていません");

const flowRateValidation = Yup.string()
  .test("min-value", "流量は10以上に設定してください", (value) => {
    if (!value) return false;
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue >= 10;
  })
  .test("max-value", "流量は100以下に設定してください", (value) => {
    if (!value) return false;
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue <= 100;
  })
  .required("流量が入力されていません");

const bottleVolumeValidation = Yup.string()
  .test("min-value", "ボトルサイズは0以上に設定してください", (value) => {
    if (!value) return false;
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue >= 0;
  })
  .test("max-value", "ボトルサイズは9999999以下に設定してください", (value) => {
    if (!value) return false;
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue <= 9999999;
  })
  .required("ボトルサイズが入力されていません");

const bottleVolumeValidationWeak = Yup.string()
  .test("max-value", "ボトルサイズは9999999以下に設定してください", (value) => {
    if (!value) return false;
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue <= 9999999;
  })
  .required("ボトルサイズが入力されていません");

const bottleLiquidVolumeValidation = Yup.string()
  .test("min-value", "ボトル内飲料量は0以上に設定してください", (value) => {
    if (!value) return false;
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && parsedValue >= 0;
  })
  .test(
    "max-value",
    "現在のボトル内飲料量（ml）は9999999以下に設定してください",
    (value) => {
      if (!value) return false;
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue) && parsedValue <= 9999999;
    }
  )
  .required("現在のボトル内飲料量（ml）が入力されていません");

const bottleLiquidVolumeValidationWeak = Yup.string()
  .test(
    "max-value",
    "現在のボトル内飲料量（ml）は9999999以下に設定してください",
    (value) => {
      if (!value) return false;
      const parsedValue = parseFloat(value);
      return !isNaN(parsedValue) && parsedValue <= 9999999;
    }
  )
  .required("現在のボトル内飲料量（ml）が入力されていません");

const descriptionValidation = Yup.string()
  .trim()
  .min(1, "銘柄は1文字以上でお願いします")
  .max(150, "銘柄は150文字以内でお願いします")
  .required("銘柄が入力されていません");

const singleAmountValidation = Yup.number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .required("単品金額が入力されていません");

const storeSubnameValidation = Yup.string()
  .transform((value) => (!value ? "" : value))
  .test(
    "check-max-size",
    "サプ店舗名は50文字以内でお願いします",
    (value: any) => {
      return (value as string).trim().length < 50;
    }
  )
  .trim()
  .optional();

const storeTypeValidation = Yup.string()
  .trim()
  .min(1, "店舖形態は1文字以上でお願いします")
  .max(50, "店舖形態は50文字以内でお願いします")
  .required("店舖形態が入力されていません");

const storeRegionValidation = Yup.string()
  .trim()
  .max(50, "店舗の地域（国）は50文字以内でお願いします")
  .required("店舗の地域（国）が入力されていません");

const storeLocationStateValidation = Yup.string()
  .trim()
  .max(50, "店舗の地域（都道府県）は50文字以内でお願いします")
  .required("店舗の地域（都道府県）が入力されていません");

const storeLocationCityValidation = Yup.string()
  .trim()
  .max(50, "店舗の地域（市区町村）は50文字以内でお願いします")
  .required("店舗の地域（市区町村）が入力されていません");

const firstnameValidation = Yup.string()
  .trim()
  .min(1, "名前は1文字以上でお願いします")
  .max(50, "名前は50文字以内でお願いします")
  .required("名前が入力されていません");

const passwordValidation = Yup.string()
  .trim()
  .min(8, "パスワードは8文字以上でなければなりません")
  .required("パスワードが入力されていません");

const confirmPasswordValidation = Yup.string()
  .trim()
  .oneOf(
    [Yup.ref("password"), ""],
    "新しいパスワードの再確認が新しいパスワードと一致しません"
  )
  .required("パスワードの再確認が入力されていません");

const currentPasswordValidation = Yup.string()
  .trim()
  .min(8, "現在のパスワードは8文字以上でなければなりません")
  .required("現在のパスワードが入力されていません");

const newPasswordValidation = Yup.string()
  .trim()
  .min(8, "新しいパスワードは8文字以上でなければなりません")
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    "新しいパスワードは、少なくとも1つの数字、1つの大文字、1つの小文字を含む必要があります"
  )
  .required("新しいパスワードが入力されていません");

const confirmNewPasswordValidation = Yup.string()
  .trim()
  .oneOf(
    [Yup.ref("new_password"), ""],
    "新しいパスワードの再確認が新しいパスワードと一致しません"
  )
  .required("パスワードの再確認が入力されていません");

const genderValidation = Yup.string()
  .trim()
  .required("性別が入力されていません");

const currentAddressValidation = Yup.string()
  .trim()
  .required("現在お住まいの地域が入力されていません");

const currentCountryValidation = Yup.string()
  .trim()
  .required("現在お住まいの国が入力されていません");

const birthDateValidation = Yup.date()
  .nullable()
  .max(new Date(Date.now() - 631065600000), "20歳以上である必要があります")
  .required("生年月日が入力されていません");

const birthAddressValidation = Yup.string()
  .trim()
  .required("出身地（都道府県・市区町村）が入力されていません");

const birthCountryValidation = Yup.string()
  .trim()
  .required("出身地（国）が入力されていません");

const verificationCodeValidation = Yup.string()
  .trim()
  .required("認証コードが必要です。")
  .length(6, "検証コードは正確に6文字である必要があります");

const nameValidation = Yup.string()
  .trim()
  .min(1, "お名前は1文字以上でなければなりません")
  .max(20, "お名前は20文字以内とします")
  .required("お名前が入力されていません");

const messageValidation = Yup.string()
  .trim()
  .min(1, "メッセージは1文字以上でなければなりません。")
  .max(5000, "メッセージは5000文字以内とします")
  .matches(/^[A-Za-z0-9.,\s]+$/, "メッセージには、文字と数字のみを使用できます")
  .required("メッセージが入力されていません");

const cardNumberValidation = Yup.string()
  .trim()
  .required("Credit card number is required.")
  .matches(/^\d+$/, "Credit card number must be numeric.")
  .test("test-card-number", "Credit card number is invalid.", (value) => {
    if (!value) {
      return false;
    }
    let sum = 0;
    let double = false;
    for (let i = value.length - 1; i >= 0; i--) {
      let digit = parseInt(value.charAt(i), 10);
      if (double) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      double = !double;
    }
    return sum % 10 === 0;
  });

const wifiSSIDValidation = Yup.string()
  .trim()
  .max(50, "SSIDは50文字以内でお願いします")
  .required("SSIDが入力されていません");

const wifiPasswordValidation = Yup.string()
  .trim()
  .max(50, "パスワードは50文字以内でお願いします")
  .required("パスワードが入力されていません");

const ticketNameValidation = Yup.string()
  .trim()
  .min(1, "チケット名は1文字以上でお願いします")
  .max(50, "チケット名は50文字以内でお願いします")
  .required("チケット名が入力されていません");

const ticketPriceValidation = Yup.number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .positive("金額は正の数でなければなりません")
  .min(100, "半角数字で100(円)以上を入力してください")
  .required("金額が入力されていません");

const ticketTypeValidation = Yup.string()
  .trim()
  .required("チケットタイプを選択してください");

const ticketQuantityValidation = Yup.number()
  .transform((value) => {
    return isNaN(value) || value === null || value === undefined ? 0 : value;
  })
  .positive("チケット枚数は正の数でなければなりません")
  .required("チケット枚数が入力されていません");

const ticketTypeUsageValidation = Yup.string()
  .trim()
  .required("利用パターンを選択してください");

const ticketCurrencyValidation = Yup.string()
  .trim()
  .required("通貨を選択してください");

const ticketPermissionValidation = Yup.string()
  .trim()
  .required("チケットを表示可能なアプリ領域を選択してください");

const ticketLifespanTypeValidation = Yup.string()
  .trim()
  .required("期限タイプを選択してください");

const ticketLifespanUnitNumValidation = Yup.number()
  .transform((value) => {
    return isNaN(value) || value === null || value === undefined ? 0 : value;
  })
  .positive("期限は正の数でなければなりません")
  .required("期限が入力されていません");

const ticketLifespanUnitTypeValidation = Yup.string()
  .trim()
  .required("オプションを選択してください");

const groupNameValidation = Yup.string()
  .trim()
  .min(1, "グループ名は1文字以上でお願いします")
  .max(50, "グループ名は50文字以内でお願いします。")
  .matches(
    /^[0-9a-zA-Z:_-]+$/,
    "数字、コロン、アンダースコア、ハイフンのみが使用可能です"
  )
  .required("グループ名が入力されていません");

const groupDescriptionValidation = Yup.string()
  .trim()
  .min(1, "グループの説明は1文字以上でお願いします")
  .max(150, "グループの説明は150文字以内でお願いします")
  .optional();

const brandNameValidation = Yup.string()
  .trim()
  .min(1, "ブランド名は1文字以上でお願いします")
  .max(50, "ブランド名は50文字以内でお願いします")
  .required("ブランド名が入力されていません");

const sakeSettingTypeValidation = Yup.string()
  .trim()
  .required("日本酒設定のタイプを選択してください");

const breweryCompanyNameValidation = Yup.string()
  .trim()
  .max(50, "酒蔵会社名は50文字以内でお願いします。")
  .required("酒蔵会社名が入力されていません");

const breweryNameValidation = Yup.string()
  .trim()
  .max(50, "醸造所名は50文字以内でお願いします。")
  .required("醸造所名が入力されていません");

const sakeNameValidation = Yup.string()
  .trim()
  .max(50, "お酒の名前は50文字以内でお願いします。")
  .required("お酒の名前が入力されていません");

const sakeSpecifiedNameValidation = Yup.string()
  .trim()
  .max(50, "特定名称酒は50文字以内でお願いします。")
  .required("特定名称酒が入力されていません");

const selectedSakeValidation = Yup.string()
  .trim()
  .required("日本酒を選択してください");

const colorValidation = Yup.string().required("色を選択してください");
const bgColorValidation = Yup.string().required("背景色を選択してください");

const contractUnitsValidation = Yup.number()
  .transform((value) => {
    return isNaN(value) || value === null || value === undefined ? 0 : value;
  })
  .positive("契約台数は正の数でなければなりません")
  .min(1, "契約台数は0台以上であることです")
  .required("ご契約台数が入力されていません");

const customRequestValidation =
  Yup.string().required("ご要望が入力されていません");

const bankNameValidation = Yup.string().required("銀行名が入力されていません");
const branchNameValidation =
  Yup.string().required("支店名が入力されていません");
const bankCodeValidation = Yup.string()
  .matches(/^\d{4}$/, "銀行コードは4桁の数字でなければなりません")
  .required("銀行コードが入力されていません");
const branchCodeValidation = Yup.string()
  .matches(/^\d{3}$/, "支店コードは3桁の数字でなければなりません")
  .required("支店コードが入力されていません");
const accountNumberValidation = Yup.string()
  .matches(/^\d{4,8}$/, "口座番号4桁から8桁の数字であることです")
  .required("口座番号が入力されていません");
const accountHolderValidation =
  Yup.string().required("口座名義人が入力されていません");

export {
  phoneNumberValidation,
  emailValidation,
  lastnameValidation,
  firstnameValidation,
  usernameValidation,
  passwordValidation,
  confirmPasswordValidation,
  genderValidation,
  birthDateValidation,
  birthAddressValidation,
  birthCountryValidation,
  currentAddressValidation,
  currentCountryValidation,
  currentPasswordValidation,
  newPasswordValidation,
  verificationCodeValidation,
  nameValidation,
  messageValidation,
  cardNumberValidation,
  storeNameValidation,
  storeSubnameValidation,
  storeTypeValidation,
  storeRegionValidation,
  storeLocationStateValidation,
  storeLocationCityValidation,
  terminalNameValidation,
  flowRateValidation,
  descriptionValidation,
  singleAmountValidation,
  wifiSSIDValidation,
  wifiPasswordValidation,
  ticketNameValidation,
  ticketPriceValidation,
  ticketTypeValidation,
  ticketQuantityValidation,
  ticketTypeUsageValidation,
  ticketCurrencyValidation,
  ticketPermissionValidation,
  ticketLifespanTypeValidation,
  ticketLifespanUnitTypeValidation,
  ticketLifespanUnitNumValidation,
  groupNameValidation,
  groupDescriptionValidation,
  brandNameValidation,
  sakeSettingTypeValidation,
  breweryCompanyNameValidation,
  breweryNameValidation,
  sakeNameValidation,
  sakeSpecifiedNameValidation,
  selectedSakeValidation,
  colorValidation,
  bgColorValidation,
  contractUnitsValidation,
  customRequestValidation,
  bankNameValidation,
  branchNameValidation,
  bankCodeValidation,
  branchCodeValidation,
  accountNumberValidation,
  accountHolderValidation,
  confirmNewPasswordValidation,
  bottleVolumeValidation,
  bottleLiquidVolumeValidation,
  bottleVolumeValidationWeak,
  bottleLiquidVolumeValidationWeak,
};
