import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

interface DialogTicketUpdateProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const DialogTicketUpdate: React.FC<DialogTicketUpdateProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: 16,
          paddingTop: "25px",
          paddingBottom: "15px",
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography
          variant="h6"
          component="h1"
          sx={{ fontWeight: 600, fontSize: "1.2rem" }}
          align="center"
        >
          本当に変更しますか？
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid>
            <Typography sx={{ fontSize: "0.82rem" }}>
              チケットを変更すると、実際に購入したチケットにも影響されます。チケット変更による不具合には対応しかねる場合もございます。慎重に設定してください。
            </Typography>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "end" }}>
        <Button onClick={handleClose} autoFocus sx={{ color: "#000" }}>
          キャンセル
        </Button>
        <Button
          onClick={handleSubmit}
          autoFocus
          sx={{ color: "#9F1831", fontWeight: 600 }}
        >
          変更する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogTicketUpdate;
