import AwsApi from "./aws-api";
import {
  UpdateStoreBodyRequestType,
  CustomerStoreIdType,
  CreateStoreBodyRequestType,
} from "../types";

export default class appStoreApi extends AwsApi {
  async getStoreList(): Promise<any> {
    return await this.get("store/" + process.env.REACT_APP_ENVNAME);
  }

  async getStore(params: any): Promise<any> {
    return await this.get("store/detail/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async createStore(params: CreateStoreBodyRequestType): Promise<any> {
    return await this.post("store/" + process.env.REACT_APP_ENVNAME, params);
  }

  async updateStore(
    params: CustomerStoreIdType,
    body: UpdateStoreBodyRequestType
  ): Promise<any> {
    return await this.put("store/" + process.env.REACT_APP_ENVNAME, body, {
      params,
    });
  }
}
