import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const NewContractComplete = () => {
  const navigate = useNavigate();
  return (
    <Grid sx={{ display: "flex", alignItems: "center", height: "100vh" }}>
      <Paper sx={{ marginX: 2, padding: 2, width: 1 }}>
        <Typography
          sx={{ fontSize: "0.85rem", fontWeight: 600, py: 2 }}
          align="center"
        >
          サブスク契約手続きが完了しました
        </Typography>
        <Typography sx={{ fontSize: "0.7rem", py: 2 }} align="center">
          5~10営業日以内に運営スタッフが確認し、会社信頼性とのまっせのご用意をいたします。ご契約進捗状況については「設定→のまっせのご契約」ページからご確認いただけます。実際の決済は送付後にサブスクリプション開始され、のまっせがお渡しできない状態の場合は、サブスクリプションが発生しないか、返金対応とさせていただきます
        </Typography>

        <Grid sx={{ pt: 4, pb: 2 }}>
          <ButtonSubmit
            type="button"
            sx={{ width: 1, fontWeight: 600 }}
            onClick={() => navigate("/contracts")}
          >
            ご契約一覧画面に移動する
          </ButtonSubmit>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default NewContractComplete;
