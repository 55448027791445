import React from "react";
import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";

interface TabItemProps {
  label: string;
  icon: JSX.Element;
  sx?: any;
  selected: boolean;
  labelSize?: string;
}

const TabItem: React.FC<TabItemProps> = ({
  label,
  icon,
  sx,
  selected,
  labelSize,
}) => {
  return (
    <Box
      className="tab-content"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 1.5,
        borderRadius: "25px",
        ...sx,
        position: "relative",
        backgroundColor: "#fff",
        color: selected ? "#fff" : "#9f1831",
      }}
    >
      {selected && (
        <motion.div
          layoutId="active-pill"
          style={{
            position: "absolute",
            inset: "0",
            borderRadius: "25px",
            backgroundColor: "#9f1831",
          }}
          transition={{ type: "spring", duration: 0.4 }}
        />
      )}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
        <Typography
          sx={{ fontSize: labelSize ? labelSize : "1.2rem", fontWeight: 600 }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default TabItem;
