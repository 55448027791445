import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  styled,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import * as Yup from "yup";
import {
  customRequestValidation,
  storeLocationCityValidation,
  storeLocationStateValidation,
  storeNameValidation,
  storeRegionValidation,
  storeSubnameValidation,
  storeTypeValidation,
} from "../../utils/validations";

const step = ["お客様情報", "店舗情報", "ご契約台数", "在庫確認", "確認/決済"];
const store_types = [
  {
    value: "coupon",
    label: "回数券",
  },
  {
    value: "all_you_can_drink",
    label: "飲み放題",
  },
];

const CustomerInfo = () => {
  const navigate = useNavigate();
  const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
    ".Mui-completed .MuiSvgIcon-root": {
      color: "#9f1831",
    },
    ".Mui-active .MuiSvgIcon-root": {
      color: "#9f1831",
    },
  }));

  const defaultValues = {
    storeName: "",
    storeSubName: "",
    storeType: "",
    storeRegion: "",
    storeLocationState: "",
    storeLocationCity: "",
    customRequest: "",
  };
  const schema = Yup.object().shape({
    storeName: storeNameValidation,
    storeSubName: storeSubnameValidation,
    storeType: storeTypeValidation,
    storeRegion: storeRegionValidation,
    storeLocationState: storeLocationStateValidation,
    storeLocationCity: storeLocationCityValidation,
    customRequest: customRequestValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let raw_store_info = localStorage.getItem("store_info");
    let store_info = raw_store_info ? JSON.parse(raw_store_info) : {};
    if (Object.keys(store_info).length > 0) {
      setValue("storeName", store_info?.storeName);
      setValue("storeSubName", store_info?.storeSubName);
      setValue("storeType", store_info?.storeType);
      setValue("storeRegion", store_info?.storeRegion);
      setValue("storeLocationState", store_info?.storeLocationState);
      setValue("storeLocationCity", store_info?.storeLocationCity);
      setValue("customRequest", store_info?.customRequest);
    }
  }, []);

  const onSubmit = async (data: FieldValues) => {
    localStorage.setItem("store_info", JSON.stringify(data));
    navigate("/contract/plan");
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      <Box sx={{ paddingY: 2 }}>
        <Stepper activeStep={1} alternativeLabel>
          {step.map((label) => (
            <Step key={label}>
              <CustomStepLabel sx={{ fontSize: "0.8px" }}>
                {label}
              </CustomStepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ p: 2, mb: 9 }}>
          <Box sx={{ backgroundColor: "#9f1831", padding: "5px 0 5px 0" }}>
            <Typography
              sx={{ color: "#fff", fontWeight: "600", paddingLeft: "10px" }}
            >
              店舗情報
            </Typography>
          </Box>
          <Box sx={{ padding: "15px 0 20px 0" }}>
            <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
              のまっせを設置する予定の店舗情報を入力してください。現在複数店舗の登録はできません。主要の店舗情報を入力してください。
            </Typography>
          </Box>
          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="storeName" sx={{ paddingY: 1 }}>
                    店舗名
                  </InputLabel>
                  <BootstrapInput {...field} sx={{ width: 1 }} id="storeName" />
                </FormControl>
              )}
              name="storeName"
              control={control}
            />
            {!!errors?.storeName?.message && (
              <FormHelperText error id="storeName_helper">
                <>{errors?.storeName?.message}</>
              </FormHelperText>
            )}
          </Grid>
          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    htmlFor="storeSubName"
                    sx={{ paddingY: 1 }}
                  >
                    サブ店舗名 ※ ◯◯支店など
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="storeSubName"
                  />
                </FormControl>
              )}
              name="storeSubName"
              control={control}
            />
            {!!errors?.storeSubName?.message && (
              <FormHelperText error id="storeSubName_helper">
                <>{errors?.storeSubName?.message}</>
              </FormHelperText>
            )}
          </Grid>
          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="storeType" sx={{ paddingY: 1 }}>
                    店舖形態
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="storeType"
                    error={!!errors?.storeType?.message}
                  />
                </FormControl>
              )}
              name="storeType"
              control={control}
            />
            {!!errors?.storeType?.message && (
              <FormHelperText error id="storeType_helper">
                <>{errors?.storeType?.message}</>
              </FormHelperText>
            )}
          </Grid>
          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="storeRegion" sx={{ paddingY: 1 }}>
                    店舗の地域（国）
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="storeRegion"
                    error={!!errors?.storeRegion?.message}
                  />
                </FormControl>
              )}
              name="storeRegion"
              control={control}
            />
            {!!errors?.storeRegion?.message && (
              <FormHelperText error id="storeRegion_helper">
                <>{errors?.storeRegion?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    htmlFor="storeLocationState"
                    sx={{ paddingY: 1 }}
                  >
                    店舗の地域（都道府県）
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="storeLocationState"
                    error={!!errors?.storeLocationState?.message}
                  />
                </FormControl>
              )}
              name="storeLocationState"
              control={control}
            />
            {!!errors?.storeLocationState?.message && (
              <FormHelperText error id="storeLocationState_helper">
                <>{errors?.storeLocationState?.message}</>
              </FormHelperText>
            )}
          </Grid>

          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    htmlFor="storeLocationCity"
                    sx={{ paddingY: 1 }}
                  >
                    店舗の地域（市区町村）
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    id="storeLocationCity"
                    error={!!errors?.storeLocationCity?.message}
                  />
                </FormControl>
              )}
              name="storeLocationCity"
              control={control}
            />
            {!!errors?.storeLocationCity?.message && (
              <FormHelperText error id="storeLocationCity_helper">
                <>{errors?.storeLocationCity?.message}</>
              </FormHelperText>
            )}
          </Grid>
          <Box
            sx={{
              backgroundColor: "#9f1831",
              padding: "5px 0 5px 0",
              marginTop: "30px",
            }}
          >
            <Typography
              sx={{ color: "#fff", fontWeight: "600", paddingLeft: "10px" }}
            >
              ご要望
            </Typography>
          </Box>
          <Box sx={{ padding: "15px 0 20px 0" }}>
            <Typography sx={{ fontWeight: "400", fontSize: "15px" }}>
              お客様のご要望がございましたらご記入ください。状況に応じて、メールもしくはお電話させていただくことがあります。カスタマー情報で入力頂いた情報宛てに送付いたしますが、他の連絡先をご希望の場合は、そちらも記載してください。
            </Typography>
          </Box>
          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    multiline={true}
                    minRows={10}
                    id="customRequest"
                  />
                </FormControl>
              )}
              name="customRequest"
              control={control}
            />
            {!!errors?.customRequest?.message && (
              <FormHelperText error id="customRequest_helper">
                <>{errors?.customRequest?.message}</>
              </FormHelperText>
            )}
          </Grid>
          <Grid sx={{ paddingY: 1 }}>
            <ButtonSubmit
              type="submit"
              variant="contained"
              sx={{ width: 1, fontWeight: "600" }}
            >
              次へ
            </ButtonSubmit>
          </Grid>
        </Paper>
      </form>
    </Box>
  );
};

export default CustomerInfo;
