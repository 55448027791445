import { useCallback, useContext, useMemo } from "react";
import appTicketApi from "../../apis/ticket-api";
import {
  CustomerStoreIdType,
  CreateTicketRawParamsType,
  CreateTicketBodyRequestType,
  UpdateTicketParamsType,
  UpdateTicketRawParamsType,
  UpdateIssuedTicketLabelType,
  IssuedNewTicket,
} from "../../types";
import {
  cloneObjWithoutAttr,
  cloneObjWithoutMultiAttr,
} from "../../utils/helper";
import GlobalDataContext from "../use-global-data";

export const useTicketApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const ticketApi = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_3 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const ticketApi2 = useMemo(
    () =>
      new appTicketApi(
        process.env.REACT_APP_API_7 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getTickets = useCallback(
    async (params: any) => {
      loadingOp.start();
      const ticketList = await ticketApi.getTickets(params);
      loadingOp.finish();
      return ticketList;
    },
    [ticketApi]
  );

  const getTicket = useCallback(
    async (params: any) => {
      loadingOp.start();
      const ticketDetail = await ticketApi.getTicket(params);
      loadingOp.finish();
      return ticketDetail;
    },
    [ticketApi]
  );

  const createTicket = useCallback(
    async (params: CreateTicketRawParamsType) => {
      loadingOp.start();
      let param: CustomerStoreIdType = {
        customer_store_id: params.customer_store_id,
      };

      let body: CreateTicketBodyRequestType = cloneObjWithoutAttr(
        params,
        "customer_store_id"
      );
      const response = await ticketApi.createTicket(param, body);
      loadingOp.finish();
      return response;
    },
    [ticketApi]
  );

  const updateTicket = useCallback(
    async (params: UpdateTicketRawParamsType) => {
      loadingOp.start();
      let param: UpdateTicketParamsType = {
        customer_store_id: params.customer_store_id,
        product_ticket_id: params.product_ticket_id,
      };

      let body: CreateTicketBodyRequestType = cloneObjWithoutMultiAttr(params, [
        "customer_store_id",
        "product_ticket_id",
      ]);
      const response = await ticketApi.updateTicket(param, body);
      loadingOp.finish();
      return response;
    },
    [ticketApi]
  );

  const getIssuedTickets = useCallback(
    async (params: any) => {
      loadingOp.start();
      const ticketList = await ticketApi.getIssuedTickets(params);
      loadingOp.finish();
      return ticketList;
    },
    [ticketApi]
  );

  const getIssuedTicket = useCallback(
    async (params: any) => {
      loadingOp.start();
      const ticket = await ticketApi2.getIssuedTicket(params);
      loadingOp.finish();
      return ticket;
    },
    [ticketApi2]
  );

  const updateIssuedTicketLabel = useCallback(
    async (params: UpdateIssuedTicketLabelType) => {
      loadingOp.start();

      const response = await ticketApi2.updateIssuedTicketLabel(params);
      loadingOp.finish();
      return response;
    },
    [ticketApi2]
  );

  const getNewIssuedTicket = useCallback(
    async (params: IssuedNewTicket) => {
      loadingOp.start();
      const ticket = await ticketApi2.getNewIssuedTicket(params);
      loadingOp.finish();
      return ticket;
    },
    [ticketApi2]
  );

  return {
    getTickets,
    getTicket,
    createTicket,
    updateTicket,
    getIssuedTickets,
    getIssuedTicket,
    updateIssuedTicketLabel,
    getNewIssuedTicket,
  };
};
