import { Fragment, useContext } from "react";
import { useQuery } from "react-query";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import Loader from "../../components/Loader";
import ChangeCardForm from "./ChangeCardForm";
import AuthContext from "../../custom-hooks/use-auth-context";
import ErrorHandler from "../../components/ErrorHandler";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY || ""
);

const appearance = {
  theme: "stripe",
  rules: {
    ".Label": {
      marginBottom: "6px",
    },
  },
} as Appearance;

const BillingPaymentMethodChange = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getClientSecret } = useBillingApi();
  const urlParams = new URLSearchParams(window.location.search);
  const redirect_url = urlParams.get("redirect_url");

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.paymentCard, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getClientSecret();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const handleBack = () => {
    if (redirect_url) window.location.href = redirect_url;
    else navigate("/billing/payment-method");
  };

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleBack}
          >
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                fontWeight: 600,
                fontSize: "1.2rem",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
              align="center"
            >
              お支払い方法
            </Typography>
          </Box>
          <Box sx={{ width: "36px", height: "1px" }}></Box>
        </Toolbar>
      </AppBar>
      <Container>
        {isError ? (
          <ErrorHandler back={handleBack} />
        ) : (
          <Paper sx={{ padding: 3 }}>
            <Typography
              align="center"
              sx={{
                paddingTop: "15px",
                paddingBottom: "25px",
                fontSize: "15pt",
                fontWeight: 700,
              }}
            >
              支払い情報
            </Typography>
            {data?.clientSecret && (
              <Elements
                options={{
                  clientSecret: data?.clientSecret,
                  locale: "ja",
                  appearance: appearance,
                }}
                stripe={stripePromise}
              >
                <ChangeCardForm clientSecret={data?.clientSecret} />
              </Elements>
            )}
          </Paper>
        )}
      </Container>
    </Fragment>
  );
};

export default BillingPaymentMethodChange;
