import { Fragment, useContext } from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import { ArrowBackIos, Refresh } from "@mui/icons-material";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import StoreGroupSettingsItem from "./StoreGroupSettingsItem";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreGroupSettings = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { hasPermission } = usePermissions();
  const { getDeviceGroupList } = useDeviceApi();

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useQuery(
      [
        QueryKeys.storeDeviceGroupList,
        authCtx.user?.signInUserSession.idToken,
        authCtx.user?.store_id,
      ],
      () => {
        if (authCtx.user?.signInUserSession.idToken)
          return getDeviceGroupList({
            customer_store_id: authCtx.user?.store_id,
          });
        return;
      },
      {
        staleTime: 0,
        refetchOnWindowFocus: true,
      }
    );

  if (isError || isRefetchError) return <ErrorHandler url="/store" />;
  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/store")}
          >
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                fontWeight: 600,
                fontSize: "1.2rem",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
              align="center"
            >
              グループ設定
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => refetch()}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ReloadAnimation>
        <Box sx={{ p: 2, mb: 7 }}>
          <Grid sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
            <ButtonSubmit
              variant="contained"
              sx={{ width: 0.9 }}
              onClick={() => {
                navigate("/store/groups/create");
              }}
              disabled={!hasPermission("group.create")}
            >
              +新規グループ追加
            </ButtonSubmit>
          </Grid>
          <Grid>
            {data?.groups && data?.groups?.length > 0 ? (
              data?.groups.map((group: any, index: number) => (
                <Fragment key={index}>
                  <StoreGroupSettingsItem
                    id={group?.id}
                    title={group?.name}
                    desc={group?.description || "なし"}
                  />
                </Fragment>
              ))
            ) : (
              <Box
                sx={{
                  height: `calc(100vh - 250px)`,
                  marginX: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography align="center">
                    作成したグループがありません
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </ReloadAnimation>
      {isRefetching && <Loader />}
    </Fragment>
  );
};

export default StoreGroupSettings;
