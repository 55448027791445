import { useContext } from "react";
import { Container, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toastError, toastSuccess } from "../../utils/toaster";
import { UpdateTicketRawParamsType } from "../../types";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";
import AuthContext from "../../custom-hooks/use-auth-context";
import IssuedTicketForm from "../../components/IssuedTicketForm";
import moment from "moment";
import ReloadAnimation from "../../components/ReloadAnimation";

const IssuedTicketUpdate = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { hasPermission } = usePermissions();
  const { getTicket, updateTicket } = useTicketApi();

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useQuery(
      [
        QueryKeys.issuedTicketUpdate,
        authCtx.user?.signInUserSession.idToken,
        id,
        authCtx.user?.store_id,
      ],
      () => {
        if (
          authCtx.user?.signInUserSession.idToken &&
          id &&
          authCtx.user?.store_id
        )
          return getTicket({
            product_ticket_id: id,
            customer_store_id: authCtx.user?.store_id,
          });
        return;
      },
      {
        staleTime: 0,
        refetchOnWindowFocus: true,
      }
    );

  const updateTicketMutation = useMutation(
    (requestBody: UpdateTicketRawParamsType) => updateTicket(requestBody),
    {
      onSuccess: async () => {
        toastSuccess("チケットを変更しました。");
        navigate("/store/issued-tickets");
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage = "チケットを更新できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    if (!authCtx.user?.store_id || !id) return;
    let params: UpdateTicketRawParamsType = {
      customer_store_id: authCtx.user?.store_id,
      product_ticket_id: id,
      ticket_name: data?.ticketName,
      ticket_price: data?.ticketPrice,
      ticket_type: data?.ticketType,
      ticket_is_show: data?.ticketIsShow,
      ticket_type_usage: data?.ticketTypeUsage,
      ticket_permission: data?.ticketPermission,
      ticket_lifespan_type: data?.ticketLifespanType,
      ticket_currency: data?.ticketCurrency,
      ticket_purchase_period: data?.ticketIsPurchasePeriod ? 1 : 0,
    };
    if (data?.ticketLifespanType === "0") {
      params = {
        ...params,
        ticket_lifespan_unit_num: data?.ticketLifespanUnitNum,
        ticket_lifespan_unit_type: data?.ticketLifespanUnitType,
        ticket_lifespan_start: null,
        ticket_lifespan_end: null,
      };
    } else if (data?.ticketLifespanType === "1") {
      params = {
        ...params,
        ticket_lifespan_unit_num: null,
        ticket_lifespan_unit_type: null,
        ticket_lifespan_start: data?.ticketLifespanStart
          ? moment(data?.ticketLifespanStart).unix()
          : null,
        ticket_lifespan_end: data?.ticketLifespanEnd
          ? moment(data?.ticketLifespanEnd).unix()
          : null,
      };
    } else if (data?.ticketLifespanType === "3") {
      params = {
        ...params,
        ticket_lifespan_unit_num: null,
        ticket_lifespan_unit_type: null,
        ticket_lifespan_start: null,
        ticket_lifespan_end: null,
      };
    }
    if (data?.ticketIsPurchasePeriod) {
      params = {
        ...params,
        ticket_purchase_period_start: data?.ticketPurchasePeriodStart
          ? moment(data?.ticketPurchasePeriodStart).unix()
          : null,
        ticket_purchase_period_end: data?.ticketPurchasePeriodEnd
          ? moment(data?.ticketPurchasePeriodEnd).unix()
          : null,
      };
    }
    if (data?.groupNames) params = { ...params, valid_group: data?.groupNames };
    if (data?.ticketType === "coupon")
      params = { ...params, ticket_quantity: data?.ticketQuantity };
    await updateTicketMutation.mutate(params);
  };

  if (isError || isRefetchError) return <ErrorHandler />;
  // if (isLoading) return <Loader />;
  return (
    <Container>
      <ReloadAnimation>
        {isRefetching === true ? (
          <Loader />
        ) : (
          <IssuedTicketForm
            onSubmit={onSubmit}
            loading={updateTicketMutation.isLoading}
            isDisabled={!hasPermission("ticket.update")}
            preValues={{ ...data?.ticket, group: data?.group }}
          />
        )}
      </ReloadAnimation>
      {(isLoading || isRefetching) && <Loader />}
    </Container>
  );
};

export default IssuedTicketUpdate;
