import { useContext } from "react";
import {
  Paper,
  Box,
  Grid,
  styled,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import moment from "moment";
import AuthContext from "../../custom-hooks/use-auth-context";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import Loader from "../../components/Loader";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import ErrorHandler from "../../components/ErrorHandler";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
const statusList = [
  "会員登録済",
  "アカウント作成中",
  "お客様情報登録済",
  "お客様情報確認中（発送前）",
  "お客様情報確認済（発送準備中）",
  "発送済",
];

const StoreContactList = () => {
  const authCtx = useContext(AuthContext);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const { getContract } = useBillingApi();

  const subscription_id = query.get("id") || "";

  const { isLoading, data, isError } = useQuery(
    [
      QueryKeys.contractDetail,
      authCtx.user?.signInUserSession.idToken,
      subscription_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken && subscription_id)
        return getContract(subscription_id);
      return;
    }
  );

  if (isError) return <ErrorHandler />;
  if (isLoading) return <Loader />;
  return (
    <Paper sx={{ paddingX: 2, mx: 2, mb: 2 }} elevation={2}>
      <Box sx={{ paddingTop: 2 }}>
        <Box sx={{ backgroundColor: "#9f1831", py: 1 }}>
          <Typography
            sx={{ paddingLeft: "20px", color: "#fff", fontWeight: 600 }}
            align="left"
          >
            ご契約内容
          </Typography>
        </Box>

        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <List
            sx={{
              width: 1,
            }}
          >
            {Object.keys(data?.subscription).length !== 0 ? (
              <ListItem alignItems="center">
                <Grid container sx={{ display: "flex", width: 1 }}>
                  <Grid item xs={7}>
                    <Box sx={{}}>
                      <Typography
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        sx={{
                          display: "block",
                          fontWeight: 550,
                          fontSize: "15px",
                          width: 1,
                        }}
                        component="h1"
                        variant="h6"
                      >
                        {data?.subscription?.productName}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "11px",
                        }}
                      >
                        単価 {data?.subscription?.amount.toLocaleString()}円,
                        数量 {data?.subscription?.quantity}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography
                        sx={{
                          fontWeight: 550,
                          fontSize: "15px",
                          display: "inline",
                          marginRight: "3px",
                          textAlign: "right",
                          width: 1,
                        }}
                        component="h1"
                        variant="h6"
                      >
                        {(
                          data?.subscription?.amount *
                          data?.subscription?.quantity
                        ).toLocaleString()}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "8px",
                          display: "inline",
                          textAlign: "right",
                        }}
                      >
                        円/月
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 550,
                          fontSize: "10px",
                          display: "block",
                          marginRight: "3px",
                          textAlign: "right",
                          width: 1,
                        }}
                        component="h1"
                        variant="h6"
                      >
                        {data?.subscription?.startDate
                          ? moment
                              .unix(data?.subscription?.startDate)
                              .format("YYYY/MM/DD")
                          : ""}
                        ~
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </ListItem>
            ) : (
              <Box></Box>
            )}
          </List>
        </Grid>
      </Box>
      <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Box sx={{ backgroundColor: "#9f1831", py: 1 }}>
          <Typography
            sx={{ paddingLeft: "20px", color: "#fff", fontWeight: 600 }}
            align="left"
          >
            ご契約状況
          </Typography>
        </Box>
        <Box sx={{ py: 1 }}>
          <Typography
            sx={{
              paddingLeft: "20px",
              paddingTop: "10px",
              color: "#000",
              fontWeight: 600,
            }}
            align="left"
          >
            {statusList[data?.subscription?.status]}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default StoreContactList;
