import { Box } from "@mui/material";

interface BoxChipProps {
    backgroundColor?: string;
    fontColor?: string;
    text?: string;
    fontSize?: string;
}

const BoxChip = ({ backgroundColor, fontColor, text, fontSize }: BoxChipProps) => {
    return (
        <Box style={{
            backgroundColor: backgroundColor,
            color: fontColor,
            fontSize: fontSize ?? "9px",
            minWidth: '70px',
            textAlign: 'center',
            borderRadius: '16px',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            padding: '1px 0',
            fontWeight: '600'
        }}>
            {text}
        </Box>
    )
}

export default BoxChip;