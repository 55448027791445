export type CreateStoreBodyRequestType = {
  storeType: string;
  storeName: string;
  storeSubname: string;
  storeRegion: string;
  storeLocationState: string;
  storeLocationCity: string;
  storeImage?: string;
};

export type UpdateStoreRawParamsType = {
  customer_store_id: string;
  storeType: string;
  storeName: string;
  storeSubname: string;
  storeRegion: string;
  storeLocationState: string;
  storeLocationCity: string;
  storeImage?: string;
};

export type CustomerStoreIdType = {
  customer_store_id: string;
};

export type UpdateStoreBodyRequestType = {
  storeType: string;
  storeName: string;
  storeSubname: string;
  storeRegion: string;
  storeLocationState: string;
  storeLocationCity: string;
  storeImage?: string;
};

export type CreateTicketRawParamsType = {
  customer_store_id: string;
  ticket_name: string;
  ticket_price: number;
  ticket_type: string;
  ticket_is_show: boolean;
  ticket_type_usage: string;
  ticket_permission: string;
  ticket_currency: string;
  ticket_purchase_period: number;
  ticket_lifespan_type: number;
  ticket_quantity?: number;
  ticket_lifespan_unit_num?: number | null;
  ticket_lifespan_unit_type?: string | null;
  ticket_lifespan_start?: number | null;
  ticket_lifespan_end?: number | null;
  ticket_purchase_period_start?: number | null;
  ticket_purchase_period_end?: number | null;
  valid_group?: string[];
};

export type CreateTicketBodyRequestType = {
  ticket_name: string;
  ticket_price: number;
  ticket_type: string;
  ticket_type_usage: string;
  ticket_permission: string;
  ticket_currency: string;
  ticket_purchase_period: number;
  ticket_lifespan_type: number;
  ticket_quantity?: number;
  ticket_lifespan_unit_num?: number | null;
  ticket_lifespan_unit_type?: string | null;
  ticket_lifespan_start?: number | null;
  ticket_lifespan_end?: number | null;
  ticket_purchase_period_start?: number | null;
  ticket_purchase_period_end?: number | null;
  valid_group?: string[];
};

export type UpdateDeviceThingShadowType = {
  serviceunit_serve_pour: string;
  serving_product_name: string;
  serving_product_unit_price: number;
  thing_custom_name: string;
};

export type UpdateDeviceGroupType = {
  thingGroupName: string;
  thingGroupDescription: string;
};

export type UpdateDeviceGroupBodyRequestType = {
  group: UpdateDeviceGroupType;
  devices: string[];
};

export type UpdateDeviceGroupRawParamsType = {
  customer_store_id: string;
  group: UpdateDeviceGroupType;
  devices: string[];
};

export type CreateDeviceGroupBodyRequestType = {
  group: CreateDeviceGroupType;
  devices: string[];
};

export type CreateDeviceGroupType = {
  thingGroupName: string;
  thingGroupDescription: string;
};

export type CreateDeviceGroupRawParamsType = {
  customer_store_id: string;
  group: CreateDeviceGroupType;
  devices: string[];
};

export type UpdateTicketParamsType = {
  customer_store_id: string;
  product_ticket_id: string;
};

export type UpdateTicketRawParamsType = {
  customer_store_id: string;
  product_ticket_id: string;
  ticket_name: string;
  ticket_price: number;
  ticket_type: string;
  ticket_is_show: boolean;
  ticket_type_usage: string;
  ticket_permission: string;
  ticket_currency: string;
  ticket_purchase_period: number;
  ticket_lifespan_type: number;
  ticket_quantity?: number;
  ticket_lifespan_unit_num?: number | null;
  ticket_lifespan_unit_type?: string | null;
  ticket_lifespan_start?: number | null;
  ticket_lifespan_end?: number | null;
  ticket_purchase_period_start?: number | null;
  ticket_purchase_period_end?: number | null;
  valid_group?: string[];
};

export type ThingNameType = {
  thing_name: string;
};

export type UpdateDeviceBodyRequestType = {
  thing_group: string[];
  thing_shadow: {
    thing_nickname: string;
    serviceunit_serve_pour: string;

    serviceunit_sdrs_1_a_serving_now?: string;
    serviceunit_sdrs_1_a_product_id?: string;
    serviceunit_sdrs_1_a_product_name?: string;
    serviceunit_sdrs_1_a_product_special_designation_sake?: string;
    serviceunit_sdrs_1_a_product_sake_brewer?: string;
    serviceunit_sdrs_1_a_product_sake_brewery?: string;
    serviceunit_sdrs_1_a_sake_price?: number;
    serviceunit_sdrs_1_b_serving_now?: string;
    serviceunit_sdrs_1_b_product_id?: string;
    serviceunit_sdrs_1_b_product_name?: string;
    serviceunit_sdrs_1_b_product_special_designation_sake?: string;
    serviceunit_sdrs_1_b_product_sake_brewer?: string;
    serviceunit_sdrs_1_b_product_sake_brewery?: string;
    serviceunit_sdrs_1_b_sake_price?: number;

    serviceunit_serving_now?: string;
    serviceunit_product_id?: string;
    serviceunit_product_name?: string;
    serviceunit_product_special_designation_sake?: string;
    serviceunit_product_sake_brewer?: string;
    serviceunit_product_sake_brewery?: string;
    serviceunit_sake_price?: number;
  };
};

export type UpdateDeviceRawParamsType = {
  thing_name: string;
  thing_group: string[];
  thing_shadow: {
    thing_nickname: string;
    serviceunit_serve_pour: string;
    bottle_size?: string;
    bottle_liquidvolume?: string;
    serviceunit_serve_filling?: string;
    serviceunit_serve_washing?: string;

    serviceunit_sdrs_1_a_product_id?: string; // set => selected id
    serviceunit_sdrs_1_a_product_name?: string; // いらない
    serviceunit_sdrs_1_a_product_special_designation_sake?: string;
    serviceunit_sdrs_1_a_product_sake_brewer?: string;
    serviceunit_sdrs_1_a_product_sake_brewery?: string;
    serviceunit_sdrs_1_a_sake_price?: number;

    serviceunit_sdrs_1_b_product_id?: string;
    serviceunit_sdrs_1_b_product_name?: string;
    serviceunit_sdrs_1_b_product_special_designation_sake?: string;
    serviceunit_sdrs_1_b_product_sake_brewer?: string;
    serviceunit_sdrs_1_b_product_sake_brewery?: string;
    serviceunit_sdrs_1_b_sake_price?: number;

    serviceunit_product_id?: string; // custom => id = 0
    serviceunit_product_name?: string; // 4 => null || ''
    serviceunit_product_special_designation_sake?: string;
    serviceunit_product_sake_brewer?: string;
    serviceunit_product_sake_brewery?: string;
    serviceunit_sake_price?: number;
  };
  thing_colors: {
    theme_color: string;
    font_color: string;
  };
};

export type PortType = {
  product_id?: number;
  product_name?: string;
  product_special_designation_sake?: string;
  product_sake_brewer?: string;
  product_sake_brewery?: string;
};

export type PostContactBodyRequestType = {
  email: string;
  name: string;
  ext_01: string;
  body: string;
};

export enum Currency {
  Jpy = "jpy",
}

enum TicketLifeSpanUnitTypes {
  Year = 0,
  Month = 1,
  Day = 2,
  Hour = 3,
  Minute = 4,
}

// ticket_lifespan_unit_type = 0: year
// ticket_lifespan_unit_type = 1: month
// ticket_lifespan_unit_type = 2: day
// ticket_lifespan_unit_type = 3: hour
// ticket_lifespan_unit_type = 4: minute

export type TicketType = {
  ticketCurrency: Currency;
  ticketId: string;
  ticketIsShow: boolean;
  ticketLifespanType: number;
  ticketLifespanUnitNum: number;
  ticketLifespanUnitType: TicketLifeSpanUnitTypes;
  ticketName: string;
  ticketPermission: string;
  ticketPrice: number;
  ticketPurchasePeriodStart: number | null;
  ticketPurchasePeriodEnd: number | null;
  ticketLifespanStart: number | null;
  ticketLifespanEnd: number | null;
  ticketQuantity: number;
  ticketStatus: string;
  ticketType: string;
  ticketUsageType: string;
};

export type GroupItem = {
  groupId: string;
  groupName?: string;
};

export type CreateStore = {
  storeName: string;
  storeSubname: string;
  storeCountry: string;
  storeType: string;
  storePostalCode: string;
  storeState: string;
  storeCity: string;
  storePhoneNumber: string;
};

export type InvoiceType = {
  id: string;
  createdAt: number;
  total: number;
  currency: string;
  invoicePdf: string;
  status: string;
};

export type TransactionType = {
  id: string;
  createdAt: string;
  amount: number;
  type: string;
  status: string;
};

export type QuickPayType = {
  transaction_id: string;
  stripePriceObjectId: string;
  stripePriceObjectQuantity: number;
};

export type TabType = {
  label: string;
  icon: JSX.Element;
};

export type IssuedTicketDetailType = {
  label?: IssuedTicketLabelType | null;
  store: {
    name: string;
    subname: string;
  };
  ticket: {
    name: string;
    price: number;
    ticketStatus: string;
    type: string;
  };
};

export type IssuedTicketLabelType = {
  top: string;
  bottom: string;
};

export type UpdateIssuedTicketLabelType = {
  top: {
    imageContentType: string;
  } | null;
  bottom: {
    imageContentType: string;
  } | null;
  customerStoreId: string;
};

export type IssuedNewTicket = {
  customerStoreId: string;
  ticketId: string;
};

export type UploadImageType = {
  url: string;
  body: FormData;
};

export type WifiSetupType = {
  ssid: string;
  password: string;
};

export enum ModalDeviceType {
  FILL = "fill",
  CLEANING = "wash",
  EXCTRACTION = "changeExtraction",
  BOTTLE = "reset",
  EXCHANGE = "exchange",
}

export type ModalDeviceParam = {
  type: ModalDeviceType;
  reset?: string;
  item: any;
  option?: {
    volume?: string;
    newVolume?: string;
    onSubmit?: () => void;
    type?: ModalDeviceType;
    reset?: string;
  } | null;
};

type EdgeCommandType = {
  type: ModalDeviceType;
  reset?: string;
  body: {
    volume?: number;
  };
};

export type EdgeCommandParam = {
  command: EdgeCommandType;
  devices: string[];
};

export enum DeviceStatusType {
  FILLING = "Filling",
  PROCESSING = "processing",
}

export interface SocketDeviceData {
  connectionId: string | null;
  customer_store_id: string | null;
  device_name: string | null;
  status: {
    maintenance_qr: DeviceDetailMaintenanceType | null;
    working: string | null;
  };
}

export type DeviceDetailSinglePortType = {
  serveProductName?: string | null;
  serveProductId?: string | null;
  serveProductCurrency?: string | null;
  serveProductPrice?: number | null;
  serveProductStatus?: string | null;
};

export type DeviceDetailMultiPortType = {
  portA?: DeviceDetailSinglePortType;
  portB?: DeviceDetailSinglePortType;
};

export enum DeviceDetailMaintenanceType {
  WASHING = "washing",
  FILLING = "filling",
  RESET = "reset",
  CHANGE_EXTRACTION = "changeExtraction",
  WAITING = "waiting",
  READY = "ready",
}

export type DeviceDetailType = {
  actionData: {
    filling?: string | null;
    washing?: string | null;
    exchange?: string | null;
  };
  bottleLiquidVolume?: string;
  bottleSize?: string;
  bottleStatus?: string;
  thingName: string;
  thingNickname?: string | null;
  thingType: string;
  servePour: number;
  maintenance_qr?: DeviceDetailMaintenanceType | null;
  device_status?: string | null;
  working?: string | null;
  softVersion: string;
  thingsBelongToGroup: string[];
} & DeviceDetailSinglePortType &
  DeviceDetailMultiPortType;

export type AlertDetailType = {
  count: number;
  datetime: string;
  detail: string;
  device: string;
  deviceNickname: string;
  title: string;
  filename: string;
};

export type SelectedAlertType = {
  device: string;
  filename: string;
};

export type BeverageItemType = {
  userId: string;
  date: string;
  productName: string;
  deviceNickName: string;
  device: string;
  storeName: string;
  brewerName: string;
};

export type BeverageBodyParam = {
  customerStoreId: string;
  startDate?: number | null;
  endDate?: number | null;
};

export enum TotalServedDrinkParamType {
  DAILY = "daily",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export type GetTotalServedDrinkParams = {
  period: TotalServedDrinkParamType;
} & StoreParamType;

export type TotalServedDrinkRankingType = {
  productId: number;
  productName: string;
  productBrewer: string;
  productProvidedCount: number;
};

export type TotalServedDrinkType = {
  count: number;
  ranking: TotalServedDrinkRankingType[];
};

export type StoreParamType = {
  customerStoreId: string;
};

export type RemainingBeverageItemType = {
  bottleLiquidVolume: string | null;
  bottleSize: string | null;
  productId: string | null;
  thingNickname: string;
  thingName: string;
};

export type RemainingBeveragesType = {
  ranking: RemainingBeverageItemType[];
};

export type EkaItemType = {
  createdAt: number,  // 生成日時
  ekaLifespanEnd: number | null, // 利用期限
  issuedEkaSerial: string, // Eka id
  lastUpdatedAt: number, // 最終利用日時
  leftQuantity: number | null, // 残額
  ticketName: string, // チケット名
  ticketPrice: number, // 販売額
  ticketType: string,
};

export type FormattedEkaItemType = {
  "チケット名": string,
  "連番": string,
  "生成日時": string,
  "利用期限": string | null,
  "チケット販売額": number,
  "チケットの最終利用日時": string,
  "残額・残杯数": number | string | null,
}

export type TicketStyle = {
  backgroundColor: string;
  fontColor: string;
  text: string;
  unit?: string;
  fontSize?: string;
};

export type TicketStyles = {
  [key: string]: TicketStyle;
};

// 不要型
export enum EkaTicketType {
  Coupon = 0,
  Prepaid = 1,
  Something = 2
}

export type EkaSearchItem = {
  ekaId: string | null;
  start: string | null;
  end: string | null;
}

export type SearchPeriod = {
  start: string;
  end: string;
}


export type QueryParam = {
  customerStoreId: string;
  issuedEkaSerial?: string | null;
  startDate?: number | null;
  endDate?: number | null;
};

export type BodyParam = {
  nextIndex: any
};