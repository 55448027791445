import { Fragment, useContext, useState } from "react";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import { TicketType } from "../../types";
import { useNavigate } from "react-router-dom";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { ArrowBackIos, Refresh } from "@mui/icons-material";
import TicketList from "./TicketList";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ButtonRegular from "../../components/Buttons/ButtonRegular";
import AuthContext from "../../custom-hooks/use-auth-context";
import ErrorHandler from "../../components/ErrorHandler";
import Loader from "../../components/Loader";
import ReloadAnimation from "../../components/ReloadAnimation";
import ButtonSwitch from "../../components/Buttons/ButtonSwitch";

const IssuedTicketList = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getIssuedTickets } = useTicketApi();

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useQuery(
      [
        QueryKeys.issuedTickets,
        authCtx.user?.signInUserSession.idToken,
        authCtx.user?.store_id,
      ],
      () => {
        if (authCtx.user?.signInUserSession.idToken && authCtx.user?.store_id)
          return getIssuedTickets({
            customer_store_id: authCtx.user?.store_id,
            usage: "print",
          });
        return;
      },
      {
        staleTime: 0,
        refetchOnWindowFocus: true,
      }
    );

  const [show, setShow] = useState<boolean>(false);

  if (isError || isRefetchError) return <ErrorHandler url="/store" />;
  // if (isLoading) return <Loader />;
  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/store")}
          >
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                fontWeight: 600,
                fontSize: "1.2rem",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
              align="center"
            >
              店舗発行チケット一覧
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => refetch()}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ mx: 2, mb: 9.5 }}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ButtonSubmit
            startIcon={<Add />}
            sx={{
              width: 0.9,
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
              py: 1.5,
              fontSize: "1.1rem",
            }}
            onClick={() => navigate("/store/issued-tickets/create")}
          >
            新規店舗発行チケット追加
          </ButtonSubmit>
          <ButtonRegular
            startIcon={<Edit />}
            sx={{
              width: 0.9,
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
              py: 1.5,
              fontSize: "1.1rem",
            }}
            onClick={() => navigate("/store/issued-tickets/receipt")}
          >
            レシートラベル編集
          </ButtonRegular>
        </Grid>
        <Grid
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontSize: "0.8rem" }}>
            すべてのチケットを表示する
          </Typography>
          <ButtonSwitch
            sx={{
              "& .MuiSwitch-track": {
                backgroundColor: "#C3C3C3",
              },
            }}
            onChange={() => setShow(!show)}
            checked={show}
          />
        </Grid>
        <Grid sx={{ my: 2, paddingBottom: "20px" }}>
          {data?.tickets.map((ticket: TicketType, index: number) => {
            if (!show) {
              return (
                ticket.ticketIsShow && (
                  <ReloadAnimation>
                    <Fragment key={index}>
                      <TicketList ticket={ticket} />
                    </Fragment>
                  </ReloadAnimation>
                )
              );
            } else {
              return (
                <ReloadAnimation>
                  <Fragment key={index}>
                    <TicketList ticket={ticket} />
                  </Fragment>
                </ReloadAnimation>
              );
            }
          })}
        </Grid>
      </Box>
      {(isLoading || isRefetching) && <Loader />}
    </Fragment>
  );
};

export default IssuedTicketList;
