import { Fragment, useContext, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { TicketType } from "../../types";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import { ArrowBackIos, Refresh } from "@mui/icons-material";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import StoreTicketSettingItem from "./StoreTicketSettingItem";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";
import ButtonSwitch from "../../components/Buttons/ButtonSwitch";

// interface TicketItem {
//   ticket_id: string;
//   ticket_status: string;
//   ticket_name: string;
//   ticket_type: string;
//   ticket_quantity: number;
//   ticket_price: number;
//   ticket_lifespan_end: string;
//   ticket_lifespan_start: string;
//   ticket_lifespan_type: number;
//   ticket_lifespan_unit_type: number;
//   ticket_lifespan_unit_num: number;
// }
// ticket_lifespan_unit_type = 0: year
// ticket_lifespan_unit_type = 1: month
// ticket_lifespan_unit_type = 2: day
// ticket_lifespan_unit_type = 3: hour
// ticket_lifespan_unit_type = 4: minute

const StoreTicketSettings = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getTickets } = useTicketApi();
  const { hasPermission } = usePermissions();

  const [show, setShow] = useState<boolean>(false);

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useQuery(
      [
        QueryKeys.storeTickets,
        authCtx.user?.signInUserSession.idToken,
        authCtx.user?.store_id,
      ],
      () => {
        if (authCtx.user?.signInUserSession.idToken && authCtx.user?.store_id)
          return getTickets({
            customer_store_id: authCtx.user?.store_id,
          });
        return;
      },
      {
        staleTime: 0,
        refetchOnWindowFocus: true,
      }
    );

  if (isError || isRefetchError) return <ErrorHandler url="/store" />;
  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/store")}
          >
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                fontWeight: 600,
                fontSize: "1.2rem",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
              align="center"
            >
              チケット設定
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => refetch()}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ReloadAnimation>
        <Container sx={{ pb: 2, mb: 7 }}>
          <Grid my={3} sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonSubmit
              sx={{ width: 0.9 }}
              variant="contained"
              onClick={() => {
                navigate("/store/tickets/create");
              }}
              disabled={!hasPermission("ticket.create")}
            >
              + 新規チケット追加
            </ButtonSubmit>
          </Grid>

          <Grid
            sx={{
              my: 2,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontSize: "0.8rem" }}>
              すべてのチケットを表示する
            </Typography>
            <ButtonSwitch
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: "#C3C3C3",
                },
              }}
              onChange={() => setShow(!show)}
              checked={show}
            />
          </Grid>

          {data?.tickets && data?.tickets.length > 0 ? (
            data?.tickets.map((ticket: TicketType, index: number) => {
              if (!show) {
                return (
                  ticket.ticketIsShow && (
                    <ReloadAnimation>
                      <Fragment key={index}>
                        <StoreTicketSettingItem ticket={ticket} />
                      </Fragment>
                    </ReloadAnimation>
                  )
                );
              } else {
                return (
                  <ReloadAnimation>
                    <Fragment key={index}>
                      <StoreTicketSettingItem ticket={ticket} />
                    </Fragment>
                  </ReloadAnimation>
                );
              }
            })
          ) : (
            <Box
              sx={{
                height: `calc(100vh - 300px)`,
                marginX: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography align="center">
                  作成したチケットがありません
                </Typography>
              </Box>
            </Box>
          )}
        </Container>
      </ReloadAnimation>
      {isRefetching && <Loader />}
    </Fragment>
  );
};

export default StoreTicketSettings;
