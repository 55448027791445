import { RefObject, useContext, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ArrowBackIos, Edit } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { LOGO, LOGO_SECOND } from "../../assets";
import { useReactToPrint } from "react-to-print";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { toastError } from "../../utils/toaster";
import { IssuedNewTicket, IssuedTicketDetailType } from "../../types";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ButtonRegular from "../../components/Buttons/ButtonRegular";
import AuthContext from "../../custom-hooks/use-auth-context";
import ErrorHandler from "../../components/ErrorHandler";
import Loader from "../../components/Loader";
import ImagePreview from "../../components/ImagePreview";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import moment from "moment";
import ReloadAnimation from "../../components/ReloadAnimation";

interface IssuedTicketDetailContentProps {
  visibility: boolean;
  isPrinting: boolean;
  data: IssuedTicketDetailType;
  refContainer: RefObject<HTMLDivElement>;
  qr: {
    encodeImage: string;
    lifeSpanEnd: string;
    issuedEkaSerial: string;
  } | null;
  componentRef: React.MutableRefObject<HTMLDivElement | null>;
}

const IssuedTicketDetailContent: React.FC<IssuedTicketDetailContentProps> = ({
  visibility,
  data,
  qr,
  refContainer,
  isPrinting,
  componentRef,
}) => {
  const [issuedEkaSerial, setIssuedEkaSerial] = useState<string>("");

  useEffect(() => {
    if (visibility && qr?.issuedEkaSerial) {
      setIssuedEkaSerial(qr.issuedEkaSerial);
    }
  }, [visibility, qr]);

  return (
    <Paper
      sx={{
        p: isPrinting ? 0 : 2,
        my: isPrinting ? 0 : 2,
        ml: isPrinting ? "-3.54px" : 0,
      }}
      ref={componentRef}
    >
      <Grid
        sx={
          isPrinting
            ? {
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                alignItems: "center",
                mt: 1,
                mb: 1,
                mr: 6,
              }
            : {
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                alignItems: "center",
                mt: 1,
                mb: 1,
              }
        }
      >
        {visibility && (
          <Typography
            sx={{ fontWeight: 600, fontSize: isPrinting ? "8px" : "0.8rem" }}
          >
            {issuedEkaSerial}
          </Typography>
        )}
      </Grid>
      <Grid
        sx={
          isPrinting
            ? {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                mb: 1,
              }
            : {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                mt: 8,
                mb: 4,
              }
        }
      >
        {data?.label?.top ? (
          <img
            src={data?.label?.top}
            width={isPrinting ? 35 : 80}
            height={isPrinting ? 35 : 80}
          />
        ) : (
          <ImagePreview
            img={LOGO_SECOND}
            width={isPrinting ? 35 : 80}
            height={isPrinting ? 35 : 80}
          />
        )}
      </Grid>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {visibility ? (
          <ReloadAnimation>
            <Box ref={refContainer}></Box>
          </ReloadAnimation>
        ) : (
          <Box
            sx={{
              height: isPrinting ? "20px" : "20px",
              width: isPrinting ? "20px" : "20px",
            }}
          ></Box>
        )}
      </Grid>
      {visibility && (
        <Grid sx={{ my: isPrinting ? 1 : 2 }}>
          <Typography
            align="center"
            sx={{ fontWeight: 600, fontSize: isPrinting ? "8px" : "0.8rem" }}
          >
            <>有効期限</>
          </Typography>
          <Typography
            align="center"
            sx={{ fontWeight: 600, fontSize: isPrinting ? "10px" : "0.8rem" }}
          >
            <>
              {qr?.lifeSpanEnd && parseFloat(qr?.lifeSpanEnd) > 0 ? (
                moment
                  .unix(parseFloat(qr?.lifeSpanEnd))
                  .format("YYYY/MM/DD HH:mm")
              ) : (
                <>無制限</>
              )}
            </>
          </Typography>
        </Grid>
      )}

      <Grid sx={{ my: isPrinting ? 0 : 4 }}>
        <Typography
          align="center"
          sx={{
            fontWeight: 600,
            fontSize: isPrinting ? "8px" : "1.0rem",
            wordWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {data?.store?.name} {data?.store?.subname}
        </Typography>
        <Typography
          align="center"
          sx={{
            fontWeight: 600,
            fontSize: isPrinting ? "10px" : "1.0rem",
            wordWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          {data?.ticket?.name}
        </Typography>
      </Grid>

      {/* <Grid sx={{ my: 2, display: "flex", justifyContent: "center" }}>
        <Typography
          align="center"
          sx={{
            width: 0.85,
            fontSize: isPrinting ? "8px" : "0.8rem",
          }}
        >
          このチケットは当店限り有効となります。
          <br />
          他ののまっせ設置店ではご利用いただけません。
        </Typography>
      </Grid> */}

      <Grid
        sx={
          isPrinting
            ? {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }
            : {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                my: 8,
              }
        }
      >
        {data?.label?.bottom ? (
          <img
            src={data?.label?.bottom}
            width={isPrinting ? 35 : 80}
            height={isPrinting ? 35 : 80}
          />
        ) : (
          <ImagePreview
            img={LOGO}
            width={isPrinting ? 35 : 80}
            height={isPrinting ? 35 : 80}
          />
        )}
      </Grid>
    </Paper>
  );
};

const IssuedTicketDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPrinting, setIsPrinting] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [qr, setQr] = useState<any | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { getIssuedTicket, getNewIssuedTicket } = useTicketApi();
  const authCtx = useContext(AuthContext);
  const { loadingOp } = useContext(GlobalDataContext);
  const refContainer: RefObject<HTMLDivElement> = useRef(null);

  const pageStyle = `
    @page { 
      size: 200mm;
    }

    @media print {
      body {
        -webkit-print-color-adjust: economy;
        color-adjust: economy;
      }
    }
  `;

  const { isLoading, data, isError, refetch, isRefetching, isRefetchError } =
    useQuery(
      [
        QueryKeys.issuedTicket,
        authCtx.user?.signInUserSession.idToken,
        id,
        authCtx.user?.store_id,
      ],
      () => {
        if (
          authCtx.user?.signInUserSession.idToken &&
          id &&
          authCtx.user?.store_id
        )
          return getIssuedTicket({
            customerStoreId: authCtx.user?.store_id,
            ticketId: id,
          });
        return;
      },
      {
        staleTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const getNewIssuedTicketMutation = useMutation(
    (requestBody: IssuedNewTicket) => getNewIssuedTicket(requestBody),
    {
      onSuccess: async (data) => {
        setQr(data);
        setVisibility(true);
      },
      onError: async (error: any) => {
        console.log("🚀 ~ file: index.tsx:40 ~ onError: ~ error:", error);
        let msg = "申し訳ありませんが、新しく発行することができませんでした。";
        if (error.response?.data?.code) {
          msg = error.response?.data?.message;
        }

        loadingOp.finish();
        toastError(msg);
      },
    }
  );

  const handleIssuedNewTicket = () => {
    if (!authCtx.user?.store_id || !id) return;
    getNewIssuedTicketMutation.mutate({
      ticketId: id,
      customerStoreId: authCtx.user?.store_id,
    });
  };

  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef?.current,
    onBeforeGetContent: async () => {
      setIsPrinting(true);
      return Promise.resolve();
    },
    onAfterPrint: () => {
      setVisibility(false);
      setIsPrinting(false);
      let qr_img = refContainer?.current;
      if (qr_img) {
        while (qr_img.firstChild) {
          qr_img.removeChild(qr_img.firstChild);
        }
      }
    },
    onPrintError: (error) => {
      console.log("🚀 ~ IssuedTicketDetail ~ error:", error);
    },
  });

  useEffect(() => {
    let qr_img = refContainer.current?.children[0];
    if (!qr_img) return;
    if (isPrinting) {
      qr_img.setAttribute("height", "120px");
      qr_img.setAttribute("width", "120px");
    } else {
      qr_img.setAttribute("height", "200px");
      qr_img.setAttribute("width", "200px");
    }
  }, [isPrinting]);

  const loadSVG = (container: HTMLDivElement, base64: string) => {
    var dataURL = "data:image/svg+xml;base64," + base64;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", dataURL);
    xhr.addEventListener("load", function (ev: any) {
      var xml = ev.target.response;
      var dom = new DOMParser();
      var svg = dom.parseFromString(xml, "image/svg+xml");
      svg.documentElement?.setAttribute("height", "200px");
      svg.documentElement?.setAttribute("width", "200px");
      if (container.children.length === 0)
        container.appendChild(svg.documentElement);
    });
    xhr.send(null);
  };

  useEffect(() => {
    if (qr?.encodeImage && refContainer.current) {
      console.log("reload");
      loadSVG(refContainer.current, qr?.encodeImage);
    }
  }, [qr?.encodeImage, refContainer?.current]);

  if (isError || isRefetchError) return <ErrorHandler url="/store" />;
  // if (isLoading) return <Loader />;
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: `calc(100vh - 76px)`,
        bgcolor: "#E6E4EB",
        padding: 0,
      }}
    >
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid sx={{ minWidth: "64px" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate("/store/issued-tickets")}
            >
              <ArrowBackIos />
            </IconButton>
          </Grid>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: "#4F4F62",
              fontWeight: 600,
              fontSize: "1.2rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            チケット印刷
          </Typography>

          <ButtonSubmit
            type="button"
            disabled={isError}
            sx={{ py: "5px", px: "10px", minWidth: "40px", boxShadow: 5 }}
            onClick={() => navigate(`/store/issued-tickets/${id}/update`)}
          >
            <Grid sx={{ flexDirection: "column" }}>
              {/* <img src={QR_ICON} alt="qr_icon" width="30" height="30" /> */}
              <Edit />
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  color: "#fff",
                  marginTop: "-8px",
                  fontWeight: "bold",
                }}
              >
                編集
              </Typography>
            </Grid>
          </ButtonSubmit>
        </Toolbar>
      </AppBar>
      {isError ? (
        <ErrorHandler />
      ) : (
        <ReloadAnimation>
          <Box sx={{ minHeight: `calc(100vh - 160px)`, px: 2, pb: 2, mb: 7 }}>
            {/* header */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ButtonRegular
                  type="button"
                  sx={{
                    width: 1,
                    boxShadow: 2,
                    py: 1.5,
                    borderRadius: "30px",
                  }}
                  onClick={handleIssuedNewTicket}
                >
                  新しく発行する
                </ButtonRegular>
              </Grid>
              <Grid item xs={6}>
                <ButtonSubmit
                  type="button"
                  sx={{ width: 1, boxShadow: 2, py: 1.5, borderRadius: "30px" }}
                  onClick={handlePrint}
                  disabled={!visibility || isPrinting}
                >
                  印刷する
                </ButtonSubmit>
              </Grid>
            </Grid>
            {/* content */}
            <IssuedTicketDetailContent
              qr={qr}
              data={data}
              isPrinting={isPrinting}
              visibility={visibility}
              refContainer={refContainer}
              componentRef={componentRef}
            />
            {(getNewIssuedTicketMutation.isLoading || isPrinting) && <Loader />}
          </Box>
        </ReloadAnimation>
      )}
      {(isLoading || isRefetching) && <Loader />}
    </Container>
  );
};

export default IssuedTicketDetail;
