import { useCallback, useContext, useMemo } from "react";
import appStoreApi from "../../apis/store-api";
import {
  UpdateStoreBodyRequestType,
  CustomerStoreIdType,
  UpdateStoreRawParamsType,
  CreateStoreBodyRequestType,
} from "../../types";
import { cloneObjWithoutAttr } from "../../utils/helper";
import GlobalDataContext from "../use-global-data";

export const useStoreApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const storeApi = useMemo(
    () =>
      new appStoreApi(
        process.env.REACT_APP_API_1 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getStoreList = useCallback(async () => {
    loadingOp.start();
    const storeList = await storeApi.getStoreList();
    loadingOp.finish();
    return storeList;
  }, [storeApi]);

  const getStore = useCallback(
    async (params: any) => {
      loadingOp.start();
      const store = await storeApi.getStore(params);
      loadingOp.finish();
      return store;
    },
    [storeApi]
  );

  const createStore = useCallback(
    async (params: CreateStoreBodyRequestType) => {
      loadingOp.start();
      const response = await storeApi.createStore(params);
      loadingOp.finish();
      return response;
    },
    [storeApi]
  );

  const updateStore = useCallback(
    async (params: UpdateStoreRawParamsType) => {
      loadingOp.start();

      let param: CustomerStoreIdType = {
        customer_store_id: params.customer_store_id,
      };

      let body: UpdateStoreBodyRequestType = cloneObjWithoutAttr(
        params,
        "customer_store_id"
      );

      const response = await storeApi.updateStore(param, body);
      loadingOp.finish();
      return response;
    },
    [storeApi]
  );

  return {
    getStoreList,
    getStore,
    createStore,
    updateStore,
  };
};
