import React from "react";
import { Box, Typography } from "@mui/material";
import { DeviceDetailMaintenanceType } from "../../types";
import { Cached } from "@mui/icons-material";

interface CustomContainerProps {
  children: JSX.Element;
  sx?: {
    height?: string;
    borderRadius?: string;
  };
}
const CustomContainer: React.FC<CustomContainerProps> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 100,
        width: "100%",
        position: "absolute",
        height: sx?.height || "72px",
        backgroundColor: "#707070",
        borderRadius: sx?.borderRadius || "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.85,
      }}
    >
      {children}
    </Box>
  );
};

interface DeviceDetailMaintenanceProps {
  status?: DeviceDetailMaintenanceType | null;
  isOutVersion: boolean;
  sx?: {
    height?: string;
    borderRadius?: string;
  };
}

const DeviceDetailMaintenance: React.FC<DeviceDetailMaintenanceProps> = ({
  status,
  isOutVersion,
  sx,
}) => {
  const getStatusText = (): string => {
    let result: string = "";
    switch (status) {
      case DeviceDetailMaintenanceType.FILLING:
        result = "充填中";
        break;
      case DeviceDetailMaintenanceType.WASHING:
        result = "洗浄中";
        break;
      case DeviceDetailMaintenanceType.CHANGE_EXTRACTION:
        result = "充填中";
        break;
      case DeviceDetailMaintenanceType.RESET:
        result = "再起動中";
        break;
      case DeviceDetailMaintenanceType.WAITING:
        result = "処理待機中";
        break;
      default:
        result = "";
        break;
    }
    return result;
  };

  let statusText = getStatusText();

  if (isOutVersion) {
    return (
      <CustomContainer sx={sx}>
        <Typography
          sx={{ color: "#fff", fontWeight: 600, textAlign: "center" }}
        >
          バージョン未対応
        </Typography>
      </CustomContainer>
    );
  }

  if (statusText.length > 0) {
    return (
      <CustomContainer sx={sx}>
        <Box sx={{ color: "#fff", display: "flex" }}>
          <Typography
            sx={{ color: "#fff", fontWeight: 600, textAlign: "center" }}
          >
            {statusText}
          </Typography>
          {status !== DeviceDetailMaintenanceType.WAITING && <Cached />}
        </Box>
      </CustomContainer>
    );
  }
  return <></>;
};

export default DeviceDetailMaintenance;
