import { FormControlLabel, FormControlLabelProps, styled } from "@mui/material";

const FormLabelDefault = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .Mui-checked": {
      color: "#9f1831 !important",
    },
  })
);

export default FormLabelDefault;
