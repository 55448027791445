import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Cached } from "@mui/icons-material";

interface BottomRefresherProps {
  refetch: () => void;
}

const BottomRefresher: React.FC<BottomRefresherProps> = ({ refetch }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        borderTop: 1,
        mt: 1,
        borderColor: "#c3c3c3",
        cursor: "pointer",
      }}
      onClick={refetch}
    >
      <Typography
        sx={{ fontSize: "0.6rem", fontWeight: 600, color: "#8D8B8B" }}
      >
        タップしてデータを閲覧・更新
      </Typography>

      <IconButton aria-label="" edge="end">
        <Cached sx={{ fontSize: "1rem" }} />
      </IconButton>
    </Box>
  );
};

export default BottomRefresher;
