import React from "react";
import { Grid, Typography } from "@mui/material";
import CurrencyFormat from "react-currency-format";

interface PlanItemProps {
  item: any;
  handleSelect: (item: any) => void;
}

const PlanItem: React.FC<PlanItemProps> = ({ item, handleSelect }) => {
  return (
    <Grid container my={1}>
      <Grid
        xs={9}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "8px",
          height: "86px",
          backgroundColor: "#9f1831",
          paddingX: "20px",
          color: "#fff",
          paddingTop: "15px",
          paddingBottom: "10px",
        }}
      >
        <Typography
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
        >
          {item?.name}
        </Typography>
        <Grid sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
            }}
            component="span"
          >
            <CurrencyFormat
              value={item?.amount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "0.6rem",
              opacity: "0.75",
              lineHeight: "2.2rem",
            }}
            component="span"
          >
            円/月 (１台)
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={3}
        sx={{
          borderRadius: "8px",
          height: "86px",
          border: 1,
          borderColor: "#9f1831",
          padding: "5px",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: "15px",
          paddingBottom: "10px",
          justifyContent: "center",
          fontWeight: 600,
        }}
        onClick={() => {
          handleSelect(item);
        }}
      >
        <Typography
          sx={{
            fontSize: "0.6rem",
            fontWeight: "bold",
          }}
          component="span"
        >
          プランの詳細を見る
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PlanItem;
