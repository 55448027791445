import React from "react";
import { Box, Paper, Typography, Container, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { LOGO } from "../../assets";

const AuthLayout = () => {
  return (
    <Container
      sx={{
        minHeight: "100vh",
        bgcolor: "#9f1831",
        width: 1,
        maxWidth: "none !important",
        display: "flex",
        justifyContent: "center",
        m: 0,
      }}
    >
      <Box
        sx={{
          paddingX: "10px",
          paddingY: "20px",
          maxWidth: "sm",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingY: "20px",
          }}
        >
          <div>
            <img alt="logo" src={LOGO} width={125} height={125} />
          </div>
        </Box>
        <Grid sx={{ marginBottom: 3, color: "#fff", width: 1 }}>
          <Typography align="center">for Customer</Typography>
        </Grid>

        <Paper sx={{ width: 1 }} variant="elevation" elevation={12}>
          <Outlet />
        </Paper>
      </Box>
    </Container>
  );
};

export default AuthLayout;
