import React from "react";
import { Box, Paper, Skeleton } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

const AppNotificationSkeleton = () => {
  return (
    <Paper
      sx={{
        px: 2,
        py: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Skeleton width="140px" height="34px" />
        <Skeleton width="200px" height="34px" />
        <Skeleton width="220px" height="50px" />
      </Box>
      <Box>
        <ArrowForwardIos
          sx={{
            height: "0.6em",
            width: "0.6em",
          }}
        />
      </Box>
    </Paper>
  );
};

export default AppNotificationSkeleton;
