import { Fragment, useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { ArrowForwardIos } from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  ticketPriceValidation,
  ticketNameValidation,
  ticketTypeValidation,
  ticketQuantityValidation,
  ticketTypeUsageValidation,
  ticketCurrencyValidation,
  ticketPermissionValidation,
  ticketLifespanTypeValidation,
  ticketLifespanUnitTypeValidation,
  ticketLifespanUnitNumValidation,
} from "../../utils/validations";
import { GroupItem } from "../../types";
import { findDuplicatesById } from "../../utils/helper";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import dayjs from "dayjs";
import moment from "moment";
import Loader from "../Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import CustomDateTimePicker from "../Inputs/CustomDateTimePicker";
import BootstrapInput from "../Inputs/BootstrapInput";
import ButtonSwitch from "../Buttons/ButtonSwitch";
import ButtonSubmit from "../Buttons/ButtonSubmit";
// import DialogTicketCreateInfo from "../IssuedTicketForm/DialogTicketCreateInfo";
import ModalGroupNameSelection from "../Modals/GroupNameSelectionModal";
import ErrorHandler from "../ErrorHandler";
import DialogTicketUpdate from "../Dialogs/DialogTicketUpdate";
import FormLabelDefault from "../FormLabelDefault";

const ticket_types = [
  {
    value: "coupon",
    label: "回数券",
  },
  {
    value: "all_you_can_drink",
    label: "飲み放題",
  },
];

const ticket_usage_patterns = [
  {
    value: "0",
    label: "通常チケット",
  },
  {
    value: "1",
    label: "特殊チケット",
  },
];

const ticket_areas = [
  {
    value: "0",
    label: "すべて",
  },
  {
    value: "1",
    label: "御酒飲帳のみ",
  },
  {
    value: "2",
    label: "サードパーティのみ",
  },
  {
    value: "3",
    label: "非表示",
  },
  {
    value: "4",
    label: "無効",
  },
];

const ticket_currencies = [
  {
    value: "jpy",
    label: "JPY",
  },
];

const ticket_lifespan_unit_type_options = [
  {
    value: "4",
    label: "分間",
  },
  {
    value: "3",
    label: "時間",
  },
  {
    value: "2",
    label: "日間",
  },
  {
    value: "1",
    label: "ヶ月間",
  },
  {
    value: "0",
    label: "年間",
  },
];
interface TicketFormProps {
  preValues?: any;
  loading: boolean;
  isDisabled: boolean;
  onSubmit: (data: FieldValues) => void;
}

const TicketForm: React.FC<TicketFormProps> = ({
  preValues,
  loading,
  isDisabled,
  onSubmit,
}) => {
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [form, setForm] = useState<FieldValues | null>(null);
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [groupNameIds, setGroupNameIds] = useState<string[]>([]);
  const [openInfo, setOpenInfo] = useState(false);
  const { getDeviceGroupList } = useDeviceApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.storeDeviceGroupList, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken)
        return getDeviceGroupList({
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const defaultValues = {
    ticketName: preValues?.ticketName || null,
    ticketPrice: preValues?.ticketPrice || null,
    ticketType: preValues?.ticketType || null,
    ticketIsShow: preValues?.ticketIsShow || true,
    ticketQuantity: preValues?.ticketQuantity || 0,
    ticketTypeUsage: preValues?.ticketUsageType || null,
    ticketCurrency: preValues?.ticketCurrency || "JPY",
    ticketPermission: preValues?.ticketPermission || null,
    ticketLifespanType: preValues?.ticketLifespanType || "0",
    ticketLifespanUnitNum: preValues?.ticketLifespanUnitNum || 0,
    ticketLifespanUnitType: preValues?.ticketLifespanUnitType || "1",
    ticketIsPurchasePeriod:
      !!preValues?.ticketPurchasePeriodStart &&
      !!preValues?.ticketPurchasePeriodEnd,
    ticketLifespanStart: preValues?.ticketLifespanStart
      ? dayjs(
        moment
          .unix(preValues?.ticketLifespanStart)
          .format("YYYY/MM/DD HH:mm:ss")
      )
      : undefined,
    ticketLifespanEnd: preValues?.ticketLifespanEnd
      ? dayjs(
        moment
          .unix(preValues?.ticketLifespanEnd)
          .format("YYYY/MM/DD HH:mm:ss")
      )
      : undefined,
    ticketPurchasePeriodStart: preValues?.ticketPurchasePeriodStart
      ? dayjs(
        moment
          .unix(preValues?.ticketPurchasePeriodStart)
          .format("YYYY/MM/DD HH:mm:ss")
      )
      : undefined,
    ticketPurchasePeriodEnd: preValues?.ticketPurchasePeriodEnd
      ? dayjs(
        moment
          .unix(preValues?.ticketPurchasePeriodEnd)
          .format("YYYY/MM/DD HH:mm:ss")
      )
      : undefined,
  };

  const schema = Yup.object().shape({
    ticketName: ticketNameValidation,
    ticketPrice: ticketPriceValidation,
    ticketType: ticketTypeValidation,
    ticketIsShow: Yup.boolean().optional(),
    ticketQuantity: Yup.string().when(
      "ticketType",
      ([ticketLifespanType], schema) => {
        return ticketLifespanType === "coupon"
          ? ticketQuantityValidation
          : Yup.number().optional();
      }
    ),
    ticketTypeUsage: ticketTypeUsageValidation,
    ticketCurrency: ticketCurrencyValidation,
    ticketPermission: ticketPermissionValidation,
    ticketLifespanType: ticketLifespanTypeValidation,
    ticketIsPurchasePeriod: Yup.boolean().optional(),
    ticketPurchasePeriodStart: Yup.string().when(
      "ticketIsPurchasePeriod",
      ([ticketIsPurchasePeriod], schema) => {
        return ticketIsPurchasePeriod
          ? Yup.date()
            .nullable()
            .required("購入可能開始日時が入力されていません")
          : // Yup.date()
          //     .required("購入可能開始日時が入力されていません")
          //     .max(
          //       Yup.ref("ticketPurchasePeriodEnd"),
          //       "購入可能開始日時はチケット利用終了日時より前でなければならない"
          //     )
          Yup.date().optional();
      }
    ),
    ticketPurchasePeriodEnd: Yup.string().when(
      "ticketIsPurchasePeriod",
      ([ticketIsPurchasePeriod], schema) => {
        return ticketIsPurchasePeriod
          ? Yup.date()
            .nullable()
            .required("購入可能終了日時が入力されていません")
          : // Yup.date()
          //     .required("購入可能終了日時が入力されていません")
          //     .min(
          //       Yup.ref("ticketPurchasePeriodStart"),
          //       "購入可能終了日時はチケット利用開始日時以降でなければならない"
          //     )
          Yup.date().optional();
      }
    ),
    ticketLifespanUnitNum: Yup.string().when(
      "ticketLifespanType",
      ([ticketLifespanType], schema) => {
        return ticketLifespanType === "0"
          ? ticketLifespanUnitNumValidation
          : Yup.number().optional();
      }
    ),
    ticketLifespanUnitType: Yup.string().when(
      "ticketLifespanType",
      ([ticketLifespanType], schema) => {
        return ticketLifespanType === "0"
          ? ticketLifespanUnitTypeValidation
          : Yup.string().optional();
      }
    ),
    ticketLifespanStart: Yup.string().when(
      "ticketLifespanType",
      ([ticketLifespanType], schema) => {
        return ticketLifespanType === "1"
          ? Yup.date()
            .required("チケット利用開始日時が入力されていません")
            .max(
              Yup.ref("ticketLifespanEnd"),
              "チケット利用開始日時はチケット利用終了日時より前でなければなりません"
            )
          : Yup.date().optional();
      }
    ),
    ticketLifespanEnd: Yup.string().when(
      "ticketLifespanType",
      ([ticketLifespanType], schema) => {
        return ticketLifespanType === "1"
          ? Yup.date()
            .required("チケット利用終了日時が入力されていません")
            .min(
              Yup.ref("ticketLifespanStart"),
              "チケット利用終了日時はチケット利用開始日時以降でなければなりません"
            )
          : Yup.date().optional();
      }
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (
      data?.groups &&
      data?.groups.length > 0 &&
      preValues?.group &&
      preValues?.group.length > 0
    ) {
      setGroupNames(
        preValues?.group.map(
          (item: GroupItem) => item.groupName || item.groupId
        )
      );
      const duplicatedItems = findDuplicatesById(
        data?.groups,
        preValues?.group.map((item: GroupItem) => item.groupId)
      );

      let arr: string[] = [];

      duplicatedItems.forEach((item) => {
        arr.push(item.id);
      });
      setGroupNameIds(arr);
    }
  }, [data?.groups, preValues?.group]);

  const formValues = watch();
  const handleGroupSelections = (param: FieldValues) => {
    const duplicatedItems: any[] = findDuplicatesById(
      data?.groups,
      param?.groupNames
    );

    let arr: string[] = [];
    let arr2: string[] = [];

    duplicatedItems.forEach((item) => {
      arr.push(item.name || item.id);
      arr2.push(item.id);
    });

    setGroupNames(arr);
    setGroupNameIds(arr2);
    setOpen(false);
  };

  const submit = (data: FieldValues) => {
    if (preValues) {
      setOpenDialog(true);
      setForm(data);
    } else {
      if (groupNames.length > 0)
        onSubmit({ ...data, groupNames: groupNameIds });
      else onSubmit(data);
    }
  };

  if (isError) return <ErrorHandler />;
  if (isLoading) return <Loader />;
  return (
    <Paper sx={{ padding: 2, marginBottom: 9 }}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid my={1}>
          <Typography
            sx={{ fontWeight: 550, color: "#4F4F62" }}
            component="h1"
            variant="h6"
          >
            基本設定
          </Typography>
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="ticketName" sx={{ paddingY: 1 }}>
                  チケット名
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="ticketName"
                  error={!!errors?.ticketName?.message}
                />
              </FormControl>
            )}
            name="ticketName"
            control={control}
          />
          {!!errors?.ticketName?.message && (
            <FormHelperText error id="ticketName_helper">
              <>{errors?.ticketName?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="combo-box-ticketType"
                options={ticket_types}
                defaultValue={
                  ticket_types[
                  ticket_types.findIndex((item) => {
                    return item.value === formValues?.ticketType;
                  })
                  ]
                }
                getOptionLabel={(option) => option.label}
                sx={{ width: 1 }}
                renderInput={(params) => {
                  return (
                    <>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{ paddingY: 1 }}
                      >
                        チケットタイプ
                      </InputLabel>
                      <TextField {...params} />
                    </>
                  );
                }}
                onChange={(e, value) => {
                  field.onChange(value?.value);
                }}
              />
            )}
            name="ticketType"
            control={control}
          />
          {!!errors?.ticketType?.message && (
            <FormHelperText error id="ticketType_helper">
              <>{errors?.ticketType?.message}</>
            </FormHelperText>
          )}
        </Grid>

        {formValues?.ticketType === "coupon" && (
          <Grid sx={{ paddingY: 1 }}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    shrink
                    htmlFor="ticketQuantity"
                    sx={{ paddingY: 1 }}
                  >
                    チケット枚数 ※半角数字を入力してください。
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    type="number"
                    id="ticketQuantity"
                    inputProps={{ style: { textAlign: "right" } }}
                    error={!!errors?.ticketQuantity?.message}
                  />
                </FormControl>
              )}
              name="ticketQuantity"
              control={control}
            />
            {!!errors?.ticketQuantity?.message && (
              <FormHelperText error id="ticketQuantity_helper">
                <>{errors?.ticketQuantity?.message}</>
              </FormHelperText>
            )}
          </Grid>
        )}

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="combo-box-ticketTypeUsage"
                options={ticket_usage_patterns}
                defaultValue={
                  ticket_usage_patterns[
                  ticket_usage_patterns.findIndex((item) => {
                    return item.value === formValues?.ticketTypeUsage;
                  })
                  ]
                }
                getOptionLabel={(option) => option.label}
                sx={{ width: 1 }}
                renderInput={(params) => {
                  return (
                    <>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{ paddingY: 1 }}
                      >
                        利用パターン
                      </InputLabel>
                      <TextField {...params} />
                    </>
                  );
                }}
                onChange={(e, value) => {
                  field.onChange(value?.value);
                }}
              />
            )}
            name="ticketTypeUsage"
            control={control}
          />
          {!!errors?.ticketTypeUsage?.message && (
            <FormHelperText error id="ticketTypeUsage_helper">
              <>{errors?.ticketTypeUsage?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="ticketPrice" sx={{ paddingY: 1 }}>
                  金額
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="ticketPrice"
                  type="number"
                  inputProps={{ style: { textAlign: "right" } }}
                  error={!!errors?.ticketPrice?.message}
                />
              </FormControl>
            )}
            name="ticketPrice"
            control={control}
          />
          {!!errors?.ticketPrice?.message && (
            <FormHelperText error id="ticketPrice_helper">
              <>{errors?.ticketPrice?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="combo-box-ticketCurrency"
                options={ticket_currencies}
                defaultValue={
                  ticket_currencies[
                  ticket_currencies.findIndex((item) => {
                    return (
                      String(item.value).toLowerCase() ===
                      String(formValues?.ticketCurrency).toLowerCase()
                    );
                  })
                  ]
                }
                getOptionLabel={(option) => option.label}
                sx={{ width: 1 }}
                renderInput={(params) => {
                  return (
                    <>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{
                          paddingY: 1,
                          overflow: "inherit",
                          textOverflow: "inherit",
                          maxWidth: "100%",
                          width: 1,
                        }}
                      >
                        通貨（ご利用時点では日本円のみとなります）
                      </InputLabel>
                      <TextField {...params} />
                    </>
                  );
                }}
                onChange={(e, value) => {
                  field.onChange(value?.value);
                }}
              />
            )}
            name="ticketCurrency"
            control={control}
          />
          {!!errors?.ticketCurrency?.message && (
            <FormHelperText error id="ticketCurrency_helper">
              <>{errors?.ticketCurrency?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="combo-box-ticketPermission"
                options={ticket_areas}
                defaultValue={
                  ticket_areas[
                  ticket_areas.findIndex((item) => {
                    return item.value === formValues?.ticketPermission;
                  })
                  ]
                }
                getOptionLabel={(option) => option.label}
                sx={{ width: 1 }}
                renderInput={(params) => {
                  return (
                    <>
                      <InputLabel
                        shrink
                        htmlFor="bootstrap-input"
                        sx={{ paddingY: 1 }}
                      >
                        チケットを表示可能なアプリ領域
                      </InputLabel>
                      <TextField {...params} />
                    </>
                  );
                }}
                onChange={(e, value) => {
                  field.onChange(value?.value);
                }}
              />
            )}
            name="ticketPermission"
            control={control}
          />
          {!!errors?.ticketPermission?.message && (
            <FormHelperText error id="ticketPermission_helper">
              <>{errors?.ticketPermission?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid
          sx={{
            my: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "0.8rem" }}>一覧に表示する</Typography>

          <Controller
            name="ticketIsShow"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <FormControlLabel
                label=""
                control={
                  <ButtonSwitch {...field} checked={formValues.ticketIsShow} />
                }
                sx={{ marginRight: 0 }}
              />
            )}
          />
        </Grid>

        <Grid sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontWeight: 550, color: "#4F4F62" }}
            component="h1"
            variant="h6"
          >
            チケット購入期限
          </Typography>
          <Controller
            name="ticketIsPurchasePeriod"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <FormControlLabel
                label=""
                control={
                  <ButtonSwitch
                    {...field}
                    checked={formValues.ticketIsPurchasePeriod}
                  />
                }
                sx={{ marginRight: 0 }}
              />
            )}
          />
        </Grid>

        {formValues?.ticketIsPurchasePeriod && (
          <>
            <Grid my={3}>
              <InputLabel
                shrink
                sx={{
                  width: 1,
                  display: "inline-block",
                  color: "#000",
                }}
              >
                購入可能
                <Typography
                  sx={{
                    display: "inline-block",
                    color: "red",
                  }}
                >
                  開始
                </Typography>
                日時
              </InputLabel>

              <Controller
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["MobileDateTimePicker"]}>
                      <CustomDateTimePicker {...field} />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
                name="ticketPurchasePeriodStart"
                control={control}
              />
              {!!errors?.ticketPurchasePeriodStart?.message && (
                <FormHelperText error id="ticketPurchasePeriodStart_helper">
                  <>{errors?.ticketPurchasePeriodStart?.message}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid my={3}>
              <InputLabel
                shrink
                sx={{
                  width: 1,
                  display: "inline-block",
                  color: "#000",
                }}
              >
                購入可能
                <Typography
                  sx={{
                    display: "inline-block",
                    color: "blue",
                  }}
                >
                  終了
                </Typography>
                日時
              </InputLabel>

              <Controller
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["MobileDateTimePicker"]}>
                      <CustomDateTimePicker {...field} />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
                name="ticketPurchasePeriodEnd"
                control={control}
              />
              {!!errors?.ticketPurchasePeriodEnd?.message && (
                <FormHelperText error id="ticketPurchasePeriodEnd_helper">
                  <>{errors?.ticketPurchasePeriodEnd?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </>
        )}

        <Grid sx={{ my: 1, display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontWeight: 550, color: "#4F4F62" }}
            component="h1"
            variant="h6"
          >
            有効期限設定
          </Typography>
          {/* <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              setOpenInfo(true);
            }}
          >
            <HelpIcon />
          </IconButton> */}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Typography sx={{ fontSize: "0.8rem" }}>期限タイプ</Typography>
          <Grid marginLeft={1}>
            <Controller
              name="ticketLifespanType"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormLabelDefault
                    value="0"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        決済直後に有効期限を発行する
                      </Typography>
                    }
                  />
                  <FormLabelDefault
                    value="1"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        カスタム設定
                      </Typography>
                    }
                  />
                  <FormLabelDefault
                    value="3"
                    control={<Radio />}
                    label={
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        無制限
                      </Typography>
                    }
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          {!!errors?.ticketLifespanType?.message && (
            <FormHelperText error id="ticketLifespanType_helper">
              <>{errors?.ticketLifespanType?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid my={1}>
          <InputLabel shrink sx={{ color: "#000" }}>
            期限
          </InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1, paddingY: 1 }}
                      id="ticketLifespanUnitNum"
                      inputProps={{
                        style: {
                          textAlign: "right",
                        },
                      }}
                      type="number"
                      error={!!errors?.ticketLifespanUnitNum?.message}
                      disabled={+formValues?.ticketLifespanType !== 0}
                    />
                  </FormControl>
                )}
                name="ticketLifespanUnitNum"
                control={control}
              />
              {!!errors?.ticketLifespanUnitNum?.message && (
                <FormHelperText error id="ticketLifespanUnitNum_helper">
                  <>{errors?.ticketLifespanUnitNum?.message}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                  {...control.register("ticketLifespanUnitType")}
                  variant="outlined"
                  fullWidth
                  defaultValue={
                    ticket_lifespan_unit_type_options[
                      ticket_lifespan_unit_type_options.findIndex((item) => {
                        return (
                          String(item.value) ===
                          String(formValues?.ticketLifespanUnitType)
                        );
                      })
                    ].value
                  }
                  disabled={+formValues?.ticketLifespanType !== 0}
                >
                  {ticket_lifespan_unit_type_options.map((item, index) => (
                    <MenuItem value={item?.value} key={index}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {!!errors?.ticketLifespanUnitType?.message && (
                  <FormHelperText error id="ticketLifespanUnitType_helper">
                    <>{errors?.ticketLifespanUnitType?.message}</>
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid my={3}>
          <InputLabel
            shrink
            sx={{
              width: 1,
              display: "inline-block",
              color: "#000",
            }}
          >
            チケット利用
            <Typography
              sx={{
                display: "inline-block",
                color: "red",
              }}
            >
              開始
            </Typography>
            日時
          </InputLabel>

          <Controller
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["MobileDateTimePicker"]}>
                  <CustomDateTimePicker
                    disablePast
                    {...field}
                    maxDate={formValues?.ticketLifespanEnd}
                    disabled={+formValues?.ticketLifespanType !== 1}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
            name="ticketLifespanStart"
            control={control}
          />
          {!!errors?.ticketLifespanStart?.message && (
            <FormHelperText error id="ticketLifespanStart_helper">
              <>{errors?.ticketLifespanStart?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid my={3}>
          <InputLabel
            shrink
            sx={{
              width: 1,
              display: "inline-block",
              color: "#000",
            }}
          >
            チケット利用
            <Typography
              sx={{
                display: "inline-block",
                color: "blue",
              }}
            >
              終了
            </Typography>
            日時
          </InputLabel>

          <Controller
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["MobileDateTimePicker"]}>
                  <CustomDateTimePicker
                    {...field}
                    disablePast
                    minDate={formValues?.ticketLifespanStart}
                    disabled={+formValues?.ticketLifespanType !== 1}
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
            name="ticketLifespanEnd"
            control={control}
          />
          {!!errors?.ticketLifespanEnd?.message && (
            <FormHelperText error id="ticketLifespanEnd_helper">
              <>{errors?.ticketLifespanEnd?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid my={2} container spacing={2}>
          <Grid item xs={5}>
            <Typography sx={{ fontSize: "0.8rem", fontWeight: 550 }}>
              のまっせグループ
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              align="right"
            >
              {groupNames.length > 0
                ? groupNames.map((name, index) => (
                  <Fragment key={index}>
                    {index > 0 ? `, ` : ""} {name}
                  </Fragment>
                ))
                : "なし"}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <ArrowForwardIos
              sx={{
                height: "0.8em",
                width: "0.8em",
              }}
            />
          </Grid>
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <ButtonSubmit
            type="submit"
            variant="contained"
            sx={{ width: 1 }}
            disabled={isDisabled}
          >
            チケットを
            {preValues ? "変更" : "追加"}
          </ButtonSubmit>
        </Grid>
      </form>
      {/* <DialogTicketCreateInfo
        open={openInfo}
        handleClose={() => {
          setOpenInfo(false);
        }}
      /> */}

      <ModalGroupNameSelection
        open={open}
        setOpen={setOpen}
        preValues={groupNameIds}
        options={data?.groups}
        onSubmit={handleGroupSelections}
      />
      {form && (
        <DialogTicketUpdate
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          handleSubmit={() => {
            setOpenDialog(false);

            if (groupNames.length > 0)
              onSubmit({ ...form, groupNames: groupNameIds });
            else onSubmit(form);
          }}
        />
      )}
      {loading && <Loader />}
    </Paper>
  );
};

export default TicketForm;
