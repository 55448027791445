import { Box, CircularProgress } from "@mui/material";

const MiniLoader = () => {
  return (
    <Box
      sx={{ 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '50vh',
      }}>
      <CircularProgress sx={{ color: "green" }} />
    </Box>
  );
};

export default MiniLoader;