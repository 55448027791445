import { styled } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

const CustomDateTimePicker = styled(MobileDateTimePicker)(({ theme }) => ({
  "& input": {
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
}));

export default CustomDateTimePicker;
