import React from "react";
import { Grid, Hidden, Paper, Typography } from "@mui/material";
import { BeverageItemType } from "../../types";

interface BeverageItemProps {
  item: BeverageItemType;
}

const BeverageItem: React.FC<BeverageItemProps> = ({ item }) => {
  return (
    <Paper sx={{ mx: 2, my: 0.5, p: 2 }}>
      <Typography sx={{ fontSize: "0.6rem" }}>{item.date}</Typography>
      <Typography sx={{ fontSize: "0.8rem", fontWeight: 600 }}>
        {item.productName}{" "}
        {item.brewerName != "" ? "(" + item.brewerName + ")" : ""}
      </Typography>

      <Hidden smUp>
        <Grid container>
          <Grid item xs={12} sx={{ mt: "5px " }}>
            <Typography sx={{ fontSize: "0.6rem", fontWeight: 600 }}>
              のまっせ: {item.deviceNickName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "0.6rem", fontWeight: 600 }}>
              ユーザーID：{item.userId}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smDown>
        <Grid sx={{ display: "flex", gap: "5px", mt: 0.5 }}>
          <Typography sx={{ fontSize: "0.6rem", fontWeight: 600 }}>
            のまっせ: {item.deviceNickName}
          </Typography>
          <Typography sx={{ fontSize: "0.6rem", fontWeight: 600 }}>
            ユーザーID：{item.userId}
          </Typography>
        </Grid>
      </Hidden>
    </Paper>
  );
};

export default BeverageItem;
