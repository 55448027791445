import React from "react";
import { motion, AnimatePresence } from "framer-motion";

interface ReloadAnimationProps {
  children: JSX.Element;
}

const ReloadAnimation: React.FC<ReloadAnimationProps> = ({
  children,
}) => {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={"tab0"}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default ReloadAnimation;
