import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useQuery } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import WalletIcon from "@mui/icons-material/Wallet";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";
import ErrorHandler from "../../components/ErrorHandler";

const BillingPaymentMethod = () => {
  const navigate = useNavigate();

  const { getPaymentCard } = useBillingApi();
  const authCtx = useContext(AuthContext);

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.paymentCard, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getPaymentCard();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const handleUpdateCardInformation = async () => {
    navigate(
      "/billing/payment-method/update?redirect_url=" + window.location.href
    );
  };

  if (isError) return <ErrorHandler url={"/billing"} />;
  if (isLoading) return <Loader />;
  return (
    <Container>
      <Paper sx={{ padding: 2 }}>
        <Typography
          align="center"
          sx={{
            paddingTop: "15px",
            fontSize: "15pt",
            fontWeight: 700,
          }}
        >
          支払い情報
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{
            paddingY: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={2}>
            <IconButton>
              <WalletIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: "4px" }}>
            <Typography sx={{ fontWeight: "600", fontSize: "0.8rem" }}>
              **** {data?.card?.last4} {data?.card?.brand}
            </Typography>
            <Typography
              sx={{ color: `rgba(0, 0, 0, 0.54)`, fontSize: "0.6rem" }}
            >
              有効期限： {("00" + data?.card?.expMonth).slice(-2)}/
              {data?.card?.expYear}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: "4px" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => handleUpdateCardInformation()}
            >
              <EditIcon style={{ fontSize: "0.8rem", marginRight: "5px" }} />
              <Typography sx={{ fontSize: "0.6rem", fontWeight: "600" }}>
                編集する
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default BillingPaymentMethod;
