import axios, { AxiosRequestConfig } from "axios";

export default class BaseApi {
  accessToken: string;

  constructor(accessToken?: string) {
    this.accessToken = accessToken || "";
  }

  setAccessToken(token: string) {
    this.accessToken = token;
  }

  protected get axiosApiConfig() {
    const API_URL = process.env.REACT_APP_API_KUROCO || "";
    return {
      baseURL: API_URL,
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: this.accessToken ? `Bearer ${this.accessToken}` : "",
      // },
      responseType: "json",
    } as AxiosRequestConfig;
  }

  protected get get() {
    return axios.create(this.axiosApiConfig).get;
  }

  protected get post() {
    return axios.create(this.axiosApiConfig).post;
  }

  protected get put() {
    return axios.create(this.axiosApiConfig).put;
  }

  protected get delete() {
    return axios.create(this.axiosApiConfig).delete;
  }
}
