import { Fragment } from "react";
import { Box, Paper, Typography } from "@mui/material";
import {
  RemainingBeverageItemType,
  RemainingBeveragesType,
} from "../../../types";
import BottleLevelItem from "./BottleLevelItem";
import BottomRefresher from "../BottomRefresher";

interface BottleLevelProps {
  data: RemainingBeveragesType;
  isError: boolean;
  isLoading: boolean;
  fetch: () => void;
}

const BottleLevel: React.FC<BottleLevelProps> = ({
  data,
  isError,
  isLoading,
  fetch,
}) => {
  return (
    <Paper sx={{ p: 2, my: 2, pb: 0.5 }}>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ color: "#9f1831", fontWeight: 600 }}>
          現在のボトル内飲料量
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1.5 }}>
        {!data || isLoading || isError ? (
          <>
            <BottleLevelItem />
            <BottleLevelItem />
            <BottleLevelItem />
            <BottleLevelItem />
            <BottleLevelItem />
          </>
        ) : (
          <>
            {data?.ranking?.map((item: RemainingBeverageItemType) => (
              <Fragment>
                <BottleLevelItem item={item} />
              </Fragment>
            ))}
          </>
        )}
      </Box>

      <BottomRefresher refetch={fetch} />
    </Paper>
  );
};

export default BottleLevel;
