import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Hidden,
  styled,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useDeviceApi } from "../../custom-hooks/apis/use-device-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import {
  FormatListBulleted,
  WineBar,
  ArrowBackIos,
  Refresh,
} from "@mui/icons-material";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import ListItem from "./ListItem";
import ErrorHandler from "../../components/ErrorHandler";
import TabDeviceSm from "../../components/DeviceTabs/TabDeviceSm";
import TabDeviceMd from "../../components/DeviceTabs/TabDeviceMd";
import TabDeviceLg from "../../components/DeviceTabs/TabDeviceLg";
import FillUpListItem from "./FillUpListItem";
import CleaningListItem from "./CleaningListItem";
import ExtractionVolumeListItem from "./ExtractionVolumeListItem";
import BottleReplacementListItem from "./BottleReplacementListItem";
import ReloadAnimation from "../../components/ReloadAnimation";
import ModalBottleExchange from "./ModalBottleExchange";
import {
  DeviceDetailMaintenanceType,
  DeviceDetailType,
  EdgeCommandParam,
  ModalDeviceParam,
  ModalDeviceType,
  SocketDeviceData,
} from "../../types";
import { toastError } from "../../utils/toaster";
import ModalConfirmFill from "./ModalConfirmFill";
import ModalConfirmCleaning from "./ModalConfirmCleaning";
import ModalConfirmExtraction from "./ModalConfirmExtraction";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const StoreDeviceList = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { getDevices, edgeCommand } = useDeviceApi();
  const { hasPermission } = usePermissions();

  const [tab, setTab] = useState<number>(0);
  const [connectionId, setConnectionId] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState<ModalDeviceParam | null>(
    null
  );
  const [socketData, setSocketData] = useState<SocketDeviceData | null>(null);
  const [list, setList] = useState<DeviceDetailType[]>([]);
  const websocket = useRef<null | WebSocket>(null);

  const tabs = [
    { label: "一覧", icon: <FormatListBulleted /> },
    { label: "充填", icon: <WineBar /> },
    { label: "洗浄", icon: <WineBar /> },
    { label: "抽出量", icon: <WineBar /> },
    { label: "ボトル交換", icon: <WineBar /> },
  ];

  const { isLoading, isError, refetch, isRefetching } = useQuery(
    [
      QueryKeys.storeDevices,
      authCtx.user?.signInUserSession.idToken,
      authCtx.user?.store_id,
    ],
    () => {
      if (authCtx.user?.signInUserSession.idToken && authCtx.user?.store_id)
        return getDevices({
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      onSuccess: (data) => {
        setList(data?.things);
      },
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const getEdgeCommandErrorMessage = (): string => {
    switch (selectedDevice?.type) {
      case ModalDeviceType.FILL:
        return "申し訳ありませんが、デバイスを充填することができませんでした。";
      case ModalDeviceType.CLEANING:
        return "申し訳ありませんが、デバイスを洗浄することができませんでした。";
      case ModalDeviceType.EXCTRACTION:
        return "申し訳ありませんが、デバイスを抽出量変更することができませんでした。";
      case ModalDeviceType.BOTTLE:
        return "申し訳ありませんが、デバイスを交換することができませんでした。";
      default:
        return "予期せぬエラーが発生しました。";
    }
  };

  const edgeCommandMutation = useMutation(
    (requestBody: EdgeCommandParam) => edgeCommand(requestBody),
    {
      onSuccess: async (data) => {
        console.log("🚀 ~ onSuccess: ~ selectedDevice:", selectedDevice);
        if (selectedDevice?.type !== ModalDeviceType.EXCTRACTION) {
          // update list state
          setList((prevList) =>
            prevList.map((item: DeviceDetailType) =>
              item.thingName === selectedDevice?.item?.thingName
                ? {
                    ...item,
                    maintenance_qr: DeviceDetailMaintenanceType.WAITING,
                  }
                : item
            )
          );
        }
      },
      onError: async (error: any) => {
        console.log("🚀 ~ onError: ~ error:", error);
        setSelectedDevice(null);
        let errMessage = getEdgeCommandErrorMessage();

        // if (String(error?.message).toLowerCase().includes("network error"))
        //   errMessage = "デバイスを更新できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const handleSubmitFillUp = (volume: number) => {
    let param: EdgeCommandParam = {
      command: {
        type: ModalDeviceType.FILL,
        body: {
          volume: volume,
        },
      },
      devices: [selectedDevice?.item?.thingName],
    };
    edgeCommandMutation.mutate(param);
  };

  const handleSubmitCleaning = () => {
    let param: EdgeCommandParam = {
      command: {
        type: ModalDeviceType.CLEANING,
        body: {},
      },
      devices: [selectedDevice?.item?.thingName],
    };
    edgeCommandMutation.mutate(param);
  };

  const handleSubmitExtractionVolume = () => {
    let param: EdgeCommandParam = {
      command: {
        type: ModalDeviceType.EXCTRACTION,
        body: { volume: parseFloat(selectedDevice?.option?.newVolume || "0") },
      },
      devices: [selectedDevice?.item?.thingName],
    };
    edgeCommandMutation.mutate(param);
  };

  const handleSubmitReset = (param: ModalDeviceParam) => {
    if (!param?.option?.type) return;
    console.log(param);
    let params: EdgeCommandParam = {
      command: {
        type: param.option?.type,
        body: {},
        reset: param?.option?.reset || "",
      },
      devices: [param?.item?.thingName],
    };
    edgeCommandMutation.mutate(params);
  };

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken || !authCtx.user?.store_id) return;

    let url = `wss://3y757tihmk.execute-api.ap-northeast-1.amazonaws.com/${
      process.env.NODE_ENV === "production"
        ? "prod"
        : process.env.REACT_APP_ENVNAME
    }?authorizationToken=${idToken}&customerStoreId=${authCtx.user?.store_id}`;
    // let url = `wss://3y757tihmk.execute-api.ap-northeast-1.amazonaws.com/${process.env.REACT_APP_ENVNAME}?authorizationToken=${idToken}`;

    websocket.current = new WebSocket(url);

    websocket.current.addEventListener("open", (event) => {
      console.log("connected");
    });

    websocket.current.addEventListener("message", (event) => {
      let socket_data = JSON.parse(event.data);
      setConnectionId(socket_data.connectionId);
      setSocketData(socket_data);
      console.log("🚀 ~ useEffect ~ socket_data:", socket_data);

      console.log(`connection id is ${socket_data.connectionId}`);
    });

    websocket.current.addEventListener("close", () => {
      console.log("disconnected");
    });

    websocket.current.addEventListener("error", (error) => {
      console.error("WebSocket Error:", error);
    });

    return () => {
      if (websocket?.current?.readyState === WebSocket.OPEN) {
        websocket.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (socketData && list.length > 0) {
      setList((prevList) =>
        prevList.map((item: DeviceDetailType) =>
          item.thingName === socketData?.device_name
            ? {
                ...item,
                maintenance_qr: socketData?.status?.maintenance_qr,
                working: socketData?.status?.working,
              }
            : item
        )
      );
      setSocketData(null);
    }
  }, [socketData, list]);

  if (isError) return <ErrorHandler url="/store" />;
  // if (isLoading) return <Loader />;
  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/store")}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: "#4F4F62",
              fontWeight: 600,
              fontSize: "1.2rem",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            のまっせ一覧
          </Typography>
          <IconButton
            size="small"
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => refetch()}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ px: 2 }}>
        <>
          <Hidden smUp>
            <TabDeviceSm tabs={tabs} selectedTab={tab} setTab={setTab} />
          </Hidden>
          <Hidden smDown mdUp>
            <TabDeviceMd tabs={tabs} selectedTab={tab} setTab={setTab} />
          </Hidden>
          <Hidden mdDown>
            <TabDeviceLg tabs={tabs} selectedTab={tab} setTab={setTab} />
          </Hidden>
        </>
        <>
          {tab === 0 && (
            <ReloadAnimation>
              <Box
                sx={{ marginTop: { md: "100px", lg: "150px", xl: "170px" } }}
              >
                {list &&
                  list.length > 0 &&
                  list.map((device: DeviceDetailType, index: number) => (
                    <Fragment key={index}>
                      {index > 0 && <Divider />}
                      <ListItem item={device} />
                    </Fragment>
                  ))}
              </Box>
            </ReloadAnimation>
          )}
          {tab === 1 && (
            <ReloadAnimation>
              <Box
                sx={{ marginTop: { md: "100px", lg: "150px", xl: "170px" } }}
              >
                {list &&
                  list.length > 0 &&
                  list.map((device: DeviceDetailType, index: number) => (
                    <Fragment key={index}>
                      <FillUpListItem
                        item={device}
                        setItem={setSelectedDevice}
                      />
                    </Fragment>
                  ))}
              </Box>
            </ReloadAnimation>
          )}
          {tab === 2 && (
            <ReloadAnimation>
              <Box
                sx={{ marginTop: { md: "100px", lg: "150px", xl: "170px" } }}
              >
                {list &&
                  list.length > 0 &&
                  list.map((device: DeviceDetailType, index: number) => (
                    <Fragment key={index}>
                      <CleaningListItem
                        item={device}
                        setItem={setSelectedDevice}
                      />
                    </Fragment>
                  ))}
              </Box>
            </ReloadAnimation>
          )}
          {tab === 3 && (
            <ReloadAnimation>
              <Box
                sx={{ marginTop: { md: "100px", lg: "150px", xl: "170px" } }}
              >
                {list &&
                  list.length > 0 &&
                  list.map((device: DeviceDetailType, index: number) => (
                    <Fragment key={index}>
                      <ExtractionVolumeListItem
                        item={device}
                        setItem={setSelectedDevice}
                      />
                    </Fragment>
                  ))}
              </Box>
            </ReloadAnimation>
          )}
          {tab === 4 && (
            <ReloadAnimation>
              <Box
                sx={{ marginTop: { md: "100px", lg: "150px", xl: "170px" } }}
              >
                {list &&
                  list.length > 0 &&
                  list.map((device: DeviceDetailType, index: number) => (
                    <Fragment key={index}>
                      <BottleReplacementListItem
                        item={device}
                        setItem={setSelectedDevice}
                      />
                    </Fragment>
                  ))}
              </Box>
            </ReloadAnimation>
          )}
          <Grid sx={{ marginY: 3, display: "flex", justifyContent: "center" }}>
            <Link
              to="/contact-us"
              style={{
                fontSize: "14px",
                textUnderlineOffset: "0.5em",
                color: "#4F4F62",
                fontWeight: 600,
              }}
            >
              店舗設置のまっせの移動・返却のお問い合わせ
            </Link>
          </Grid>
        </>
        {selectedDevice?.type === ModalDeviceType.FILL && (
          <ModalConfirmFill
            item={selectedDevice}
            isLoading={edgeCommandMutation.isLoading}
            setItem={setSelectedDevice}
            onSubmit={handleSubmitFillUp}
          />
        )}
        {selectedDevice?.type === ModalDeviceType.CLEANING && (
          <ModalConfirmCleaning
            item={selectedDevice}
            isLoading={edgeCommandMutation.isLoading}
            setItem={setSelectedDevice}
            onSubmit={handleSubmitCleaning}
          />
        )}
        {selectedDevice?.type === ModalDeviceType.EXCTRACTION && (
          <ModalConfirmExtraction
            item={selectedDevice}
            isLoading={edgeCommandMutation.isLoading}
            setItem={setSelectedDevice}
            onSubmit={handleSubmitExtractionVolume}
          />
        )}
        {selectedDevice?.type === ModalDeviceType.BOTTLE && (
          <ModalBottleExchange
            item={selectedDevice}
            isLoading={edgeCommandMutation.isLoading}
            setItem={setSelectedDevice}
            onSubmit={handleSubmitReset}
          />
        )}
        {(isLoading || isRefetching) && <Loader />}
      </Box>
    </>
  );
};

export default StoreDeviceList;
