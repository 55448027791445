import React from "react";
import { Box, Checkbox, Grid, Paper, Typography } from "@mui/material";
import { DeviceDetailType } from "../../types";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import FormLabelDefault from "../../components/FormLabelDefault";
import DeviceDetailMaintenance from "../StoreDeviceList/DeviceDetailMaintenance";
import { isOutVersion } from "../../utils/helper";

interface DeviceItemProps {
  item: DeviceDetailType;
  isDisabled: boolean;
  reboot: (id: string) => void;
  onChange: (isChecked: boolean, id: string) => void;
}

const DeviceItem: React.FC<DeviceItemProps> = ({
  item,
  isDisabled,
  reboot,
  onChange,
}) => {
  return (
    <Paper sx={{ px: 1, py: 2.5, my: 1, position: "relative" }} elevation={3}>
      <FormLabelDefault
        control={
          <Checkbox
            value={item.thingName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange(event.target.checked, item.thingName)
            }
          />
        }
        label={
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
              height: "65px",
            }}
          >
            <Grid xs={8}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item?.thingNickname || item?.thingName}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "0.6rem",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
              >
                最終再起動日時：2024/03/04 00:00 etc
              </Typography> */}
            </Grid>
            <Grid
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ButtonSubmit
                type="button"
                sx={{ py: 2.5 }}
                disabled={isDisabled}
                onClick={() => {
                  reboot(item.thingName);
                }}
              >
                再起動
              </ButtonSubmit>
            </Grid>
          </Grid>
        }
        sx={{
          margin: 0,
          width: 1,
          "& .MuiFormControlLabel-label": {
            width: 1,
          },
        }}
      />

      <DeviceDetailMaintenance
        status={item?.maintenance_qr}
        isOutVersion={isOutVersion(
          item.softVersion,
          process.env.REACT_APP_OUT_VERSION || "0.55"
        )}
        sx={{ height: "105px" }}
      />
    </Paper>
  );
};

export default DeviceItem;
