import { useContext } from "react";
import { Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";
import { useMutation } from "react-query";
import { toastError, toastSuccess } from "../../utils/toaster";
import { CreateTicketRawParamsType } from "../../types";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import moment from "moment";
import TicketForm from "../../components/TicketForm";
import AuthContext from "../../custom-hooks/use-auth-context";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreTicketSettingsCreate = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { createTicket } = useTicketApi();
  const { hasPermission } = usePermissions();

  const createTicketMutation = useMutation(
    (requestBody: CreateTicketRawParamsType) => createTicket(requestBody),
    {
      onSuccess: async (data: any) => {
        toastSuccess(data?.message);
        navigate("/store/tickets");
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage =
            "新しいチケットを作成できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    if (!authCtx.user?.store_id) return;

    let params: CreateTicketRawParamsType = {
      customer_store_id: authCtx.user?.store_id,
      ticket_name: data?.ticketName,
      ticket_price: data?.ticketPrice,
      ticket_type: data?.ticketType,
      ticket_is_show: data?.ticketIsShow,
      ticket_type_usage: data?.ticketTypeUsage,
      ticket_permission: data?.ticketPermission,
      ticket_lifespan_type: data?.ticketLifespanType,
      ticket_currency: data?.ticketCurrency,
      ticket_purchase_period: data?.ticketIsPurchasePeriod ? 1 : 0,
    };

    if (data?.ticketLifespanType === "0") {
      params = {
        ...params,
        ticket_lifespan_unit_num: data?.ticketLifespanUnitNum,
        ticket_lifespan_unit_type: data?.ticketLifespanUnitType,
        ticket_lifespan_start: null,
        ticket_lifespan_end: null,
      };
    } else if (data?.ticketLifespanType === "1") {
      params = {
        ...params,
        ticket_lifespan_unit_num: null,
        ticket_lifespan_unit_type: null,
        ticket_lifespan_start: data?.ticketLifespanStart
          ? moment(data?.ticketLifespanStart).unix()
          : null,
        ticket_lifespan_end: data?.ticketLifespanEnd
          ? moment(data?.ticketLifespanEnd).unix()
          : null,
      };
    } else if (data?.ticketLifespanType === "3") {
      params = {
        ...params,
        ticket_lifespan_unit_num: null,
        ticket_lifespan_unit_type: null,
        ticket_lifespan_start: null,
        ticket_lifespan_end: null,
      };
    }
    if (data?.ticketIsPurchasePeriod) {
      params = {
        ...params,
        ticket_purchase_period_start: data?.ticketPurchasePeriodStart
          ? moment(data?.ticketPurchasePeriodStart).unix()
          : null,
        ticket_purchase_period_end: data?.ticketPurchasePeriodEnd
          ? moment(data?.ticketPurchasePeriodEnd).unix()
          : null,
      };
    }
    if (data?.groupNames) params = { ...params, valid_group: data?.groupNames };

    if (data?.ticketType === "coupon")
      params = { ...params, ticket_quantity: data?.ticketQuantity };

    createTicketMutation.mutate(params);
  };

  return (
    <Container>
      <ReloadAnimation>
        <TicketForm
          onSubmit={onSubmit}
          isDisabled={!hasPermission("ticket.create")}
          loading={createTicketMutation.isLoading}
        />
      </ReloadAnimation>
    </Container>
  );
};

export default StoreTicketSettingsCreate;
