import { useContext, useState } from "react";
import { FormHelperText, Grid, Paper, Typography } from "@mui/material";
import { QR_SAMPLE } from "../../assets/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { useMutation } from "react-query";
import { UpdateIssuedTicketLabelType, UploadImageType } from "../../types";
import { useNavigate } from "react-router-dom";
import { LOGO, LOGO_SECOND } from "../../assets";
import { useImageApi } from "../../custom-hooks/apis/use-image-api";
import { toastError, toastSuccess } from "../../utils/toaster";
import * as Yup from "yup";
import IconUploader from "./IconUploader";
import ReceipTabs from "./ReceipTabs";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import ImagePreview from "../../components/ImagePreview";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import ReloadAnimation from "../../components/ReloadAnimation";

const ReceiptLabelEditing = () => {
  const navigate = useNavigate();
  const { loading } = useContext(GlobalDataContext);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [topImage, setTopImage] = useState<any | null>(null);
  const [bottomImage, setBottomImage] = useState<any | null>(null);
  const { updateIssuedTicketLabel } = useTicketApi();
  const { uploadImage } = useImageApi();
  const authCtx = useContext(AuthContext);

  const uploadImageMutation = useMutation(
    (requestBody: UploadImageType) => uploadImage(requestBody),
    {
      onSuccess: async () => {
        toastSuccess("画像のアップロードに成功しました。。");
        // navigate(`/store/issued-tickets`);
      },
      onError: async (error: any) => {
        console.log("🚀 ~ onError: ~ error:", error);
        toastError("申し訳ありませんが、画像のアップロードに失敗しました。");
      },
    }
  );

  const updateIssuedTicketLabelMutation = useMutation(
    (requestBody: UpdateIssuedTicketLabelType) =>
      updateIssuedTicketLabel(requestBody),
    {
      onSuccess: async (data) => {
        if (imageA) {
          const file = new File([imageA], "top");
          const formData = new FormData();
          formData.append("key", data?.top?.params?.key);
          formData.append("content-type", "image/png");
          formData.append("policy", data?.top?.params?.policy);
          formData.append(
            "x-amz-algorithm",
            data?.top?.params["x-amz-algorithm"]
          );
          formData.append(
            "x-amz-credential",
            data?.top?.params["x-amz-credential"]
          );
          formData.append("x-amz-date", data?.top?.params["x-amz-date"]);
          formData.append(
            "x-amz-security-token",
            data?.top?.params["x-amz-security-token"]
          );
          formData.append(
            "x-amz-signature",
            data?.top?.params["x-amz-signature"]
          );
          formData.append("file", file);
          let params: UploadImageType = {
            url: data?.top?.url,
            body: formData,
          };
          uploadImageMutation.mutate(params);
        }
        if (imageB) {
          const file = new File([imageB], "bottom");
          const formData = new FormData();
          formData.append("key", data?.bottom?.params?.key);
          formData.append("content-type", "image/png");
          formData.append("policy", data?.bottom?.params?.policy);
          formData.append(
            "x-amz-algorithm",
            data?.bottom?.params["x-amz-algorithm"]
          );
          formData.append(
            "x-amz-credential",
            data?.bottom?.params["x-amz-credential"]
          );
          formData.append("x-amz-date", data?.bottom?.params["x-amz-date"]);
          formData.append(
            "x-amz-security-token",
            data?.bottom?.params["x-amz-security-token"]
          );
          formData.append(
            "x-amz-signature",
            data?.bottom?.params["x-amz-signature"]
          );
          formData.append("file", file);
          let params: UploadImageType = {
            url: data?.bottom?.url,
            body: formData,
          };
          uploadImageMutation.mutate(params);
        }
      },
      onError: async (error: any) => {
        console.log("🚀 ~ onError: ~ error:", error);
        toastError(
          "申し訳ありませんが、チケットラベル編集することができませんでした。"
        );
      },
    }
  );

  const schema = Yup.object().shape({
    imageA: Yup.mixed()
      .test("fileSize", "画像サイズは5MB以下であることです", (value: any) => {
        if (!value) return true;
        return value?.size <= 5 * 1024 * 1024;
      })
      .optional(),
    imageB: Yup.mixed()
      .test("fileSize", "画像サイズは5MB以下であることです", (value: any) => {
        if (!value) return true;
        return value?.size <= 5 * 1024 * 1024;
      })
      .optional(),
  });

  const defaultValues = {
    imageA: "",
    imageB: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const { imageA, imageB } = watch();

  const onSubmit = async (data: FieldValues) => {
    if (authCtx?.user && authCtx.user?.store_id) {
      let params: UpdateIssuedTicketLabelType = {
        top: { imageContentType: data?.imageA ? "png" : "" },
        bottom: { imageContentType: data?.imageB ? "png" : "" },
        customerStoreId: authCtx.user?.store_id,
      };
      updateIssuedTicketLabelMutation.mutate(params);
    }
  };
  return (
    <ReloadAnimation>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid sx={{ px: 2, pb: 12 }}>
          <ReceipTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          <Paper sx={{ my: 2, p: 2 }}>
            <Grid
              sx={
                {
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: 1,
                  mb: 1,
                }
              }
            >
              <Typography
                sx={{ fontWeight: 600, fontSize: "0.8rem" }}
              >
                YYYYMMDD-XXXXX
              </Typography>
            </Grid>
            <Grid
              sx={
                selectedTab === 1 && !imageA
                  ? {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }
                  : {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 8,
                    mb: 4,
                  }
              }
            >
              {selectedTab === 0 && (
                <IconUploader
                  img={topImage}
                  onChange={(blob: any, image: any) => {
                    setValue("imageA", blob);
                    setTopImage(image);
                  }}
                />
              )}
              {selectedTab === 1 && (
                <ImagePreview
                  img={topImage || LOGO_SECOND}
                  height={80}
                  width={80}
                />
              )}
              {!!errors?.imageA?.message && (
                <FormHelperText error id="imageA_helper">
                  <>{errors?.imageA?.message}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <img
                src={QR_SAMPLE}
                height={250}
                width={250}
                style={{ opacity: 0.5 }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "#9B1E2D",
                  fontWeight: 600,
                  fontSize: "2.5rem",
                  zIndex: 100,
                }}
              >
                SAMPLE
              </Typography>
            </Grid>

            <Grid sx={{ my: 2 }}>
              <Typography align="center">有効期限：YYYY/MM/DD hh:mm</Typography>
            </Grid>

            <Grid sx={{ my: 4 }}>
              <Typography
                align="center"
                sx={{ fontWeight: 600, fontSize: "1.2rem" }}
              >
                店舗名サンプル
              </Typography>
              <Typography
                align="center"
                sx={{ fontWeight: 600, fontSize: "1.2rem" }}
              >
                チケット名サンプル
              </Typography>
            </Grid>

            <Grid sx={{ my: 2, display: "flex", justifyContent: "center" }}>
              <Typography align="center" sx={{ width: 0.85 }}>
                このチケットは当店限り有効となります。他ののまっせ設置店ではご利用いただけません。
              </Typography>
            </Grid>

            <Grid
              sx={
                selectedTab === 1 && !imageB
                  ? {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }
                  : {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    my: 8,
                  }
              }
            >
              {selectedTab === 0 && (
                <IconUploader
                  img={bottomImage}
                  onChange={(blob: any, image: any) => {
                    setValue("imageB", blob);
                    setBottomImage(image);
                  }}
                />
              )}
              {selectedTab === 1 && (
                <ImagePreview
                  img={bottomImage || LOGO}
                  width={80}
                  height={80}
                />
              )}
              {!!errors?.imageB?.message && (
                <FormHelperText error id="imageB_helper">
                  <>{errors?.imageB?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Paper>

          <ButtonSubmit
            type="submit"
            sx={{ width: 1, py: 1.5, boxShadow: 10, fontSize: "1.2rem" }}
          >
            設定
          </ButtonSubmit>
        </Grid>
        {(updateIssuedTicketLabelMutation.isLoading ||
          uploadImageMutation.isLoading) && <Loader />}
      </form>
    </ReloadAnimation>
  );
};

export default ReceiptLabelEditing;
