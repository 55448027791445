import React from "react";
import { Grid, Typography } from "@mui/material";
import ReceiptTabItem from "./ReceiptTabItem";

interface ReceipTabsProps {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

const ReceipTabs: React.FC<ReceipTabsProps> = ({
  selectedTab,
  setSelectedTab,
}) => {
  return (
    <Grid
      container
      sx={{
        borderRadius: "40px",
        backgroundColor: "#fff",
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      }}
    >
      <ReceiptTabItem
        label="編集"
        selected={selectedTab === 0}
        setSelectedTab={() => setSelectedTab(0)}
      />
      <ReceiptTabItem
        label="イメージ"
        selected={selectedTab === 1}
        setSelectedTab={() => setSelectedTab(1)}
      />
    </Grid>
  );
};

export default ReceipTabs;
