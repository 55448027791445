import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";

interface NotificationTabItemProps {
  selected: boolean;
  label: string;
  unreadCount: number;
  setSelectedTab: () => void;
}

const NotificationTabItem: React.FC<NotificationTabItemProps> = ({
  label,
  selected,
  unreadCount,
  setSelectedTab,
}) => {
  const defaultCss = {
    px: 1,
    py: 1.8,
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
  };
  const activeCss = {
    background: "#9F1831",
    color: "#fff",
  };

  return (
    <Grid
      item
      xs={6}
      sx={selected ? { ...activeCss, ...defaultCss } : defaultCss}
      onClick={setSelectedTab}
    >
      {selected && (
        <motion.div
          layoutId="active-pill"
          style={{
            position: "absolute",
            inset: "0",
            borderRadius: "20px",
            backgroundColor: "#9f1831",
          }}
          transition={{ type: "spring", duration: 1 }}
        />
      )}
      <Typography
        sx={{ fontSize: "0.8rem", fontWeight: 600, position: "relative" }}
      >
        {label}
      </Typography>
      {selected && unreadCount > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            ml: 2.5,
            background: "#fff",
            borderRadius: "50%",
            mt: -1,
            right: 5,
            zIndex: 1000,
            px: unreadCount < 10 ? "8px" : unreadCount < 100 ? "4px" : "1px",
          }}
        >
          <Typography
            sx={{ color: "#9f1831", fontWeight: 600, fontSize: "0.8rem" }}
          >
            {unreadCount < 100 ? unreadCount : "99+"}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default NotificationTabItem;
