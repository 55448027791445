import { useCallback, useContext } from "react";
import { UploadImageType } from "../../types";
import axios from "axios";
import GlobalDataContext from "../use-global-data";

export const useImageApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const uploadImage = useCallback(async (params: UploadImageType) => {
    loadingOp.start();
    const response = await axios.post(params.url, params.body);
    loadingOp.finish();
    return response;
  }, []);

  return {
    uploadImage,
  };
};
