import { useContext } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useTicketApi } from "../../custom-hooks/apis/use-ticket-api";
import { usePermissions } from "../../custom-hooks/use-permissions";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import StoreTicketSettingsDetailItem from "./StoreTicketSettingsDetailItem";
import moment from "moment";
import ErrorHandler from "../../components/ErrorHandler";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreTicketSettingsDetail = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const [t] = useTranslation();
  const { id } = useParams();
  const { getTicket } = useTicketApi();
  const { hasPermission } = usePermissions();

  const { isLoading, data, isError } = useQuery(
    [
      QueryKeys.storeTicket,
      authCtx.user?.signInUserSession.idToken,
      id,
      authCtx.user?.store_id,
    ],
    () => {
      if (
        authCtx.user?.signInUserSession.idToken &&
        id &&
        authCtx.user?.store_id
      )
        return getTicket({
          product_ticket_id: id,
          customer_store_id: authCtx.user?.store_id,
        });
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isError) return <ErrorHandler url="/store/tickets" />;
  if (isLoading) return <Loader />;
  return data?.ticket ? (
    <ReloadAnimation>
      <Box sx={{ paddingX: 2 }}>
        <Grid sx={{ my: 2 }}>
          <ButtonSubmit
            fullWidth
            sx={{
              paddingY: 1.5,
              paddingLeft: 2.5,
              // justifyContent: "flex-start",
              color: "#fff",
              fontWeight: 600,
            }}
            onClick={() => {
              navigate(`/store/tickets/${id}/update`);
            }}
            disabled={!hasPermission("ticket.update")}
          >
            チケットを変更する
          </ButtonSubmit>
        </Grid>
        <Paper sx={{ paddingY: 2 }}>
          <StoreTicketSettingsDetailItem
            label="チケット状況"
            value={t(data?.ticket?.ticketStatus)}
          />
          {/* <StoreTicketSettingsDetailItem
          label="チケットID"
          value={data?.ticket?.ticketId}
        /> */}
          <StoreTicketSettingsDetailItem
            label="チケット名"
            value={data?.ticket?.ticketName}
          />
          <StoreTicketSettingsDetailItem
            label="タイプ"
            value={t(data?.ticket?.ticketType)}
          />
          <StoreTicketSettingsDetailItem
            label="金額"
            value={`${data?.ticket?.ticketPrice}円`}
          />
          {data?.ticket?.ticketType === "coupon" && (
            <StoreTicketSettingsDetailItem
              label="券杯数"
              value={data?.ticket?.ticketQuantity}
            />
          )}
          <StoreTicketSettingsDetailItem
            label="有効期限"
            value={
              data?.ticket?.ticketLifespanType === 0 ? (
                <>
                  決済完了後 {data?.ticket?.ticketLifespanUnitNum}
                  {data?.ticket?.ticketLifespanUnitType === 0 && <>年間</>}
                  {data?.ticket?.ticketLifespanUnitType === 1 && <>か月間</>}
                  {data?.ticket?.ticketLifespanUnitType === 2 && <>日間</>}
                  {data?.ticket?.ticketLifespanUnitType === 3 && <>時間</>}
                  {data?.ticket?.ticketLifespanUnitType === 4 && <>分間</>}
                </>
              ) : data?.ticket?.ticketLifespanType === 1 ? (
                <>
                  {moment
                    .unix(data?.ticket?.ticketLifespanStart)
                    .format("YYYY/MM/DD HH:mm")}{" "}
                  ~{" "}
                  {moment
                    .unix(data?.ticket?.ticketLifespanEnd)
                    .format("YYYY/MM/DD HH:mm")}
                </>
              ) : data?.ticket?.ticketLifespanType === 3 ? (
                <>期限なし</>
              ) : (
                <></>
              )
            }
          />
        </Paper>
        {loading && <Loader />}
      </Box>
    </ReloadAnimation>
  ) : (
    <ReloadAnimation>
      <Box
        sx={{
          height: `calc(100vh - 300px)`,
          marginX: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography align="center" sx={{ fontWeight: 550 }}>
            チケットが見つかりません
          </Typography>

          <Typography
            align="center"
            sx={{
              width: 1,
              marginX: "auto",
              marginY: 4,
              fontSize: "0.8rem",
            }}
          >
            再度この画面を表示し直してください。
          </Typography>
        </Box>
      </Box>
    </ReloadAnimation>
  );
};

export default StoreTicketSettingsDetail;
