import React from "react";
import { Box, Paper, Typography } from "@mui/material";

interface StoreMenuItemMdProps {
  title: string;
  icon: JSX.Element;
  isDisabled: boolean;
  handleClick: () => void;
}

const StoreMenuItemMd: React.FC<StoreMenuItemMdProps> = ({
  title,
  icon,
  isDisabled,
  handleClick,
}) => {
  return (
    <Paper
      onClick={isDisabled ? () => {} : handleClick}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        opacity: isDisabled ? 0.65 : 1,
        p: 2.5,
      }}
    >
      <Typography sx={{ color: "#9F1831", fontWeight: 600, fontSize: "1rem" }}>
        {title}
      </Typography>
      <Box>{icon}</Box>
    </Paper>
  );
};

export default StoreMenuItemMd;
