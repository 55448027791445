import { Fragment } from "react";
import { Box, Container, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CELL_PHONE_ICON,
  MULTI_CELL_PHONE_ICON,
  STORE_ICON,
  TICKET_ICON,
  PRINT_ICON,
  STATISTIC_ICON,
} from "../../assets/icons";
import { usePermissions } from "../../custom-hooks/use-permissions";
import StoreMenuItemSm from "./StoreMenuItemSm";
import StoreMenuItemMd from "./StoreMenuItemMd";
import ReloadAnimation from "../../components/ReloadAnimation";

const StoreDetailMenu = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const menuItems = [
    {
      title: "のまっせ設定",
      icon: (
        <img
          src={CELL_PHONE_ICON}
          style={{ height: "40px", maxWidth: "40px" }}
        />
      ),
      isDisabled: !hasPermission("device.read"),
      handleClick: () => {
        navigate(`/store/devices`);
      },
    },
    {
      title: "店舗Eka発行",
      icon: (
        <img src={PRINT_ICON} style={{ height: "40px", maxWidth: "40px" }} />
      ),
      isDisabled: !hasPermission("device.read"),
      handleClick: () => {
        navigate(`/store/issued-tickets`);
      },
    },
    {
      title: "グループ設定",
      icon: (
        <img
          src={MULTI_CELL_PHONE_ICON}
          style={{ height: "40px", maxWidth: "40px" }}
        />
      ),
      isDisabled: !hasPermission("group.read"),
      handleClick: () => {
        navigate(`/store/groups`);
      },
    },
    {
      title: "データ閲覧",
      icon: (
        <img
          src={STATISTIC_ICON}
          style={{ height: "40px", maxWidth: "40px" }}
        />
      ),
      // isDisabled: !hasPermission("beverage_data.read"),
      isDisabled: false,
      handleClick: () => {
        navigate(`/store/data`);
      },
    },
    {
      title: "チケット設定",
      icon: (
        <img src={TICKET_ICON} style={{ height: "40px", maxWidth: "40px" }} />
      ),
      isDisabled: !hasPermission("ticket.read"),
      handleClick: () => {
        navigate(`/store/tickets`);
      },
    },
    {
      title: "店舗設定",
      icon: (
        <img src={STORE_ICON} style={{ height: "40px", maxWidth: "40px" }} />
      ),
      isDisabled: !hasPermission("settings.read"),
      handleClick: () => {
        navigate(`/store/settings`);
      },
    },
  ];

  return (
    <Container>
      <ReloadAnimation>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
          }}
        >
          {menuItems.map((item, index) => (
            <Fragment key={index}>
              <Hidden smUp>
                <StoreMenuItemSm
                  icon={item?.icon}
                  title={item?.title}
                  isDisabled={item?.isDisabled}
                  handleClick={item?.handleClick}
                  key={`store_menu_${index}`}
                />
              </Hidden>
              <Hidden smDown>
                <StoreMenuItemMd
                  icon={item?.icon}
                  title={item?.title}
                  isDisabled={item?.isDisabled}
                  handleClick={item?.handleClick}
                  key={`store_menu_${index}`}
                />
              </Hidden>
            </Fragment>
          ))}
        </Box>
      </ReloadAnimation>
    </Container>
  );
};

export default StoreDetailMenu;
