import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { CAMERA_ICON } from "../../assets/icons";
import {
  storeLocationCityValidation,
  storeLocationStateValidation,
  storeNameValidation,
  storeRegionValidation,
  storeSubnameValidation,
  storeTypeValidation,
} from "../../utils/validations";
import Loader from "../Loader";
import ImageUploader from "../ImageUploader";
import BootstrapInput from "../Inputs/BootstrapInput";
import ButtonSubmit from "../Buttons/ButtonSubmit";

interface StoreFormProps {
  data?: any;
  isLoading: boolean;
  isDisabled: boolean;
  submit: (data: FieldValues) => void;
}

const StoreForm: React.FC<StoreFormProps> = ({
  data,
  isLoading,
  isDisabled,
  submit,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imgSrc, setImgSrc] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  function validateBase64ImageSize(
    base64Image: any,
    maxSizeInBytes: number
  ): boolean {
    if (!base64Image || base64Image.startsWith("http")) return true;

    const decodedImage = atob(base64Image.split(",")[1]);
    const sizeInBytes = decodedImage.length;
    const sizeInKb = sizeInBytes / 1024;
    return sizeInKb <= maxSizeInBytes / 1024;
  }

  const schema = Yup.object().shape({
    storeName: storeNameValidation,
    storeSubname: storeSubnameValidation,
    storeType: storeTypeValidation,
    storeRegion: storeRegionValidation,
    storeLocationState: storeLocationStateValidation,
    storeLocationCity: storeLocationCityValidation,
    storeImage: Yup.string()
      .trim()
      .test("fileSize", "画像サイズは5MB以下であること", (value) => {
        return validateBase64ImageSize(value, 5 * 1024 * 1024);
      })
      .test("is-base64", "無効な店舗イメージ画像", function (value) {
        if (!value) {
          return true; // Let Yup's 'required' validation handle empty values
        }
        if (value.startsWith("http")) return true;

        const regex = /^data:image\/([a-zA-Z]*);base64,([^\s]*)$/;
        if (!regex.test(value)) {
          return this.createError({
            message: "無効な店舗イメージ画像",
          });
        }

        return true;
      })
      .optional(),
  });

  useEffect(() => {
    if (data?.id && data?.storeImage) setImgSrc(data?.storeImage);
  }, [data?.storeImage]);

  const defaultValues = {
    storeName: data?.storeName,
    storeSubname: data?.storeSubname,
    storeType: data?.storeType,
    storeRegion: data?.storeRegion,
    storeLocationState: data?.storeLocationState,
    storeLocationCity: data?.storeLocationCity,
    storeImage: data?.storeImage,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  interface SelectOptions {
    label: string;
    value: string;
  }

  const storeRegionOptions: SelectOptions[] = [
    { label: "日本", value: "日本" },
  ];
  const storeLocationStateOptions: SelectOptions[] = [
    { label: "東京都", value: "東京都" },
    { label: "会津若松市大町", value: "会津若松市大町" },
    { label: "渋谷交差点", value: "渋谷交差点" },
  ];
  const storeLocationCityOptions: SelectOptions[] = [
    { label: "渋谷区", value: "渋谷区" },
    { label: "福島県", value: "福島県" },
  ];

  const getStoreRegionOptObj = (option: any) => {
    if (!option?.label)
      option = storeRegionOptions.find((op: any) => op.label === option.label);
    return option;
  };

  const getStoreLocationStateOptObj = (option: any) => {
    if (!option?.label)
      option = storeLocationStateOptions.find(
        (op: any) => op.label === option.label
      );
    return option;
  };

  const getStoreLocationCityOptObj = (option: any) => {
    if (!option?.label)
      option = storeLocationCityOptions.find(
        (op: any) => op.label === option.label
      );
    return option;
  };

  const convertBlobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const base64Image = reader.result;
      setValue("storeImage", base64Image);
    };
  };

  const handleUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const createBlob = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const blob = new Blob([reader.result as ArrayBuffer], {
          type: file.type,
        });
        resolve(blob);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (files && files?.length > 0) {
      const file = files[0];
      if (file && file.size < 1024 * 1024 * 5) {
        const blob = await createBlob(file);
        setImgSrc(URL.createObjectURL(blob));
        setOpen(true);
      } else {
        alert("ファイルサイズが大きすぎる");
      }
    }
  };

  const formValues = watch();

  const onSubmit = async (data: FieldValues) => {
    submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ paddingX: 2, paddingTop: "20px" }}>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="storeName" sx={{ paddingY: 1 }}>
                  店舖名
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="storeName"
                  error={!!errors?.storeName?.message}
                />
              </FormControl>
            )}
            name="storeName"
            control={control}
          />
          {!!errors?.storeName?.message && (
            <FormHelperText error id="storeName_helper">
              <>{errors?.storeName?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="storeSubname" sx={{ paddingY: 1 }}>
                  サプ店舗名 ※支店名など
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="storeSubname"
                  error={!!errors?.storeSubname?.message}
                />
              </FormControl>
            )}
            name="storeSubname"
            control={control}
          />
          {!!errors?.storeSubname?.message && (
            <FormHelperText error id="storeSubname_helper">
              <>{errors?.storeSubname?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="storeType" sx={{ paddingY: 1 }}>
                  店舖形態
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="storeType"
                  error={!!errors?.storeType?.message}
                />
              </FormControl>
            )}
            name="storeType"
            control={control}
          />
          {!!errors?.storeType?.message && (
            <FormHelperText error id="storeType_helper">
              <>{errors?.storeType?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="storeRegion" sx={{ paddingY: 1 }}>
                  店舗の地域（国）
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="storeRegion"
                  error={!!errors?.storeRegion?.message}
                />
              </FormControl>
            )}
            name="storeRegion"
            control={control}
          />
          {!!errors?.storeRegion?.message && (
            <FormHelperText error id="storeRegion_helper">
              <>{errors?.storeRegion?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor="storeLocationState"
                  sx={{ paddingY: 1 }}
                >
                  店舗の地域（都道府県）
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="storeLocationState"
                  error={!!errors?.storeLocationState?.message}
                />
              </FormControl>
            )}
            name="storeLocationState"
            control={control}
          />
          {!!errors?.storeLocationState?.message && (
            <FormHelperText error id="storeLocationState_helper">
              <>{errors?.storeLocationState?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid sx={{ paddingY: 1 }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor="storeLocationCity"
                  sx={{ paddingY: 1 }}
                >
                  店舗の地域（市区町村）
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  id="storeLocationCity"
                  error={!!errors?.storeLocationCity?.message}
                />
              </FormControl>
            )}
            name="storeLocationCity"
            control={control}
          />
          {!!errors?.storeLocationCity?.message && (
            <FormHelperText error id="storeLocationCity_helper">
              <>{errors?.storeLocationCity?.message}</>
            </FormHelperText>
          )}
        </Grid>

        {/* start of image uploader */}
        <Grid
          sx={{
            paddingTop: 2,
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>店舗イメージ画像</Typography>
          <Typography sx={{ fontSize: "10px", color: "#7E7E7E" }}>
            以下のイメージをタップして画像を選択してください
          </Typography>

          <Box
            sx={{
              marginY: 2,
              backgroundColor: "#9f1831",
              opacity: 0.7,
              height: "220px",
              position: "relative",
            }}
            style={
              imgSrc
                ? {
                    backgroundImage: `url(${imgSrc})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }
                : {
                    backgroundImage: `url(${CAMERA_ICON})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "50px",
                  }
            }
            onClick={handleUploadFile}
          >
            <Paper
              sx={{
                position: "absolute",
                bottom: 0,
                width: 0.95,
                margin: 1,
                padding: 1,
              }}
            >
              <input
                type="file"
                accept="image/jpeg,image/png"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
                onFocus={handleFileInputChange}
              />
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "#7E7E7E",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
              >
                {formValues.storeLocationState || "都道府県"} &nbsp;&nbsp;&nbsp;
                {formValues.storeLocationCity || "市区町村"}&nbsp;&nbsp;&nbsp;
                {formValues.storeRegion ? ` / ${formValues.storeRegion}` : "国"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                }}
              >
                {formValues.storeName || "店舖名"}　
                {formValues.storeSubname || "サブ店舗名"}
              </Typography>
            </Paper>
          </Box>
          {!!errors?.storeImage?.message && (
            <FormHelperText error id="storeImage_helper">
              <>{errors?.storeImage?.message}</>
            </FormHelperText>
          )}
        </Grid>
        {open && (
          <ImageUploader
            src={imgSrc}
            setImgSrc={(blobURI: any, blob: any) => {
              setImgSrc(blobURI);
              convertBlobToBase64(blob);
            }}
            open={open}
            handleClose={() => {
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
              setOpen(false);
            }}
          />
        )}
        {/* end of image uploader */}
        <Grid sx={{ paddingBottom: 4 }}>
          <ButtonSubmit
            type="submit"
            sx={{ width: 1 }}
            disabled={isLoading || isDisabled}
          >
            登録
          </ButtonSubmit>
        </Grid>
        {isLoading && <Loader />}
      </Paper>
    </form>
  );
};

export default StoreForm;
