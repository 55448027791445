import { Fragment } from "react";
import { AppBar, Box, Container, Grid, Hidden, IconButton, Paper, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    CELL_PHONE_ICON,
    MULTI_CELL_PHONE_ICON,
    STORE_ICON,
    TICKET_ICON,
    PRINT_ICON,
    STATISTIC_ICON,
} from "../../assets/icons";
import { usePermissions } from "../../custom-hooks/use-permissions";
import ReloadAnimation from "../../components/ReloadAnimation";
import { ArrowBackIos, DownloadForOffline } from "@mui/icons-material";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import { useForm } from "react-hook-form";

import { useContext, useState } from "react";
import {
    FormHelperText,
    InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Controller, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarMonth } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { BeverageBodyParam, BeverageItemType, EkaSearchItem, FormattedEkaItemType, SearchPeriod } from "../../types";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { useBeverageApi } from "../../custom-hooks/apis/use-beverage-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Papa from "papaparse";
import * as Yup from "yup";
import "dayjs/locale/ja";
import FilterDatePicker from "../../components/Inputs/FilterDatePicker";
import moment from "moment";
import dayjs from "dayjs";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";
import DataTabs from "./DataTabs";
import EkaData from "../EkaData"
import BeverageData from "../BeverageData";
import { EkaItemType } from "../../types";

const Data = () => {
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();
    const [selectedTab, setSelectedTab] = useState<number>(0);


    const [BeverageList, setBeverageList] = useState<BeverageItemType[] | null>(null);
    const [BeverageSearchPeriod, setBeverageSearchPeriod] = useState<SearchPeriod>({
        start: "",
        end: ''
    })

    const [EkaList, setEkaList] = useState<EkaItemType[] | null>(null);
    const [EkaSearchItem, setEkaSearchItem] = useState<EkaSearchItem>({
        ekaId: "",
        start: "",
        end: ''
    })

    const menuItems = [
        {
            title: "飲料データ閲覧",
            icon: (
                <img
                    src={STATISTIC_ICON}
                    style={{ height: "40px", maxWidth: "40px" }}
                />
            ),
            isDisabled: false,
            handleClick: () => {
                navigate(`/store/beverage`);
            },
        },
        {
            title: "Ekaデータ閲覧",
            icon: (
                <img
                    src={STATISTIC_ICON}
                    style={{ height: "40px", maxWidth: "40px" }}
                />
            ),
            isDisabled: false,
            handleClick: () => {
                navigate(`/store/eka-data`);
            },
        },
    ];

    const authCtx = useContext(AuthContext);

    const defaultValues = {
        start: null,
        end: null,
    };

    const schema = Yup.object().shape({
        start: Yup.date()
            .required("開始日が入力されていません")
            .nullable()
            .max(Yup.ref("end"), "開始日は終了日より前でなければなりません"),
        end: Yup.date()
            .required("終了日が入力されていません")
            .nullable()
            .min(Yup.ref("start"), "終了日は開始日以降でなければなりません")
            .test("is-between", "検索可能な期間は2ヶ月以内です", function (value) {
                const start = moment(this.parent.start);
                const end = moment(value);
                let diff = end.diff(start, "months");
                return diff <= 2;
            }),
    });


    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });
    const formValues = watch();

    const { i18n } = useTranslation();

    const convertJsonToCsv = (jsonData: (BeverageItemType | FormattedEkaItemType)[]) => {
        const csv = Papa.unparse(jsonData);
        return csv;
    };

    const downloadCsv = (jsonData: (BeverageItemType | FormattedEkaItemType)[], filename: string) => {
        const csv = convertJsonToCsv(jsonData);
        const chr = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([chr, csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, filename);
    };

    const handleDownload = () => {
        if (selectedTab === 0 && BeverageList && BeverageList.length > 0) {
            downloadCsv(BeverageList, uuidv4() + ".csv");
        } else if (selectedTab === 1 && EkaList && EkaList.length > 0) {
            const formattedObjects = EkaList.map(obj => ({
                "チケット名": obj.ticketName,
                "連番": obj.issuedEkaSerial,
                "生成日時": `${moment.unix(obj.createdAt).format("YYYY/MM/DD HH:mm")}`,
                "利用期限": obj.ekaLifespanEnd ? moment.unix(obj.ekaLifespanEnd).format("YYYY/MM/DD HH:mm") : "無制限",
                "チケット販売額": obj.ticketPrice,
                "チケットの最終利用日時": `${moment.unix(obj.lastUpdatedAt).format("YYYY/MM/DD HH:mm")}`,
                "残額・残杯数": obj.ticketType === "all_you_can_drink" ? "" : obj.leftQuantity
            }));

            downloadCsv(formattedObjects, uuidv4() + ".csv");
        } else {
            return
        }
    };

    const isDisabled = () => {
        let flag = true
        if (selectedTab === 0 && BeverageList && BeverageList.length > 0) {
            flag = false
        } else if (selectedTab === 1 && EkaList && EkaList.length > 0) {
            // 伝票番号での検索時は、CSVDLする必要がないため
            // 検索前の検索条件
            // {ekaId: "", start: "", end: ""}
            // 伝票番号での検索時
            // {ekaId: "YYYYMMDD-xxxxx", start: null, end: null}
            // 日付での検索時
            // {ekaId: null, start: "1234567890", end: "1234567890"}
            if (!EkaSearchItem.ekaId) {
                flag = false
            }
        } 

        return flag
    }

    return (
        <ReloadAnimation>
            <Fragment>
                <AppBar
                    elevation={0}
                    position="sticky"
                    color="transparent"
                    sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
                >
                    <Toolbar
                        sx={{
                            width: 1,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid sx={{ minWidth: "64px" }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={() => navigate("/store")}
                            >
                                <ArrowBackIos />
                            </IconButton>
                        </Grid>
                        <Typography
                            variant="h6"
                            component="h1"
                            sx={{
                                color: "#4F4F62",
                                fontWeight: 600,
                                fontSize: "1.2rem",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                            }}
                            align="center"
                        >
                            データ閲覧
                        </Typography>
                        <ButtonSubmit
                            type="button"
                            onClick={handleDownload}
                            disabled={isDisabled()}
                            sx={{ py: "5px", px: "10px", minWidth: "40px", boxShadow: 5 }}
                        >
                            <Grid sx={{ flexDirection: "column" }}>
                                <DownloadForOffline height={30} />
                                <Typography
                                    sx={{
                                        fontSize: "0.45rem",
                                        color: "#fff",
                                        marginTop: "-8px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    CSV
                                </Typography>
                            </Grid>
                        </ButtonSubmit>
                    </Toolbar>
                </AppBar>

                <Grid sx={{ p: 2, my: 1 }}>
                    <DataTabs
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                </Grid>


                {selectedTab === 0 && (
                    <BeverageData
                        list={BeverageList}
                        setList={setBeverageList}
                        searchPeriod={BeverageSearchPeriod}
                        setSearchPeriod={setBeverageSearchPeriod}
                    />
                )}
                {selectedTab === 1 && (
                    <EkaData
                        list={EkaList}
                        setList={setEkaList}
                        searchItem={EkaSearchItem}
                        setSearchItem={setEkaSearchItem}
                    />
                )}

            </Fragment>
        </ReloadAnimation>
    );
}

export default Data;