import AwsApi from "./aws-api";
import {
  CustomerStoreIdType,
  CreateTicketBodyRequestType,
  UpdateTicketParamsType,
  UpdateIssuedTicketLabelType,
  IssuedNewTicket,
} from "../types";

export default class appTicketApi extends AwsApi {
  async getTickets(params: any): Promise<any> {
    return await this.get("store/ticket/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getTicket(params: any): Promise<any> {
    return await this.get(
      "store/ticket/detail/" + process.env.REACT_APP_ENVNAME,
      {
        params,
      }
    );
  }

  async createTicket(
    params: CustomerStoreIdType,
    body: CreateTicketBodyRequestType
  ): Promise<any> {
    return await this.post(
      "store/ticket/" + process.env.REACT_APP_ENVNAME,
      body,
      { params }
    );
  }

  async updateTicket(
    params: UpdateTicketParamsType,
    body: CreateTicketBodyRequestType
  ): Promise<any> {
    return await this.put(
      "store/ticket/" + process.env.REACT_APP_ENVNAME,
      body,
      { params }
    );
  }

  async getIssuedTickets(params: any): Promise<any> {
    return await this.get("store/ticket/" + process.env.REACT_APP_ENVNAME, {
      params,
    });
  }

  async getIssuedTicket(params: any): Promise<any> {
    return await this.get(
      process.env.REACT_APP_ENVNAME + "/customer/store-ticket/printable/detail",
      {
        params,
      }
    );
  }

  async updateIssuedTicketLabel(
    params: UpdateIssuedTicketLabelType
  ): Promise<any> {
    return await this.post(
      process.env.REACT_APP_ENVNAME + "/customer/store-ticket/printable/label",
      params
    );
  }

  async getNewIssuedTicket(params: IssuedNewTicket): Promise<any> {
    return await this.post(
      process.env.REACT_APP_ENVNAME + "/customer/store-ticket/printable/eka",
      params
    );
  }
}
