import { Fragment, useState, useContext } from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Help } from "@mui/icons-material";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useCommonApi } from "../../custom-hooks/apis/use-common-api";
import AuthContext from "../../custom-hooks/use-auth-context";
import DialogContractInfo from "./DialogContractInfo";
import Loader from "../Loader";

interface ContractAppbarProps {
  title: string;
}

const ContractAppbar: React.FC<ContractAppbarProps> = ({ title }) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const authCtx = useContext(AuthContext);
  const { getContract } = useCommonApi();

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.contract, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getContract();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );
  if (isLoading) return <Loader />;

  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
      >
        <Toolbar
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "48px", height: "1px" }}></Box>
          <Box>
            <Typography
              variant="h6"
              component="h1"
              sx={{
                color: "#4F4F62",
                fontWeight: 600,
                fontSize: "1.2rem",
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
              align="center"
            >
              {title}
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setOpenInfo(true)}
          >
            <Help />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContractInfo
        data={data}
        open={openInfo}
        handleClose={() => {
          setOpenInfo(false);
        }}
      />
    </Fragment>
  );
};

export default ContractAppbar;
