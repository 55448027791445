import React from "react";
import {
  AppBar,
  Box,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { PortType } from "../../../types";
import { ArrowBackIos } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { selectedSakeValidation } from "../../../utils/validations";
import Footer from "../../Layout/Footer";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import SakeSelectionItem from "./SakeSelectionItem";
import CustomFormControlLabel from "./CustomFormControlLabel";
import ReloadAnimation from "../../ReloadAnimation";

interface ModalSakeSelectionProps {
  open: boolean;
  preValue: string;
  options: any[];
  setOpen: (value: boolean) => void;
  onSubmit: (data: FieldValues) => void;
  setPort: (value: PortType) => void;
}

const ModalSakeSelection: React.FC<ModalSakeSelectionProps> = ({
  open,
  preValue,
  options,
  setOpen,
  onSubmit,
  setPort,
}) => {
  const schema = Yup.object().shape({
    sake: selectedSakeValidation,
  });

  let defaultValues = {
    sake: preValue,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <Modal
      open={open}
      onClose={() => setOpen(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ pb: 7, backgroundColor: "#E6E4EB" }}>
        <Container
          maxWidth="sm"
          sx={{
            minHeight: `100vh`,
            bgcolor: "#E6E4EB",
            padding: 0,
            position: "relative",
          }}
        >
          <AppBar
            elevation={0}
            position="sticky"
            color="transparent"
            sx={{ bgcolor: "#E6E4EB", width: 1, paddingY: "15px" }}
          >
            <Toolbar
              sx={{
                width: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setOpen(false)}
              >
                <ArrowBackIos />
              </IconButton>
              <Box>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{
                    color: "#4F4F62",
                    fontWeight: 600,
                    fontSize: "1.2rem",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                  align="center"
                >
                  日本酒選択
                </Typography>
              </Box>
              <Box sx={{ width: "36px", height: "1px" }}></Box>
            </Toolbar>
          </AppBar>
          <ReloadAnimation>
            <Box sx={{ position: "relative" }}>
              <Container sx={{ position: "relative" }}>
                {options && options?.length > 0 ? (
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ position: "relative" }}
                  >
                    {!!errors?.sake?.message && (
                      <Grid sx={{ my: 2 }}>
                        <FormHelperText error id="sake_helper">
                          <>{errors?.sake?.message}</>
                        </FormHelperText>
                      </Grid>
                    )}
                    <Paper
                      sx={{
                        py: 2,
                        maxHeight: errors?.sake?.message
                          ? `calc(100vh - 355px)`
                          : `calc(100vh - 270px)`,
                        overflowY: "scroll",
                      }}
                    >
                      <Controller
                        name="sake"
                        control={control}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            {options.map((option: any, index: number) => (
                              <CustomFormControlLabel
                                key={index}
                                value={option?.sake_id}
                                control={<Radio />}
                                onChange={(e: any) => {
                                  field.onChange(e.target.value);
                                  setPort({
                                    product_id: +option?.sake_id,
                                    product_name: option?.product_name || "-",
                                    product_sake_brewer:
                                      option?.sake_brewer || "-",
                                    product_sake_brewery:
                                      option?.sake_brewery || "-",
                                    product_special_designation_sake:
                                      option?.special_designation_sake || "-",
                                  });
                                }}
                                label={
                                  <SakeSelectionItem
                                    title={option?.product_name}
                                    desc={option?.sake_brewer}
                                  />
                                }
                                sx={{
                                  "& .Mui-checked": {
                                    color: "#9f1831",
                                  },
                                }}
                                labelPlacement="start"
                              />
                            ))}
                          </RadioGroup>
                        )}
                      />
                    </Paper>
                    <Grid
                      sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        width: 1,
                        my: 1,
                      }}
                    >
                      <ButtonSubmit
                        type="submit"
                        variant="contained"
                        sx={{ width: 1, py: 1, fontWeight: 600 }}
                      >
                        完了
                      </ButtonSubmit>
                    </Grid>
                  </form>
                ) : (
                  <Box
                    sx={{
                      height: `calc(100vh - 300px)`,
                      marginX: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography align="center">
                        日本酒リストが見つかりません
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Container>
            </Box>
          </ReloadAnimation>
        </Container>
        <Footer />
      </Box>
    </Modal>
  );
};

export default ModalSakeSelection;
