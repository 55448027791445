import React from "react";
import { Box, Paper, Typography } from "@mui/material";

interface StoreMenuItemSmProps {
  title: string;
  icon: JSX.Element;
  isDisabled: boolean;
  handleClick: () => void;
}

const StoreMenuItemSm: React.FC<StoreMenuItemSmProps> = ({
  title,
  icon,
  isDisabled,
  handleClick,
}) => {
  return (
    <Paper
      onClick={isDisabled ? () => {} : handleClick}
      sx={{
        height: "130px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "5px",
        opacity: isDisabled ? 0.65 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </Box>
      <Typography sx={{ color: "#9F1831", fontWeight: 600, fontSize: "1rem" }}>
        {title}
      </Typography>
    </Paper>
  );
};

export default StoreMenuItemSm;
