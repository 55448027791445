import React, { Fragment } from "react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

interface TicketListProps {
  ticket: any;
}

const TicketList: React.FC<TicketListProps> = ({ ticket }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <Paper
      elevation={2}
      sx={{ paddingY: 2, paddingLeft: 2, my: 2 }}
      onClick={() => {
        navigate(`/store/issued-tickets/${ticket?.ticketId}`, {
          state: { ticket: ticket },
        });
      }}
    >
      <Grid container sx={{ display: "flex", width: 1 }}>
        <Grid item xs={11}>
          <Box sx={{}}>
            <Typography
              sx={{
                display: "block",
                fontWeight: 550,
                fontSize: "18px",
                width: 1,
                wordWrap: "break-word",
                wordBreak: "break-all",
              }}
              component="h1"
              variant="h6"
            >
              {ticket?.ticketName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              タイプ : {t(ticket?.ticketType)}
              {ticket?.ticketType === "coupon" &&
                `杯数 : ${ticket?.ticketQuantity}`}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              金額 :{" "}
              {ticket?.ticketPrice ? (
                <Fragment>
                  <CurrencyFormat
                    value={ticket?.ticketPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  円
                </Fragment>
              ) : (
                "-"
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              有効期限 :{" "}
              {ticket?.ticketLifespanType === 0 && (
                <>
                  決済完了後{ticket?.ticketLifespanUnitNum}
                  {ticket?.ticketLifespanUnitType === 0 && <>年間</>}
                  {ticket?.ticketLifespanUnitType === 1 && <>か月間</>}
                  {ticket?.ticketLifespanUnitType === 2 && <>日間</>}
                  {ticket?.ticketLifespanUnitType === 3 && <>時間</>}
                  {ticket?.ticketLifespanUnitType === 4 && <>分間</>}
                </>
              )}
              {ticket?.ticketLifespanType === 1 &&
                ticket.ticketLifespanStart &&
                ticket.ticketLifespanEnd && (
                  <>
                    {moment
                      .unix(ticket.ticketLifespanStart)
                      .format("YYYY/MM/DD")}{" "}
                    ~{" "}
                    {moment.unix(ticket.ticketLifespanEnd).format("YYYY/MM/DD")}
                  </>
                )}
              {ticket?.ticketLifespanType === 3 && <>期限なし</>}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {}}
          >
            <ArrowForwardIos
              sx={{ fontSize: "1rem !important" }}
              fontSize="small"
            />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TicketList;
