import { Fragment, useContext } from "react";
import { Paper, Typography } from "@mui/material";
import { useBillingApi } from "../../custom-hooks/apis/use-billing-api";
import { QueryKeys } from "../../global-state/react-query-keys";
import { useQuery } from "react-query";
import { InvoiceType } from "../../types";
import Loader from "../../components/Loader";
import BillingReceiptItem from "./BillingReceiptItem";
import AuthContext from "../../custom-hooks/use-auth-context";
import ErrorHandler from "../../components/ErrorHandler";

const BillingReceipt = () => {
  const { getInvoices } = useBillingApi();
  const authCtx = useContext(AuthContext);

  const { isLoading, data, isError } = useQuery(
    [QueryKeys.invoices, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getInvoices();
      return;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  if (isError) return <ErrorHandler url={"/billing"} />;
  if (isLoading) return <Loader />;
  return (
    <Paper sx={{ padding: 2, marginX: 2, pb: 9 }}>
      {data?.invoices && data?.invoices?.length > 0 ? (
        <>
          {data?.invoices?.map((item: InvoiceType, index: number) => (
            <Fragment key={index}>
              <BillingReceiptItem item={item} />
            </Fragment>
          ))}
        </>
      ) : (
        <Typography sx={{ fontSize: "0.8rem" }}>請求書はありません</Typography>
      )}
    </Paper>
  );
};

export default BillingReceipt;
